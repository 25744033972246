<template>
  <div class="flex flex-wrap">
    <!-- <div ref="cursor" class="bg-red-500 w-[10px] h-[10px] fixed z-10">&nbsp;</div> -->
    <div class="flex justify-between">
      <router-link
        to="/admin"
        class="shadow-lg rounded-lg bg-white px-3 pb-2 pt-3"
      >
        Back to Admin
      </router-link>
    </div>
    <div class="w-full">
      <div class="flex mt-3">
        <div
          v-for="column in columns"
          :key="column.name"
          :class="{ 'w-full expanded': column.expanded }"
          class="mr-5"
        >
          <h3 class="text-2xl">{{ column.name }}</h3>
          <assembly-line-collaboration-request-card
            v-for="item in items[column.name].collaborationRequests"
            :key="item.hashedId"
            :collaboration-request="item"
            :column="column"
            :roles="roles"
            @reload="getCollaborationRequests()"
            @select-collaboration-request="selectCollaborationRequest(item)"
          />
          <assembly-line-work-card
            v-for="item in items[column.name].projects"
            :key="item.hashedId"
            :work="item"
            :column="column"
            :roles="roles"
            @reload="getWork()"
            @select-work="selectWork(item)"
          />
        </div>
      </div>
    </div>
    <assembly-line-collaboration-request-modal
      ref="collaborationRequestModal"
      :collaboration-request="selectedCollaborationRequest"
    />
    <assembly-line-work-modal ref="workModal" :work="selectedWork" />
  </div>
</template>

<script>
import Spaces from "@ably/spaces";
import { utils } from "common-frontend";
import AssemblyLineCollaborationRequestCard from "../../components/AssemblyLine/CollaborationRequestCard.vue";
import AssemblyLineWorkCard from "../../components/AssemblyLine/WorkCard.vue";
import AssemblyLineCollaborationRequestModal from "../../components/AssemblyLine/CollaborationRequestModal.vue";
import AssemblyLineWorkModal from "../../components/AssemblyLine/WorkModal.vue";

const { keysToCamel, keysToSnake, loadEnums, addRequest, removeRequest } =
  utils;

const handleMouseMove = ({ clientX, clientY }) => {
  this.space.cursors.set({
    position: { x: clientX, y: clientY },
    data: { color: "red" },
  });
};

export default {
  name: "AssemblyLineDashboard",
  components: {
    AssemblyLineCollaborationRequestCard,
    AssemblyLineWorkCard,
    AssemblyLineCollaborationRequestModal,
    AssemblyLineWorkModal,
  },
  data() {
    return {
      spaces: new Spaces(this.$ably),
      space: null,
      activeRequests: [],
      enums: {},
      selectedCollaborationRequest: {},
      selectedWork: {},
      roles: [],
      collaborationRequests: {
        loading: false,
        results: [],
        sort: [{ created_at: "desc" }],
        filters: {
          "collaborationSpace.isTestAccount": false,
        },
        offset: 0,
        limit: 100,
        totalCount: 0,
        metadata: {},
      },
      work: {
        loading: false,
        results: [],
        sort: [{ created_at: "desc" }],
        filters: {
          "collaborationSpace.isTestAccount": false,
          parentWorkId: null,
        },
        offset: 0,
        limit: 100,
        totalCount: 0,
        metadata: {},
      },
      columns: [
        {
          name: "Draft",
          expanded: false,
          category: "Collaboration Requests",
          filterFunction: (collaborationRequests) => {
            return {
              collaborationRequests: collaborationRequests.filter(
                (collaborationRequest) => {
                  return collaborationRequest.status.value === "DRAFT";
                },
              ),
              projects: [],
            };
          },
        },
        {
          name: "Sourcing",
          expanded: true,
          category: "Collaboration Requests",
          filterFunction: (collaborationRequests) => {
            return {
              collaborationRequests: collaborationRequests.filter(
                (collaborationRequest) => {
                  return (
                    collaborationRequest.status.value === "SOURCING_CANDIDATES"
                  );
                },
              ),
              projects: [],
            };
          },
        },
        {
          name: "Client Review",
          expanded: true,
          category: "Collaboration Requests",
          filterFunction: (collaborationRequests) => {
            return {
              collaborationRequests: collaborationRequests.filter(
                (collaborationRequest) => {
                  return collaborationRequest.status.value === "CLIENT_REVIEW";
                },
              ),
              projects: [],
            };
          },
        },
        {
          name: "Underway",
          expanded: true,
          category: "Projects",
          filterFunction: (collaborationRequests, work) => {
            return {
              collaborationRequests: [],
              projects: work.filter((w) => {
                return ["NEW", "UNDERWAY"].indexOf(w?.status?.value) > -1;
              }),
            };
          },
        },
        {
          name: "Completed",
          expanded: false,
          category: "Projects",
          filterFunction: (collaborationRequests, work) => {
            return {
              collaborationRequests: [],
              projects: work.filter((w) => {
                return w?.status?.value === "COMPLETED";
              }),
            };
          },
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.activeRequests.length > 0;
    },
    items() {
      const results = {};
      const self = this;
      this.columns.forEach((column) => {
        results[column.name] = column.filterFunction(
          self.collaborationRequests?.results,
          self.work?.results,
        );
      });
      return results;
    },
  },
  async mounted() {
    this.getCollaborationRequests();
    this.getWork();
    this.getRoles();
    loadEnums(this, {
      CompanySize: "companySizeOptions",
    });
    this.space = await this.spaces.get("assembly-line");
    await this.space.enter({
      username: "Cory",
    });
    window.addEventListener("mousemove", handleMouseMove);
    this.space.cursors.subscribe("update", () => {
      // console.log(cursorUpdate, self.$refs.cursor[0]);
      // self.$refs.cursor.style.top = cursorUpdate.position.y + 'px';
      // self.$refs.cursor.style.left = cursorUpdate.position.x + 'px';
    });
  },
  beforeUnmount() {
    window.removeEventListener("mousemove", handleMouseMove);
  },
  methods: {
    getCollaborationRequests(
      limit = this.collaborationRequests.limit,
      offset = this.collaborationRequests.offset,
      sort = this.collaborationRequests.sort,
      filters = this.collaborationRequests.filters,
    ) {
      const key = addRequest(this, "Getting collaboration requests...");
      this.collaborationRequests.loading = true;
      this.$api.axiosThrottled
        .get(`/work/request/`, {
          params: keysToSnake({
            limit,
            offset,
            sort: JSON.stringify(keysToSnake(sort)),
            filters: JSON.stringify(keysToSnake(filters)),
          }),
        })
        .then((resp) => {
          removeRequest(this, key);
          this.collaborationRequests = keysToCamel(resp.data);
        })
        .catch(() => removeRequest(this, key, true));
    },
    getWork(
      limit = this.work.limit,
      offset = this.work.offset,
      sort = this.work.sort,
      filters = this.work.filters,
    ) {
      const key = addRequest(this, "Getting work...");
      this.work.loading = true;
      this.$api.axiosThrottled
        .get(`/work/`, {
          params: keysToSnake({
            limit,
            offset,
            sort: JSON.stringify(keysToSnake(sort)),
            filters: JSON.stringify(keysToSnake(filters)),
          }),
        })
        .then((resp) => {
          removeRequest(this, key);
          this.work = keysToCamel(resp.data);
        })
        .catch(() => removeRequest(this, key, true));
    },
    getRoles() {
      const key = addRequest(this, "Roles...");
      this.$api.axiosThrottled
        .get(`/role/`, {
          params: keysToSnake({
            limit: 100,
            offset: 0,
          }),
        })
        .then((resp) => {
          removeRequest(this, key);
          this.roles = keysToCamel(resp.data).results;
        })
        .catch(() => removeRequest(this, key, true));
    },
    selectCollaborationRequest(item) {
      this.selectedWork = {};
      this.selectedCollaborationRequest = item;
      this.$refs.workModal.$refs.modal.showModal = false;
      this.$refs.collaborationRequestModal.$refs.modal.showModal = true;
    },
    selectWork(item) {
      this.selectedCollaborationRequest = {};
      this.selectedWork = item;
      this.$refs.collaborationRequestModal.$refs.modal.showModal = false;
      this.$refs.workModal.$refs.modal.showModal = true;
    },
  },
};
</script>
