<template>
  <section class="relative bg-white">
    <div class="container mx-auto">
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div class="mx-10 my-5">
          <div class="bg-orange-200 py-3 px-4">
            <p>
              To copy for use in emails, click and drag inside the gray box and
              select all of the text, which should grab the border, formatting
              and image.
            </p>
            <p class="text-blueGray-500 italic text-sm">
              This is designed to have too much information; please edit down
              the parts that aren't needed after pasting the card.
            </p>
            <p class="text-blueGray-500 italic text-sm">
              It has been tested in modern email clients but results may vary.
            </p>
          </div>

          <div class="bg-blueGray-200 p-5">
            <table
              style="
                border: 1px solid #dddddd;
                width: 450px;
                margin: 0px 50px;
                background: white;
              "
            >
              <tr style="border: none">
                <td
                  style="
                    border: none;
                    padding: 10px;
                    vertical-align: top;
                    width: 120px;
                  "
                >
                  <div
                    :style="`background-size: cover; border-radius: 50%; background-image: url(${avatarUrl(
                      profile,
                    )}); width: 100px; height: 100px; border: 3px solid white; box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);`"
                  ></div>
                </td>
                <td style="padding: 10px; vertical-align: top; border: none">
                  <p style="font-weight: normal; font-size: 18px; border: none">
                    {{ profile.fullDisplayName }}
                  </p>
                  <p
                    style="
                      font-weight: normal;
                      font-size: 12px;
                      margin-bottom: 10px;
                      border: none;
                    "
                  >
                    <span style="border: none"
                      ><a
                        style="text-decoration: underline; border: none"
                        :href="externalLink"
                        >View Full Public Profile & Publications</a
                      ></span
                    >
                  </p>
                  <ul
                    style="
                      list-style-type: disc;
                      padding-left: 15px;
                      border: none;
                    "
                  >
                    <li
                      v-if="profile.city || profile.state || profile.country"
                      style="font-weight: normal; font-size: 12px; border: none"
                    >
                      <span style="font-weight: bold; border: none"
                        >Location:
                      </span>
                      <span
                        v-if="profile.city && profile.state && profile.country"
                        style="border: none"
                      >
                        {{ profile.city }}, {{ profile.state[0] }},
                        {{ profile.country[0] }}
                      </span>
                      <span
                        v-else-if="profile.city && profile.state"
                        style="border: none"
                      >
                        {{ profile.city }}, {{ profile.state[0] }}
                      </span>
                      <span v-else-if="profile.state" style="border: none">
                        {{ profile.state[0] }}
                      </span>
                      <span v-else-if="profile.city" style="border: none">
                        {{ profile.city }}
                      </span>
                    </li>
                    <li
                      style="font-weight: normal; font-size: 12px; border: none"
                    >
                      <span style="font-weight: bold; border: none"
                        >Area of Expertise:
                      </span>
                      <span style="border: none">
                        {{ degrees || topResearchInterests }}
                      </span>
                    </li>
                    <li
                      style="font-weight: normal; font-size: 12px; border: none"
                    >
                      <span style="font-weight: bold; border: none"
                        >Current Occupation:
                      </span>
                      <span style="border: none">
                        {{ profile?.headline }}
                      </span>
                    </li>
                    <li
                      style="font-weight: normal; font-size: 12px; border: none"
                    >
                      <span style="font-weight: bold; border: none"
                        >Affiliated Institution:
                      </span>
                      <span style="border: none">
                        {{ companyNames.join(", ") }}
                      </span>
                    </li>
                    <li
                      style="font-weight: normal; font-size: 12px; border: none"
                    >
                      <span style="font-weight: bold; border: none"
                        >Highlights:
                      </span>
                      <span style="border: none">
                        {{ profile?.about }}
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="px-6 py-6">
          <div class="flex flex-wrap">
            <div class="w-full lg:w-4/12 pr-3">
              <div class="mb-6">
                <profile-image :size="200" :img-url="avatarUrl(profile)" />
              </div>
            </div>
            <div class="w-full lg:w-8/12 px-4">
              <div class="w-full text-left mb-5">
                <h3
                  class="text-3xl font-light leading-normal mb-2 text-mallard-500"
                >
                  {{ profile.fullDisplayName }}
                </h3>
                <h5 v-if="profile.headline" class="text-lg">
                  {{ profile.headline }}
                </h5>
              </div>

              <a
                v-if="cvUrl"
                :href="cvUrl"
                target="_blank"
                class="mb-3 bg-blueGray-400 text-white active:bg-chartreuseYellow-600 text-xs uppercase px-2 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 w-full ease-linear transition-all duration-150 w-auto inline-block"
                >Download CV</a
              >

              <div
                v-if="profile.city || profile.state || profile.country"
                class="flex items-center text-sm leading-normal mt-0 mb-2 text-mallard-500/50"
              >
                <i
                  class="fas fa-fw fa-map-marker-alt mr-2 text-mallard-500/50"
                ></i>
                <div>
                  <span v-if="profile.city && profile.state && profile.country">
                    {{ profile.city }}, {{ profile.state[0] }},
                    {{ profile.country[0] }}
                  </span>
                  <span v-else-if="profile.city && profile.state">
                    {{ profile.city }}, {{ profile.state[0] }}
                  </span>
                  <span v-else-if="profile.state">
                    {{ profile.state[0] }}
                  </span>
                  <span v-else-if="profile.city">
                    {{ profile.city }}
                  </span>
                </div>
              </div>

              <div v-if="profile.researchInterests?.length > 0" class="mt-10">
                <span
                  class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50 font-bold uppercase"
                >
                  Research Expertise ({{ profile.researchInterests.length }})
                </span>
                <div class="w-full">
                  <div class="mt-2">
                    <span
                      v-for="researchInterest in profile.researchInterests"
                      :key="researchInterest.hashedId"
                      class="border bg-mallard-500/10 border-mallard-500/30 px-2 py-1 rounded text-sm mr-2 whitespace-nowrap mb-2 inline-block"
                    >
                      {{ researchInterest.researchInterest }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full">
              <template v-if="profile.about">
                <div
                  class="text-sm leading-normal mt-10 mb-2 text-mallard-500/50 font-bold uppercase"
                >
                  About
                </div>
                <div
                  class="w-full bg-mallard-500/5 py-2 markdown-container text-sm border-l border-mallard-500/30 pl-3"
                >
                  <markdown-viewer
                    v-if="profile.about"
                    :text="profile?.about"
                  />
                </div>
              </template>

              <div
                v-if="profile.experiences?.length > 0"
                class="text-sm leading-normal mt-10 mb-2"
              >
                <div
                  class="text-sm leading-normal mb-2 text-mallard-500/50 font-bold uppercase"
                >
                  Experience
                </div>
                <div>
                  <div
                    v-for="experience in profile.experiences"
                    :key="experience.hashedId"
                    class="mt-2 py-2 bg-mallard-500/5 border-l border-mallard-500/30 pl-3"
                  >
                    <strong class="text-black">{{
                      experience.companyName
                    }}</strong>
                    <div
                      v-for="position in experience.positions"
                      :key="position.hashedId"
                      class="mt-2 py-2 bg-mallard-500/5 border-l border-mallard-500/30 pl-3"
                    >
                      <p v-if="position.positionName">
                        {{ position.positionName }}
                      </p>
                      <p
                        v-if="
                          (position.startMonth && position.startYear) ||
                          (position.endMonth && position.endYear)
                        "
                      >
                        <span v-if="position.startMonth && position.startYear"
                          >{{ position.startMonth[0] }},
                          {{ position.startYear }}</span
                        >
                        &mdash;
                        <span v-if="position.endMonth && position.endYear"
                          >{{ position.endMonth[0] }},
                          {{ position.endYear }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="profile.educations?.length > 0"
                class="text-sm leading-normal mt-10 mb-2"
              >
                <div
                  class="text-sm leading-normal mb-2 text-mallard-500/50 font-bold uppercase"
                >
                  Education
                </div>
                <div>
                  <div
                    v-for="education in profile.educations"
                    :key="education.hashedId"
                    class="mt-2 bg-mallard-500/5 py-2 border-l border-mallard-500/30 pl-3"
                  >
                    <strong class="text-black">{{
                      education.schoolName
                    }}</strong>
                    <p v-if="education.degree || education.specialization">
                      <span v-if="education.degree">{{
                        education.degree
                      }}</span>
                      <span v-if="education.degree && education.specialization"
                        >,
                      </span>
                      <span v-if="education.specialization">{{
                        education.specialization
                      }}</span>
                    </p>
                    <p
                      v-if="
                        education.graduationYear ||
                        education.schoolCity ||
                        education.schoolState ||
                        education.schoolCountry
                      "
                    >
                      <span v-if="education.graduationYear">{{
                        education.graduationYear
                      }}</span>
                      <span
                        v-if="
                          education.graduationYear &&
                          (education.schoolCity ||
                            education.schoolState ||
                            education.schoolCountry)
                        "
                      >
                        &bull;
                      </span>
                      <span
                        v-if="
                          education.schoolCity &&
                          education.schoolState &&
                          education.schoolCountry
                        "
                      >
                        {{ education.schoolCity }},
                        {{ education.schoolState[0] }},
                        {{ education.schoolCountry[0] }}
                      </span>
                      <span
                        v-else-if="
                          education.schoolCity && education.schoolState
                        "
                      >
                        {{ education.schoolCity }},
                        {{ education.schoolState[0] }}
                      </span>
                      <span v-else-if="education.schoolState">
                        {{ education.schoolState[0] }}
                      </span>
                      <span v-else-if="education.schoolCity">
                        {{ education.schoolCity }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div
                v-if="profile.publications?.length > 0"
                class="text-sm leading-normal mt-10 mb-2"
              >
                <div
                  class="text-sm leading-normal mb-2 text-mallard-500/50 font-bold uppercase"
                >
                  Publications
                </div>
                <div>
                  <div
                    v-for="publication in profile.publications"
                    :key="publication.hashedId"
                    class="mt-2 bg-mallard-500/5 py-2 border-l border-mallard-500/30 pl-3"
                  >
                    <p class="text-black">
                      <a
                        v-if="publication.url"
                        target="_blank"
                        :href="publication.url"
                        >{{ publication.title }}</a
                      >
                      <span v-else>{{ publication.title }}</span>
                    </p>
                    <p class="text-black/30">
                      {{
                        publication.adjustedCitation || "No citation available"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { utils } from "common-frontend";
import MarkdownViewer from "../MarkdownViewer.vue";
import ProfileImage from "../ProfileImage.vue";

const { avatarUrl, keysToCamel, keysToSnake } = utils;

export default {
  components: {
    ProfileImage,
    MarkdownViewer,
  },
  props: {
    hashedId: String,
  },
  data() {
    return {
      profile: {},
      visitorType: null,
      educationExpanded: false,
      experienceExpanded: false,
      avatarUrl,
    };
  },
  computed: {
    companyNames() {
      if (this.profile?.experiences) {
        const experiences = this.profile.experiences.map((experience) => {
          return experience.companyName;
        });
        return [...new Set(experiences)];
      }
      return [];
    },
    schoolNames() {
      if (this.profile?.educations) {
        const educations = this.profile.educations.map((education) => {
          return education.schoolName;
        });
        return [...new Set(educations)];
      }
      return [];
    },
    journalNames() {
      if (this.profile?.publications) {
        const publications = this.profile.publications.map((publication) => {
          return publication.containerTitle;
        });
        return [...new Set(publications)];
      }
      return [];
    },
    cvUrl() {
      if (this.profile?.cvHash) {
        return `${import.meta.env.VITE_API_BASE_URL}/file/${
          this.profile.cvHash
        }/content/`;
      }
      return "";
    },
    externalLink() {
      if (this.profile?.slug) {
        return `${import.meta.env.VITE_CV_BASE_URL}/me/${this.profile.slug}`;
      }
      return `${import.meta.env.VITE_CV_BASE_URL}/me/${this.profile?.hashedId}`;
    },
    degrees() {
      let degreeString = "";
      this.profile?.educations?.forEach((education) => {
        if (education.degree && education.specialization) {
          degreeString += `${education.degree}, ${education.specialization}`;
        }
      });
      return degreeString;
    },
    topResearchInterests() {
      const researchInterestStrings = [];
      this.profile?.researchInterests?.forEach((researchInterest) => {
        researchInterestStrings.push(researchInterest.researchInterest);
      });
      return researchInterestStrings.slice(0, 10).join(", ");
    },
  },
  mounted() {
    this.loadProfileDetail();
  },
  methods: {
    loadProfileDetail() {
      this.$api.axios.get(`/person/profile/${this.hashedId}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.profile = data.result;
        this.visitorType = data.visitorType;
        this.recordProfileView(this.visitorType);
      });
    },
    recordProfileView(viewType) {
      const data = {
        profileId: this.hashedId,
        viewerId: this.$store.getters.person.hashedId,
        visitorType: viewType,
        url: window.location.href,
        referer: window.document.referrer,
        userAgent: window.navigator.userAgent,
      };
      this.$api.axios.post(`/record-profile-view/`, keysToSnake(data));
    },
    changeConnections() {
      this.$emit("changeConnections");
    },
  },
};
</script>
