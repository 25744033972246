<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <h4
          class="bg-blueGray-500 text-white text-xl font-light px-6 pt-3 pb-2 rounded-t-lg"
        >
          Log in
        </h4>
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-white/75 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-6">
            <div class="relative w-full mb-3">
              <div v-if="errorMessage">
                <p class="text-sm text-red-500">{{ errorMessage }}</p>
              </div>
            </div>

            <div v-if="!loggingIn" class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-chartreuseYellow-600 text-sm uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                @click="samlLogin()"
              >
                <span>Log in</span>
              </button>
            </div>

            <div v-if="loggingIn" class="text-center mt-6">
              <p class="text-sm">Success! Logging you in...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { utils } from "common-frontend";

const { isValidAccessToken } = utils;

export default {
  data() {
    return {
      loggingIn: false,
      errorMessage: null,
      r: this.$route.query?.r,
    };
  },
  mounted() {
    if (this.$route.query?.saml && this.$route.query?.saml === "success") {
      this.loggingIn = true;
      this.$api.attemptAccessTokenRefresh(true).then(() => {
        this.$router.push("/admin");
      });
    } else {
      this.checkAccessToken();
    }
  },
  methods: {
    checkAccessToken() {
      const { accessToken } = this.$store.state;
      if (isValidAccessToken(accessToken)) {
        if (this.r) {
          this.$router.push(this.r);
        } else {
          this.$router.push("/admin");
        }
      }
    },
    samlLogin() {
      const baseUrl = import.meta.env.VITE_API_BASE_URL.replace("/api/v0", "");
      window.location.href = `${baseUrl}/saml/`;
    },
  },
};
</script>
