<template>
  <article
    class="search-result border border-blueGray-300 bg-white rounded-lg shadow mb-3 cursor-pointer overflow-hidden flex"
    @click="openProfileDetailModal()"
  >
    <div class="flex px-5 py-2 w-3/4">
      <div class="w-auto py-3">
        <profile-image
          class="mx-auto"
          :size="150"
          :img-url="imageSrc(result)"
        />
      </div>
      <div class="ml-5 w-full">
        <div class="my-2 flex w-full justify-end text-blueGray-400">
          <div class="flex justify-between text-sm">
            <div class="text-sm">
              Last Login
              <moment-from-now
                v-if="result.last_login_at * 1000"
                :title="$dayjs.unix(result.last_login_at).format('MM/DD/YYYY')"
                :value="$dayjs.unix(result.last_login_at).format('MM/DD/YYYY')"
                :interval="5000"
                :tag="'span'"
                class=""
              />
            </div>
            <div class="mx-3">&bull;</div>
            <div class="text-sm">
              {{ result.profile_completeness[0] }}% Complete
            </div>
            <div class="mx-3">&bull;</div>
            <div class="text-sm">
              {{ result.publication_count[0] }} Publications
            </div>
          </div>
        </div>
        <div>
          <h1
            class="text-xl highlighted-sentence"
            v-html="highlightedName()"
          ></h1>
          <h2 class="text-lg italic">{{ result.headline }}</h2>
        </div>
        <template v-if="result.about">
          <p class="form-label mt-3">About</p>
          <div :class="{ 'feathered-bottom': result.about.length > 200 }">
            <div class="text-sm" v-html="result.about"></div>
          </div>
        </template>
        <template v-if="result.educations">
          <p class="form-label mt-3">Education</p>
          <div
            :class="{
              'feathered-bottom':
                Array.isArray(result.educations) &&
                result.educations.length > 5,
            }"
          >
            <ul class="list-disc ml-4 text-sm">
              <template v-if="Array.isArray(result.educations)">
                <li
                  v-for="education in result.educations"
                  :key="education.hashedId"
                >
                  <span v-if="education.degree && education.degree.length"
                    >{{ education.degree }},
                  </span>
                  <span v-if="education.school_name"
                    >{{ education.school_name }},
                  </span>
                  <span v-if="education.graduation_year">{{
                    education.graduation_year
                  }}</span>
                </li>
              </template>
              <template v-else>
                <li>
                  <span
                    v-if="
                      result.educations.degree &&
                      result.educations.degree.length
                    "
                    >{{ result.educations.degree }},
                  </span>
                  <span v-if="result.educations.school_name"
                    >{{ result.educations.school_name }},
                  </span>
                  <span v-if="result.educations.graduation_year">{{
                    result.educations.graduation_year
                  }}</span>
                </li>
              </template>
            </ul>
          </div>
          <span
            v-if="
              Array.isArray(result.educations) && result.educations.length > 5
            "
            class="bg-blueGray-500 text-white rounded-lg text-xs px-1 float-right -mt-[25px] mr-3 z-10 relative"
            >+ {{ result.educations.length - 5 }} more</span
          >
        </template>
        <template v-if="result.experiences">
          <p class="form-label mt-3">Experience</p>
          <div
            :class="{
              'feathered-bottom':
                Array.isArray(result.experiences) &&
                result.experiences.length > 5,
            }"
          >
            <ul class="list-disc ml-4 text-sm">
              <template v-if="Array.isArray(result.experiences)">
                <li
                  v-for="experience in result.experiences"
                  :key="experience.hashedId"
                >
                  <span v-if="experience.company_name">{{
                    experience.company_name
                  }}</span>
                </li>
              </template>
              <template v-else>
                <li>
                  <span v-if="result.experiences.company_name">{{
                    result.experiences.company_name
                  }}</span>
                </li>
              </template>
            </ul>
          </div>
          <span
            v-if="
              Array.isArray(result.experiences) && result.experiences.length > 5
            "
            class="bg-blueGray-500 text-white rounded-lg text-xs px-1 float-right -mt-[25px] mr-3 z-10 relative"
            >+ {{ result.experiences.length - 5 }} more</span
          >
        </template>
        <template v-if="result['research_interests.research_interest']">
          <p class="form-label mt-3">Research Expertise</p>
          <div
            :class="{
              'feathered-bottom':
                Array.isArray(result['research_interests.research_interest']) &&
                result['research_interests.research_interest'].length > 5,
            }"
          >
            <template
              v-if="
                Array.isArray(result['research_interests.research_interest'])
              "
            >
              <span
                v-for="researchInterest in result[
                  'research_interests.research_interest'
                ]"
                :key="researchInterest.hashedId"
                class="bg-blueGray-200 inline-block rounded-lg px-3 py-1 mr-2 mb-2 text-sm"
              >
                {{ researchInterest }}
              </span>
            </template>
            <template v-else>
              <span
                class="bg-blueGray-200 inline-block rounded-lg px-3 py-1 mr-2 mb-2 text-sm"
              >
                {{ result["research_interests.research_interest"] }}
              </span>
            </template>
          </div>
          <span
            v-if="
              Array.isArray(result['research_interests.research_interest']) &&
              result['research_interests.research_interest'].length > 5
            "
            class="bg-blueGray-500 text-white rounded-lg text-xs px-1 float-right -mt-[25px] mr-3 z-10 relative"
            >+
            {{ result["research_interests.research_interest"].length - 5 }}
            more</span
          >
        </template>
        <template v-if="result.publications">
          <p class="form-label mt-3">Publication Journal Titles</p>
          <div
            :class="{
              'feathered-bottom':
                Array.isArray(result.publications) &&
                result.publications.length > 3,
            }"
          >
            <ul class="list-disc ml-4 text-sm">
              <li
                v-for="count in distinctJournalList(
                  result.publications.map((item) => {
                    return item.container_title;
                  }),
                )"
                :key="count"
              >
                {{ count[0][0] }} ({{ count[0][1] }} article<span
                  v-if="count[0][1] != 1"
                  >s</span
                >)
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
    <div class="bg-porcelain px-5 py-3 w-1/4">
      <div v-if="score" class="flex flex-col items-end">
        <p class="text-xs text-blueGray-400">Relevance Score</p>
        <div>
          <pre class="bg-ceruleanBlue text-white px-2 py-1">{{
            Math.floor(score * 100)
          }}</pre>
          <p class="text-right">
            <span class="text-xs">/ {{ Math.floor(maxScore * 100) }}</span>
          </p>
        </div>
      </div>
      <div>
        <p v-if="highlight" class="form-label mt-3">Why this result?</p>
        <div v-for="(list, title) in highlight" :key="title">
          <div>
            <p class="form-label mt-3 text-xs">
              {{ title.split(".")[0].replaceAll("_", " ") }}
            </p>
          </div>
          <ul class="list-disc ml-5">
            <li v-for="item in list" :key="item" class="-pl-1 text-xs">
              <p class="highlighted-sentence" v-html="item"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import ProfileImage from "../ProfileImage.vue";
import MomentFromNow from "../MomentFromNow.vue";

export default {
  components: {
    ProfileImage,
    MomentFromNow,
  },
  props: {
    hashedId: String,
    score: Number,
    maxScore: Number,
    highlight: Object,
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileDetailHashedId: this.hashedId,
    };
  },
  methods: {
    openProfileDetailModal() {
      this.$emit("openProfileDetailModal", this.hashedId);
    },
    imageSrc(result) {
      if (result?.image_hash) {
        return `${import.meta.env.VITE_API_BASE_URL}/file/${
          result.image_hash
        }/content/`;
      }
      if (result && result.first_name && result.last_name) {
        return `${import.meta.env.VITE_API_BASE_URL}/avatar/${
          result.first_name
        }%20${result.last_name}/`;
      }
      return `${import.meta.env.VITE_API_BASE_URL}/avatar/noted%20source/`;
    },
    externalLink(result) {
      if (result.slug) {
        return `${import.meta.env.VITE_CV_BASE_URL}/me/${result.slug}`;
      }
      return `${import.meta.env.VITE_CV_BASE_URL}/me/${result.id}`;
    },
    distinctJournalList(overallList) {
      const filteredList = {};
      const journalsByCount = {};
      overallList.forEach((item) => {
        if (item) {
          if (Object.keys(filteredList).indexOf(item) === -1) {
            filteredList[item] = 0;
          }
          filteredList[item]++;
        }
      });
      Object.entries(filteredList).forEach((key, value) => {
        if (Object.keys(journalsByCount).indexOf(value) === -1) {
          journalsByCount[value] = [];
        }
        journalsByCount[value].push(key);
      });
      return journalsByCount;
    },
    highlightedName() {
      if (
        this.highlight &&
        Object.keys(this.highlight).indexOf("first_name") > -1 &&
        Object.keys(this.highlight).indexOf("last_name") > -1
      ) {
        return `<em>${this.result.first_name} ${this.result.last_name}</em>`;
      }
      if (
        this.highlight &&
        Object.keys(this.highlight).indexOf("first_name") > -1
      ) {
        return `<em>${this.result.first_name}</em> ${this.result.last_name}`;
      }
      if (
        this.highlight &&
        Object.keys(this.highlight).indexOf("last_name") > -1
      ) {
        return `${this.result.first_name} <em>${this.result.last_name}</em>`;
      }
      return `${this.result.first_name} ${this.result.last_name}`;
    },
  },
};
</script>

<style lang="scss">
.highlighted-sentence em {
  font-style: normal;
  background-color: #d3f52e;
  position: relative;
  width: fit-content;
}
</style>
