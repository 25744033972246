<template>
  <div class="flex items-start justify-between px-3 py-2 mb-2">
    <div v-if="Object.keys(project).length > 0" class="w-full mr-3">
      <label class="flex">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
          >Name</span
        >
        <div class="w-full md:w-5/6">
          <textarea
            v-model="project.title"
            class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
            rows="2"
          ></textarea>
        </div>
      </label>
      <label class="flex">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
          >Description</span
        >
        <div class="w-full md:w-5/6">
          <textarea
            v-model="project.description"
            class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
            rows="10"
          ></textarea>
        </div>
      </label>
      <label class="flex">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
          >Milestones</span
        >
        <div class="w-full md:w-5/6">
          <div
            v-for="milestone in project.milestones"
            :key="milestone.hashedId"
          >
            <demo-milestone
              :project="project"
              :enums="enums"
              :active-requests="activeRequests"
              :milestone="milestone"
            />
          </div>
        </div>
      </label>
    </div>
    <div class="text-right grow">
      <button @click="$emit('generateCollaborationRequestContent')">
        <i class="fa-solid fa-rotate" :class="{ 'fa-spin': loading }"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import DemoMilestone from "./Milestone.vue";

const { keysToCamel, askQuestion } = utils;

export default {
  components: {
    DemoMilestone,
  },
  props: {
    enums: Object,
    activeRequests: Array,
    companySize: String,
    companyStage: String,
    industry: String,
    jobFunction: String,
    project: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.fillInTheGaps();
  },
  methods: {
    fillInTheGaps() {
      if (Object.keys(this.project).indexOf("milestones") === -1) {
        this.generateMilestones();
      }
      if (Object.keys(this.project).indexOf("messageThreads") === -1) {
        this.generateMessageThreads();
      }
    },
    generateMilestones() {
      askQuestion(
        this,
        "Generating project milestones...",
        `What are three milestones for a research project titled "${this.project.title}" and two deliverables for each milestone? Please answer in the form [milestone] - [deliverable 1; deliverable 2]`,
      ).then((resp) => {
        const data = keysToCamel(resp.data);
        this.project.milestones = data.answer
          .trim()
          .replaceAll("\n\n", "\n")
          .split("\n")
          .map((milestone) => {
            let milestone_ = milestone;
            if (milestone[0] === "-") {
              milestone_ = milestone.replace("-", "").trim();
            }
            const contents = milestone_.split(" - ");
            return {
              title: contents[0]
                .replaceAll("•", "")
                .replaceAll(/^\d+\s*[-\\.)]?\s+/gi, "")
                .trim(),
              deliverables: contents[1]
                .replaceAll("Deliverable:", "")
                .trim()
                .replace("[", "")
                .replace("]", "")
                .split("; ")
                .map((item, index) => {
                  return { index, title: item };
                }),
            };
          });
      });
    },
    generateMessageThreads() {
      this.project.messageThreads = [
        {
          subject: "Introduction",
          messages: [],
        },
      ];
    },
  },
};
</script>
