<template>
  <div class="flex flex-wrap">
    <div class="px-4 w-full">
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div class="rounded-t mb-0 pb-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="text-3xl text-blueGray-700 font-light mt-4">
                Dashboard
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 w-full">
      <div class="w-full rounded-lg bg-white px-4 pt-4 pb-3 mb-5 shadow-lg">
        <div class="justify-between">
          <iframe
            v-if="iframeUrl"
            :src="iframeUrl"
            frameborder="0"
            class="w-full"
            height="1200"
            allowtransparency
          ></iframe>
        </div>
      </div>
    </div>
    <div class="px-4 w-full">
      <div class="w-full rounded-lg bg-white px-4 pt-4 pb-3 mb-5 shadow-lg">
        <div class="justify-between">
          <!-- Daily Active Users -->
          <iframe
            class="w-full"
            height="null"
            src="https://lookerstudio.google.com/embed/reporting/f812ee2c-f9a1-4e31-b75e-207036db034a/page/J8XaD"
            frameborder="0"
            style="border: 0; aspect-ratio: 2 / 1"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="w-full rounded-lg bg-white px-4 pt-4 pb-3 mb-5 shadow-lg">
        <div class="justify-between">
          <!-- Research Communities -->
          <iframe
            class="w-full"
            height="null"
            src="https://lookerstudio.google.com/embed/reporting/23d7d64a-68b4-4248-9f3a-327f002bc060/page/cC5aD"
            frameborder="0"
            style="border: 0; aspect-ratio: 1.1 / 1"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="w-full rounded-lg bg-white px-4 pt-4 pb-3 mb-5 shadow-lg">
        <div class="justify-between">
          <!-- Google Analytics -->
          <iframe
            class="w-full"
            height="null"
            src="https://lookerstudio.google.com/embed/reporting/c6775903-22ad-43ae-af92-16850b348188/page/1M"
            frameborder="0"
            style="border: 0; aspect-ratio: 0.7 / 1"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div class="w-full rounded-lg bg-white px-4 pt-4 pb-3 mb-5 shadow-lg">
        <div class="justify-between">
          <!-- Google Search Console -->
          <iframe
            class="w-full"
            height="null"
            src="https://lookerstudio.google.com/embed/reporting/bdca6d67-2b34-4ccd-bb2f-7fdf77a1d7dc/page/6zXD"
            frameborder="0"
            style="border: 0; aspect-ratio: 1.25 / 1"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iframeUrl: null,
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
    };
  },
  mounted() {
    this.getIframeUrl();
  },
  methods: {
    getIframeUrl() {
      this.$api.axios.get(`${this.apiBaseUrl}/metabase-url/`).then((res) => {
        this.iframeUrl = res.data.url;
      });
    },
  },
};
</script>
