<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Research Communities
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "Roles",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "research community",
      pluralLabel: "research communities",
      adminPrefix: "research-communities",
      apiPrefix: "research-community",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Research Community ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">rc-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "rc-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        slug: {
          type: "string",
          label: "Slug",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        botName: {
          type: "string",
          label: "Bot Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      createForm: {
        name: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter slug",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        botName: {
          label: "Bot Name:",
          placeholder: "Name for bot in this community",
          type: "string",
          default: "",
          value: "",
          validatorTypes: [],
        },
        digestSendHour: {
          label: "Digest Send Hour (UTC):",
          placeholder: "Hour when the community digest should be sent",
          type: "integer",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        isDiscoverable: {
          label: "Discoverable:",
          checkboxLabel:
            'This should be visible on the "View All" page for logged-in users.',
          type: "boolean",
          value: "",
          default: true,
          validatorTypes: [],
        },
        autonotify: {
          label: "Autonotify:",
          checkboxLabel:
            "The leader(s) should be notified on every new post or reply.",
          type: "boolean",
          value: "",
          default: true,
          validatorTypes: [],
        },
        jobSearchText: {
          label: "Pre-populated text in job search box:",
          placeholder: "Enter text",
          type: "string",
          value: "",
          default: "",
          validatorTypes: [],
        },
        description: {
          label: "Description:",
          placeholder: "Enter description",
          type: "text",
          value: "",
          default: "",
          validatorTypes: [],
        },
        welcomeMessage: {
          label: "Welcome Message:",
          placeholder: "Enter welcome message",
          type: "text",
          value: "",
          default: "",
          validatorTypes: [],
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter slug",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        botName: {
          label: "Bot Name:",
          placeholder: "Name for bot in this community",
          type: "string",
          default: "",
          value: "",
          validatorTypes: [],
        },
        digestSendHour: {
          label: "Digest Send Hour (UTC):",
          placeholder: "Hour when the community digest should be sent",
          type: "integer",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        isDiscoverable: {
          label: "Discoverable:",
          checkboxLabel:
            'This should be visible on the "View All" page for logged-in users.',
          type: "boolean",
          value: "",
          default: true,
          validatorTypes: [],
        },
        autonotify: {
          label: "Autonotify:",
          checkboxLabel:
            "The leader(s) should be notified on every new post or reply.",
          type: "boolean",
          value: "",
          default: true,
          validatorTypes: [],
        },
        jobSearchText: {
          label: "Pre-populated text in job search box:",
          placeholder: "Enter text",
          type: "string",
          value: "",
          default: "",
          validatorTypes: [],
        },
        description: {
          label: "Description:",
          placeholder: "Enter description",
          type: "text",
          value: "",
          default: "",
          validatorTypes: [],
        },
        welcomeMessage: {
          label: "Welcome Message:",
          placeholder: "Enter welcome message",
          type: "text",
          value: "",
          default: "",
          validatorTypes: [],
        },
        people: {
          label: "People",
          type: "dataTable",
          value: "",
          singularLabel: "research community person",
          pluralLabel: "research community people",
          adminPrefix: "profiles",
          apiPrefix: "person",
          parentKey: "research_community_roles.research_community.hashed_id",
          columns: {
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "formattedString",
              label: "Pimrary Email Address",
              format(row) {
                const els = [];
                els.push(`${row.calc_EmailAddress}`);
                if (row.primaryEmailAddress.verifiedAt) {
                  els.push(`<i class="fas fa-check-square ml-2"></i>`);
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            "role.name": {
              type: "formattedString",
              label: "Role",
              format(row, vm) {
                const els = [];
                const thisRole = row.researchCommunityRoles.find((role) => {
                  return (
                    role.researchCommunity.hashedId === vm.parentRow.hashedId
                  );
                });
                els.push(`${thisRole?.role?.name}`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
          },
          createForm: {
            hashedId: {
              label: "Research Community Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            person: {
              label:
                "Person (First, Last or Full Name) / Primary Email Address",
              placeholder: "Select person",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "fullName",
              labelFunction(row) {
                return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
              },
              autocompleteEndpoint: "person",
              searchOnFocus: false,
            },
            role: {
              label: "Role",
              placeholder: "Select role",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "name",
              autocompleteEndpoint: "role",
              filters: { role_type: "research_community" },
              searchOnFocus: true,
            },
          },
          updateForm: {},
          actions: {
            create: {
              allow: true,
              endpoint(hashedId) {
                return `research-community/${hashedId}/add-person/`;
              },
            },
            update: {
              allow: false,
            },
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `profiles/edit/${row.profile.hashedId}`;
              },
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
        posts: {
          label: "Posts",
          type: "dataTable",
          value: "",
          singularLabel: "post",
          pluralLabel: "posts",
          apiPrefix: "research-community-post",
          parentKey: "research_community.hashed_id",
          columns: {
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: false,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            "person.firstName": {
              type: "string",
              label: "First Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            "person.lastName": {
              type: "string",
              label: "Last Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            isCommunityBot: {
              type: "boolean",
              label: "Community Bot?",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "boolean",
                value: "",
              },
            },
            body: {
              type: "string",
              label: "Body",
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            researchCommunityId: {
              label: "Research Community Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            person: {
              label:
                "Person (First, Last or Full Name) / Primary Email Address",
              placeholder: "Select person",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "fullName",
              labelFunction(row) {
                return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
              },
              autocompleteEndpoint: "person",
              searchOnFocus: false,
            },
            body: {
              label: "Body:",
              placeholder: "Enter body",
              type: "text",
              value: "",
              default: "",
              validatorTypes: [],
            },
          },
          updateForm: {
            hashedId: {
              label: "Research Community Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            body: {
              label: "Body:",
              placeholder: "Enter body",
              type: "text",
              value: "",
              default: "",
              validatorTypes: [],
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
        menuItems: [
          {
            label: "View Public Page",
            qualifier() {
              return true;
            },
            action(event, row) {
              const marketingBaseUrl = import.meta.env.VITE_MARKETING_BASE_URL;
              return [
                "visitUrl",
                "get",
                `${marketingBaseUrl}/research-communities/${row.slug}`,
              ];
            },
          },
          {
            label: "Delete Research Community",
            qualifier() {
              return true;
            },
            confirmFirst: true,
            confirmationMessage:
              "Are you sure you want to delete this Research Community?",
            action(event, row) {
              const url = `research-community/${row.hashedId}/`;
              return [
                "apiAction",
                "deleteReload",
                url,
                { researchCommunityId: row.hashedId },
              ];
            },
          },
        ],
      },
    };
  },
};
</script>
