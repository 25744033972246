<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">Firms</h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :test-boolean-key="testBooleanKey"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import { utils } from "common-frontend";
import DataTable from "./DataTable.vue";

const { avatarUrl, logoUrl } = utils;

export default {
  name: "Firms",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "firm",
      pluralLabel: "firms",
      adminPrefix: "firms",
      apiPrefix: "firm",
      testBooleanKey: "isTestAccount",
      columns: {
        isTestAccount: {
          type: "testBoolean",
          label: "Is Test Account",
          hidden: "never",
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "testBoolean",
            default: { label: "No", value: false },
          },
        },
        hashedId: {
          type: "formattedString",
          label: "Firm ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">frm-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "frm-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        avatar: {
          type: "avatar",
          label: "Logo",
          format(row) {
            return logoUrl(row);
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
          },
        },
        displayName: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      createForm: {
        displayName: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        isTestAccount: {
          label: "Test Account:",
          checkboxLabel:
            "This is a test account (exclude from search results, etc.)",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: [],
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter Slug",
          type: "string",
          value: "",
          default: "",
        },
        headline: {
          label: "Headline:",
          placeholder: "Enter Headline",
          type: "string",
          value: "",
          default: "",
        },
        about: {
          label: "About:",
          placeholder: "Enter About",
          type: "string",
          value: "",
          default: "",
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        displayName: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        isTestAccount: {
          label: "Test Account:",
          checkboxLabel:
            "This is a test account (exclude from search results, etc.)",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter Slug",
          type: "string",
          value: "",
          default: "",
        },
        headline: {
          label: "Headline:",
          placeholder: "Enter Headline",
          type: "string",
          value: "",
          default: "",
        },
        about: {
          label: "About:",
          placeholder: "Enter About",
          type: "string",
          value: "",
          default: "",
        },
        people: {
          label: "People",
          type: "dataTable",
          value: "",
          singularLabel: "person",
          pluralLabel: "people",
          adminPrefix: "people",
          apiPrefix: "person",
          parentKey: "firm_roles.firm.hashed_id",
          columns: {
            avatar: {
              type: "avatarWithActivityLevel",
              label: "Avatar",
              format(row) {
                return avatarUrl(null, row);
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "formattedString",
              label: "Email Address",
              format(row) {
                const els = [];
                els.push(`${row.calc_EmailAddress}`);
                if (row.primaryEmailAddress.verifiedAt) {
                  els.push(`<i class="fas fa-check-square ml-2"></i>`);
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            role: {
              type: "formattedString",
              label: "Role",
              format(row, vm) {
                const els = [];
                const thisRole = row.firmRoles.find((role) => {
                  return role.firm.hashedId === vm.parentRow.hashedId;
                });
                els.push(`${thisRole?.role?.name}`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            hashedId: {
              label: "Firm Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            person: {
              label:
                "Person (First, Last or Full Name) / Primary Email Address",
              placeholder: "Select person",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "fullName",
              labelFunction(row) {
                return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
              },
              autocompleteEndpoint: "person",
              searchOnFocus: false,
            },
            role: {
              label: "Role",
              placeholder: "Select role",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "name",
              autocompleteEndpoint: "role",
              filters: { role_type: "firm" },
              searchOnFocus: true,
            },
          },
          updateForm: {},
          actions: {
            create: {
              allow: true,
              endpoint(hashedId) {
                return `firm/${hashedId}/add-person/`;
              },
            },
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `profiles/edit/${row.profile.hashedId}`;
              },
            },
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Remove from Firm",
                qualifier() {
                  return true;
                },
                confirmFirst: true,
                confirmationMessage:
                  "Are you sure you want to remove this person from this Firm?",
                action(event, row, vm) {
                  const url = `firm/${vm.parentRow.hashedId}/remove-person/`;
                  return [
                    "apiAction",
                    "postReload",
                    url,
                    { person: { id: row.hashedId } },
                  ];
                },
              },
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
        industries: {
          label: "Industries",
          type: "dataTable",
          value: "",
          singularLabel: "industry",
          pluralLabel: "industries",
          adminPrefix: "industries",
          apiPrefix: "firm/industry",
          parentKey: "firm.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Firm ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">frm-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "frm-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            industry: {
              type: "enum",
              label: "Industry",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
        companySizes: {
          label: "Company Sizes",
          type: "dataTable",
          value: "",
          singularLabel: "company size",
          pluralLabel: "company sizes",
          adminPrefix: "company-sizes",
          apiPrefix: "firm/company-size",
          parentKey: "firm.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "FIrm ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">frm-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "frm-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            companySize: {
              type: "enum",
              label: "Company Size",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
        companyStages: {
          label: "Company Stages",
          type: "dataTable",
          value: "",
          singularLabel: "company stage",
          pluralLabel: "company stages",
          adminPrefix: "company-stages",
          apiPrefix: "firm/company-stage",
          parentKey: "firm.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Organization ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">frm-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "frm-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            companyStage: {
              type: "enum",
              label: "companyStage",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
      },
      actions: {
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [
          {
            label: "Delete Firm",
            qualifier(row) {
              return row.isTestAccount;
            },
            confirmFirst: true,
            confirmationMessage: "Are you sure you want to delete this firm?",
            action(event, row) {
              const url = `firm/${row.hashedId}/`;
              return [
                "apiAction",
                "deleteReload",
                url,
                { firmId: row.hashedId },
              ];
            },
          },
        ],
      },
    };
  },
};
</script>
