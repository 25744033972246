<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Contract Packages
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "Contracts",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "contract",
      pluralLabel: "contracts",
      adminPrefix: "contracts",
      apiPrefix: "contract",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Contract ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">con-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "CON-",
          },
        },
        "contractType.name": {
          type: "formattedString",
          label: "Contract Type",
          format(row) {
            const els = [];
            if (row.contractTypr) {
              els.push(
                `<span class="text-nowrap">${row.contractType.name}</span>`,
              );
              els.push(
                `<button class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"><a href="/admin/contract-types/edit/${row.contractType.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>`,
              );
            }
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter Name",
          type: "string",
          value: "",
          default: "",
        },
        text: {
          label: "Text:",
          placeholder: "Enter Text",
          type: "text",
          value: "",
          default: "",
        },
        variables: {
          label: "Variables",
          type: "dataTable",
          value: "",
          singularLabel: "variable",
          pluralLabel: "variables",
          adminPrefix: "contract-variables",
          apiPrefix: "contract-variable",
          parentKey: "contract.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Variable ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">convar-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "CONVAR-",
              },
            },
            name: {
              type: "string",
              label: "Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            slug: {
              type: "string",
              label: "Slug",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            variableType: {
              type: "enum",
              label: "Contract Variable Type",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            defaultValue: {
              type: "string",
              label: "Default Value",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            value: {
              type: "string",
              label: "Value",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            contractId: {
              label: "Contract ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            name: {
              label: "Name:",
              placeholder: "Enter Name",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter Slug",
              type: "string",
              value: "",
              default: "",
            },
            description: {
              label: "Description:",
              placeholder: "Enter Description",
              type: "string",
              value: "",
              default: "",
            },
            variableType: {
              label: "Contract Variable Type",
              placeholder: "Select contract variable type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/ContractVariableType",
              searchOnFocus: true,
            },
            defaultValue: {
              label: "Default Value:",
              placeholder: "Enter Default Value",
              type: "string",
              value: "",
              default: "",
            },
            value: {
              label: "Value:",
              placeholder: "Enter Value",
              type: "string",
              value: "",
              default: "",
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            name: {
              label: "Name:",
              placeholder: "Enter Name",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter Slug",
              type: "string",
              value: "",
              default: "",
            },
            description: {
              label: "Description:",
              placeholder: "Enter Description",
              type: "string",
              value: "",
              default: "",
            },
            defaultValue: {
              label: "Default Value:",
              placeholder: "Enter Default Value",
              type: "string",
              value: "",
              default: "",
            },
            value: {
              label: "Value:",
              placeholder: "Enter Value",
              type: "string",
              value: "",
              default: "",
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
      },
      createForm: {
        contractType: {
          label: "Contract Type",
          placeholder: "Select contract type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: ["required"],
          idKey: "hashedId",
          labelKey: "name",
          labelFunction(row) {
            return `${row.name}, contyp-${row.hashedId})`;
          },
          autocompleteEndpoint: "contract-type",
          searchOnFocus: true,
        },
        name: {
          label: "Name:",
          placeholder: "Enter Name",
          type: "string",
          value: "",
          default: "",
        },
        text: {
          label: "Text:",
          placeholder: "Enter Text",
          type: "text",
          value: "",
          default: "",
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [],
      },
    };
  },
};
</script>
