<template>
  <span @click="toggle()">
    <div
      class="w-8 h-5 my-2 rounded-lg cursor-pointer mx-auto transition-all duration-150"
      :class="{
        'bg-guardsman-red/70': !loading && !value,
        'bg-christi/80': !loading && value,
        'bg-gray-500': loading,
      }"
    >
      <div
        class="w-5 h-5 -mt-1 rounded-lg border bg-white transition-all duration-150"
        :class="{
          'ml-auto mr-[-1px] border-christi/90': !loading && value,
          'mr-auto ml-[-1px] border-guardsman-red/80': !loading && !value,
          'mx-auto': loading,
        }"
      ></div>
    </div>
  </span>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["change"]);

const loading = ref(false);

watch(
  () => props,
  () => {
    loading.value = false;
  },
  { immediate: true, deep: true },
);

function toggle() {
  loading.value = true;
  emit("change", !props.value);
}
</script>
