<template>
  <div>
    <SearchHeader v-model="searchInputValue" @submit="handleFormSubmit" />
    <div v-if="searchState.wasSearched">
      <div class="flex">
        <div class="min-w-[250px] px-3">
          <SearchFacet
            :label="facetValues['journal_title'].label"
            :checked="facetValues['journal_title'].values"
            :facet="searchState?.facets['journal_title'][0]"
            @change="handleFacetChange($event, 'journal_title')"
          />
          <SearchFacet
            :label="facetValues['disciplines.name'].label"
            :checked="facetValues['disciplines.name'].values"
            :facet="searchState?.facets['disciplines.name'][0]"
            @change="handleFacetChange($event, 'disciplines.name')"
          />
          <SearchFacet
            :label="facetValues['subdisciplines.name'].label"
            :checked="facetValues['subdisciplines.name'].values"
            :facet="searchState?.facets['subdisciplines.name'][0]"
            @change="handleFacetChange($event, 'subdisciplines.name')"
          />
        </div>
        <div>
          <div class="flex w-full justify-between items-center px-3 mb-5">
            <SearchPagingInfo :search-state="searchState" />
            <SearchResultsPerPage
              v-show="thereAreResults"
              v-model.number="resultsPerPage"
            />
          </div>
          <div>
            <SearchResults
              v-show="thereAreResults"
              :results="searchState.results"
              :enums="enums"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchDriver } from "@elastic/search-ui";
import { utils } from "common-frontend";
import config from "../../feedItemSearchConfig";
import SearchResults from "./SearchResults.vue";
import SearchFacet from "./SearchFacet.vue";
import SearchHeader from "./SearchHeader.vue";
import SearchPagingInfo from "./SearchPagingInfo.vue";
import SearchResultsPerPage from "./SearchResultsPerPage.vue";

const { loadEnums } = utils;

const driver = new SearchDriver(config);

export default {
  components: {
    SearchResults,
    SearchFacet,
    SearchHeader,
    SearchPagingInfo,
    SearchResultsPerPage,
  },
  data() {
    return {
      enums: {},
      facetValues: {
        journal_title: {
          label: "Journal Title",
          values: [],
        },
        "disciplines.name": {
          label: "disciplines",
          values: [],
        },
        "subdisciplines.name": {
          label: "Subdisciplines",
          values: [],
        },
      },
      searchInputValue: "",
      searchState: {},
      resultsPerPage: 20,
      sortBy: "",
    };
  },
  computed: {
    thereAreResults() {
      return this.searchState.totalResults && this.searchState.totalResults > 0;
    },
  },
  watch: {
    resultsPerPage(newResultsPerPage) {
      driver.setResultsPerPage(newResultsPerPage);
    },
    sortBy(newSortBy) {
      const sort = newSortBy.split(":");
      driver.setSort(sort[0], sort[1]);
    },
  },
  mounted() {
    loadEnums(this, {
      RssFeedDiscipline: "rssFeedDisciplineOptions",
    });
    const { searchTerm, resultsPerPage, filters, facets } = driver.getState();

    // restoring UI from url query
    this.searchInputValue = searchTerm;
    this.sortBy = "display_published_at:desc";
    this.resultsPerPage = resultsPerPage;
    filters.forEach((filter) => {
      if (facets[filter.field][0].type === "range") {
        this[filter.field] = filter.values.map((value) => value.name);
      } else {
        this[filter.field] = filter.values;
      }
    });

    driver.subscribeToStateChanges((state) => {
      this.searchState = state;
    });
  },
  methods: {
    handleFormSubmit() {
      driver.getActions().setSearchTerm(this.searchInputValue);
    },
    handleFacetChange(event, facet) {
      const { value, checked } = event.target;
      const facetFromDriver = driver.getState().facets[facet][0];
      const valueforApi =
        facetFromDriver.type === "range"
          ? facetFromDriver.data.find((item) => item.value.name === value).value
          : value;

      const index = this.facetValues[facet].values.indexOf(value);
      if (checked) {
        if (index === -1) {
          this.facetValues[facet].values.push(value);
          driver.addFilter(facet, valueforApi, "any");
        }
      } else {
        if (index > -1) {
          this.facetValues[facet].values.splice(index, 1);
        }
        driver.removeFilter(facet, valueforApi, "any");
      }
    },
    setCurrentPage(page) {
      driver.setCurrent(page);
    },
  },
};
</script>
