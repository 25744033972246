<template>
  <div>
    <div class="flex justify-between items-start">
      <div class="flex">
        <span class="mr-2 cursor-pointer" @click="toggleExpanded()"
          ><i
            class="fa-solid fa-caret-right transition-all duration-500"
            :class="{
              'rotate-90': expanded,
            }"
          ></i
        ></span>
        <div
          class="text-sm rounded ml-1 mt-1 inline-block px-1 cursor-pointer"
          :style="`background-color: #${discipline.rssFeedDiscipline.bgColor}; color: #${discipline.rssFeedDiscipline.textColor}`"
          @click="deleteResearchInterestDiscipline(discipline.hashedId)"
        >
          {{ discipline.rssFeedDiscipline.name?.replace(" ", "&nbsp;") }}
        </div>
      </div>
      <div class="flex">
        <div class="w-[10px] mr-2">
          <context-popper text="Slug">
            <i
              v-if="discipline.slug"
              class="fa-solid fa-check text-green-500"
            ></i
            ><i v-else class="fa-solid fa-xmark text-red-500"></i>
          </context-popper>
        </div>
        <div class="w-[10px] mr-2">
          <context-popper text="Meta Description">
            <i
              v-if="discipline.metaDescription"
              class="fa-solid fa-check text-green-500"
            ></i
            ><i v-else class="fa-solid fa-xmark text-red-500"></i>
          </context-popper>
        </div>
        <div class="w-[10px] mr-2">
          <context-popper text="On-Page Description">
            <i
              v-if="discipline.onPageDescription"
              class="fa-solid fa-check text-green-500"
            ></i
            ><i v-else class="fa-solid fa-xmark text-red-500"></i>
          </context-popper>
        </div>
        <div class="w-[10px] mr-2">
          <context-popper text="Project Examples">
            <i
              v-if="discipline.projectExamples"
              class="fa-solid fa-check text-green-500"
            ></i
            ><i v-else class="fa-solid fa-xmark text-red-500"></i>
          </context-popper>
        </div>
        <div class="w-[10px] mr-2">
          <context-popper text="Top Domestic Schools">
            <i
              v-if="discipline.topDomesticSchools"
              class="fa-solid fa-check text-green-500"
            ></i
            ><i v-else class="fa-solid fa-xmark text-red-500"></i>
          </context-popper>
        </div>
        <div class="w-[10px] mr-2">
          <context-popper text="Top International Schools">
            <i
              v-if="discipline.topInternationalSchools"
              class="fa-solid fa-check text-green-500"
            ></i
            ><i v-else class="fa-solid fa-xmark text-red-500"></i>
          </context-popper>
        </div>
      </div>
    </div>
    <div
      class="transition-all duration-500 relative overflow-y-hidden"
      :class="{ 'max-h-[1000px]': expanded, 'max-h-0': !expanded }"
    >
      <div class="mt-2 px-1">
        <label class="form-label" :for="`slug${discipline.hashedId}`">
          Slug
        </label>
        <input
          :id="`slug${discipline.hashedId}`"
          v-model="discipline.slug"
          :name="`slug${discipline.hashedId}`"
          class="form-text-input"
          placeholder="slug"
        />
      </div>
      <div class="mt-2 px-1">
        <label
          class="form-label"
          :for="`metaDescription${discipline.hashedId}`"
        >
          Meta Description
        </label>
        <textarea
          :id="`metaDescription${discipline.hashedId}`"
          v-model="discipline.metaDescription"
          :name="`metaDescription${discipline.hashedId}`"
          class="form-text-input"
          placeholder="meta description"
        />
      </div>
      <div class="mt-2 px-1">
        <label
          class="form-label"
          :for="`onPageDescription${discipline.hashedId}`"
        >
          On-Page Description
        </label>
        <textarea
          :id="`onPageDescription${discipline.hashedId}`"
          v-model="discipline.onPageDescription"
          :name="`onPageDescription${discipline.hashedId}`"
          class="form-text-input"
          placeholder="on-page description"
        />
      </div>
      <div class="mt-2 px-1">
        <label
          class="form-label"
          :for="`projectExamples${discipline.hashedId}`"
        >
          Project Examples
        </label>
        <textarea
          :id="`projectExamples${discipline.hashedId}`"
          v-model="discipline.projectExamples"
          :name="`projectExamples${discipline.hashedId}`"
          class="form-text-input"
          placeholder="project examples"
        />
      </div>
      <div class="mt-2 px-1">
        <label
          class="form-label"
          :for="`topDomesticSchools${discipline.hashedId}`"
        >
          Top Domestic Schools
        </label>
        <textarea
          :id="`topDomesticSchools${discipline.hashedId}`"
          v-model="discipline.topDomesticSchools"
          :name="`topDomesticSchools${discipline.hashedId}`"
          class="form-text-input"
          placeholder="top domestic schools"
        />
      </div>
      <div class="mt-2 px-1">
        <label
          class="form-label"
          :for="`topInternationalSchools${discipline.hashedId}`"
        >
          Top International Schools
        </label>
        <textarea
          :id="`topInternationalSchools${discipline.hashedId}`"
          v-model="discipline.topInternationalSchools"
          :name="`topInternationalSchools${discipline.hashedId}`"
          class="form-text-input"
          placeholder="top international schools"
        />
      </div>
      <div class="text-right">
        <button
          class="bg-blueGray-500 px-2 py-1 uppercase text-sm text-white rounded"
          @click="updateResearchInterestDiscipline(discipline)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import ContextPopper from "./ContextPopper.vue";

const { keysToSnake } = utils;

export default {
  name: "DataIntegrityResearchInterestDiscipline",
  components: {
    ContextPopper,
  },
  props: {
    discipline: Object,
    enums: Object,
  },
  data() {
    return {
      expanded: false,
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
    };
  },
  methods: {
    updateResearchInterestDiscipline(discipline) {
      this.loading = true;
      this.$api.axios
        .put(
          `${this.apiBaseUrl}/person/public-profile/research-interest-discipline/${discipline.hashedId}/`,
          keysToSnake({
            slug: discipline.slug,
            metaDescription: discipline.metaDescription,
            onPageDescription: discipline.onPageDescription,
            projectExamples: discipline.projectExamples,
            topDomesticSchools: discipline.topDomesticSchools,
            topInternationalSchools: discipline.topInternationalSchools,
          }),
        )
        .then(() => {
          this.$toast.success(`Updated discipline research expertise.`);
          this.$emit("loadResearchInterests");
        });
    },
    disciplineEnumForLabel(disciplineLabel) {
      return this.enums.rssFeedDisciplineOptions.find((item) => {
        return disciplineLabel === item.label;
      });
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    deleteResearchInterestDiscipline(hashedId) {
      this.loading = true;
      this.$api.axios
        .delete(
          `${this.apiBaseUrl}/person/public-profile/research-interest-discipline/${hashedId}/`,
        )
        .then(() => {
          this.$toast.success(`Removed discipline from research expertise.`);
          this.$emit("loadResearchInterests");
        });
    },
  },
};
</script>
