<template>
  <section>
    <div class="flex items-center mt-2 justify-between">
      <h6 class="uppercase text-xs">Collaboration Space Representatives</h6>
      <div>
        <button
          v-if="!isRep"
          class="border border-black px-1 rounded-sm text-xs uppercase"
          @click="addMe()"
        >
          + Me
        </button>
        <button
          v-else
          class="border border-black px-1 rounded-sm text-xs uppercase"
          @click="removeMe()"
        >
          &mdash; Me
        </button>
      </div>
    </div>
    <div
      v-for="rep in notedSourceReps"
      :key="rep.hashedId"
      class="flex items-center py-2"
    >
      <profile-image :size="35" :img-url="avatarUrl(null, rep.person)" />
      <div class="ml-2">
        <p class="m-0">
          <span v-if="rep.person.hashedId">
            {{ rep.person.firstName }} {{ rep.person.lastName }}
          </span>
          <span v-else><em>Unknown</em></span>
        </p>
        <p v-if="rep.createdAt" class="relative -mt-1 uppercase text-xs">
          Added
          <moment-from-now
            :title="rep.createdAt"
            :value="rep.createdAt"
            :interval="5000"
            :tag="'span'"
          />
        </p>
      </div>
    </div>
    <div v-if="notedSourceReps.length === 0">
      <em>None</em>
    </div>
  </section>
</template>

<script>
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";
import MomentFromNow from "../MomentFromNow.vue";

const { avatarUrl, keysToSnake } = utils;

export default {
  components: {
    MomentFromNow,
    ProfileImage,
  },
  props: {
    collaborationSpace: Object,
    roles: Array,
  },
  data() {
    return {
      avatarUrl,
    };
  },
  computed: {
    notedSourceReps() {
      return this.collaborationSpace?.collaborationSpaceRoles.filter((role) => {
        return role.role.name === "NotedSource Representative";
      });
    },
    isRep() {
      return this.notedSourceReps.find((role) => {
        return role.person.hashedId === this.$store.getters.person.hashedId;
      });
    },
  },
  methods: {
    addMe() {
      const role = this.roles.find((r) => {
        return r.name === "NotedSource Representative";
      });
      this.$api.axios
        .post(
          `/collaboration-space/${this.collaborationSpace.hashedId}/add-person/`,
          keysToSnake({
            person: { id: this.$store.getters.person.hashedId },
            role: { id: role.hashedId },
          }),
        )
        .then(() => {
          this.$emit("reload");
        });
    },
    removeMe() {
      this.$api.axios
        .post(
          `/collaboration-space/${this.collaborationSpace.hashedId}/remove-person/`,
          keysToSnake({ person: { id: this.$store.getters.person.hashedId } }),
        )
        .then(() => {
          this.$emit("reload");
        });
    },
  },
};
</script>
