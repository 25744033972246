<script>
import { onMounted, ref, h, watch } from "vue";

import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";

import "@toast-ui/editor/dist/toastui-editor-viewer.css";

export default {
  props: {
    text: String,
  },
  setup(props) {
    const viewer = ref();

    const initViewer = function () {
      return new Viewer({
        el: viewer.value,
        initialValue: props.text ? props.text : "",
      });
    };

    onMounted(() => {
      // eslint-disable-next-line
      const v = initViewer();
    });

    watch(
      () => props.text,
      // eslint-disable-next-line
      newText => {
        // eslint-disable-next-line
        const v = initViewer();
      },
    );

    return () =>
      h("div", {
        ref: viewer,
      });
  },
};
</script>
