<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Meetings
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import { utils } from "common-frontend";
import DataTable from "./DataTable.vue";

const { avatarUrl } = utils;

export default {
  name: "Work",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "meeting",
      pluralLabel: "meetings",
      adminPrefix: "meetings",
      apiPrefix: "meeting",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Meeting ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">mt-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "mt-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        topic: {
          type: "formattedString",
          label: "Topic",
          format(row) {
            const els = [];
            els.push(
              `<p class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">${row.topic}</p>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        status: {
          type: "enum",
          label: "Status",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        availability: {
          type: "formattedString",
          label: "Availability",
          format(row, vm) {
            const els = [];
            row.meetingRoles.forEach((meetingRole) => {
              const subels = [];
              meetingRole.availability.forEach((availability) => {
                subels.push(
                  `<li>${vm.$dayjs
                    .utc(availability.startAt)
                    .local()
                    .calendar()} - ${availability.availability.label}</li>`,
                );
              });
              els.push(
                `<div class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">
                  <p>${meetingRole.person.profile.fullDisplayName}</p>
                  <ul class="list-disc ml-5">${subels.join("")}</ul>
                </div>`,
              );
            });
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        topic: {
          label: "Topic:",
          placeholder: "Enter topic",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        people: {
          label: "People",
          type: "dataTable",
          value: "",
          singularLabel: "person",
          pluralLabel: "people",
          adminPrefix: "people",
          apiPrefix: "person",
          parentKey: "meeting_roles.meeting.hashed_id",
          createForm: {
            hashedId: {
              label: "Meeting Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            person: {
              label:
                "Person (First, Last or Full Name / Primary Email Address)",
              placeholder: "Select person",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "fullName",
              labelFunction(row) {
                return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
              },
              autocompleteEndpoint: "person",
              searchOnFocus: false,
            },
            role: {
              label: "Role",
              placeholder: "Select role",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "name",
              autocompleteEndpoint: "role",
              filters: { role_type: "meeting" },
              searchOnFocus: true,
            },
          },
          updateForm: {},
          columns: {
            avatar: {
              type: "avatarWithActivityLevel",
              label: "Avatar",
              format(row) {
                return avatarUrl(null, row);
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "formattedString",
              label: "Primary Email Address",
              format(row) {
                const els = [];
                els.push(`${row.calc_EmailAddress}`);
                if (row.primaryEmailAddress.verifiedAt) {
                  els.push(`<i class="fas fa-check-square ml-2"></i>`);
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            personType: {
              type: "enum",
              label: "Person Type",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            create: {
              allow: true,
              endpoint(hashedId) {
                return `meeting/${hashedId}/add-person/`;
              },
            },
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `profiles/edit/${row.profile.hashedId}`;
              },
            },
            menuItems: [
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
      },
      actions: {
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [],
      },
    };
  },
};
</script>
