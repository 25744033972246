<template>
  <div
    class="collaboration-space-banner-container mx-auto"
    :style="`background-image: url(${imgUrl}); width: ${
      width ? width + 'px' : '100%'
    } height: ${height}px;`"
  >
    <div
      class="collaboration-space-banner-gradient"
      :style="`width: 100%; height: ${height}px; width: ${
        width ? width + 'px' : '100%'
      }`"
    >
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgUrl: String,
    width: Number,
    height: Number,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.collaboration-space-banner-container {
  background-size: cover;
}
.collaboration-space-banner-gradient {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 1) 80%
  );
}
</style>
