<template>
  <div v-if="qualifyingMenuItems.length > 0" v-click-outside="onClickOutside">
    <div v-for="menuItem in qualifyingMenuItems" :key="menuItem.label">
      <regular-modal
        v-if="menuItem.confirmFirst"
        :ref="`confirmModal${menuItem.label.replace(' ', '')}`"
        :showing="false"
      >
        <template #header>
          <h4 class="text-xl font-light">Confirmation</h4>
        </template>
        <template #body>
          <div
            class="w-full max-h-[calc(100vh-200px)] overflow-scroll px-6 py-3"
          >
            <p>{{ menuItem.confirmationMessage }}</p>
          </div>
        </template>
        <template #footer>
          <button
            class="modal-nonprimary-button"
            type="button"
            @click="toggleConfirmModal(menuItem.label.replace(' ', ''))"
          >
            Cancel
          </button>
          <button
            class="modal-primary-button"
            type="button"
            @click="
              toggleConfirmModal(menuItem.label.replace(' ', ''));
              handleApiAction(menuItem.action($event, row, vm));
            "
          >
            <span>Yes</span>
          </button>
        </template>
      </regular-modal>
    </div>
    <a
      ref="btnDropdownRef"
      class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"
      :class="{
        'bg-gray-500 text-white': dropdownPopoverShow,
        'text-blueGray-500': !dropdownPopoverShow,
      }"
      href="#"
      @click="toggleDropdown"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      :class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <span v-for="menuItem in qualifyingMenuItems" :key="menuItem.label">
        <a
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 cursor-pointer hover:bg-gray-500 hover:text-white"
          @click.prevent="
            toggleDropdown($event);
            doOrConfirm($event, menuItem, row);
          "
        >
          {{ menuItem.label }}
        </a>
      </span>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import vClickOutside from "click-outside-vue3";
import { utils } from "common-frontend";
import RegularModal from "../Modals/Regular.vue";

const { keysToSnake } = utils;

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    RegularModal,
  },
  props: {
    menuItems: Array,
    menuItemFactory: Function,
    row: Object,
    apiBaseUrl: String,
    vm: Object,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      processedMenuItems: [],
      popper: null,
    };
  },
  computed: {
    qualifyingMenuItems() {
      return this.processedMenuItems.filter((menuItem) => {
        return menuItem.qualifier(this.row, this.vm);
      });
    },
  },
  mounted() {
    if (this.menuItemFactory) {
      this.processedMenuItems = this.menuItemFactory(this.row, this.vm);
    } else {
      this.processedMenuItems = this.menuItems;
    }
  },
  methods: {
    onClickOutside() {
      this.dropdownPopoverShow = false;
    },
    toggleDropdown(event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        if (!this.popper) {
          this.popper = createPopper(
            this.$refs.btnDropdownRef,
            this.$refs.popoverDropdownRef,
            {
              placement: "bottom-start",
            },
          );
        }
      }
    },
    doOrConfirm($event, menuItem, row) {
      if (menuItem.confirmFirst) {
        this.toggleConfirmModal(menuItem.label.replace(" ", ""));
      } else {
        this.handleApiAction(menuItem.action($event, row, this.vm));
      }
    },
    toggleConfirmModal(modalName) {
      let ref = this.$refs[`confirmModal${modalName}`];
      if (Array.isArray(ref)) {
        ref = ref[0]; // eslint-disable-line
      }
      ref.toggleModal();
    },
    handleApiAction(result) {
      this[result[0]](result[1], result[2], result[3]);
    },
    apiAction(methodAction, url, data) {
      let method = methodAction;
      if (
        methodAction === "postConsole" ||
        methodAction === "postCopy" ||
        methodAction === "postReload"
      ) {
        method = "post";
      } else if (methodAction === "putReload") {
        method = "put";
      } else if (methodAction === "getCopy") {
        method = "get";
      } else if (methodAction === "deleteReload") {
        method = "delete";
      }
      const payload = keysToSnake(data, true);
      this.$api.axios[method](`${this.apiBaseUrl}/${url}`, payload).then(
        (res) => {
          if (methodAction === "postConsole") {
            this.$toast.success("View the console.");
          } else if (
            methodAction === "putReload" ||
            methodAction === "postReload" ||
            methodAction === "deleteReload"
          ) {
            this.$toast.success("This action has been taken.");
            this.$emit("refresh");
          } else if (
            methodAction === "postCopy" ||
            methodAction === "getCopy"
          ) {
            const resultData = [
              new ClipboardItem({
                "text/plain": new Blob([res.data.link], { type: "text/plain" }),
              }),
            ];
            const self = this;
            navigator.clipboard.write(resultData).then(function _() {
              self.$toast.success("Copied to clipboard.");
            });
          } else {
            this.$toast.success("This action has been taken.");
          }
        },
      );
    },
    visitUrl(method, url) {
      window.open(url, "_blank").focus();
    },
    executeFunc(func) {
      func();
    },
  },
};
</script>
