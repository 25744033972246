<template>
  <div>
    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative my-6 mx-auto max-w-3xl lg:w-160">
        <!--content-->
        <div
          class="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--header-->
          <div
            class="flex items-start justify-between py-3 px-5 border-b border-solid border-blueGray-200 rounded-t"
          >
            <slot name="header"> </slot>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click="toggleModal()"
            >
              <span
                class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
              >
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative flex-auto">
            <slot name="body"> </slot>
          </div>
          <!--footer-->
          <div
            class="flex items-center justify-end px-6 py-3 border-t border-solid border-blueGray-200 rounded-b"
          >
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  name: "RegularModal",
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
