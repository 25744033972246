<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div v-if="encodedJwt">
        <JaaSMeeting
          :app-id="jitsiAppId"
          :use-staging="false"
          :room-name="`${jitsiAppId}/SampleAppFinalTricksAccuseWhereby`"
          :jwt="encodedJwt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import { JaaSMeeting } from "@jitsi/vue-sdk";

const { keysToCamel } = utils;

export default {
  name: "MeetingExperiment",
  components: {
    JaaSMeeting,
  },
  data() {
    return {
      encodedJwt: "",
      jitsiAppId: import.meta.env.VITE_JITSI_APP_ID,
    };
  },
  mounted() {
    this.getJwt();
  },
  methods: {
    getJwt() {
      this.$api.axios.get(`/get-jaas-token/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.encodedJwt = data.body;
      });
    },
  },
};
</script>
