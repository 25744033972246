<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Expert Dashboard Categories & Links
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import { utils } from "common-frontend";
import DataTable from "./DataTable.vue";

const { imageUrl } = utils;

export default {
  name: "Roles",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "expert dashboard category",
      pluralLabel: "expert dashboard categories",
      adminPrefix: "expert-dashboard",
      apiPrefix: "expert-dashboard-category",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Category ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">pi-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "cat-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        position: {
          type: "enum",
          label: "Position",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        displayOrder: {
          type: "string",
          label: "Display Order",
          sort: {
            allow: true,
            default: false,
          },
          filter: {
            allow: false,
          },
        },
      },
      createForm: {
        name: {
          label: "Name:",
          placeholder: "Enter category name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        position: {
          label: "Position:",
          placeholder: "Select position",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/ExpertDashboardCategoryPosition",
          searchOnFocus: true,
        },
        displayOrder: {
          label: "Display Order:",
          placeholder: "Enter display order",
          type: "integer",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter category name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        position: {
          label: "Position:",
          placeholder: "Select position",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/ExpertDashboardCategoryPosition",
          searchOnFocus: true,
        },
        displayOrder: {
          label: "Display Order:",
          placeholder: "Enter display order",
          type: "integer",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        links: {
          label: "Expert Dashboard Links",
          type: "dataTable",
          value: "",
          singularLabel: "link",
          pluralLabel: "links",
          adminPrefix: "expert-dashbaord",
          apiPrefix: "expert-dashboard-link",
          parentKey: "category.hashed_id",
          createForm: {
            categoryId: {
              label: "Category Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            title: {
              label: "Title:",
              placeholder: "Enter link title",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            url: {
              label: "Url:",
              placeholder: "Enter link url",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            source: {
              label: "Source:",
              placeholder: "Enter link source",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            image_file: {
              label: "Image:",
              placeholder: "Select image file",
              type: "file",
              default: "",
              value: "",
              fileKey: "imageFile",
              validatorTypes: [],
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            title: {
              label: "Title:",
              placeholder: "Enter link title",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            url: {
              label: "Url:",
              placeholder: "Enter link url",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            source: {
              label: "Source:",
              placeholder: "Enter link source",
              type: "string",
              default: "",
              value: "",
              validatorTypes: ["required"],
            },
            image_file: {
              label: "Image:",
              placeholder: "Select image file",
              type: "file",
              default: "",
              value: "",
              fileKey: "imageFile",
              validatorTypes: [],
            },
          },
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Link ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">lin-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "pip-",
              },
            },
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: false,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            title: {
              type: "string",
              label: "Title",
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            url: {
              type: "string",
              label: "URL",
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            source: {
              type: "string",
              label: "Source",
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            previewImage: {
              type: "formattedString",
              label: "Preview Image",
              format(row) {
                if (row.imageHash) {
                  return `<img src="${imageUrl(row.imageHash)}" height="50" />`;
                }
                return "";
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [
              {
                label: "Add Meta Image",
                qualifier(row) {
                  return !row.imageHash;
                },
                action(event, row) {
                  const url = `expert-dashboard-link/${row.hashedId}/add-meta-image/`;
                  return ["apiAction", "postReload", url, {}];
                },
              },
            ],
          },
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [],
      },
    };
  },
};
</script>
