<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-4">
            Contract Packages
          </h3>
        </div>
        <div class="text-right mt-3">
          <button @click="toggleContractPackageModal()">
            <span class="rounded-lg px-3 py-2 bg-blueGray-500 text-white">
              <i class="fa fa-sm fa-plus"></i> Contract Package
            </span>
          </button>
        </div>
      </div>
    </div>
    <DataTable
      ref="dataTable"
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
    <right-side-modal
      ref="contractPackageModal"
      :showing="false"
      :hide-footer="true"
    >
      <template #header>
        <h4 class="text-xl font-light">New Contract Package</h4>
      </template>
      <template #body>
        <contract-package-modal
          :contract-package-hashed-id="contractPackageHashedId"
          @reloadList="$refs.dataTable.getData()"
          @finish="toggleContractPackageModal()"
        />
      </template>
    </right-side-modal>
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";
import RightSideModal from "../Modals/RightSide.vue";
import ContractPackageModal from "../Contract/ContractPackageModal.vue";

export default {
  name: "Contracts",
  components: {
    DataTable,
    RightSideModal,
    ContractPackageModal,
  },
  data() {
    return {
      singularLabel: "contract package",
      pluralLabel: "contract packages",
      adminPrefix: "contract-packages",
      apiPrefix: "contract-package",
      contractPackageHashedId: null,
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Contract Package ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">ctp-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "CTP-",
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter Name",
          type: "string",
          value: "",
          default: "",
        },
        contractPackageSignatures: {
          label: "Signatures",
          type: "dataTable",
          value: "",
          singularLabel: "signature",
          pluralLabel: "signatures",
          adminPrefix: "contract-package-signatures",
          apiPrefix: "contract-package-signature",
          parentKey: "contract_package.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Signature ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">consig-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "CONSIG-",
              },
            },
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "string",
              label: "Email Address",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            contractPackageId: {
              label: "Contract Package ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            firstName: {
              label: "First Name:",
              placeholder: "Enter First Name",
              type: "string",
              value: "",
              default: "",
            },
            lastName: {
              label: "Last Name:",
              placeholder: "Enter Last Name",
              type: "string",
              value: "",
              default: "",
            },
            emailAddress: {
              label: "Email Address:",
              placeholder: "Enter Email Address",
              type: "string",
              value: "",
              default: "",
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            firstName: {
              label: "First Name:",
              placeholder: "Enter First Name",
              type: "string",
              value: "",
              default: "",
            },
            lastName: {
              label: "Slug:",
              placeholder: "Enter Last Name",
              type: "string",
              value: "",
              default: "",
            },
            emailAddress: {
              label: "Email Address:",
              placeholder: "Enter Email Address",
              type: "string",
              value: "",
              default: "",
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [
              {
                label: "Get Signature Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `contract-package-signature/${row.hashedId}/link/`;
                  return ["apiAction", "getCopy", url, {}];
                },
              },
            ],
          },
        },
        contracts: {
          label: "Contracts",
          type: "dataTable",
          value: "",
          singularLabel: "contract",
          pluralLabel: "contracts",
          adminPrefix: "contracts",
          apiPrefix: "contract",
          parentKey: "contract_package.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Contract ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">con-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "CON-",
              },
            },
            name: {
              type: "string",
              label: "Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            contractPackageId: {
              label: "Contract Package ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            contract: {
              label: "Contract",
              placeholder: "Select contract",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "name",
              labelFunction(row) {
                return `${row.name}, con-${row.hashedId})`;
              },
              autocompleteEndpoint: "contract",
              searchOnFocus: true,
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: false,
            },
            delete: {
              allow: true,
            },
          },
        },
      },
      actions: {
        create: {
          allow: false,
        },
        update: {
          allow: false,
        },
        delete: {
          allow: true,
        },
        menuItems: [
          {
            label: "Update Signatures or Documents",
            qualifier() {
              return true;
            },
            action(event, row) {
              const self = this;
              return [
                "executeFunc",
                function _() {
                  self.vm.toggleContractPackageModal(row.hashedId);
                },
              ];
            },
            vm: this,
          },
        ],
        menuItemFactory(row) {
          const items = [
            {
              label: "Update Signatures or Documents",
              qualifier() {
                return true;
              },
              action(event, actionRow) {
                const self = this;
                return [
                  "executeFunc",
                  function _() {
                    self.vm.toggleContractPackageModal(actionRow.hashedId);
                  },
                ];
              },
              vm: this,
            },
          ];
          items.push(
            ...row.contractPackageSignatures.map((item) => {
              return {
                label: `Get Signature Link for ${item.firstName} ${item.lastName}`,
                qualifier() {
                  return true;
                },
                action() {
                  const url = `contract-package-signature/${item.hashedId}/link/`;
                  return ["apiAction", "getCopy", url, {}];
                },
              };
            }),
          );
          return items;
        },
      },
    };
  },
  methods: {
    toggleContractPackageModal(hashedId) {
      if (hashedId) {
        this.contractPackageHashedId = hashedId;
      } else {
        this.contractPackageHashedId = null;
      }
      this.$refs.contractPackageModal.toggleModal();
    },
  },
};
</script>
