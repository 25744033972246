<template>
  <div class="flex flex-wrap p-2">
    <div class="w-full">
      <p v-html="chat"></p>
    </div>

    <input
      v-model="message"
      class="p-1 grow"
      type="text"
      placeholder="Enter question ..."
      @keyup.enter="send"
    />
    <select
      v-model="selectedMode"
      class="dropdown mx-2"
      @change="updateFocusMode"
    >
      <option v-for="(code, index) in focusModes" :key="index" :value="code">
        {{ code }}
      </option>
    </select>
    <button
      :disabled="!canSend"
      :onclick="send"
      class="text-xs bg-porcelain-300 px-2 py-1 mr-2 text-white rounded"
    >
      <i class="fa-solid fa-wand-sparkles"></i> Send
    </button>
    <button
      :onclick="end"
      class="text-xs bg-porcelain-300 px-3 py-1 text-white rounded"
    >
      <i class="fa-solid fa-plug-circle-xmark"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "PerplexicaExperiment",
  components: {},
  data() {
    return {
      message: "Is plant or animal agriculture more destructive?",
      chat: "Enter question ...",
      canSend: true,
      selectedMode: this.$perplexica.focusModes[0],
      focusModes: this.$perplexica.focusModes,
    };
  },
  mounted() {
    this.$perplexica.onerror = (errMsg) => {
      this.updateChat(`\n<span class="text-red-600">${errMsg}</span>\n`);
    };

    // this.$perplexica.onmessagefinished = (messageId, messageData) - Full callback signature
    this.$perplexica.onmessagefinished = (messageId) => {
      this.updateChat(
        `\nMessage id="${messageId}" DONE! Awaiting next question ...\n`,
      );
      this.canSend = true;
    };

    this.$perplexica.onrecieved = (_messageId, _messageData, segmentText) => {
      this.updateChat(segmentText);
    };
  },
  methods: {
    updateChat(message) {
      const escapedMessage = message.replaceAll("\n", "<br/>");
      this.chat += escapedMessage;
    },
    send() {
      if (this.canSend && this.message.length > 0) {
        this.canSend = false;
        this.$perplexica.send(this.message);
        this.updateChat(`\n${this.message}\n`);
        this.message = "";
        return;
      }

      this.updateChat("\nCurrently processing a request.\n");
    },
    updateFocusMode() {
      this.$perplexica.setFocusMode(this.selectedMode);
    },
    end() {
      this.$perplexica.endSession();
      this.message = "";
      this.updateChat("\nStarting new session ... enter question ...\n");
    },
  },
};
</script>
