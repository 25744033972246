<template>
  <div class="flex">
    <div class="tree-leaf w-full">
      <div class="flex h-[90px] md:h-[50px] items-center">
        <div class="w-full flex flex-wrap">
          <div class="w-full md:w-1/2">
            <vue-select
              v-model="selectedFieldModel"
              class="border border-1 border-black/10 focus:border-white px-2 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150 mr-2"
              label-by="label"
              value-by="value"
              :close-on-select="true"
              :searchable="true"
              :clear-on-close="true"
              :clear-on-select="true"
              :options="[{ label: 'Select...', value: '' }, ...fieldOptions]"
              placeholder="Select..."
              @focus="$emit('searchHasFocus')"
            />
          </div>
          <div class="w-full md:w-1/2 md:pl-3">
            <input
              v-if="
                (selectedField &&
                  availableFields[selectedField]?.type == 'text') ||
                (selectedField &&
                  availableFields[selectedField]?.type == 'query_string')
              "
              ref="inputField"
              v-model="queryModel"
              class="border border-1 border-black/10 focus:border-white px-2 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
              type="text"
              @focus="$emit('searchHasFocus')"
            />
            <vue-select
              v-if="
                selectedField &&
                availableFields[selectedField]?.type == 'selectEnum' &&
                enums[availableFields[selectedField].values]
              "
              v-model="queryModel"
              class="border border-1 border-black/10 focus:border-white px-2 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
              label-by="label"
              value-by="value"
              :close-on-select="true"
              :searchable="true"
              :clear-on-close="true"
              :clear-on-select="true"
              :options="[
                { label: 'Select...', value: '' },
                ...transformEnumForElasticsearch(
                  enums[availableFields[selectedField].values],
                ),
              ]"
              placeholder="Select..."
              @focus="$emit('searchHasFocus')"
            />
            <vue-select
              v-if="
                selectedField &&
                availableFields[selectedField]?.type == 'select' &&
                this[availableFields[selectedField].values] &&
                (!queryModel ||
                  this[availableFields[selectedField].values]
                    .map((item) => {
                      return item.value;
                    })
                    .indexOf(queryModel) > -1)
              "
              v-model="queryModel"
              class="border border-1 border-black/10 focus:border-white px-2 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
              label-by="label"
              value-by="value"
              :close-on-select="true"
              :searchable="true"
              :clear-on-close="true"
              :clear-on-select="true"
              :options="[
                { label: 'Select...', value: '' },
                ...this[availableFields[selectedField].values],
              ]"
              placeholder="Select..."
              @focus="$emit('searchHasFocus')"
            />
            <div
              v-else-if="
                selectedField &&
                availableFields[selectedField]?.type == 'select' &&
                this[availableFields[selectedField].values] &&
                queryModel &&
                this[availableFields[selectedField].values]
                  .map((item) => {
                    return item.value;
                  })
                  .indexOf(queryModel) === -1
              "
              class="py-2 px-3 border border-1 border-black/10 text-sm rounded"
            >
              {{ queryModel }}
            </div>
          </div>
        </div>
        <div class="">
          <div class="cursor-pointer ml-2" @click="$emit('removeCondition')">
            <i class="fa-regular fa-circle-xmark text-xl text-red-600"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    enums: Object,
    journalTitleList: Array,
    researchInterestList: Array,
    availableFields: Object,
    selectedField: String,
    query: String,
  },
  data() {
    return {
      selectedFieldModel: this.selectedField || "",
      queryModel: this.query || "",
    };
  },
  computed: {
    journalTitleOptions() {
      return this.journalTitleList.map((item) => {
        return { label: item.journalTitle, value: item.journalTitle };
      });
    },
    researchInterestOptions() {
      return this.researchInterestList.map((item) => {
        return { label: item.researchInterest, value: item.researchInterest };
      });
    },
    fieldOptions() {
      return Object.keys(this.availableFields).map((option) => {
        return { label: this.availableFields[option].label, value: option };
      });
    },
  },
  watch: {
    selectedField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectedFieldModel = newValue;
      }
    },
    query(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.queryModel = newValue;
      }
    },
    selectedFieldModel(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("selectField", newValue);
      }
    },
    queryModel(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("setQuery", newValue);
      }
    },
  },
  mounted() {
    this.$emit("selectField", this.selectedField);
    this.$emit("setQuery", this.query);
  },
  methods: {
    setFocus() {
      if (this.$refs.inputField) {
        this.$refs.inputField.focus();
      }
    },
    transformEnumForElasticsearch(values) {
      return values.map((value) => {
        return { label: value.label, value: value.label };
      });
    },
  },
};
</script>
