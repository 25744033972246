<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Schedule Meeting
          </h3>
        </div>
      </div>
    </div>
    <div v-if="step == 0" class="px-5">
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Meeting Duration
          </h6>
        </div>
        <vue-select
          v-model="durationSeconds"
          class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-blue-500 w-full ease-linear transition-all duration-150 w-full"
          label-by="label"
          value-by="value"
          :close-on-select="true"
          :searchable="true"
          :clear-on-close="true"
          :clear-on-select="true"
          :options="[{ label: 'Select...', value: '' }, ...durationOptions]"
          placeholder="Select..."
        />
      </div>
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Topic</h6>
        </div>
        <input v-model="topic" class="form-text-input" />
      </div>
      <div class="flex">
        <div class="relative min-w-0 break-words w-full sm:w-1/2 pr-5">
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">
              Attendee(s)
            </h6>
          </div>
          <div
            v-for="item in peopleWithCalendarStatus"
            :key="item.person.hashedId"
            class="border bg-white rounded py-2 px-3 flex justify-between"
          >
            {{ item.person.firstName }}&nbsp;{{ item.person.lastName }}
            <p
              class="text-blueGray-400 text-sm overflow-x-hidden text-ellipsis whitespace-nowrap"
            >
              {{ item.person.calc_EmailAddress }}
            </p>
          </div>
          <autocomplete-select
            v-model="addPersonForm.person"
            placeholder="Person Name"
            class="bg-white"
            id-key="hashedId"
            label-key="fullName"
            :label-function="
              function (person) {
                return `${person.firstName} ${person.lastName} (per-${person.hashedId})`;
              }
            "
            :filters="{}"
            autocomplete-endpoint="person"
            :initial-options="[]"
            :search-on-focus="true"
            :multiple="false"
            :row="{}"
            @input="selectPerson($event)"
          />
        </div>
        <div class="w-full sm:w-1/2">
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">
              Add Administrator
            </h6>
          </div>
          <div>
            <div v-for="admin in admins" :key="admin.hashedId" class="w-full">
              <div
                v-if="
                  peopleWithCalendarStatus
                    .map((person) => person.person.hashedId)
                    .indexOf(admin.hashedId) == -1
                "
                class="border border-blueGray-200 rounded py-2 bg-white px-3 cursor-pointer mr-2 flex items-center justify-between w-full"
                @click="addPerson(admin)"
              >
                {{ admin.firstName }}
                <p
                  class="text-blueGray-400 text-sm overflow-x-hidden text-ellipsis whitespace-nowrap"
                >
                  {{ admin.calc_EmailAddress }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="errorMessage" class="text-red-500">{{ errorMessage }}</div>
      <div class="flex justify-between mt-10">
        <div></div>
        <div>
          <button
            class="bg-porcelain-800 text-white px-3 py-2 rounded-lg"
            @click="advance()"
          >
            Create Meeting & Schedule
          </button>
        </div>
      </div>
    </div>
    <div v-if="step == 1" class="px-5">
      <div>
        <h6 class="uppercase text-sm mt-5 text-blueGray-500">Availability</h6>
      </div>
      <div class="flex">
        <div class="w-3/4">
          <div class="block text-right">
            <div
              class="cursor-pointer inline-block rounded-t-lg px-2 py-1"
              :class="{
                'bg-blueGray-500 text-white': showSettings,
                'bg-blueGray-100 text-blueGray-500': !showSettings,
              }"
              @click="toggleSettings()"
            >
              <i class="text-sm fa-solid fa-gear"></i>
            </div>
            <div
              v-if="showSettings"
              v-click-outside="onClickOutside"
              class="h-[220px] absolute bg-white overflow-hidden w-[500px] right-5 rounded-b-lg rounded-l-lg border border-blueGray-200 px-3 z-10"
            >
              <div class="h-[100px] text-center">
                <div>
                  <h6 class="uppercase text-sm text-blueGray-500 my-3">
                    Show Hours
                  </h6>
                </div>
                <div class="px-10">
                  <vue-slider
                    v-model="visibleHours"
                    :enable-cross="false"
                    :adsorb="true"
                    :interval="1"
                    :data="allHours"
                    :marks="hoursLabel"
                    :data-value="'id'"
                    :data-label="'name'"
                  ></vue-slider>
                </div>
              </div>
              <div class="h-[100px] text-center">
                <div>
                  <h6 class="uppercase text-sm text-blueGray-500 my-3">
                    Show Days
                  </h6>
                </div>
                <div class="px-10">
                  <vue-slider
                    v-model="visibleDayIndices"
                    :enable-cross="false"
                    :adsorb="true"
                    :interval="1"
                    :data="allDays"
                    :marks="daysLabel"
                    :data-value="'id'"
                    :data-label="'name'"
                  ></vue-slider>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white border rounded p-3 pt-5">
            <div class="flex justify-between mb-5 items-center px-5">
              <span
                class="bg-blueGray-500 px-2 py-1 rounded-lg cursor-pointer text-white text-sm"
                @click="changeWeek(-1)"
              >
                <i class="fa-solid fa-angles-left"></i> Previous Week
              </span>
              <span class="text-center">
                <span class="text-2xl"
                  >Week of {{ startAt.format("MMMM D, YYYY") }}</span
                ><br />
                <span class="text-sm"
                  >All times shown in local time,
                  {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}</span
                >
              </span>
              <span
                class="bg-blueGray-500 px-2 py-1 rounded-lg cursor-pointer text-white text-sm"
                @click="changeWeek(1)"
              >
                Next Week <i class="fa-solid fa-angles-right"></i>
              </span>
            </div>
            <div
              ref="bubble"
              :class="{
                'opacity-0':
                  hoverIntervalStart === null ||
                  hoverIntervalStart.isBefore($dayjs()),
              }"
              class="absolute bg-white border rounded shadow-lg px-3 py-2 w-[300px] pointer-events-none"
              :style="`margin-top:${hoverOffsetTop}px; margin-left:${hoverOffsetLeft}px; z-index:20`"
            >
              <p v-if="hoverIntervalStart" class="text-xl font-bold">
                {{ hoverIntervalStart.calendar() }}
              </p>
              <p class="text-sm">
                {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
              </p>
              <p class="text-sm">{{ durationSeconds / 60 }} minutes</p>
              <div
                v-for="(
                  personWithCalendarStatus, index
                ) in peopleWithCalendarStatus"
                :key="index"
                class="flex items-center"
              >
                <div v-if="hoverIntervalStart">
                  <div
                    v-if="
                      personWithCalendarStatus.availability &&
                      availableForHoverInterval(
                        personWithCalendarStatus.availability,
                      )
                    "
                    class="inline-block w-[15px] border-r-4 pr-5 py-2"
                    :style="{
                      'border-right-color': personWithCalendarStatus.color.bg,
                    }"
                  >
                    <i class="block text-xl fa-solid fa-check"></i>
                  </div>
                  <div
                    v-else-if="personWithCalendarStatus.availability"
                    class="inline-block w-[15px] border-r-4 pr-5 py-2"
                    :style="{
                      'border-right-color': personWithCalendarStatus.color.bg,
                    }"
                  >
                    <i class="block text-xl fa-solid fa-xmark"></i>
                  </div>
                  <div
                    v-else
                    class="inline-block w-[15px] border-r-4 pr-5 py-2"
                    :style="{
                      'border-right-color': personWithCalendarStatus.color.bg,
                    }"
                  >
                    <i class="block text-xl fa-solid fa-question"></i>
                  </div>
                </div>
                <div class="flex mt-2 pl-3">
                  <div class="pt-1">
                    <profile-image
                      :size="30"
                      :img-url="
                        avatarUrl(
                          personWithCalendarStatus.person.profile,
                          personWithCalendarStatus.person,
                        )
                      "
                    />
                  </div>
                  <div class="pl-2">
                    <div>
                      {{ personWithCalendarStatus.person.firstName }}
                      {{ personWithCalendarStatus.person.lastName }}<br />
                      <p
                        class="text-blueGray-400 text-sm overflow-x-hidden text-ellipsis whitespace-nowrap"
                      >
                        {{ personWithCalendarStatus.person.calc_EmailAddress }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full relative">
              <div class="text-center w-full">
                <div class="h-[30px]"></div>
                <div
                  v-for="(intervalStart, index) in intervalsForDate(
                    startAt.add(0, 'day'),
                  )"
                  :key="index"
                  class="border-r border-b border-blueGray-200 -mt-[1px] px-3 text-sm"
                >
                  {{ intervalStart.format("h:mm&nbsp;A") }}
                </div>
              </div>
              <div
                v-for="(day, index) in visibleDays"
                :key="index"
                class="w-full"
              >
                <div class="">
                  <div class="h-[30px] text-center">
                    <strong
                      >{{ day.name }}.,
                      {{ startAt.add(day.id, "day").format("MM/DD") }}</strong
                    >
                  </div>
                  <div
                    v-for="(intervalStart, intervalIndex) in intervalsForDate(
                      startAt.add(day.id, 'day'),
                    )"
                    :ref="`slot${intervalStart.unix()}`"
                    :key="intervalIndex"
                    class="border-r border-b border-blueGray-200 -mt-[1px] cursor-pointer text-sm"
                    :class="{
                      'bg-blueGray-500': isIntervalSelected(intervalStart),
                      'bg-blueGray-300':
                        hoverIntervalStart <= intervalStart &&
                        intervalStart < hoverIntervalEnd,
                    }"
                    @click="toggleSelectedInterval(intervalStart)"
                    @mouseover="setHoverInterval(intervalStart)"
                    @mouseleave="setHoverInterval(null)"
                  >
                    <span
                      v-if="isIntervalStart(intervalStart)"
                      class="absolute text-white ml-2"
                      >{{ intervalStart.format("h:mm&nbsp;A") }}</span
                    >
                    <div
                      v-if="intervalStart.isAfter($dayjs())"
                      class="ml-[70px]"
                    >
                      <span
                        v-for="(
                          personWithCalendarStatus, personIndex
                        ) in peopleWithCalendarStatus"
                        :key="personIndex"
                      >
                        <span v-if="personWithCalendarStatus.availability">
                          <span
                            v-if="
                              personWithCalendarStatus.availability[
                                intervalStart
                                  .utc()
                                  .format('YYYY-MM-DDTHH:mm:ss+00:00')
                              ]
                            "
                            class="inline-block w-[15px] text-center"
                            :style="{
                              'background-color':
                                personWithCalendarStatus.color.bg,
                              color: personWithCalendarStatus.color.text,
                            }"
                          >
                            &nbsp;
                          </span>
                          <span
                            v-else
                            class="inline-block w-[15px] text-center"
                          >
                            &nbsp;
                          </span>
                        </span>
                        <span
                          v-else
                          class="inline-block w-[15px] text-center"
                          :style="{
                            'background-color':
                              personWithCalendarStatus.color.bg,
                            color: personWithCalendarStatus.color.text,
                          }"
                          ><i class="fa-solid fa-question text-xs"></i
                        ></span>
                      </span>
                    </div>
                    <div v-else>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/4 px-5">
          <h3 class="text-2xl text-blueGray-700 font-light mt-1">
            Possible Times
          </h3>

          <div v-for="(v, k) in selectedIntervalsByDay" :key="k">
            <h4 class="text-xl text-blueGray-700 font-light mt-1 mb-2 mt-4">
              {{ k }}
            </h4>
            <div
              v-for="selectedInterval in v"
              :key="selectedInterval"
              class="ml-4"
            >
              {{ selectedInterval }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="errorMessage" class="text-red-500">{{ errorMessage }}</div>
      <div class="flex justify-between mt-10">
        <div>
          <button
            class="bg-porcelain-800 text-white px-3 py-2 rounded-lg"
            @click="back()"
          >
            Back
          </button>
        </div>
        <div>
          <button
            class="bg-porcelain-800 text-white rounded-lg px-3 py-2 mb-2"
            @click="addPossibleTimes()"
          >
            Request Availability
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import VueSlider from "vue-slider-component";
import { utils } from "common-frontend";
import AutocompleteSelect from "../../components/AutocompleteSelect.vue";
import ProfileImage from "../../components/ProfileImage.vue";
import "vue-slider-component/theme/default.css";

const { avatarUrl, keysToCamel, keysToSnake } = utils;

export default {
  name: "ScheduleMeeting",
  components: {
    AutocompleteSelect,
    ProfileImage,
    VueSlider,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      loading: false,
      step: 0,
      errorMessage: "",
      topic: "",
      meeting: {},
      hoverIntervalStart: null,
      hoverOffsetTop: 0,
      hoverOffsetLeft: 0,
      selectedIntervalStart: null,
      selectedIntervalStarts: [],
      intervalSeconds: 15 * 60,
      visibleHours: [8, 20],
      showSettings: false,
      admins: [],
      allHours: [
        {
          id: 0,
          name: "12am",
        },
        {
          id: 1,
          name: "1am",
        },
        {
          id: 2,
          name: "2am",
        },
        {
          id: 3,
          name: "3am",
        },
        {
          id: 4,
          name: "4am",
        },
        {
          id: 5,
          name: "5am",
        },
        {
          id: 6,
          name: "6am",
        },
        {
          id: 7,
          name: "7am",
        },
        {
          id: 8,
          name: "8am",
        },
        {
          id: 9,
          name: "9am",
        },
        {
          id: 10,
          name: "10am",
        },
        {
          id: 11,
          name: "11am",
        },
        {
          id: 12,
          name: "12pm",
        },
        {
          id: 13,
          name: "1pm",
        },
        {
          id: 14,
          name: "2pm",
        },
        {
          id: 15,
          name: "3pm",
        },
        {
          id: 16,
          name: "4pm",
        },
        {
          id: 17,
          name: "5pm",
        },
        {
          id: 18,
          name: "6pm",
        },
        {
          id: 19,
          name: "7pm",
        },
        {
          id: 20,
          name: "8pm",
        },
        {
          id: 21,
          name: "9pm",
        },
        {
          id: 22,
          name: "10pm",
        },
        {
          id: 23,
          name: "11pm",
        },
      ],
      hoursLabel(val) {
        return val % 6 === 0 ? {label: val === 0 ? '12am' : val === 12 ? '12pm' : val < 12 ? `${val  }am` : `${val - 12  }pm`} : false; // eslint-disable-line
      },
      visibleDayIndices: [1, 5],
      allDays: [
        {
          id: 0,
          name: "Sun",
        },
        {
          id: 1,
          name: "Mon",
        },
        {
          id: 2,
          name: "Tue",
        },
        {
          id: 3,
          name: "Wed",
        },
        {
          id: 4,
          name: "Thu",
        },
        {
          id: 5,
          name: "Fri",
        },
        {
          id: 6,
          name: "Sat",
        },
      ],
      daysLabel(val) {
        return {
          label: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ][val],
        };
      },
      startAt: this.$dayjs().startOf("week"),
      weekdays: this.$dayjs.weekdays(),
      addPersonForm: {
        person: { id: null, label: null },
      },
      peopleWithCalendarStatus: [],
      durationSeconds: 60 * 60,
      durationOptions: [
        {
          label: "15 minutes",
          value: 15 * 60,
        },
        {
          label: "30 minutes",
          value: 30 * 60,
        },
        {
          label: "45 minutes",
          value: 45 * 60,
        },
        {
          label: "60 minutes",
          value: 60 * 60,
        },
        {
          label: "1 hour 15 minutes",
          value: 75 * 60,
        },
        {
          label: "1 hour 30 minutes",
          value: 90 * 60,
        },
        {
          label: "1 hour 45 minutes",
          value: 105 * 60,
        },
        {
          label: "2 hours",
          value: 120 * 60,
        },
      ],
      palette: [
        {
          bg: "#3cb44b",
          text: "#ffffff",
        },
        {
          bg: "#ffe119",
          text: "#000000",
        },
        {
          bg: "#4363d8",
          text: "#ffffff",
        },
        {
          bg: "#e6194b",
          text: "#ffffff",
        },
        {
          bg: "#f58231",
          text: "#ffffff",
        },
        {
          bg: "#911eb4",
          text: "#ffffff",
        },
        {
          bg: "#42d4f4",
          text: "#000000",
        },
        {
          bg: "#f032e6",
          text: "#ffffff",
        },
        {
          bg: "#bcf60c",
          text: "#000000",
        },
        {
          bg: "#fabebe",
          text: "#000000",
        },
        {
          bg: "#008080",
          text: "#ffffff",
        },
        {
          bg: "#e6beff",
          text: "#000000",
        },
        {
          bg: "#9a6324",
          text: "#ffffff",
        },
        {
          bg: "#fffac8",
          text: "#000000",
        },
        {
          bg: "#800000",
          text: "#ffffff",
        },
        {
          bg: "#aaffc3",
          text: "#000000",
        },
        {
          bg: "#808000",
          text: "#ffffff",
        },
        {
          bg: "#ffd8b1",
          text: "#000000",
        },
        {
          bg: "#000075",
          text: "#ffffff",
        },
        {
          bg: "#808080",
          text: "#ffffff",
        },
      ],
      avatarUrl,
    };
  },
  computed: {
    hoverIntervalEnd() {
      if (this.hoverIntervalStart) {
        return this.hoverIntervalStart.add(this.durationSeconds, "second");
      }
      return null;
    },
    selectedIntervals() {
      const intervals = [];
      this.selectedIntervalStarts.forEach((intervalStart) => {
        intervals.push({
          start: intervalStart,
          end: intervalStart.add(this.durationSeconds, "second"),
        });
      });
      return intervals;
    },
    showCalendarConnectionWarning() {
      return (
        this.peopleWithCalendarStatus.filter((personWithCalendarStatus) => {
          return !personWithCalendarStatus.calendarStatus;
        }).length > 0
      );
    },
    visibleDays() {
      return this.allDays.filter((day, index) => {
        return (
          index >= this.visibleDayIndices[0] &&
          index <= this.visibleDayIndices[1]
        );
      });
    },
    selectedIntervalsByDay() {
      const days = {};
      this.selectedIntervalStarts.forEach((intervalStart) => {
        const result = intervalStart.calendar().split(" at ");
        if (Object.keys(days).indexOf(result[0]) === -1) {
          days[result[0]] = [];
        }
        if (intervalStart.calendar().indexOf(" at ") > -1) {
          days[result[0]].push(
            `${result[1]} to ${intervalStart
              .add(this.durationSeconds, "second")
              .format("h:mm A")}`,
          );
        } else {
          days[result[0]].push(
            `${intervalStart.format("h:mm A")} to ${intervalStart
              .add(this.durationSeconds, "second")
              .format("h:mm A")}`,
          );
        }
      });
      return days;
    },
  },
  watch: {
    startAt: {
      handler() {
        this.peopleWithCalendarStatus.forEach((personWithCalendarStatus) => {
          if (personWithCalendarStatus.calendarStatus) {
            personWithCalendarStatus.loadingStatus = true;
            this.loadAvailability(personWithCalendarStatus.person.hashedId);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query.person_id) {
      this.selectPerson({ id: this.$route.query.person_id });
    }
    this.loadAdmins();
  },
  methods: {
    advance() {
      if (this.step === 0) {
        if (this.topic === "") {
          this.errorMessage = "Please add a topic for this meeting.";
        } else if (this.peopleWithCalendarStatus.length < 2) {
          this.errorMessage =
            "Please select at least two people for this meeting.";
        } else {
          this.createMeeting();
          this.step += 1;
        }
      }
    },
    back() {
      this.step -= 1;
    },
    isIntervalSelected(intervalStart) {
      let selected = false;
      this.selectedIntervals.forEach((interval) => {
        if (interval.start <= intervalStart && intervalStart < interval.end) {
          selected = true;
        }
      });
      return selected;
    },
    isIntervalStart(intervalStart) {
      let isStart = false;
      this.selectedIntervals.forEach((interval) => {
        if (
          interval.start.utc().format("YYYY-MM-DDTHH:mm:ss+00:00") ===
          intervalStart.utc().format("YYYY-MM-DDTHH:mm:ss+00:00")
        ) {
          isStart = true;
        }
      });
      return isStart;
    },
    checkCalendarConnection(personId) {
      this.$api.axios
        .get(`/calendar/check-connection/${personId}/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.peopleWithCalendarStatus.forEach((personWithCalendarStatus) => {
            if (personWithCalendarStatus.person.hashedId === personId) {
              personWithCalendarStatus.calendarStatus = data.result;
              if (data.result === true) {
                this.loadAvailability(personId);
              } else {
                personWithCalendarStatus.loadingStatus = false;
              }
            }
          });
        });
    },
    loadAdmins() {
      this.$api.axios.get(`/person/get-admins/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.admins = data.results;
      });
    },
    loadAvailability(personId) {
      this.$api.axios
        .get(`/calendar/${personId}/slots/${this.intervalSeconds / 60}/`, {
          params: keysToSnake({ startAt: this.startAt.utc().toISOString() }),
        })
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.peopleWithCalendarStatus.forEach((personWithCalendarStatus) => {
            if (personWithCalendarStatus.person.hashedId === personId) {
              personWithCalendarStatus.availability = data.result;
              personWithCalendarStatus.loadingStatus = false;
            }
          });
        });
    },
    selectPerson(event) {
      this.addPersonForm.person = event;
      this.$api.axios.get(`/person/${event.id}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.addPerson(data);
        this.addPersonForm = { person: { id: null, label: null } };
      });
    },
    changeWeek(byHowMuch) {
      this.startAt = this.startAt.add(byHowMuch, "week");
    },
    setHoverInterval(intervalStart) {
      this.hoverIntervalStart = intervalStart;
      if (intervalStart) {
        const { bubble } = this.$refs;
        const div = this.$refs[`slot${intervalStart.unix()}`];
        if (div) {
          const rect = div[0].getBoundingClientRect();
          const bottom =
            window.innerHeight -
            (rect.top + bubble.getBoundingClientRect().height);
          if (bottom < 20) {
            this.hoverOffsetTop =
              div[0].offsetTop - bubble.getBoundingClientRect().height;
          } else {
            this.hoverOffsetTop = div[0].offsetTop;
          }
          const right = window.innerWidth - (rect.left + rect.width);
          if (right < 300) {
            this.hoverOffsetLeft = div[0].offsetLeft - 300;
          } else {
            this.hoverOffsetLeft = div[0].offsetLeft + rect.width;
          }
        }
      }
    },
    availableForHoverInterval(availability) {
      const intervals = [];
      let intervalToCheck = this.hoverIntervalStart;
      while (intervalToCheck < this.hoverIntervalEnd) {
        intervals.push(intervalToCheck);
        intervalToCheck = intervalToCheck.add(this.intervalSeconds, "second");
      }
      const unavailableIntervals = intervals.filter((interval) => {
        return (
          availability[interval.utc().format("YYYY-MM-DDTHH:mm:ss+00:00")] ===
          false
        );
      });
      return unavailableIntervals.length === 0;
    },
    toggleSelectedInterval(intervalStart) {
      this.hoverIntervalStart = null;
      const index = this.selectedIntervalStarts.findIndex((interval) => {
        return (
          interval.utc().format("YYYY-MM-DDTHH:mm:ss+00:00") ===
          intervalStart.utc().format("YYYY-MM-DDTHH:mm:ss+00:00")
        );
      });
      if (index > -1) {
        this.selectedIntervalStarts.splice(index, 1);
      } else {
        this.selectedIntervalStarts.push(intervalStart);
        this.selectedIntervalStarts.sort();
      }
    },
    intervalsForDate(startAt) {
      const intervals = [];
      const endAt = startAt.add(24, "hour");
      let intervalStart = startAt;
      while (intervalStart < endAt) {
        if (
          intervalStart.format("HH") >= this.visibleHours[0] &&
          intervalStart.format("HH") < this.visibleHours[1]
        ) {
          intervals.push(intervalStart);
        }
        intervalStart = intervalStart.add(this.intervalSeconds, "second");
      }
      return intervals;
    },
    addPerson(person) {
      this.errorMessage = "";
      const usedColors = this.peopleWithCalendarStatus.map(
        (personWithCalendarStatus) => {
          return personWithCalendarStatus.color;
        },
      );
      const firstUnusedColor = this.palette.find((color) => {
        return usedColors.indexOf(color) === -1;
      });
      this.peopleWithCalendarStatus.push({
        calendarStatus: null,
        person,
        availability: null,
        loadingStatus: true,
        color: firstUnusedColor,
      });
      this.checkCalendarConnection(person.hashedId);
    },
    createMeeting() {
      const payload = keysToSnake({
        topic: this.topic,
        durationSeconds: this.durationSeconds,
        inviteeIds: this.peopleWithCalendarStatus.map(
          (personWithCalendarStatus) => {
            return personWithCalendarStatus.person.hashedId;
          },
        ),
      });
      this.$api.axios.post(`/meeting/`, payload).then((resp) => {
        const data = keysToCamel(resp.data);
        this.meeting = data;
      });
    },
    addPossibleTimes() {
      this.meeting.meetingRoles.forEach((meetingRole) => {
        const startTimes = this.selectedIntervalStarts.map((intervalStart) => {
          return intervalStart.utc().format("YYYY-MM-DDTHH:mm:ss");
        });
        this.$api.axios
          .post(
            `/meeting/${this.meeting.hashedId}/role/${meetingRole.hashedId}/`,
            keysToSnake({
              startTimes,
              durationSeconds: this.durationSeconds,
            }),
          )
          .then((resp) => {
            const data = keysToCamel(resp.data);
            this.meeting = data;
          });
      });
    },
    sendInvitations() {
      const payload = keysToSnake({
        title: this.inviteForm.title,
        location: this.inviteForm.location,
        inviteeIds: this.peopleWithCalendarStatus.map(
          (personWithCalendarStatus) => {
            return personWithCalendarStatus.person.hashedId;
          },
        ),
        startAt: this.selectedIntervalStart.toISOString(),
        durationSeconds: this.durationSeconds,
      });
      this.$api.axios.post(`/calendar/create-event/`, payload);
    },
    promptToConnectCalendar(emailAddress) {
      const payload = keysToSnake({ emailAddress });
      this.$api.axios
        .put(`/person/send-calendar-prompt-email/`, payload)
        .then(() => {
          this.$toast.success("Prompt has been sent.");
        });
    },
    onClickOutside() {
      this.showSettings = false;
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
  },
};
</script>
