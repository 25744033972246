<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Collaboration Requests
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :test-boolean-key="testBooleanKey"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
    <right-side-modal ref="schedulerModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">Schedule Meeting</h4>
      </template>
      <template #body>
        <scheduler-modal
          v-if="schedulerHashedId"
          id-type="collaborationRequest"
          :hashed-id="schedulerHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";
import RightSideModal from "../Modals/RightSide.vue";
import SchedulerModal from "../Scheduler/SchedulerModal.vue";

export default {
  name: "CollaborationRequests",
  components: {
    DataTable,
    RightSideModal,
    SchedulerModal,
  },
  data() {
    return {
      singularLabel: "collaboration request",
      pluralLabel: "collaboration requests",
      adminPrefix: "collaboration-requests",
      apiPrefix: "work/request",
      testBooleanKey: "collaborationSpace.isTestAccount",
      columns: {
        "collaborationSpace.isTestAccount": {
          type: "testBoolean",
          label: "Is Test Account",
          hidden: "never",
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "testBoolean",
            default: { label: "No", value: false },
          },
        },
        hashedId: {
          type: "formattedString",
          label: "ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "cr-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        "collaborationSpace.name": {
          type: "formattedString",
          label: "Collaboration Space",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-nowrap">${row.collaborationSpace.name}</span>`,
            );
            els.push(
              `<button class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"><a href="/admin/collaboration-spaces/edit/${row.collaborationSpace.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        title: {
          type: "formattedString",
          label: "Title",
          format(row) {
            const els = [];
            els.push(
              `<p class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">${row.title}</p>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          hidden: "never",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        expertCount: {
          type: "formattedString",
          label: "Expert Count",
          format(row) {
            const els = [];
            els.push(`<span>${row.profiles.length}</span>`);
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
            matchType: "wildcard",
            value: "",
          },
        },
        status: {
          type: "enum",
          label: "Status",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        title: {
          label: "Title:",
          placeholder: "Enter title",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        description: {
          label: "Description:",
          placeholder: "Enter description",
          type: "text",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        researcherExpertise: {
          label: "Researcher Expertise:",
          placeholder: "Enter description",
          type: "text",
          value: "",
          default: "",
          validatorTypes: [],
        },
        internalNotes: {
          label: "Internal Notes:",
          placeholder: "Enter internal notes",
          type: "text",
          value: "",
          default: "",
        },
        status: {
          label: "Status",
          placeholder: "Select status",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/CollaborationRequestStatus",
          searchOnFocus: true,
        },
        isAnonymized: {
          label: "Hide Researcher Names:",
          checkboxLabel:
            "This collaboration request should hide names from the client.",
          type: "boolean",
          value: "",
          default: "",
        },
        requiresLabResources: {
          label: "Requires Lab Resources",
          placeholder: "Select option",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/RequiresLabResources",
          searchOnFocus: true,
        },
        geographicRestriction: {
          label: "Geographic Restriction",
          placeholder: "Select option",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/GeographicRestriction",
          searchOnFocus: true,
        },
        engagementTimeline: {
          label: "Anticipated Start Date",
          placeholder: "Select when to start",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/EngagementTimeline",
          searchOnFocus: true,
        },
        engagementDuration: {
          label: "Anticipated Duration",
          placeholder: "Select how long this project will last",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/EngagementDuration",
          searchOnFocus: true,
        },
        creditBudget: {
          label: "Credit Budget (x100):",
          placeholder: "Enter credit budget (times 100)",
          type: "string",
          value: "",
          default: "",
        },
        profiles: {
          label: "Profiles",
          type: "dataTable",
          value: "",
          singularLabel: "profile",
          pluralLabel: "profiles",
          adminPrefix: "collaboration-request-profiles",
          apiPrefix: "work/request/profile",
          parentKey: "collaboration_request.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Hashed ID",
              hidden: "yes",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            fullDisplayName: {
              type: "formattedString",
              label: "Full Display Name",
              format(row) {
                const els = [];
                els.push(`<span>${row.profile.fullDisplayName}</span>`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
                matchType: "wildcard",
                value: "",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            status: {
              type: "enum",
              label: "Status",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            orderIndex: {
              type: "integer",
              label: "Order Index",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: false,
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Decrease Order Index",
                qualifier() {
                  return true;
                },
                action(event, row, vm) {
                  const url = `work/request/${vm.parentRow.hashedId}/profile/${row.hashedId}/`;
                  return [
                    "apiAction",
                    "putReload",
                    url,
                    { orderIndex: (row.orderIndex || 0) - 1 },
                  ];
                },
              },
              {
                label: "Increase Order Index",
                qualifier() {
                  return true;
                },
                action(event, row, vm) {
                  const url = `work/request/${vm.parentRow.hashedId}/profile/${row.hashedId}/`;
                  return [
                    "apiAction",
                    "putReload",
                    url,
                    { orderIndex: (row.orderIndex || 0) + 1 },
                  ];
                },
              },
            ],
          },
        },
        deliverableTypes: {
          label: "Deliverable Types",
          type: "dataTable",
          value: "",
          singularLabel: "deliverable type",
          pluralLabel: "deliverable types",
          adminPrefix: "deliverable-types",
          apiPrefix: "work/request/deliverable-type",
          parentKey: "collaboration_request.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Request ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            deliverableType: {
              type: "enum",
              label: "Deliverable Type",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
        collaboratorTypes: {
          label: "Collaborator Types",
          type: "dataTable",
          value: "",
          singularLabel: "collaborator type",
          pluralLabel: "collaborator types",
          adminPrefix: "collaborator-types",
          apiPrefix: "work/request/collaborator-type",
          parentKey: "collaboration_request.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Request ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            collaboratorType: {
              type: "enum",
              label: "Collaborator Type",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
        revisions: {
          type: "revisionHistory",
          modelClassName: "collaboration_request",
          childKeys: [
            {
              modelClassName: "collaboration_request_deliverable_type",
              relationship: "deliverableTypes",
            },
            {
              modelClassName: "collaboration_request_industry",
              relationship: "industries",
            },
            {
              modelClassName: "collaboration_request_company_size",
              relationship: "companySizes",
            },
            {
              modelClassName: "collaboration_request_company_stage",
              relationship: "companyStages",
            },
            {
              modelClassName: "collaboration_request_language",
              relationship: "languages",
            },
            {
              modelClassName: "collaboration_request_profile",
              relationship: "profiles",
            },
          ],
          value: "",
        },
      },
      actions: {
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
        menuItems: [
          {
            label: "Search for Profiles",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `${
                import.meta.env.VITE_ADMIN_BASE_URL
              }/admin/collaboration-requests/detail/${row.hashedId}`;
              return ["visitUrl", "get", url];
            },
          },
          {
            label: "Schedule Meeting",
            qualifier() {
              return true;
            },
            action(event, row) {
              const self = this;
              return [
                "executeFunc",
                function _() {
                  self.vm.loadScheduler(row.hashedId);
                },
              ];
            },
            vm: this,
          },
        ],
      },
    };
  },
  methods: {
    loadScheduler(hashedId) {
      this.schedulerHashedId = hashedId;
      this.toggleSchedulerModal();
    },
    toggleSchedulerModal() {
      this.$refs.schedulerModal.toggleModal();
    },
  },
};
</script>
