<template>
  <section
    v-if="column.expanded"
    class="flex flex-col min-h-[360px] justify-between rounded-lg bg-white shadow-lg mb-3 border-2 border-blueGray-500"
  >
    <div class="min-h-[300px]">
      <collaboration-space-banner
        class="relative rounded-md bg-blueGray-500"
        :height="300"
        :img-url="
          bannerUrl(this, collaborationRequest?.collaborationSpace.banner, 400)
        "
        :banner="collaborationRequest?.collaborationSpace.banner"
      />
      <div
        class="relative -mt-[290px] flex items-center justify-between mb-3 px-3"
      >
        <div class="flex items-center">
          <collaboration-space-logo
            class="bg-white ml-0 z-10"
            :size="30"
            :img-url="logoUrl(collaborationRequest?.collaborationSpace)"
          />
          <div
            class="-ml-3 pl-5 bg-black/50 rounded-r-lg pr-2 text-sm py-1 text-white"
          >
            {{ collaborationRequest?.collaborationSpace.name }}
          </div>
        </div>
        <assembly-line-collaboration-request-dropdown
          :column="column"
          :collaboration-request="collaborationRequest"
        />
      </div>
      <div class="relative rounded-lg bg-white px-3 mx-3 py-2 min-h-[200px]">
        <h4
          class="text-lg underline cursor-pointer"
          @click="$emit('selectCollaborationRequest')"
          v-html="collaborationRequest?.title || '<em>Untitled</em>'"
        ></h4>
        <hr class="mt-1 mb-3" />
        <section>
          <h6 class="uppercase text-xs">
            Created
            <moment-from-now
              :title="collaborationRequest.createdAt"
              :value="collaborationRequest.createdAt"
              :interval="5000"
              :tag="'span'"
            />
            By
          </h6>
          <div class="flex items-center py-2">
            <profile-image
              :size="45"
              :img-url="avatarUrl(null, collaborationRequest?.author)"
            />
            <div class="ml-2">
              <p class="m-0">
                <span v-if="collaborationRequest?.author.hashedId">
                  {{ collaborationRequest?.author.firstName }}
                  {{ collaborationRequest?.author.lastName }}
                  <span
                    v-if="collaborationRequest.author.isTestAccount"
                    class="bg-orange-200 ml-2 px-1 text-xs"
                    >TEST</span
                  >
                </span>
                <span v-else><em>Unknown</em></span>
              </p>
              <p class="relative -mt-1">
                <span class="form-help mb-0">{{
                  collaborationRequest?.author.calc_EmailAddress
                }}</span>
              </p>
              <p
                v-if="collaborationRequest?.author.lastLoginAt"
                class="relative -mt-1 uppercase text-xs"
              >
                Last Login
                <moment-from-now
                  v-if="collaborationRequest?.author.lastLoginAt"
                  :title="collaborationRequest?.author.lastLoginAt"
                  :value="collaborationRequest?.author.lastLoginAt"
                  :interval="5000"
                  :tag="'span'"
                />
              </p>
            </div>
          </div>
          <h6 class="uppercase text-xs mt-4 mb-2">Candidate Profiles</h6>
          <div
            v-for="profile in collaborationRequest.profiles"
            :key="profile.hashedId"
            class="flex items-center mb-3"
          >
            <div>
              <profile-image :size="45" :img-url="avatarUrl(profile.profile)" />
            </div>
            <div class="ml-2 w-full">
              <p class="m-0">
                {{ profile.profile.person.firstName }}
                {{ profile.profile.person.lastName }}
                <span
                  v-if="collaborationRequest.author.isTestAccount"
                  class="bg-orange-200 ml-2 px-1 text-xs"
                  >TEST</span
                >
              </p>
              <p class="relative -mt-1">
                <span class="form-help mb-0">{{
                  profile.profile.person.calc_EmailAddress
                }}</span>
              </p>
              <p
                v-if="profile.profile.person.lastLoginAt"
                class="relative uppercase text-xs"
              >
                Last Login
                <moment-from-now
                  v-if="profile.profile.person.lastLoginAt"
                  :title="profile.profile.person.lastLoginAt"
                  :value="profile.profile.person.lastLoginAt"
                  :interval="5000"
                  :tag="'span'"
                />
              </p>
            </div>
          </div>
          <div v-if="collaborationRequest.profiles.length === 0">
            <em>None</em>
          </div>
        </section>
      </div>
    </div>
    <div class="border-t px-4 pb-2 bg-blueGray-300 rounded-b-md">
      <assembly-line-representatives
        :collaboration-space="collaborationRequest?.collaborationSpace"
        :roles="roles"
        @reload="$emit('reload')"
      />
    </div>
  </section>
  <section v-else>
    <div class="w-[200px]">
      <h4
        class="text-xs underline cursor-pointer"
        @click="$emit('selectCollaborationRequest')"
        v-html="collaborationRequest?.title || '<em>Untitled</em>'"
      ></h4>
    </div>
  </section>
</template>
<script>
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";
import CollaborationSpaceBanner from "../CollaborationSpaceBanner.vue";
import CollaborationSpaceLogo from "../CollaborationSpaceLogo.vue";
import MomentFromNow from "../MomentFromNow.vue";
import AssemblyLineCollaborationRequestDropdown from "../Dropdowns/AssemblyLineCollaborationRequest.vue";
import AssemblyLineRepresentatives from "./Representatives.vue";

const { avatarUrl, bannerUrl, logoUrl } = utils;

export default {
  components: {
    MomentFromNow,
    ProfileImage,
    CollaborationSpaceBanner,
    CollaborationSpaceLogo,
    AssemblyLineCollaborationRequestDropdown,
    AssemblyLineRepresentatives,
  },
  props: {
    collaborationRequest: Object,
    column: Object,
    roles: Array,
  },
  data() {
    return {
      avatarUrl,
      bannerUrl,
      logoUrl,
      menuOpen: false,
    };
  },
  computed: {},
  methods: {},
};
</script>
