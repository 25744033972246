<template>
  <div>
    <form @submit.prevent="submitForm()">
      <div>
        <button type="submit">Submit</button>
      </div>
    </form>
    <div id="gjs" class="block"></div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import grapesjs from "grapesjs";
import grapesJsPresetWebpage from "grapesjs-preset-webpage";
import grapesJsBlocksBasic from "grapesjs-blocks-basic";
import grapesJsParserPostCss from "grapesjs-parser-postcss";
import { utils } from "common-frontend";
import "grapesjs/dist/css/grapes.min.css";

const { keysToSnake, keysToCamel, loadEnums } = utils;

export default {
  name: "WebBuilder",
  props: {
    hashedId: String,
  },
  data() {
    return {
      enums: {},
      editorContent: {},
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      editor: {},
      fontGroupings: [],
      site: {},
    };
  },
  computed: {
    selectedFontGrouping() {
      if (this.enums.fontGroupingOptions) {
        const match = this.enums.fontGroupingOptions.find((fontGrouping) => {
          return fontGrouping.value === this.site.fontGrouping?.value;
        });
        if (match) {
          return JSON.parse(match.metadataJson);
        }
      }
      return null;
    },
  },
  mounted() {
    loadEnums(this, {
      FontGrouping: "fontGroupingOptions",
    });
    this.loadContent();
  },
  methods: {
    constructEditor() {
      // Inline storage
      const inlineStorage = (editor) => {
        const self = this;

        editor.Storage.add("inline", {
          load() {
            return self.editorContent;
          },
          store(data) {
            const component = editor.Pages.getSelected().getMainComponent();
            self.editorContent = data;
            self.editorContent.html = editor.getHtml({ component });
            self.editorContent.css = editor.getCss({ component });
          },
        });
      };
      const setFonts = (fontGrouping) => {
        if (fontGrouping) {
          window.editor = this.editor;
          const styleManager = this.editor.StyleManager;
          const fontProperty = styleManager.getProperty(
            "typography",
            "font-family",
          );
          const list = fontProperty.get("options");
          list.splice(0, list.length);
          fontGrouping.forEach((font) => {
            list.push({ id: font.value, name: font.label });
          });
          fontProperty.set("list", list);
          styleManager.render();
        }
      };
      this.editor = grapesjs.init({
        container: "#gjs",
        height: "900px",
        width: "100%",
        plugins: [
          grapesJsPresetWebpage,
          grapesJsBlocksBasic,
          grapesJsParserPostCss,
          inlineStorage,
        ],
        storageManager: { type: "inline" },
        deviceManager: {
          devices: [
            {
              id: "desktop",
              name: "Desktop",
              width: "",
            },
            {
              id: "tablet",
              name: "Tablet",
              width: "768px",
              widthMedia: "992px",
            },
            {
              id: "mobilePortrait",
              name: "Mobile portrait",
              width: "320px",
              widthMedia: "575px",
            },
          ],
        },
        colorPicker: {
          appendTo: "parent",
          offset: {
            top: 0,
            left: 0,
          },
          palette: [
            [
              "#000000",
              "#ff0000",
              "#ff8000",
              "#ffff00",
              "#008000",
              "#0000ff",
              "#4b0082",
            ],
          ],
        },
        canvas: {
          styles: this.selectedFontGrouping?.map((font) => {
            return font.url;
          }),
        },
        pluginsOpts: {
          [grapesJsPresetWebpage]: {
            blocksBasicOpts: {
              blocks: [
                "column1",
                "column2",
                "column3",
                "column3-7",
                "text",
                "link",
                "image",
                "video",
              ],
              flexGrid: 1,
            },
            blocks: ["link-block", "quote", "text-basic"],
          },
          [grapesJsBlocksBasic]: { flexGrid: true },
        },
      });
      this.editor.on("load", setFonts(this.selectedFontGrouping));
      this.editor.render();
    },
    submitForm() {
      this.submitContent();
    },
    loadContent() {
      this.loading = true;
      this.$api.axios
        .get(`${this.apiBaseUrl}/site-section/${this.hashedId}/`)
        .then((res) => {
          const data = keysToCamel(res.data);
          this.editorContent = JSON.parse(data.content);
          this.site = data.site;
          nextTick(() => {
            this.constructEditor();
          });
          this.loading = false;
        });
    },
    submitContent() {
      this.loading = true;
      const payload = keysToSnake({
        content: JSON.stringify(this.editorContent),
      });
      this.$api.axios
        .put(`${this.apiBaseUrl}/site-section/${this.hashedId}/`, payload)
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
