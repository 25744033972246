<template>
  <assembly-line-right-side-modal ref="modal" :showing="false">
    <template #body>
      <section
        class="flex flex-col h-full justify-between rounded-l-lg bg-white shadow-lg mb-3"
      >
        <div class="grow">
          <collaboration-space-banner
            class="relative rounded-l-lg bg-blueGray-500"
            :height="300"
            :img-url="
              bannerUrl(
                this,
                collaborationRequest?.collaborationSpace.banner,
                400,
              )
            "
            :banner="collaborationRequest?.collaborationSpace.banner"
          />
          <div
            class="relative -mt-[290px] flex items-center justify-between mb-3 px-3"
          >
            <div class="flex items-center">
              <collaboration-space-logo
                class="bg-white ml-0 z-10"
                :size="30"
                :img-url="logoUrl(collaborationRequest?.collaborationSpace)"
              />
              <div
                class="-ml-3 pl-5 bg-black/50 rounded-r-lg pr-2 text-sm py-1 text-white"
              >
                {{ collaborationRequest?.collaborationSpace.name }}
              </div>
            </div>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click.prevent="toggleModal()"
            >
              <span
                class="bg-transparent text-white h-6 w-6 text-2xl block outline-none focus:outline-none"
              >
                ×
              </span>
            </button>
          </div>
          <div
            class="relative rounded-lg bg-white px-3 mx-3 py-2 min-h-[200px]"
          >
            <h4
              class="text-lg"
              v-html="collaborationRequest?.title || '<em>Untitled</em>'"
            ></h4>
            <hr class="mt-1 mb-3" />
            {{ collaborationRequest }}
            <section>
              <div
                v-for="profile in collaborationRequest?.profiles"
                :key="profile.hashedId"
                class="flex items-top mb-3"
              >
                <div>
                  <profile-image
                    :size="40"
                    :img-url="avatarUrl(profile.profile)"
                  />
                </div>
                <div class="ml-2 w-full">
                  <p class="m-0">
                    {{ profile.profile.person.firstName }}
                    {{ profile.profile.person.lastName }}
                  </p>
                  <p class="relative -mt-1">
                    <span class="form-help mb-0">{{
                      profile.profile.person.calc_EmailAddress
                    }}</span>
                  </p>
                  <div
                    v-if="
                      profile.clientNotes ||
                      profile.internalNotes ||
                      profile.sharedNotes
                    "
                    class="bg-blueGray-100 rounded-lg px-3 py-3 w-full mt-1"
                  >
                    <div v-if="profile.sharedNotes">
                      <p class="text-xs uppercase font-bold">Shared Notes</p>
                      <markdown-viewer
                        v-if="profile.sharedNotes"
                        :text="profile.sharedNotes"
                      />
                    </div>
                    <div v-if="profile.clientNotes" class="mt-3">
                      <p class="text-xs uppercase font-bold">Client Notes</p>
                      <markdown-viewer
                        v-if="profile.clientNotes"
                        :text="profile.clientNotes"
                      />
                    </div>
                    <div v-if="profile.internalNotes" class="mt-3">
                      <p class="text-xs uppercase font-bold">Internal Notes</p>
                      <markdown-viewer
                        v-if="profile.internalNotes"
                        :text="profile.internalNotes"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </template>
  </assembly-line-right-side-modal>
</template>
<script>
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";
import CollaborationSpaceBanner from "../CollaborationSpaceBanner.vue";
import CollaborationSpaceLogo from "../CollaborationSpaceLogo.vue";
import MarkdownViewer from "../MarkdownViewer.vue";
import AssemblyLineRightSideModal from "../Modals/AssemblyLineRightSide.vue";

const { avatarUrl, bannerUrl, logoUrl } = utils;

export default {
  components: {
    ProfileImage,
    CollaborationSpaceBanner,
    CollaborationSpaceLogo,
    MarkdownViewer,
    AssemblyLineRightSideModal,
  },
  props: {
    collaborationRequest: Object,
  },
  data() {
    return {
      avatarUrl,
      bannerUrl,
      logoUrl,
      menuOpen: false,
    };
  },
  computed: {
    notedSourceReps() {
      return this.collaborationRequest?.collaborationSpace?.collaborationSpaceRoles.filter(
        (role) => {
          return role.role.name === "NotedSource Representative";
        },
      );
    },
  },
  methods: {
    toggleModal() {
      this.$refs.modal.toggleModal();
    },
  },
};
</script>
