<template>
  <nav
    class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <a
          href="/"
          class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        >
          <div class="flex mt-[18px]">
            <img src="/img/notedsource-logo.svg" class="w-[197px] h-[35px]" />
          </div>
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
export default {};
</script>
