<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Collaboragent
          </h3>
        </div>
      </div>
    </div>
    <div class="px-8">
      <div class="mx-auto max-w-[400px] mb-10 border bg-blueGray-200 px-5 py-3">
        <div class="text-sm mb-10">Chat ID: {{ sessionId }}</div>
        <div
          v-for="message in messagesToShow"
          :key="message"
          class="flex items-end"
        >
          <div>
            <div
              v-if="message.slice(0, 6) !== '[User]'"
              class="bg-blueGray-400 w-[40px] h-[40px] rounded-full absolute flex items-center justify-center -mt-[50px]"
            >
              <i class="fa-solid fa-robot"></i>
            </div>
          </div>
          <div
            class="rounded-lg border w-full"
            :class="{
              'ml-5 mr-10 px-3 py-2 text-right bg-blueGray-700 text-white mb-3':
                message.slice(0, 6) === '[User]',
              'mr-5 ml-10 px-3 py-2 text-blueGray-700 bg-white mb-3':
                message.slice(0, 6) !== '[User]',
            }"
          >
            {{ message.replace("[User]", "") }}
          </div>
          <div>
            <div
              v-if="message.slice(0, 6) === '[User]'"
              class="bg-blueGray-400 w-[40px] h-[40px] rounded-full absolute flex items-center justify-center -mt-[50px] -ml-[40px]"
            >
              <i class="fa-solid fa-user"></i>
            </div>
          </div>
        </div>
        <div class="flex border-t border-blueGray-700 mt-3 pt-3 items-center">
          <textarea
            v-model="q"
            class="rounded-lg bg-white w-full mr-3 border-blueGray-300"
            rows="5"
            :class="{ 'opacity-50': loading }"
          />
          <button @click="sendMessage()">
            <i v-if="!loading" class="fa-solid fa-paper-plane"></i>
            <i v-else class="fa-solid fa-circle-notch fa-spin"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";

const { keysToCamel, keysToSnake } = utils;

export default {
  name: "Collaboragent",
  components: {},
  data() {
    return {
      loading: false,
      sessionId: null,
      q: "",
      messageHistory: [],
    };
  },
  computed: {
    messagesToShow() {
      return this.messageHistory.slice(1);
    },
  },
  mounted() {
    this.startSession();
  },
  methods: {
    startSession() {
      this.loading = true;
      this.$api.axios.post(`/collaboragent/`, {}).then((resp) => {
        this.loading = false;
        const data = keysToCamel(resp.data);
        this.sessionId = data.sessionId;
        this.messageHistory = data.nextQuery.messageHistory;
      });
    },
    sendMessage() {
      if (!this.loading) {
        this.loading = true;
        this.$api.axios
          .post(
            `/collaboragent/`,
            keysToSnake({
              sessionId: this.sessionId,
              q: this.q,
            }),
          )
          .then((resp) => {
            this.loading = false;
            this.q = "";
            const data = keysToCamel(resp.data);
            this.sessionId = data.sessionId;
            this.messageHistory = data.nextQuery.messageHistory;
          });
      }
    },
  },
};
</script>
