<template>
  <section class="relative bg-white">
    <div class="container mx-auto">
      <div class="relative flex flex-col min-w-0 break-words w-full px-5">
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Meeting Duration
          </h6>
        </div>
        <vue-select
          v-model="durationSeconds"
          class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-blue-500 w-full ease-linear transition-all duration-150 w-full"
          label-by="label"
          value-by="value"
          :close-on-select="true"
          :searchable="true"
          :clear-on-close="true"
          :clear-on-select="true"
          :options="[{ label: 'Select...', value: '' }, ...durationOptions]"
          placeholder="Select..."
        />
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Attendees</h6>
        </div>
        <div class="bg-blueGray-100 p-5 rounded-lg">
          <div
            v-if="showCalendarConnectionWarning"
            class="rounded-lg bg-porcelain-200/80 text-porcelain-900/75 hover:text-porcelain-900 px-3 py-1 border border-porcelain-300 mb-3"
          >
            One or more of the attendees below does not have an active
            connection to Calendly, indicated by the warning on their tile.
          </div>
          <div class="flex flex-wrap">
            <div
              v-for="(
                personWithCalendarStatus, index
              ) in peopleWithCalendarStatus"
              :key="index"
              class="border-l-[10px] border border-blueGray-200 rounded-lg flex py-2 mb-2 mr-2 w-[300px] bg-white"
              :style="{
                'border-left-color': personWithCalendarStatus.color.bg,
              }"
            >
              <div class="w-3/4 px-3">
                <profile-image
                  :size="50"
                  :img-url="
                    avatarUrl(
                      personWithCalendarStatus.person.profile,
                      personWithCalendarStatus.person,
                    )
                  "
                />
                <div class="mt-2">
                  {{ personWithCalendarStatus.person.firstName }}
                  {{ personWithCalendarStatus.person.lastName }}<br />
                  <p
                    class="text-blueGray-400 text-sm overflow-x-hidden text-ellipsis whitespace-nowrap"
                  >
                    {{ personWithCalendarStatus.person.calc_EmailAddress }}
                  </p>
                </div>
              </div>
              <div class="w-1/4 px-3 text-right flex flex-col justify-between">
                <span v-if="personWithCalendarStatus.loadingStatus">
                  <i class="fas fa-circle-notch fa-spin mb-3"></i>
                </span>
                <span v-else>
                  <i
                    v-if="personWithCalendarStatus.calendarStatus"
                    class="fa-solid fa-calendar-check"
                  ></i>
                  <span v-else>
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    <i
                      class="fa-regular fa-envelope ml-1 cursor-pointer"
                      @click="
                        promptToConnectCalendar(
                          personWithCalendarStatus.person.calc_EmailAddress,
                        )
                      "
                    ></i>
                  </span>
                </span>
                <i
                  class="fa-regular fa-trash-can cursor-pointer"
                  @click="
                    removePerson(personWithCalendarStatus.person.hashedId)
                  "
                ></i>
              </div>
            </div>
          </div>
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">Add Person</h6>
          </div>
          <autocomplete-select
            v-model="addPersonForm.person"
            placeholder="Person Name"
            class="bg-white"
            id-key="hashedId"
            label-key="fullName"
            :label-function="
              function (person) {
                return `${person.firstName} ${person.lastName} (per-${person.hashedId})`;
              }
            "
            :filters="{}"
            autocomplete-endpoint="person"
            :initial-options="[]"
            :search-on-focus="true"
            :multiple="false"
            :row="{}"
            @input="selectPerson($event)"
          />
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">
              Add Administrator
            </h6>
          </div>
          <div class="flex flex-wrap">
            <div
              v-for="admin in admins"
              :key="admin.hashedId"
              class="w-full md:w-1/2 lg:w-1/3"
            >
              <div
                v-if="
                  peopleWithCalendarStatus
                    .map((person) => person.person.hashedId)
                    .indexOf(admin.hashedId) == -1
                "
                class="border border-blueGray-200 rounded-lg py-2 bg-white px-3 cursor-pointer mr-2 flex items-center justify-between"
                @click="addPerson(admin)"
              >
                {{ admin.firstName }}
                <p
                  class="text-blueGray-400 text-sm overflow-x-hidden text-ellipsis whitespace-nowrap"
                >
                  {{ admin.calc_EmailAddress }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Availability</h6>
        </div>
        <div class="block text-right">
          <div
            class="cursor-pointer inline-block rounded-t-lg px-2 py-1"
            :class="{
              'bg-blueGray-500 text-white': showSettings,
              'bg-blueGray-100 text-blueGray-500': !showSettings,
            }"
            @click="toggleSettings()"
          >
            <i class="text-sm fa-solid fa-gear"></i>
          </div>
          <div
            v-if="showSettings"
            v-click-outside="onClickOutside"
            class="h-[220px] absolute bg-white overflow-hidden w-[500px] right-5 rounded-b-lg rounded-l-lg border border-blueGray-200 px-3 z-10"
          >
            <div class="h-[100px] text-center">
              <div>
                <h6 class="uppercase text-sm text-blueGray-500 my-3">
                  Show Hours
                </h6>
              </div>
              <div class="px-10">
                <vue-slider
                  v-model="visibleHours"
                  :enable-cross="false"
                  :adsorb="true"
                  :interval="1"
                  :data="allHours"
                  :marks="hoursLabel"
                  :data-value="'id'"
                  :data-label="'name'"
                ></vue-slider>
              </div>
            </div>
            <div class="h-[100px] text-center">
              <div>
                <h6 class="uppercase text-sm text-blueGray-500 my-3">
                  Show Days
                </h6>
              </div>
              <div class="px-10">
                <vue-slider
                  v-model="visibleDayIndices"
                  :enable-cross="false"
                  :adsorb="true"
                  :interval="1"
                  :data="allDays"
                  :marks="daysLabel"
                  :data-value="'id'"
                  :data-label="'name'"
                ></vue-slider>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-blueGray-100 p-3 pt-5 rounded-l-lg rounded-b-lg">
          <div class="flex justify-between mb-5 items-center px-5">
            <span
              class="bg-blueGray-500 px-2 py-1 rounded-lg cursor-pointer text-white text-sm"
              @click="changeWeek(-1)"
            >
              <i class="fa-solid fa-angles-left"></i> Previous Week
            </span>
            <span class="text-center">
              <span class="text-2xl"
                >Week of {{ startAt.format("MMMM D, YYYY") }}</span
              ><br />
              <span class="text-sm"
                >All times shown in local time,
                {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}</span
              >
            </span>
            <span
              class="bg-blueGray-500 px-2 py-1 rounded-lg cursor-pointer text-white text-sm"
              @click="changeWeek(1)"
            >
              Next Week <i class="fa-solid fa-angles-right"></i>
            </span>
          </div>
          <hr class="mb-3" />
          <div
            ref="bubble"
            :class="{
              'opacity-0':
                hoverIntervalStart === null ||
                hoverIntervalStart.isBefore($dayjs()),
            }"
            class="absolute bg-white border rounded shadow-lg px-3 py-2 w-[300px] pointer-events-none"
            :style="`margin-top:${hoverOffsetTop}px; margin-left:${hoverOffsetLeft}px; z-index:20`"
          >
            <p v-if="hoverIntervalStart" class="text-xl font-bold">
              {{ hoverIntervalStart.calendar() }}
            </p>
            <p class="text-sm">
              {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
            </p>
            <p class="text-sm">{{ durationSeconds / 60 }} minutes</p>
            <div
              v-for="(
                personWithCalendarStatus, index
              ) in peopleWithCalendarStatus"
              :key="index"
              class="flex items-center"
            >
              <div v-if="hoverIntervalStart">
                <div
                  v-if="
                    personWithCalendarStatus.availability &&
                    availableForHoverInterval(
                      personWithCalendarStatus.availability,
                    )
                  "
                  class="inline-block w-[15px] border-r-4 pr-5 py-2"
                  :style="{
                    'border-right-color': personWithCalendarStatus.color.bg,
                  }"
                >
                  <i class="block text-xl fa-solid fa-check"></i>
                </div>
                <div
                  v-else-if="personWithCalendarStatus.availability"
                  class="inline-block w-[15px] border-r-4 pr-5 py-2"
                  :style="{
                    'border-right-color': personWithCalendarStatus.color.bg,
                  }"
                >
                  <i class="block text-xl fa-solid fa-xmark"></i>
                </div>
                <div
                  v-else
                  class="inline-block w-[15px] border-r-4 pr-5 py-2"
                  :style="{
                    'border-right-color': personWithCalendarStatus.color.bg,
                  }"
                >
                  <i class="block text-xl fa-solid fa-question"></i>
                </div>
              </div>
              <div class="flex mt-2 pl-3">
                <div class="pt-1">
                  <profile-image
                    :size="30"
                    :img-url="
                      avatarUrl(
                        personWithCalendarStatus.person.profile,
                        personWithCalendarStatus.person,
                      )
                    "
                  />
                </div>
                <div class="pl-2">
                  <div>
                    {{ personWithCalendarStatus.person.firstName }}
                    {{ personWithCalendarStatus.person.lastName }}<br />
                    <p
                      class="text-blueGray-400 text-sm overflow-x-hidden text-ellipsis whitespace-nowrap"
                    >
                      {{ personWithCalendarStatus.person.calc_EmailAddress }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex w-full relative">
            <div class="text-center w-full">
              <div class="h-[50px]"></div>
              <div
                v-for="(intervalStart, index) in intervalsForDate(
                  startAt.add(0, 'day'),
                )"
                :key="index"
                class="border-r border-b border-blueGray-200 -mt-[1px] px-3"
              >
                {{ intervalStart.format("h:mm&nbsp;a") }}
              </div>
            </div>
            <div
              v-for="(day, index) in visibleDays"
              :key="index"
              class="w-full"
            >
              <div class="text-center">
                <div class="h-[50px]">
                  <strong>{{ day.name }}</strong>
                  <br />
                  {{ startAt.add(day.id, "day").format("MM/DD") }}
                </div>
                <div
                  v-for="(intervalStart, intervalIndex) in intervalsForDate(
                    startAt.add(day.id, 'day'),
                  )"
                  :ref="`slot${intervalStart.unix()}`"
                  :key="intervalIndex"
                  class="border-r border-b border-blueGray-200 -mt-[1px] cursor-pointer"
                  :class="{
                    'bg-blueGray-500':
                      selectedIntervalStart <= intervalStart &&
                      intervalStart < selectedIntervalEnd,
                    'bg-blueGray-300':
                      hoverIntervalStart <= intervalStart &&
                      intervalStart < hoverIntervalEnd,
                  }"
                  @click="setSelectedInterval(intervalStart)"
                  @mouseover="setHoverInterval(intervalStart)"
                  @mouseleave="setHoverInterval(null)"
                >
                  <div v-if="intervalStart.isAfter($dayjs())">
                    <span
                      v-for="(
                        personWithCalendarStatus, personIndex
                      ) in peopleWithCalendarStatus"
                      :key="personIndex"
                    >
                      <span v-if="personWithCalendarStatus.availability">
                        <span
                          v-if="
                            personWithCalendarStatus.availability[
                              intervalStart
                                .utc()
                                .format('YYYY-MM-DDTHH:mm:ss+00:00')
                            ]
                          "
                          class="inline-block w-[15px]"
                          :style="{
                            'background-color':
                              personWithCalendarStatus.color.bg,
                            color: personWithCalendarStatus.color.text,
                          }"
                        >
                          &nbsp;
                        </span>
                        <span v-else class="inline-block w-[15px]">
                          &nbsp;
                        </span>
                      </span>
                      <span
                        v-else
                        class="inline-block w-[15px]"
                        :style="{
                          'background-color': personWithCalendarStatus.color.bg,
                          color: personWithCalendarStatus.color.text,
                        }"
                        ><i class="fa-solid fa-question"></i
                      ></span>
                    </span>
                  </div>
                  <div v-else>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedIntervalStart">
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">
              Invite Details
            </h6>
          </div>
          <div
            class="bg-blueGray-100 pt-1 px-5 rounded-l-lg rounded-b-lg mb-10"
          >
            <div>
              <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                Start and End
              </h6>
            </div>
            <div>
              <p class="text-xl font-bold">
                {{ selectedIntervalStart.calendar() }}
              </p>
              <p class="text-sm">
                {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
              </p>
              <p class="text-sm">{{ durationSeconds / 60 }} minutes</p>
            </div>
            <div>
              <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                Meeting Title
              </h6>
            </div>
            <div class="w-full">
              <input
                v-model="inviteForm.title"
                class="rounded mr-2 text-sm px-2 py-1 bg-white border-black/20 w-full"
                type="text"
              />
            </div>
            <div>
              <h6 class="uppercase text-sm mt-5 text-blueGray-500">Location</h6>
            </div>
            <div>
              <input
                v-model="inviteForm.location"
                class="rounded mr-2 text-sm px-2 py-1 bg-white border-black/20 w-full"
                type="text"
              />
            </div>
            <div class="text-right">
              <button
                class="bg-blueGray-500 text-white my-3 py-1 px-2 rounded-lg text-sm"
                @click="sendInvitations()"
              >
                Send Invitations
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import vClickOutside from "click-outside-vue3";
import VueSlider from "vue-slider-component";
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";
import AutocompleteSelect from "../AutocompleteSelect.vue";
import "vue-slider-component/theme/default.css";

const { avatarUrl, keysToCamel, keysToSnake } = utils;

export default {
  components: {
    ProfileImage,
    AutocompleteSelect,
    VueSlider,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    hashedId: String,
    idType: String,
  },
  data() {
    return {
      durationSeconds: 60 * 60,
      intervalSeconds: 15 * 60,
      visibleHours: [8, 20],
      showSettings: false,
      inviteForm: {
        title: null,
        location: null,
      },
      allHours: [
        {
          id: 0,
          name: "12am",
        },
        {
          id: 1,
          name: "1am",
        },
        {
          id: 2,
          name: "2am",
        },
        {
          id: 3,
          name: "3am",
        },
        {
          id: 4,
          name: "4am",
        },
        {
          id: 5,
          name: "5am",
        },
        {
          id: 6,
          name: "6am",
        },
        {
          id: 7,
          name: "7am",
        },
        {
          id: 8,
          name: "8am",
        },
        {
          id: 9,
          name: "9am",
        },
        {
          id: 10,
          name: "10am",
        },
        {
          id: 11,
          name: "11am",
        },
        {
          id: 12,
          name: "12pm",
        },
        {
          id: 13,
          name: "1pm",
        },
        {
          id: 14,
          name: "2pm",
        },
        {
          id: 15,
          name: "3pm",
        },
        {
          id: 16,
          name: "4pm",
        },
        {
          id: 17,
          name: "5pm",
        },
        {
          id: 18,
          name: "6pm",
        },
        {
          id: 19,
          name: "7pm",
        },
        {
          id: 20,
          name: "8pm",
        },
        {
          id: 21,
          name: "9pm",
        },
        {
          id: 22,
          name: "10pm",
        },
        {
          id: 23,
          name: "11pm",
        },
      ],
      hoursLabel(val) {
        return val % 6 === 0 ? {label: val === 0 ? '12am' : val === 12 ? '12pm' : val < 12 ? `${val  }am` : `${val - 12  }pm`} : false; // eslint-disable-line
      },
      visibleDayIndices: [1, 5],
      allDays: [
        {
          id: 0,
          name: "Sunday",
        },
        {
          id: 1,
          name: "Monday",
        },
        {
          id: 2,
          name: "Tuesday",
        },
        {
          id: 3,
          name: "Wednesday",
        },
        {
          id: 4,
          name: "Thursday",
        },
        {
          id: 5,
          name: "Friday",
        },
        {
          id: 6,
          name: "Saturday",
        },
      ],
      daysLabel(val) {
        return {
          label: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ][val],
        };
      },
      startAt: this.$dayjs().startOf("week"),
      weekdays: this.$dayjs.weekdays(),
      work: {},
      admins: [],
      collaborationRequest: {},
      collaborationSpace: {},
      peopleWithCalendarStatus: [],
      loading: true,
      hoverIntervalStart: null,
      hoverOffsetTop: 0,
      hoverOffsetLeft: 0,
      selectedIntervalStart: null,
      addPersonForm: {
        person: { id: null, label: null },
      },
      durationOptions: [
        {
          label: "15 minutes",
          value: 15 * 60,
        },
        {
          label: "30 minutes",
          value: 30 * 60,
        },
        {
          label: "45 minutes",
          value: 45 * 60,
        },
        {
          label: "60 minutes",
          value: 60 * 60,
        },
        {
          label: "1 hour 15 minutes",
          value: 75 * 60,
        },
        {
          label: "1 hour 30 minutes",
          value: 90 * 60,
        },
        {
          label: "1 hour 45 minutes",
          value: 105 * 60,
        },
        {
          label: "2 hours",
          value: 120 * 60,
        },
      ],
      palette: [
        {
          bg: "#3cb44b",
          text: "#ffffff",
        },
        {
          bg: "#ffe119",
          text: "#000000",
        },
        {
          bg: "#4363d8",
          text: "#ffffff",
        },
        {
          bg: "#e6194b",
          text: "#ffffff",
        },
        {
          bg: "#f58231",
          text: "#ffffff",
        },
        {
          bg: "#911eb4",
          text: "#ffffff",
        },
        {
          bg: "#42d4f4",
          text: "#000000",
        },
        {
          bg: "#f032e6",
          text: "#ffffff",
        },
        {
          bg: "#bcf60c",
          text: "#000000",
        },
        {
          bg: "#fabebe",
          text: "#000000",
        },
        {
          bg: "#008080",
          text: "#ffffff",
        },
        {
          bg: "#e6beff",
          text: "#000000",
        },
        {
          bg: "#9a6324",
          text: "#ffffff",
        },
        {
          bg: "#fffac8",
          text: "#000000",
        },
        {
          bg: "#800000",
          text: "#ffffff",
        },
        {
          bg: "#aaffc3",
          text: "#000000",
        },
        {
          bg: "#808000",
          text: "#ffffff",
        },
        {
          bg: "#ffd8b1",
          text: "#000000",
        },
        {
          bg: "#000075",
          text: "#ffffff",
        },
        {
          bg: "#808080",
          text: "#ffffff",
        },
      ],
      avatarUrl,
    };
  },
  computed: {
    hoverIntervalEnd() {
      if (this.hoverIntervalStart) {
        return this.hoverIntervalStart.add(this.durationSeconds, "second");
      }
      return null;
    },
    selectedIntervalEnd() {
      if (this.selectedIntervalStart) {
        return this.selectedIntervalStart.add(this.durationSeconds, "second");
      }
      return null;
    },
    showCalendarConnectionWarning() {
      return (
        this.peopleWithCalendarStatus.filter((personWithCalendarStatus) => {
          return !personWithCalendarStatus.calendarStatus;
        }).length > 0
      );
    },
    visibleDays() {
      return this.allDays.filter((day, index) => {
        return (
          index >= this.visibleDayIndices[0] &&
          index <= this.visibleDayIndices[1]
        );
      });
    },
  },
  watch: {
    startAt: {
      handler() {
        this.peopleWithCalendarStatus.forEach((personWithCalendarStatus) => {
          if (personWithCalendarStatus.calendarStatus) {
            personWithCalendarStatus.loadingStatus = true;
            this.loadAvailability(personWithCalendarStatus.person.hashedId);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    if (this.idType === "work") {
      this.loadWork(this.hashedId);
    } else if (this.idType === "collaborationRequest") {
      this.loadCollaborationRequest(this.hashedId);
    }
    this.loading = false;
    this.loadAdmins();
  },
  methods: {
    changeWeek(byHowMuch) {
      this.startAt = this.startAt.add(byHowMuch, "week");
    },
    setHoverInterval(intervalStart) {
      this.hoverIntervalStart = intervalStart;
      if (intervalStart) {
        const { bubble } = this.$refs;
        const div = this.$refs[`slot${intervalStart.unix()}`];
        if (div) {
          const rect = div[0].getBoundingClientRect();
          const bottom =
            window.innerHeight -
            (rect.top + bubble.getBoundingClientRect().height);
          if (bottom < 20) {
            this.hoverOffsetTop =
              div[0].offsetTop - bubble.getBoundingClientRect().height;
          } else {
            this.hoverOffsetTop = div[0].offsetTop;
          }
          const right = window.innerWidth - (rect.left + rect.width);
          if (right < 300) {
            this.hoverOffsetLeft = div[0].offsetLeft - 300;
          } else {
            this.hoverOffsetLeft = div[0].offsetLeft + rect.width;
          }
        }
      }
    },
    availableForHoverInterval(availability) {
      const intervals = [];
      let intervalToCheck = this.hoverIntervalStart;
      while (intervalToCheck < this.hoverIntervalEnd) {
        intervals.push(intervalToCheck);
        intervalToCheck = intervalToCheck.add(this.intervalSeconds, "second");
      }
      const unavailableIntervals = intervals.filter((interval) => {
        return (
          availability[interval.utc().format("YYYY-MM-DDTHH:mm:ss+00:00")] ===
          false
        );
      });
      return unavailableIntervals.length === 0;
    },
    setSelectedInterval(intervalStart) {
      this.hoverIntervalStart = null;
      this.selectedIntervalStart = intervalStart;
    },
    intervalsForDate(startAt) {
      const intervals = [];
      const endAt = startAt.add(24, "hour");
      let intervalStart = startAt;
      while (intervalStart < endAt) {
        if (
          intervalStart.format("HH") >= this.visibleHours[0] &&
          intervalStart.format("HH") < this.visibleHours[1]
        ) {
          intervals.push(intervalStart);
        }
        intervalStart = intervalStart.add(this.intervalSeconds, "second");
      }
      return intervals;
    },
    addPerson(person) {
      const usedColors = this.peopleWithCalendarStatus.map(
        (personWithCalendarStatus) => {
          return personWithCalendarStatus.color;
        },
      );
      const firstUnusedColor = this.palette.find((color) => {
        return usedColors.indexOf(color) === -1;
      });
      this.peopleWithCalendarStatus.push({
        calendarStatus: null,
        person,
        availability: null,
        loadingStatus: true,
        color: firstUnusedColor,
      });
      this.checkCalendarConnection(person.hashedId);
    },
    removePerson(hashedId) {
      this.peopleWithCalendarStatus = this.peopleWithCalendarStatus.filter(
        (personWithCalendarStatus) => {
          return personWithCalendarStatus.person.hashedId !== hashedId;
        },
      );
    },
    loadWork(hashedId) {
      this.$api.axios.get(`/work/${hashedId}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.work = data;
        data.workRoles.forEach((workRole) => {
          this.addPerson(workRole.person);
        });
      });
    },
    loadCollaborationRequest(hashedId) {
      this.$api.axios.get(`/work/request/${hashedId}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationRequest = data;
        data.profiles.forEach((profile) => {
          this.addPerson(profile.profile.person);
        });
        this.loadCollaborationSpace(data.collaborationSpace.hashedId);
      });
    },
    loadCollaborationSpace(hashedId) {
      this.$api.axios.get(`/collaboration-space/${hashedId}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationSpace = data;
        data.collaborationSpaceRoles.forEach((collaborationSpaceRole) => {
          this.addPerson(collaborationSpaceRole.person);
        });
      });
    },
    loadAdmins() {
      this.$api.axios.get(`/person/get-admins/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.admins = data.results;
      });
    },
    checkCalendarConnection(personId) {
      this.$api.axios
        .get(`/calendar/check-connection/${personId}/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.peopleWithCalendarStatus.forEach((personWithCalendarStatus) => {
            if (personWithCalendarStatus.person.hashedId === personId) {
              personWithCalendarStatus.calendarStatus = data.result;
              if (data.result === true) {
                this.loadAvailability(personId);
              } else {
                personWithCalendarStatus.loadingStatus = false;
              }
            }
          });
        });
    },
    loadAvailability(personId) {
      this.$api.axios
        .get(`/calendar/${personId}/slots/${this.intervalSeconds * 60}/`, {
          params: keysToSnake({ startAt: this.startAt.utc().toISOString() }),
        })
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.peopleWithCalendarStatus.forEach((personWithCalendarStatus) => {
            if (personWithCalendarStatus.person.hashedId === personId) {
              personWithCalendarStatus.availability = data.result;
              personWithCalendarStatus.loadingStatus = false;
            }
          });
        });
    },
    selectPerson(event) {
      this.addPersonForm.person = event;
      this.$api.axios.get(`/person/${event.id}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.addPerson(data);
        this.addPersonForm = { person: { id: null, label: null } };
      });
    },
    sendInvitations() {
      const payload = keysToSnake({
        title: this.inviteForm.title,
        location: this.inviteForm.location,
        inviteeIds: this.peopleWithCalendarStatus.map(
          (personWithCalendarStatus) => {
            return personWithCalendarStatus.person.hashedId;
          },
        ),
        startAt: this.selectedIntervalStart.toISOString(),
        durationSeconds: this.durationSeconds,
      });
      this.$api.axios.post(`/calendar/create-event/`, payload);
    },
    promptToConnectCalendar(emailAddress) {
      const payload = keysToSnake({ emailAddress });
      this.$api.axios
        .put(`/person/send-calendar-prompt-email/`, payload)
        .then(() => {
          this.$toast.success("Prompt has been sent.");
        });
    },
    onClickOutside() {
      this.showSettings = false;
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
  },
};
</script>
