<template>
  <div class="flex flex-wrap px-4 md:px-10 pt-10 pb-6">
    <div class="w-full">
      <h6 class="text-mallard-500/50 text-sm mb-2 uppercase">
        <router-link to="/account">Dashboard</router-link>
        <span class="px-3"> > </span>
        <router-link to="/account/preview-profile">Preview Profile</router-link>
      </h6>
    </div>
    <main class="w-full md:w-3/4 profile-page">
      <section class="relative block h-200-px">
        <div class="absolute top-0 w-full bg-center bg-cover"></div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0)"
        ></div>
      </section>
      <section class="relative pt-10 pb-6">
        <div class="container mx-auto">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg border-blueGray-500 border-0 border-t-2 border-solid"
          >
            <div class="px-6 py-6">
              <div class="flex flex-wrap">
                <div class="w-full md:w-3/12 px-4 flex">
                  <profile-image
                    v-if="avatarUrl(profile)"
                    :size="150"
                    :img-url="avatarUrl(profile)"
                  />
                </div>
                <div class="w-full md:w-9/12 px-4">
                  <h3
                    v-if="profile && profile.person"
                    class="mt-6 text-4xl font-light leading-normal mb-2 text-blueGray-500 mb-2"
                  >
                    {{ profile.person.firstName }} {{ profile.person.lastName }}
                  </h3>
                  <p class="mb-3">{{ profile.headline }}</p>
                  <div
                    class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50"
                  >
                    <i
                      class="fas fa-map-marker-alt mr-2 text-lg text-mallard-500/50"
                    ></i>
                    <span
                      v-if="profile.city && profile.state && profile.country"
                    >
                      {{ profile.city }}, {{ profile.state.label }},
                      {{ profile.country.label }}
                    </span>
                    <span v-else-if="profile.city && profile.state">
                      {{ profile.city }}, {{ profile.state.label }}
                    </span>
                    <span v-else-if="profile.state">
                      {{ profile.state.label }}
                    </span>
                    <span v-else-if="profile.city">
                      {{ profile.city }}
                    </span>
                    <span v-else>
                      <em>No location provided</em>
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap justify-between pb-10 mt-8">
                <div class="w-full lg:w-6/12 px-4 lg:order-2 text-left px-4">
                  <div
                    v-if="profile.about"
                    class="text-sm leading-normal mt-10 mb-2 text-mallard-500/50 font-bold uppercase"
                  >
                    About
                  </div>
                  <div class="w-full text-sm">
                    <markdown-viewer
                      v-if="profile.about"
                      :text="profile.about"
                    />
                  </div>

                  <div
                    v-if="companyNames"
                    class="mb-4 text-mallard-500/50 mt-10"
                  >
                    <i
                      class="fas fa-briefcase mr-2 text-lg text-mallard-500/50/50"
                    ></i>
                    {{ companyNames }}
                    <!-- <span
                      class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50/50 font-bold uppercase ml-3 cursor-pointer"
                      @click="experienceExpanded = !experienceExpanded">
                        <span v-if="experienceExpanded">Less</span>
                        <span v-if="!experienceExpanded">More</span>
                    </span> -->
                    <div v-if="experienceExpanded" class="text-sm">
                      <div
                        v-for="experience in profile.experiences"
                        :key="experience.hashedId"
                        class="mt-3 ml-7"
                      >
                        <span class="font-bold">{{
                          experience.companyName
                        }}</span>
                        <div
                          v-for="position in experience.positions.filter(
                            (position) => {
                              return position.positionName;
                            },
                          )"
                          :key="position.hashedId"
                        >
                          {{ position.positionName }},
                          {{ position.startYear }} &mdash;
                          {{ position.endYear }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="schoolNames"
                    class="mb-4 text-mallard-500/50 mt-10"
                  >
                    <i
                      class="fas fa-university mr-2 text-lg text-mallard-500/50/50"
                    ></i>
                    {{ schoolNames }}
                    <div v-if="educationExpanded" class="text-sm">
                      <div
                        v-for="education in profile.educations"
                        :key="education.hashedId"
                        class="mt-3 ml-7"
                      >
                        <span class="font-bold">{{
                          education.schoolName
                        }}</span>
                        <span v-if="education.schoolState">
                          &bull; {{ education.schoolCity }}</span
                        >
                        <span v-if="education.schoolState"
                          >, {{ education.schoolState.label }}</span
                        >
                        <span v-if="education.schoolCountry"
                          >, {{ education.schoolCountry.label }}</span
                        >
                        <br />
                        <span v-if="education.degree"
                          >{{ education.degree }},
                        </span>
                        <span v-if="education.specialization"
                          >{{ education.specialization }},
                        </span>
                        <span v-if="education.graduationYear">{{
                          education.graduationYear
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4 lg:order-2">
                  <div
                    v-if="
                      profile.researchInterests &&
                      profile.researchInterests.length
                    "
                    class="mt-10"
                  >
                    <span
                      class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50 font-bold uppercase"
                    >
                      Research Expertise
                    </span>
                    <div class="w-full">
                      <div class="mt-2">
                        <span
                          v-for="researchInterest in profile.researchInterests"
                          :key="researchInterest.hashedId"
                          class="bg-mallard-300 px-2 py-1 rounded text-white text-sm mr-2 whitespace-nowrap mb-2 inline-block"
                        >
                          {{ researchInterest.researchInterest }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="profile.publications && profile.publications.length"
                    class="mt-10"
                  >
                    <span
                      class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50 font-bold uppercase"
                    >
                      Publications
                    </span>
                    <div class="w-full">
                      <div
                        v-for="publication in profile.publications"
                        :key="publication.hashedId"
                        class="mt-2 text-sm"
                      >
                        <p>{{ publication.adjustedCitation }}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="profile.links && profile.links.length"
                    class="mt-10"
                  >
                    <span
                      class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50 font-bold uppercase"
                    >
                      Links
                    </span>
                    <div class="w-full">
                      <div
                        v-for="link in profile.links"
                        :key="link.hashedId"
                        class="mt-2 text-sm"
                      >
                        <a class="underline" target="_blank" :href="link.url">{{
                          link.url
                        }}</a>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="profile.languages && profile.languages.length"
                    class="mt-10"
                  >
                    <span
                      class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50 font-bold uppercase"
                    >
                      Languages
                    </span>
                    <div class="w-full">
                      <div
                        v-for="language in profile.languages"
                        :key="language.hashedId"
                        class="mt-2 text-sm"
                      >
                        {{ language.language.label }}
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="profile.media && profile.media.length"
                    class="mt-10"
                  >
                    <span
                      class="text-sm leading-normal mt-0 mb-2 text-mallard-500/50 font-bold uppercase"
                    >
                      Media
                    </span>
                    <div class="w-full">
                      <div
                        v-for="media in profile.media"
                        :key="media.hashedId"
                        class="mt-2 text-sm"
                      >
                        <a
                          class="underline"
                          target="_blank"
                          :href="media.url"
                          >{{ media.description }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import { utils } from "common-frontend";
import MarkdownViewer from "../../components/MarkdownViewer.vue";
import ProfileImage from "../../components/ProfileImage.vue";

const { keysToCamel, avatarUrl } = utils;

export default {
  components: {
    ProfileImage,
    MarkdownViewer,
  },
  data() {
    return {
      profile: {},
      profileId: this.$route.params.hashedId,
      educationExpanded: true,
      experienceExpanded: true,
      avatarUrl,
    };
  },
  computed: {
    companyNames() {
      if (this.profile?.experiences) {
        return this.profile.experiences
          .map((experience) => {
            return experience.companyName;
          })
          .join(", ");
      }
      return [];
    },
    schoolNames() {
      if (this.profile?.educations) {
        return this.profile.educations
          .map((education) => {
            return education.schoolName;
          })
          .join(", ");
      }
      return [];
    },
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
    loadProfile() {
      this.$api.axios.get(`/person/profile/${this.profileId}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.profile = data;
      });
    },
  },
};
</script>
