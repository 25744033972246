<template>
  <div class="flex flex-wrap">
    <div class="px-4 w-full">
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div class="rounded-t mb-0 pb-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="text-3xl text-blueGray-700 font-light mt-4">
                Settings
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 w-full flex">
      <div
        class="w-full md:w-1/2 lg:w-1/3 rounded-lg bg-white px-4 pt-4 pb-3 shadow-lg mb-3 md:mr-3"
      >
        <div class="flex justify-between">
          <h2 class="pb-3 text-lg">Calendly Connection</h2>
          <div v-if="!calendarLoading && !calendarConnectionIsValid">
            <i class="fa-solid fa-link-slash"></i>
          </div>
          <div v-if="!calendarLoading && calendarConnectionIsValid">
            <i class="fa-solid fa-link"></i>
          </div>
        </div>
        <template v-if="calendarLoading">
          <div class="mx-auto w-full">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </template>
        <template v-else>
          <template v-if="!calendarConnectionIsValid">
            <p class="pb-3">
              Your Calendly account is not connected to NotedSource.
            </p>
            <div class="flex justify-end">
              <button
                class="rounded-lg bg-blueGray-500 text-white px-3 py-1"
                @click="connectCalendar()"
              >
                Connect Calendar
              </button>
            </div>
          </template>
          <template v-else>
            <p class="pb-3">
              Your Calendly account is connected to NotedSource.
            </p>
            <div class="flex justify-end">
              <button
                class="rounded-lg border border-blueGray-500 text-blueGray-500 px-3 py-1"
                @click="disconnectCalendar()"
              >
                Disconnect Calendar
              </button>
            </div>
            <p class="cursor-pointer" @click="calendarDebug = !calendarDebug">
              <i
                :class="{ 'rotate-90': calendarDebug }"
                class="fa-solid fa-caret-right transition-all duration-150"
              ></i>
              Debug Info
            </p>
            <div v-if="calendarDebug" class="border px-3 py-2">
              Upcoming Events:
              <pre class="text-xs">{{ unavailability }}</pre>
              Available Slots:
              <div>
                <div
                  v-for="(slot, index) in availableSlots"
                  :key="index"
                  :class="{
                    'bg-blueGray-500 text-white': slot[1],
                    'bg-blueGray-200 text-black': !slot[1],
                  }"
                  class="rounded-lg text-white block text-center mb-2 py-2"
                >
                  <span class="block text-xs">{{
                    slot[0].local().format("YYYY-MM-DD")
                  }}</span>
                  <span class="block text-lg">{{
                    slot[0].local().format("hh:mm a")
                  }}</span>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div
        class="w-full md:w-1/2 lg:w-1/3 rounded-lg bg-white px-4 pt-4 pb-3 shadow-lg mb-3 md:mr-3"
      >
        <div class="flex justify-between">
          <h2 class="pb-3 text-lg">Two-Factor Authentication</h2>
          <div v-if="!$store.getters.person.isTwoFactorAuthenticationEnabled">
            <i class="fa-solid fa-lock-open"></i>
          </div>
          <div v-if="$store.getters.person.isTwoFactorAuthenticationEnabled">
            <i class="fa-solid fa-lock"></i>
          </div>
        </div>
        <div>
          <template
            v-if="$store.getters.person.isTwoFactorAuthenticationEnabled"
          >
            <p class="pb-3">Enabled</p>
            <div class="flex justify-end">
              <button
                class="rounded-lg border border-blueGray-500 text-blueGray-500 px-3 py-1"
                @click="toggleDisableModal()"
              >
                Disable
              </button>
            </div>
          </template>
          <template v-else>
            <p class="pb-3">Disabled</p>
            <div class="flex justify-end">
              <button
                class="rounded-lg bg-blueGray-500 text-white px-3 py-1"
                @click="getTwoFactorAuthUrl()"
              >
                Enable
              </button>
            </div>
          </template>
        </div>
      </div>
      <regular-modal ref="enableTwoFactorAuthentication" :showing="false">
        <template #header>
          <h4 class="text-xl font-light">Enable Two-Factor Authentication</h4>
        </template>
        <template #body>
          <div
            class="w-full max-h-[calc(100vh-200px)] overflow-scroll px-6 py-3"
          >
            <section v-if="twoFactorStep === 0">
              <h3 class="text-lg">Instructions:</h3>
              <ul class="list-disc ml-10">
                <li>
                  Download an authenticator app like
                  <a
                    class="underline"
                    target="_blank"
                    href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS"
                    >Google Authenticator</a
                  >.
                </li>
                <li>
                  Create a new credential by either scanning the QR code or
                  entering the secret token below.
                </li>
                <li>
                  Click "Continue" and enter the code from your app to verify
                  that it's set up correctly.
                </li>
              </ul>
              <img
                class="w-[200px] mx-auto"
                :src="`data:image/png;base64, ${twoFactorQrCode}`"
              />
              <p class="my-5 mx-10">
                <em
                  >Note: This QR code needs to be scanned by an authenticator
                  app. Scanning with your camera app will not work.</em
                >
              </p>
              <div class="border px-5 py-3 mx-auto">
                Secret (if entering manually): {{ twoFactorSecret }}
              </div>
            </section>
            <section v-if="twoFactorStep === 1">
              <div class="text-center my-5">
                <label class="form-help text-lg">
                  To finish setup, enter the code from your app.
                </label>
                <OTP
                  ref="enableOtp"
                  class="my-4"
                  :digit-count="6"
                  @update:otp="setEnableCode($event)"
                ></OTP>
              </div>
              <p v-if="twoFactorError" class="text-red-500 mt-2">
                {{ twoFactorError }}
              </p>
            </section>
          </div>
        </template>
        <template #footer>
          <template v-if="twoFactorStep === 0">
            <button
              class="modal-nonprimary-button"
              type="button"
              @click="toggleEnableModal()"
            >
              Cancel
            </button>
            <button class="modal-primary-button" type="button" @click="next()">
              <span v-if="!twoFactorFormSubmitting">Continue</span>
              <span v-else><i class="fas fa-circle-notch fa-spin"></i></span>
            </button>
          </template>
          <template v-else>
            <button
              class="modal-nonprimary-button"
              type="button"
              @click="twoFactorStep = 0"
            >
              Back
            </button>
            <button
              class="modal-primary-button"
              type="button"
              @click="verifyTwoFactorCode()"
            >
              <span v-if="!twoFactorFormSubmitting">Submit</span>
              <span v-else><i class="fas fa-circle-notch fa-spin"></i></span>
            </button>
          </template>
        </template>
      </regular-modal>
      <regular-modal ref="disableTwoFactorAuthentication" :showing="false">
        <template #header>
          <h4 class="text-xl font-light">Disable Two-Factor Authentication</h4>
        </template>
        <template #body>
          <div
            class="w-full max-h-[calc(100vh-200px)] overflow-scroll px-6 py-3"
          >
            <div class="text-center my-5">
              <label class="form-help text-lg">
                To disable two-factor authentication, enter the code from your
                app.
              </label>
              <OTP
                ref="disableOtp"
                class="my-4"
                :digit-count="6"
                @update:otp="setDisableCode($event)"
              ></OTP>
            </div>
            <p v-if="twoFactorError" class="text-red-500 mt-2">
              {{ twoFactorError }}
            </p>
          </div>
        </template>
        <template #footer>
          <button
            class="modal-nonprimary-button"
            type="button"
            @click="toggleDisableModal()"
          >
            Cancel
          </button>
        </template>
      </regular-modal>
    </div>
  </div>
</template>
<script>
import { utils } from "common-frontend";
import { nextTick } from "vue";
import RegularModal from "../components/Modals/Regular.vue";
import OTP from "../components/OTP.vue";

const { keysToCamel, keysToSnake } = utils;

export default {
  components: {
    RegularModal,
    OTP,
  },
  data() {
    return {
      calendarLoading: true,
      calendarDebug: false,
      calendarConnectionIsValid: false,
      unavailability: [],
      availableSlots: [],
      twoFactorStep: 0,
      twoFactorUrl: "",
      twoFactorSecret: "",
      twoFactorQrCode: "",
      twoFactorFormSubmitting: false,
      twoFactorVerifyCode: null,
      twoFactorError: null,
    };
  },
  mounted() {
    this.checkCalendarConnection();
  },
  methods: {
    checkCalendarConnection() {
      this.calendarLoading = true;
      this.$api.axios.get(`/calendar/check-connection/`).then((res) => {
        const data = keysToCamel(res.data);
        this.calendarConnectionIsValid = data.result;
        this.calendarLoading = false;
        if (this.calendarConnectionIsValid) {
          this.getUnavailability();
          this.getAvailableSlots();
        }
      });
    },
    connectCalendar() {
      this.calendarLoading = true;
      this.$api.axios.get(`/calendar/connect/`).then((resp) => {
        const data = keysToCamel(resp.data);
        window.location.href = data.redirect;
      });
    },
    disconnectCalendar() {
      this.calendarLoading = true;
      this.$api.axios.get(`/calendar/disconnect/`).then(() => {
        setTimeout(() => this.checkCalendarConnection(), 1000);
      });
    },
    getUnavailability() {
      this.$api.axios.get(`/calendar/debug/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.unavailability = data.result.collection;
      });
    },
    getAvailableSlots() {
      this.$api.axios
        .get(`/calendar/${this.$store.getters.person.hashedId}/slots/30/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          Object.keys(data.result).forEach((result) => {
            this.availableSlots.push([
              this.$dayjs(result),
              data.result[result],
            ]);
          });
        });
    },
    getTwoFactorAuthUrl() {
      this.$api.axios
        .get(`/person/mine/two-factor-authentication/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.twoFactorUrl = data.url;
          this.twoFactorSecret = data.secret;
          this.twoFactorQrCode = data.qrCode;
          this.$refs.enableTwoFactorAuthentication.toggleModal();
        });
    },
    verifyTwoFactorCode() {
      this.twoFactorError = null;
      this.$api.axios
        .post(
          `/person/mine/two-factor-authentication/`,
          keysToSnake({ code: this.twoFactorVerifyCode }),
        )
        .then((resp) => {
          const data = keysToCamel(resp.data);
          if (data.result) {
            this.$api.attemptAccessTokenRefresh(true);
            this.toggleEnableModal();
            this.$toast.success(
              "Two-Factor Authentication has been enabled for your account.",
            );
          } else {
            this.twoFactorError =
              "The code you entered did not match or expired.";
            nextTick(() => {
              if (this.$refs.enableOtp) {
                this.$refs.enableOtp.reset();
                this.$refs.enableOtp.setFocus();
              }
            });
          }
        });
    },
    next() {
      this.twoFactorStep = 1;
      nextTick(() => {
        if (this.$refs.enableOtp) {
          this.$refs.enableOtp.reset();
          this.$refs.enableOtp.setFocus();
        }
      });
    },
    disableTwoFactorAuth() {
      this.twoFactorError = null;
      this.$api.axios
        .post(
          `/person/mine/disable-two-factor-authentication/`,
          keysToSnake({ code: this.twoFactorVerifyCode }),
        )
        .then((resp) => {
          const data = keysToCamel(resp.data);
          if (data.result) {
            this.$api.attemptAccessTokenRefresh(true);
            this.toggleDisableModal();
            this.$toast.warning(
              "Two-Factor Authentication has been disabled for your account.",
            );
          } else {
            this.twoFactorError =
              "The code you entered did not match or expired.";
            nextTick(() => {
              if (this.$refs.disableOtp) {
                this.$refs.disableOtp.reset();
                this.$refs.disableOtp.setFocus();
              }
            });
          }
        });
    },
    setEnableCode(event) {
      this.twoFactorVerifyCode = event;
      this.verifyTwoFactorCode();
    },
    setDisableCode(event) {
      this.twoFactorVerifyCode = event;
      this.disableTwoFactorAuth();
    },
    toggleEnableModal() {
      this.$refs.enableTwoFactorAuthentication.toggleModal();
    },
    toggleDisableModal() {
      this.$refs.disableTwoFactorAuthentication.toggleModal();
      nextTick(() => {
        if (this.$refs.disableOtp) {
          this.$refs.disableOtp.setFocus();
        }
      });
    },
  },
};
</script>
