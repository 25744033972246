<template>
  <div v-click-outside="onClickOutside">
    <a
      ref="btnDropdownRef"
      class="text-xs px-2 py-1 ml-2 text-black/70 rounded cursor-pointer"
      :class="{
        'bg-blueGray-700 text-white': dropdownPopoverShow,
        'text-blueGray-500 bg-white': !dropdownPopoverShow,
      }"
      href="#"
      @click="toggleDropdown"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left list-none text-left rounded shadow-lg min-w-48"
      :class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <span v-if="work && column.name == 'Underway'">
        <a
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 cursor-pointer hover:bg-blueGray-200 rounded-t"
          @click.prevent=""
        >
          Mark Project Complete
        </a>
      </span>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import vClickOutside from "click-outside-vue3";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    work: Object,
    column: Object,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      popper: null,
    };
  },
  methods: {
    onClickOutside() {
      this.dropdownPopoverShow = false;
    },
    toggleDropdown(event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        if (!this.popper) {
          this.popper = createPopper(
            this.$refs.btnDropdownRef,
            this.$refs.popoverDropdownRef,
            {
              placement: "bottom-end",
            },
          );
        }
      }
    },
  },
};
</script>
