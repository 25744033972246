<template>
  <article
    class="search-result border border-blueGray-300 bg-white rounded-lg shadow mb-3 cursor-pointer overflow-hidden flex flex-wrap"
  >
    <div class="w-full md:w-1/3">
      <div
        class="w-full h-full flex flex-col justify-between"
        style="
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        "
        :style="{
          'background-image': `url(${result.banner_url?.raw}&fit=crop&crop=entropy&w=600&h=400&dpr=2)`,
        }"
      >
        <div class="px-2 py-1">
          <span
            v-for="(discipline, index) in disciplines"
            :key="index"
            class="px-1 text-sm rounded"
            :style="`background-color: #${discipline.bgColor}; color: #${discipline.textColor};`"
            >{{ discipline.label }}</span
          >
        </div>
        <p
          class="bg-white/60 text-xs px-3 py-1"
          v-html="result.banner_credit?.raw"
        ></p>
      </div>
    </div>
    <div class="w-full md:w-2/3 py-2 px-3">
      <div class="flex w-full justify-between">
        <p class="text-md" v-html="result.journal_title.raw"></p>
        <p class="text-right text-blueGray-400 text-sm">
          {{ $dayjs(result.display_published_at.raw).format("MMM DD YYYY") }}
        </p>
      </div>
      <h2 class="text-2xl" v-html="result.title.raw"></h2>
      <p class="text-sm">{{ result.authors.raw }}</p>
      <template v-if="Array.isArray(result['subdisciplines.name']?.raw)">
        <span
          v-for="subdiscipline in result['subdisciplines.name']?.raw"
          :key="subdiscipline"
          class="px-1 text-sm rounded bg-blueGray-300 mr-2 inline-block"
        >
          {{ subdiscipline }}
        </span>
      </template>
      <template v-else>
        <span class="px-1 text-sm rounded bg-blueGray-300 mr-2 inline-block">{{
          result["subdisciplines.name"]?.raw
        }}</span>
      </template>
    </div>
  </article>
</template>

<script>
export default {
  components: {},
  props: {
    enums: Object,
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    disciplines() {
      const disciplines = [];
      if (Array.isArray(this.result["disciplines.name"]?.raw)) {
        this.result["disciplines.name"]?.raw.forEach((disciplineLabel) => {
          disciplines.push(this.enumForDiscipline(disciplineLabel));
        });
      } else {
        disciplines.push(
          this.enumForDiscipline(this.result["disciplines.name"]?.raw),
        );
      }
      return disciplines;
    },
  },
  methods: {
    enumForDiscipline(disciplineLabel) {
      return this.enums.rssFeedDisciplineOptions.find((discipline) => {
        return discipline.label === disciplineLabel;
      });
    },
  },
};
</script>
