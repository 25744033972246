<template>
  <section class="relative bg-white">
    <div class="container mx-auto">
      <div class="relative flex flex-col min-w-0 break-words w-full px-5">
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Contract Name
          </h6>
        </div>
        <div class="w-full">
          <input
            ref="contractName"
            v-model="name"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
            type="text"
          />
        </div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words w-full px-5">
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Variables</h6>
        </div>
        <p class="form-help">
          Adjust default values below. You can also change the variables
          themselves inside the contract details once it has been created.
        </p>
        <div
          class="relative flex flex-col min-w-0 break-words w-full px-5 pb-5 border"
        >
          <div v-for="variable in contractVariables" :key="variable.hashedId">
            <div>
              <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                {{ variable.name }}
              </h6>
            </div>
            <div class="w-full">
              <template v-if="variable.variableType.value == 'SHORT_TEXT'">
                <input
                  v-model="variable.defaultValue"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                  type="text"
                />
              </template>
              <template v-if="variable.variableType.value == 'DATE'">
                <datepicker
                  v-model="variable.defaultValue"
                  @update="variable.defaultValue = $event"
                ></datepicker>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words w-full px-5">
        <div class="text-right">
          <button
            class="bg-blueGray-500 rounded-lg px-3 py-2 text-white mb-10 mt-5"
            @click="createContract()"
          >
            Create Contract {{ templateHashedId }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { utils } from "common-frontend";
import Datepicker from "../Datepicker.vue";
import "vue-slider-component/theme/default.css";

const { keysToCamel, keysToSnake } = utils;

export default {
  components: {
    Datepicker,
  },
  props: {
    contractPackage: Object,
    templateHashedId: String,
    packageHashedId: String,
  },
  data() {
    return {
      name: null,
      contractVariables: [],
      contractSignatures: [],
      includeRequester: false,
      loading: true,
      contractTemplate: {},
    };
  },
  mounted() {
    this.loadContractTemplate();
  },
  methods: {
    loadContractTemplate() {
      this.$api.axios
        .get(`/contract-template/${this.templateHashedId}/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.contractTemplate = data;
          this.name = `${this.contractTemplate.contractType.name} with `;
          if (this.contractPackage.contractPackageSignatures.length > 0) {
            this.name = `${
              this.name +
              this.contractPackage.contractPackageSignatures[0].firstName
            } ${this.contractPackage.contractPackageSignatures[0].lastName}`;
          }
          this.$refs.contractName.focus();
          this.contractTemplate.contractTemplateVariables.forEach(
            (variable) => {
              this.contractVariables.push({
                name: variable.name,
                slug: variable.slug,
                description: variable.description,
                variableType: variable.variableType,
                defaultValue: variable.defaultValue,
              });
            },
          );
          this.addContractSignature();
          this.loading = false;
        });
    },
    addContractSignature() {
      this.contractSignatures.push({
        firstName: "",
        lastName: "",
        emailAddress: "",
      });
    },
    createContract() {
      const payload = keysToSnake({
        name: this.name,
        contractVariables: this.contractVariables,
        packageHashedId: this.packageHashedId,
      });
      this.$api.axios
        .post(
          `/contract/create-from-template/${this.templateHashedId}/`,
          payload,
        )
        .then(() => {
          this.$toast.success(`This contract has been created.`);
          this.$emit("finished");
        });
    },
  },
};
</script>
