<template>
  <div class="flex flex-wrap">
    <div class="px-4 w-full">
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div class="rounded-t mb-0 pb-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="text-3xl text-blueGray-700 font-light mt-4">
                Crawler
              </h3>
            </div>
          </div>
        </div>
        <button @click="loginFirebase()">Login</button>
        <button @click="loadPeople()">Load People</button>
        <table class="w-full">
          <thead>
            <tr>
              <th></th>
              <th class="border text-xs uppercase text-left px-2 py-1">
                Progress
              </th>
              <th
                v-for="(columnProps, columnKey) in universitySchema"
                :key="columnKey"
                class="border text-xs uppercase text-left px-2 py-1"
              >
                <div
                  class="w-full flex justify-between cursor-pointer items-center"
                  @click="setUniversityOrderBy(columnKey)"
                >
                  <span>{{ columnKey }}</span>
                  <span v-if="universityOrderBy === columnKey"
                    ><i class="fa-solid fa-caret-up text-sm"></i
                  ></span>
                  <span v-if="universityOrderBy === `-${columnKey}`"
                    ><i class="fa-solid fa-caret-down text-sm"></i
                  ></span>
                </div>
              </th>
              <th class="border"></th>
            </tr>
          </thead>
          <tbody>
            <!-- eslint-disable-next-line vue/no-v-for-template-key -->
            <template v-for="(value, key) in universities" :key="key">
              <tr>
                <td class="pt-1 pr-1">
                  <span @click="toggleUniversityExpanded(key)"
                    ><i
                      class="fa-solid fa-caret-right transition-all duration-500 cursor-pointer"
                      :class="{ 'rotate-90': isUniversityExpanded(key) }"
                    ></i
                  ></span>
                </td>
                <td
                  class="border-t-2 border-t-blueGray-500 bg-white px-2 pt-1 text-sm"
                >
                  <div class="flex items-center">
                    <div class="w-[20px] text-right mr-1">
                      {{
                        Object.keys(
                          schoolsByUniversity[key]
                            ? schoolsByUniversity[key]
                            : {},
                        ).length
                      }}
                    </div>
                    <div
                      v-if="
                        Object.keys(
                          schoolsByUniversity[key]
                            ? schoolsByUniversity[key]
                            : {},
                        ).length
                      "
                      class="w-[50px] bg-red-500 h-3"
                    >
                      <div
                        v-if="
                          Object.keys(
                            schoolsByUniversity[key]
                              ? schoolsByUniversity[key]
                              : {},
                          ).length
                        "
                        class="bg-green-500 h-3"
                        :style="{
                          width: `${
                            (doneCount(schoolsByUniversity[key]) /
                              Object.keys(
                                schoolsByUniversity[key]
                                  ? schoolsByUniversity[key]
                                  : {},
                              ).length) *
                            50
                          }px`,
                        }"
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  v-for="(columnProps, columnKey) in universitySchema"
                  :key="columnKey"
                  :width="columnProps.width ? `${columnProps.width}%` : 'auto'"
                  class="border-t-2 border-t-blueGray-500 bg-white"
                >
                  <div class="flex">
                    <input
                      v-if="columnProps.type == 'string'"
                      v-model="value[columnKey]"
                      type="text"
                      class="w-full px-2 pt-1 text-sm border-0 pb-0"
                      @change="editUniversity(key)"
                    />
                    <input
                      v-if="columnProps.type == 'number'"
                      v-model="value[columnKey]"
                      type="number"
                      class="w-full px-2 pt-1 text-sm border-0 pb-0"
                      @change="editUniversity(key)"
                    />
                    <template v-if="columnProps.type == 'link'">
                      <input
                        v-model="value[columnKey]"
                        type="text"
                        class="w-full px-2 pt-1 text-sm border-0 pb-0"
                        @change="editUniversity(key)"
                      />
                      <a
                        v-if="columnProps.type == 'link'"
                        :href="value[columnKey]"
                        target="_blank"
                        ><i class="fa-solid fa-link"></i
                      ></a>
                    </template>
                  </div>
                </td>
                <td
                  class="border-t-2 border-t-blueGray-500 border-t text-sm bg-white px-2"
                >
                  <button @click="deleteUniversity(key)">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td></td>
                <td
                  class="text-sm bg-white"
                  :colspan="Object.keys(universitySchema).length + 1"
                >
                  <div
                    class="ease-linear transition-all duration-150 overflow-hidden"
                    :style="{
                      maxHeight: isUniversityExpanded(key)
                        ? `${
                            500 +
                            Object.keys(
                              schoolsByUniversity[key]
                                ? schoolsByUniversity[key]
                                : {},
                            ).length *
                              28
                          }px`
                        : '0px',
                      height: isUniversityExpanded(key)
                        ? `${
                            500 +
                            Object.keys(
                              schoolsByUniversity[key]
                                ? schoolsByUniversity[key]
                                : {},
                            ).length *
                              28
                          }px`
                        : '0px',
                    }"
                  >
                    <table v-if="isUniversityExpanded(key)" class="mx-5 my-5">
                      <thead>
                        <tr>
                          <th></th>
                          <th
                            class="border text-xs uppercase text-left px-2 py-1"
                          >
                            Progress
                          </th>
                          <th
                            v-for="(
                              schoolColumnProps, schoolColumnKey
                            ) in schoolSchema"
                            :key="schoolColumnKey"
                            class="border text-xs uppercase text-left px-2 py-1"
                          >
                            {{ schoolColumnKey }}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- eslint-disable -->
                        <template
                          v-for="(
                            schoolValue, schoolKey
                          ) in schoolsByUniversity[key]"
                          :key="schoolKey"
                        >
                        <!-- eslint-enable -->
                          <tr>
                            <td class="pt-1 pr-1">
                              <span @click="toggleSchoolExpanded(schoolKey)"
                                ><i
                                  class="fa-solid fa-caret-right transition-all duration-500 cursor-pointer"
                                  :class="{
                                    'rotate-90': isSchoolExpanded(schoolKey),
                                  }"
                                ></i
                              ></span>
                            </td>
                            <td>
                              {{
                                Object.keys(
                                  peopleBySchool[schoolValue.name] || {},
                                ).length
                              }}
                            </td>
                            <td
                              v-for="(
                                schoolColumnProps, schoolColumnKey
                              ) in schoolSchema"
                              :key="schoolColumnKey"
                              :width="
                                schoolColumnProps.width
                                  ? `${schoolColumnProps.width}%`
                                  : 'auto'
                              "
                              class="border-t-2 border-t-blueGray-500 bg-white"
                            >
                              <div class="flex">
                                <input
                                  v-if="schoolColumnProps.type == 'string'"
                                  v-model="schoolValue[schoolColumnKey]"
                                  type="text"
                                  class="w-full px-2 pt-1 text-sm border-0 pb-0"
                                  @change="editSchool(schoolKey)"
                                />
                                <input
                                  v-if="schoolColumnProps.type == 'number'"
                                  v-model="schoolValue[schoolColumnKey]"
                                  type="number"
                                  class="w-full px-2 pt-1 text-sm border-0 pb-0"
                                  @change="editSchool(schoolKey)"
                                />
                                <template
                                  v-if="schoolColumnProps.type == 'link'"
                                >
                                  <input
                                    v-model="schoolValue[schoolColumnKey]"
                                    type="text"
                                    class="w-full px-2 pt-1 text-sm border-0 pb-0"
                                    @change="editSchool(schoolKey)"
                                  />
                                  <a
                                    :href="schoolValue[schoolColumnKey]"
                                    target="_blank"
                                    ><i class="fa-solid fa-link"></i
                                  ></a>
                                </template>
                              </div>
                            </td>
                            <td
                              class="border-t-2 border-t-blueGray-500 border-t text-sm bg-white px-2"
                            >
                              <button
                                class="mr-3"
                                @click="
                                  setupSchool(
                                    value.name,
                                    schoolValue.name,
                                    schoolValue.faculty_url,
                                  )
                                "
                              >
                                <i
                                  class="fa-solid fa-arrow-up-right-from-square"
                                ></i>
                              </button>
                              <button
                                class="mr-3"
                                @click="
                                  crawlSchool(
                                    value.name
                                      .toLowerCase()
                                      .replaceAll(' ', '-'),
                                    schoolValue.name
                                      .toLowerCase()
                                      .replaceAll(' ', '-'),
                                  )
                                "
                              >
                                <i class="fas fa-sync"></i>
                              </button>
                              <button @click="deleteSchool(schoolKey)">
                                <i class="fas fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td
                              class="text-sm bg-white"
                              :colspan="Object.keys(schoolSchema).length + 1"
                            >
                              <div
                                class="ease-linear transition-all duration-150 overflow-y-auto"
                                :class="{
                                  'max-h-[0px]': !isSchoolExpanded(schoolKey),
                                  'max-h-[400px]': isSchoolExpanded(schoolKey),
                                }"
                              >
                                <table
                                  v-if="isSchoolExpanded(schoolKey)"
                                  class="mx-5 my-5"
                                >
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th
                                        v-for="(
                                          peopleColumnProps, peopleColumnKey
                                        ) in peopleSchema"
                                        :key="peopleColumnKey"
                                        class="border text-xs uppercase text-left px-2 py-1"
                                      >
                                        {{ peopleColumnKey }}
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <!-- eslint-disable -->
                                    <template
                                      v-for="(
                                        peopleValue, peopleKey
                                      ) in peopleBySchool[schoolValue.name]"
                                      :key="peopleKey"
                                    >
                                    <!-- eslint-enable -->
                                      <tr>
                                        <td class="pt-1 pr-1">
                                          <span
                                            @click="
                                              togglePersonExpanded(peopleKey)
                                            "
                                            ><i
                                              class="fa-solid fa-caret-right transition-all duration-500 cursor-pointer"
                                              :class="{
                                                'rotate-90':
                                                  isPersonExpanded(peopleKey),
                                              }"
                                            ></i
                                          ></span>
                                        </td>
                                        <td
                                          v-for="(
                                            peopleColumnProps, peopleColumnKey
                                          ) in peopleSchema"
                                          :key="peopleColumnKey"
                                          :width="
                                            peopleColumnProps.width
                                              ? `${peopleColumnProps.width}%`
                                              : 'auto'
                                          "
                                          class="border-t-2 border-t-blueGray-500 bg-white"
                                        >
                                          <div class="flex">
                                            <input
                                              v-if="
                                                peopleColumnProps.type ==
                                                'string'
                                              "
                                              v-model="
                                                peopleValue[peopleColumnKey]
                                              "
                                              type="text"
                                              class="w-full px-2 pt-1 text-sm border-0 pb-0"
                                              @change="editPerson(peopleKey)"
                                            />
                                            <input
                                              v-if="
                                                peopleColumnProps.type ==
                                                'number'
                                              "
                                              v-model="
                                                peopleValue[peopleColumnKey]
                                              "
                                              type="number"
                                              class="w-full px-2 pt-1 text-sm border-0 pb-0"
                                              @change="editPerson(peopleKey)"
                                            />
                                            <template
                                              v-if="
                                                peopleColumnProps.type == 'link'
                                              "
                                            >
                                              <input
                                                v-model="
                                                  peopleValue[peopleColumnKey]
                                                "
                                                type="text"
                                                class="w-full px-2 pt-1 text-sm border-0 pb-0"
                                                @change="editPerson(peopleKey)"
                                              />
                                              <a
                                                :href="
                                                  peopleValue[peopleColumnKey]
                                                "
                                                target="_blank"
                                                ><i class="fa-solid fa-link"></i
                                              ></a>
                                            </template>
                                            <template
                                              v-if="
                                                peopleColumnProps.type ==
                                                'image'
                                              "
                                            >
                                              <img
                                                v-if="
                                                  peopleValue[peopleColumnKey]
                                                "
                                                :src="
                                                  peopleValue[peopleColumnKey]
                                                "
                                                style="height: 20px"
                                              />
                                            </template>
                                          </div>
                                        </td>
                                        <td
                                          class="border-t-2 border-t-blueGray-500 border-t text-sm bg-white px-2"
                                        >
                                          <button
                                            @click="deletePerson(peopleKey)"
                                          >
                                            <i class="fas fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td
                                          class="text-sm bg-white"
                                          :colspan="peopleSchema.length + 1"
                                        >
                                          <div
                                            class="ease-linear transition-all duration-150"
                                            :class="{
                                              'max-h-[0px]':
                                                !isPersonExpanded(peopleKey),
                                              'max-h-[100px]':
                                                isPersonExpanded(peopleKey),
                                            }"
                                          >
                                            Detail
                                          </div>
                                        </td>
                                      </tr>
                                    </template>
                                    <tr>
                                      <td></td>
                                      <td
                                        v-for="(
                                          peopleColumnProps, peopleColumnKey
                                        ) in peopleSchema"
                                        :key="peopleColumnKey"
                                        class="border"
                                      >
                                        <input
                                          v-model="peopleForm[peopleColumnKey]"
                                          class="w-full px-1 py-1"
                                          :placeholder="peopleColumnKey"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          @click="addPerson(schoolValue.name)"
                                        >
                                          Save
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </template>
                        <tr>
                          <td></td>
                          <td></td>
                          <td
                            v-for="(
                              schoolColumnProps, schoolColumnKey
                            ) in schoolSchema"
                            :key="schoolColumnKey"
                            class="border"
                          >
                            <input
                              v-model="schoolForm[schoolColumnKey]"
                              class="w-full px-1 py-1"
                              :placeholder="schoolColumnKey"
                            />
                          </td>
                          <td><button @click="addSchool(key)">Save</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </template>
            <tr>
              <td class="border"></td>
              <td
                v-for="(columnProps, columnKey) in universitySchema"
                :key="columnKey"
                class="border"
              >
                <input
                  v-model="universityForm[columnKey]"
                  class="w-full px-1 py-1"
                  :placeholder="columnKey"
                />
              </td>
              <td class="border">
                <button @click="addUniversity()">Save</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-between">
          <button @click="loadMoreUniversities()">Load More</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import axios from "axios";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  getDatabase,
  ref,
  child,
  get,
  query,
  set,
  limitToFirst,
  limitToLast,
  orderByChild,
  push,
  startAfter,
} from "firebase/database";

const { keysToCamel } = utils;

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase(app);

export default {
  name: "DataIntegrity",
  components: {},
  data() {
    return {
      universitySchema: {
        name: {
          type: "string",
          width: 25,
        },
        grid: {
          type: "string",
          width: 10,
        },
        url: {
          type: "link",
          width: 15,
        },
        city: {
          type: "string",
          width: 10,
        },
        state: {
          type: "string",
          width: 10,
        },
        country: {
          type: "string",
          width: 10,
        },
        university_rank: {
          type: "number",
          width: 10,
        },
        liberal_arts_rank: {
          type: "number",
          width: 10,
        },
      },
      schoolSchema: {
        status: {
          type: "string",
          width: 10,
        },
        name: {
          type: "string",
          width: 50,
        },
        faculty_url: {
          type: "link",
          width: 20,
        },
      },
      peopleSchema: {
        last_name: {
          type: "string",
          width: 10,
        },
        first_name: {
          type: "string",
          width: 10,
        },
        title: {
          type: "string",
          width: 30,
        },
        url: {
          type: "link",
          width: 20,
        },
        email_address: {
          type: "string",
          width: 20,
        },
        image_url: {
          type: "image",
          width: 10,
        },
      },
      universityForm: {},
      universityLimit: 500,
      universityOffset: 0,
      universityOrderBy: "university_rank",
      schoolForm: {},
      schoolLimit: 500,
      schoolOffset: "",
      schoolOrderBy: "name",
      peopleForm: {},
      peopleLimit: 5000,
      peopleOffset: "",
      peopleOrderBy: "last_name",
      expandedUniversities: [],
      expandedSchools: [],
      expandedPeople: [],
      universities: {},
      schools: {},
      people: {},
    };
  },
  computed: {
    schoolsByUniversity() {
      if (this.schools) {
        const schoolsByUniversity = {};
        Object.entries(this.schools).forEach(([key, value]) => {
          if (
            Object.keys(schoolsByUniversity).indexOf(value.university) === -1
          ) {
            schoolsByUniversity[value.university] = {};
          }
          schoolsByUniversity[value.university][key] = value;
        });
        return schoolsByUniversity;
      }
      return {};
    },
    peopleBySchool() {
      if (this.people) {
        const peopleBySchool = {};
        Object.entries(this.people).forEach(([key, value]) => {
          if (Object.keys(peopleBySchool).indexOf(key) === -1) {
            peopleBySchool[key] = {};
          }
          peopleBySchool[key] = value;
        });
        return peopleBySchool;
      }
      return {};
    },
  },
  mounted() {
    this.loadUniversities();
    this.loadSchools();
    this.loadPeople();
    this.initCreateUniversityForm();
  },
  methods: {
    doneCount(schools) {
      if (schools) {
        return Object.entries(schools).filter(([key, school]) => {  // eslint-disable-line
          return school.status === "Done";
        }).length;
      }
      return 0;
    },
    loginFirebase() {
      this.$api.axios.get("/firebase-auth/").then((resp) => {
        const data = keysToCamel(resp.data);
        const { token } = data;
        signInWithCustomToken(auth, token);
      });
    },
    loadUniversities(append) {
      let limitClause;
      let orderClause;
      if (this.universityOrderBy[0] === "-") {
        limitClause = limitToLast(this.universityLimit);
        orderClause = orderByChild(this.universityOrderBy.substring(1));
      } else {
        limitClause = limitToFirst(this.universityLimit);
        orderClause = orderByChild(this.universityOrderBy);
      }
      const clauses = [limitClause, orderClause];
      if (this.universityOffset !== "") {
        clauses.push(startAfter(this.universityOffset));
      }
      const universitiesRef = query(ref(db, `universities`), ...clauses);

      get(universitiesRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            if (!append) {
              this.universities = {};
            }
            if (this.universityOrderBy[0] === "-") {
              const temp = {};
              snapshot.forEach((child_) => {
                temp[child_.key] = child_.val();
              });
              Object.keys(temp)
                .reverse()
                .forEach((key) => {
                  this.universities[key] = temp[key];
                });
            } else {
              snapshot.forEach((child_) => {
                this.universities[child_.key] = child_.val();
              });
            }
          } else {
            console.log("No data available");  // eslint-disable-line
          }
        })
        .catch((error) => {
          console.error(error);  // eslint-disable-line
        });
    },
    loadMoreUniversities() {
      const highestKey = Object.keys(this.universities).slice(-1);
      this.universityOffset =
        this.universities[highestKey][this.universityOrderBy.replace("-", "")];
      this.loadUniversities(true);
    },
    initCreateUniversityForm() {
      Object.entries(this.universitySchema).forEach(([key, value]) => {  // eslint-disable-line
        this.universityForm[key] = "";
      });
    },
    initCreateSchoolForm() {
      Object.entries(this.schoolSchema).forEach(([key, value]) => {  // eslint-disable-line
        this.schoolForm[key] = "";
      });
    },
    addUniversity() {
      const newKey = push(child(ref(db), "universities")).key;
      set(ref(db, `universities/${newKey}`), this.universityForm);
    },
    editUniversity(key) {
      set(ref(db, `universities/${key}`), this.universities[key]);
    },
    deleteUniversity(key) {
      set(ref(db, `universities/${key}`), null);
    },
    setUniversityOrderBy(key) {
      if (this.universityOrderBy === key) {
        this.universityOrderBy = `-${this.universityOrderBy}`;
      } else {
        this.universityOrderBy = key;
      }
      this.loadUniversities();
    },
    loadSchools() {
      let limitClause;
      let orderClause;
      if (this.schoolOrderBy[0] === "-") {
        limitClause = limitToLast(this.schoolLimit);
        orderClause = orderByChild(this.schoolOrderBy.substring(1));
      } else {
        limitClause = limitToFirst(this.schoolLimit);
        orderClause = orderByChild(this.schoolOrderBy);
      }
      const schoolsRef = query(ref(db, `schools`), limitClause, orderClause);

      get(schoolsRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            this.schools = {};
            snapshot.forEach((child_) => {
              this.schools[child_.key] = child_.val();
            });
          } else {
            console.log("No data available");  // eslint-disable-line
          }
        })
        .catch((error) => {
          console.error(error);  // eslint-disable-line
        });
    },
    addSchool(universityKey) {
      const newKey = push(child(ref(db), "schools")).key;
      set(ref(db, `schools/${newKey}`), {
        university: universityKey,
        ...this.schoolForm,
      });
      this.initCreateSchoolForm();
      this.loadSchools();
    },
    editSchool(key) {
      set(ref(db, `schools/${key}`), this.schools[key]);
    },
    deleteSchool(key) {
      set(ref(db, `schools/${key}`), null);
      this.loadSchools();
    },
    crawlSchool(universityKey, schoolKey) {
      axios.get(
        `${
          import.meta.env.VITE_CRAWLER_BASE_URL
        }/crawl/${universityKey}/${schoolKey}/`,
      );
    },
    setupSchool(universityKey, schoolKey, url) {
      window.open(
        `${
          import.meta.env.VITE_ADMIN_BASE_URL
        }/admin/scraper?universityName=${universityKey}&schoolName=${schoolKey}&submittedUrl=${encodeURIComponent(
          url,
        )}`,
      );
    },
    isUniversityExpanded(key) {
      return this.expandedUniversities.indexOf(key) > -1;
    },
    toggleUniversityExpanded(key) {
      if (this.expandedUniversities.indexOf(key) === -1) {
        this.expandedUniversities.push(key);
      } else {
        this.expandedUniversities = this.expandedUniversities.filter(
          (e) => e !== key,
        );
      }
    },
    isSchoolExpanded(key) {
      return this.expandedSchools.indexOf(key) > -1;
    },
    toggleSchoolExpanded(key) {
      if (this.expandedSchools.indexOf(key) === -1) {
        this.expandedSchools.push(key);
      } else {
        this.expandedSchools = this.expandedSchools.filter((e) => e !== key);
      }
    },
    loadPeople() {
      let limitClause;
      let orderClause;
      if (this.peopleOrderBy[0] === "-") {
        limitClause = limitToLast(this.peopleLimit);
        orderClause = orderByChild(this.peopleOrderBy.substring(1));
      } else {
        limitClause = limitToFirst(this.peopleLimit);
        orderClause = orderByChild(this.peopleOrderBy);
      }
      const peopleRef = query(ref(db, `people`), limitClause, orderClause);

      get(peopleRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            this.people = {};
            snapshot.forEach((child_) => {
              this.people[child_.key] = child_.val();
            });
          } else {
            console.log("No data available");  // eslint-disable-line
          }
        })
        .catch((error) => {
          console.error(error);  // eslint-disable-line
        });
    },
    addPerson(schoolKey) {
      set(ref(db, `people/${schoolKey}`), {
        ...this.personForm,
      });
      this.initCreatePersonForm();
      this.loadPeople();
    },
    editPerson(key) {
      set(ref(db, `people/${key}`), this.people[key]);
    },
    deletePerson(key) {
      set(ref(db, `people/${key}`), null);
    },
    isPersonExpanded(key) {
      return this.expandedPeople.indexOf(key) > -1;
    },
    togglePersonExpanded(key) {
      if (this.expandedPeople.indexOf(key) === -1) {
        this.expandedPeople.push(key);
      } else {
        this.expandedPeople = this.expandedPeople.filter((e) => e !== key);
      }
    },
  },
};
</script>
