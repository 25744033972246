<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div
        class="relative flex flex-col min-w-0 break-words mt-3 mb-6 shadow-lg rounded-lg bg-white px-6 pb-6 mx-6"
      >
        <h4 class="text-3xl font-light mt-6 mb-6">Data Integrity</h4>

        <ul class="list-disc ml-10">
          <li>
            <router-link to="/admin/data-integrity/schools"
              >Schools</router-link
            >
          </li>
          <li>
            <router-link to="/admin/data-integrity/research-interests"
              >Research Expertise</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataIntegrity",
  components: {},
  data() {
    return {};
  },
};
</script>
