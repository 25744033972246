<template>
  <div>
    <transition name="slide-fade">
      <div
        v-if="showModal"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
      >
        <div
          v-click-outside="onClickOutside"
          class="relative mr-0 mx-auto max-w-[1000px] w-[90%] md:w-[75%] min-w-[300px] h-full bg-porcelain overflow-y-scroll"
        >
          <!--content-->
          <div
            class="border-0 relative flex flex-col w-full outline-none focus:outline-none h-full"
          >
            <!--header-->
            <div
              class="flex items-start justify-between py-3 px-5 border-b border-solid border-porcelain-300 rounded-t"
            >
              <slot name="header"> </slot>
              <button
                class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                @click.prevent="toggleModal()"
              >
                <span
                  class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
                >
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <div class="relative flex-auto">
              <slot name="body"> </slot>
            </div>
            <!--footer-->
            <div
              v-if="!hideFooter"
              class="flex items-center justify-end px-6 py-3 border-t border-solid border-porcelain-700 rounded-b"
            >
              <slot name="footer"> </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showModal"
        class="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "RightSideModal",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    preventCloseOnClick: Boolean,
    showing: Boolean,
    hideFooter: Boolean,
  },
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    if (this.showing) {
      this.showModal = true;
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      if (this.showModal) {
        this.$emit("show");
      } else {
        this.$emit("hide");
      }
    },
    onClickOutside() {
      if (!this.preventCloseOnClick) {
        this.toggleModal();
      }
    },
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
