<template>
  <div class="flex flex-wrap">
    <div class="px-4 w-full">
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div id="scraper-controls" ref="dragElement" class="w-[200px]">
          <div id="scraper-controls-header"></div>
          <div
            v-for="(phase, index) in phases"
            :key="index"
            class="py-2"
            :class="{ 'bg-red-500 text-white': index == currentPhaseIndex }"
            @click="setPhaseIndex(index)"
          >
            <div class="flex justify-between items-center">
              <p class="px-3 py-1">{{ phase.name }}</p>
              <button v-if="index > 1" class="px-3" @click="removePhase(index)">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <div class="flex justify-between items-center">
              <div class="text-xs px-3 ml-3">
                <div v-if="phase.nodeName">
                  Node Name: {{ phase.nodeName }} (selected)
                </div>
                <div v-else-if="phase.defaultNodeName">
                  Node Name: {{ phase.defaultNodeName }} (default)
                </div>
                <div v-if="phase.nodeClass">
                  Node Class: {{ phase.nodeClass }} (selected)
                </div>
                <div v-if="phase.nodeProperty">
                  Node Property: {{ phase.nodeProperty }} (selected)
                </div>
                <div v-else-if="phase.defaultNodeProperty">
                  Node Property: {{ phase.defaultNodeProperty }}
                </div>
                <div v-if="phase.nodeAttribute">
                  Node Attribute: {{ phase.nodeAttribute }} (selected)
                </div>
                <div v-else-if="phase.defaultNodeAttribute">
                  Node Attribute: {{ phase.defaultNodeAttribute }}
                </div>
              </div>
              <button
                v-if="index > 1"
                class="px-3 text-xs"
                @click="removePhaseSelection(index)"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div class="mr-4">
            <div class="flex">
              <input v-model="url" class="form-text-input" type="text" />
              <button
                class="bg-blueGray-500 text-white px-4 py-2 uppercase"
                @click="submitUrl()"
              >
                Submit
              </button>
            </div>
            <iframe
              v-if="submittedUrl"
              ref="embeddedFrame"
              class="mt-4"
              :src="`${crawlerBaseUrl}/crawler/proxy?url=${submittedUrl}`"
              width="100%"
              height="1200px"
              style="overflow: auto"
            />
          </div>
          <div class="ml-4">
            <input
              v-model="schoolName"
              class="form-text-input"
              placeholder="School Name"
            />
            <div class="mt-4">
              <div class="flex flex-wrap">
                <div class="w-1/2">
                  <label class="form-label text-blueGray-500">
                    <input v-model="invertName" class="mr-1" type="checkbox" />
                    Invert Name
                  </label>
                </div>
                <div class="w-1/2">
                  <label class="form-label text-blueGray-500">
                    <input
                      v-model="useBackgroundImage"
                      class="mr-1"
                      type="checkbox"
                    />
                    Use Background Image
                  </label>
                </div>
                <div class="w-1/2">
                  <label class="form-label text-blueGray-500">
                    <input
                      v-model="separateFirstAndLastNames"
                      class="mr-1"
                      type="checkbox"
                    />
                    Separate First and Last Names
                  </label>
                </div>
              </div>
            </div>
            <div class="flex absolute z-50 mt-5 right-2">
              <div
                class="bg-white rounded-lg px-3 py-1 text-lg cursor-pointer mr-1"
                @click="copyCode()"
              >
                <i class="fa-regular fa-clipboard"></i>
              </div>
              <div
                class="bg-white rounded-lg px-3 py-1 text-lg cursor-pointer"
                @click="visitUrl()"
              >
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </div>
            </div>
            <v-ace-editor
              ref="codeContent"
              class="mt-4"
              :value="codeContent"
              lang="python"
              theme="twilight"
              style="height: 300px"
            />
            <div class="overflow-scroll mt-4">
              <table class="text-xs">
                <thead>
                  <tr>
                    <th
                      v-for="column in dataColumns"
                      :key="column.name"
                      class="border px-2"
                    >
                      {{ column.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in scrapedData" :key="row">
                    <td
                      v-for="column in dataColumns"
                      :key="column.name"
                      class="border px-2"
                    >
                      {{ row[column.name] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { VAceEditor } from "vue3-ace-editor";

import themeTwilightUrl from "ace-builds/src-noconflict/theme-twilight?url";  // eslint-disable-line
import modePythonUrl from "ace-builds/src-noconflict/mode-python?url";  // eslint-disable-line

ace.config.setModuleUrl("ace/theme/twilight", themeTwilightUrl);  // eslint-disable-line
ace.config.setModuleUrl("ace/mode/python", modePythonUrl);  // eslint-disable-line

function dragElement(elmnt) {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  function elementDrag(e) {
    const event = e || window.event;
    event.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - event.clientX;
    pos2 = pos4 - event.clientY;
    pos3 = event.clientX;
    pos4 = event.clientY;
    // set the element's new position:
    elmnt.style.top = `${elmnt.offsetTop - pos2}px`;
    elmnt.style.left = `${elmnt.offsetLeft - pos1}px`;
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }

  function dragMouseDown(e) {
    const event = e || window.event;
    event.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = event.clientX;
    pos4 = event.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  if (document.getElementById(`${elmnt.id}header`)) {
    // if present, the header is where you move the DIV from:
    document.getElementById(`${elmnt.id}header`).onmousedown = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
  }
}

export default {
  name: "Scraper",
  components: {
    VAceEditor,
  },
  data() {
    return {
      url: null,
      submittedUrl: this.$route.query.submittedUrl
        ? decodeURIComponent(this.$route.query.submittedUrl)
        : null,
      result: null,
      crawlerBaseUrl: import.meta.env.VITE_CRAWLER_BASE_URL,
      currentPhaseIndex: null,
      scrapedData: [],
      universityName: this.$route.query.universityName
        ? this.$route.query.universityName
        : null,
      schoolName: this.$route.query.schoolName
        ? this.$route.query.schoolName
        : null,
      codeContent: "",
      invertName: false,
      useBackgroundImage: false,
      separateFirstAndLastNames: false,
      phases: [
        {
          name: "container",
          nodeName: null,
          nodeClass: null,
          nodeOverride: null,
        },
        {
          name: "name",
          nodeName: null,
          nodeClass: null,
          nodeProperty: null,
          nodeAttribute: null,
          defaultNodeName: "h3",
          defaultProperty: "text",
          nodeOverride: null,
        },
        {
          name: "title",
          nodeName: null,
          nodeClass: null,
          nodeProperty: null,
          nodeAttribute: null,
          defaultNodeName: "h4",
          defaultProperty: "text",
          nodeOverride: null,
        },
        {
          name: "email",
          nodeName: null,
          nodeClass: null,
          nodeProperty: null,
          nodeAttribute: null,
          defaultNodeName: "a",
          defaultAttribute: "href",
          nodeOverride: null,
        },
        {
          name: "url",
          nodeName: null,
          nodeClass: null,
          nodeProperty: null,
          nodeAttribute: null,
          defaultNodeName: "a",
          defaultAttribute: "href",
          nodeOverride: null,
        },
        {
          name: "image_url",
          nodeName: null,
          nodeClass: null,
          nodeProperty: null,
          nodeAttribute: null,
          defaultNodeName: "img",
          defaultAttribute: "src",
          nodeOverride: null,
        },
        {
          name: "phone",
          nodeName: null,
          nodeClass: null,
          nodeProperty: null,
          nodeAttribute: null,
          defaultNodeName: "a",
          defaultAttribute: "href",
          nodeOverride: null,
        },
        {
          name: "department",
          nodeName: null,
          nodeClass: null,
          nodeProperty: null,
          nodeAttribute: null,
          defaultNodeName: "p",
          defaultProperty: "text",
          nodeOverride: null,
        },
      ],
    };
  },
  computed: {
    dataColumns() {
      return this.phases.slice(1);
    },
  },
  watch: {
    currentPhaseIndex(newValue) {
      this.sendMessage({ subject: "setPhaseIndex", body: newValue });
    },
    phases: {
      handler(newValue) {
        this.sendMessage({
          subject: "updatePhases",
          body: JSON.parse(JSON.stringify(newValue)),
        });
        this.assembleCode();
      },
      deep: true,
    },
    schoolName() {
      this.assembleCode();
    },
    invertName() {
      this.assembleCode();
    },
    useBackgroundImage() {
      this.assembleCode();
    },
    separateFirstAndLastNames(newValue) {
      if (newValue) {
        const nameIndex = this.phases
          .map((phase) => {
            return phase.name;
          })
          .indexOf("name");
        this.phases.splice(nameIndex, 1);
        window.phases = this.phases;
        const phases = [
          ...this.phases.slice(0, nameIndex),
          {
            name: "first_name",
            nodeName: null,
            nodeClass: null,
            nodeProperty: null,
            nodeAttribute: null,
            defaultNodeName: "h3",
            defaultProperty: "text",
          },
          {
            name: "last_name",
            nodeName: null,
            nodeClass: null,
            nodeProperty: null,
            nodeAttribute: null,
            defaultNodeName: "h3",
            defaultProperty: "text",
          },
          ...this.phases.slice(nameIndex),
        ];
        this.phases = phases;
      } else {
        const firstNameIndex = this.phases
          .map((phase) => {
            return phase.name;
          })
          .indexOf("first_name");
        this.phases.splice(firstNameIndex, 1);
        const lastNameIndex = this.phases
          .map((phase) => {
            return phase.name;
          })
          .indexOf("last_name");
        this.phases.splice(lastNameIndex, 1);
        const phases = [
          ...this.phases.slice(0, lastNameIndex),
          {
            name: "name",
            nodeName: null,
            nodeClass: null,
            nodeProperty: null,
            nodeAttribute: null,
            defaultNodeName: "h3",
            defaultProperty: "text",
          },
          ...this.phases.slice(lastNameIndex),
        ];
        this.phases = phases;
      }
      this.assembleCode();
    },
  },
  mounted() {
    dragElement(this.$refs.dragElement);
    window.addEventListener("message", this.handleMessage);
    this.assembleCode();
  },
  beforeUnmount() {
    document.getElementById("scraper-controls").outerHTML = "";
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    submitUrl() {
      this.submittedUrl = this.url;
      const self = this;
      nextTick(() => {
        self.$refs.embeddedFrame.focus();
      });
    },
    sendMessage(message) {
      // message subjects handled by child: updatePhases, setPhaseIndex
      const { frames } = window;
      for (let i = 0; i < frames.length; i++) {
        frames[i].postMessage(message, "*");
      }
    },
    setPhaseIndex(index) {
      this.currentPhaseIndex = index;
    },
    setPhaseContent(content) {
      this.phases[this.currentPhaseIndex].nodeName = content.nodeName;
      this.phases[this.currentPhaseIndex].nodeClass = content.nodeClass;
      this.currentPhaseIndex += 1;
    },
    removePhase(index) {
      this.phases.splice(index, 1);
    },
    removePhaseSelection(index) {
      this.phases[index].nodeName = null;
      this.phases[index].nodeClass = null;
      this.phases[index].nodeProperty = null;
      this.phases[index].nodeAttribute = null;
    },
    handleMessage(message) {
      // message subjects handled by parent: ready, setPhaseContent, setData
      if (message.data) {
        if (message.data.subject && message.data.subject === "ready") {
          this.sendMessage({
            subject: "updatePhases",
            body: JSON.parse(JSON.stringify(this.phases)),
          });
          if (!this.schoolName) {
            this.schoolName = message.data.body;
          }
          this.currentPhaseIndex = 0;
        }
        if (
          message.data.subject &&
          message.data.subject === "setPhaseContent"
        ) {
          this.setPhaseContent(message.data.body);
        }
        if (message.data.subject && message.data.subject === "setData") {
          this.scrapedData = message.data.body;
        }
      }
    },
    assembleContainerClause(nodeName, nodeClass) {
      if (nodeClass) {
        return `nodes = soup.find_all("${nodeName}", class_="${nodeClass}")`;
      }
      return `nodes = soup.find_all("${nodeName}")`;
    },
    assembleClause(phaseName, nodeName, nodeClass, nodeAttribute) {
      let clause;
      if (nodeClass) {
        clause = `
        ${phaseName}_el = node.find("${nodeName}", class_="${nodeClass}")`;
      } else {
        clause = `
        ${phaseName}_el = node.find("${nodeName}")`;
      }
      if (this.useBackgroundImage && phaseName === "image_url") {
        clause += `
        ${phaseName} = cssutils.parseStyle(${phaseName}_el['style'])['background-image'].replace('url(', '').replace(')', '') if ${phaseName}_el else None`;
      } else if (
        nodeAttribute ||
        (nodeName === "img" && !nodeAttribute) ||
        (nodeName === "a" && !nodeAttribute)
      ) {
        clause += `
        ${phaseName} = ${phaseName}_el['${
          nodeAttribute || (nodeName === "img" ? "src" : "href")
        }'] if ${phaseName}_el else None`;
      } else {
        clause += `
        ${phaseName} = ${phaseName}_el.text.strip() if ${phaseName}_el else None`;
      }
      return clause;
    },
    assembleCode() {
      const clauses = {};
      this.phases.forEach((phase, index) => {
        if (index === 0) {
          clauses[phase.name] = this.assembleContainerClause(
            phase.nodeName,
            phase.nodeClass,
          );
        } else if (phase.nodeName || phase.defaultNodeName) {
          clauses[phase.name] = this.assembleClause(
            phase.name,
            phase.nodeName || phase.defaultNodeName,
            phase.nodeClass,
            phase.nodeAttribute || phase.defaultAttribute,
          );
        }
      });

      this.codeContent = `@${this.universityName
        .toLowerCase()
        .replaceAll(" ", "_")}_bp.route('/${this.schoolName
        .toLowerCase()
        .replaceAll(" ", "-")}/', methods=['GET'])
def crawl_${this.schoolName.toLowerCase().replaceAll(" ", "_")}():
    ref = get_firebase_ref('people', '${this.schoolName}')

    soup = soup_from_url('${this.submittedUrl}')`;

      if (clauses[this.phases[0].name]) {
        this.codeContent += `
    ${clauses[this.phases[0].name]}
    for node in nodes:
        name = None
        title = None
        email = None
        url = None
        image_url = None
        phone = None
        department = None
        `;
        this.phases.forEach((phase, index) => {
          if (index > 0 && clauses[phase.name]) {
            this.codeContent += `${clauses[phase.name]}`;
          }
        });
        if (clauses.url) {
          this.codeContent += `
        university_id = url.split('/')[-1].replace('.html', '') if url.split('/')[-1] else url.split('/')[-2]`;
        } else {
          this.codeContent += `
        university_id = ' '.join(name).replace(' ', '-').replace('.', '-').lower()`;
        }

        if (!this.separateFirstAndLastNames && this.invertName) {
          this.codeContent += `
        name = name.split(', ')
        name.reverse()`;
        } else if (!this.separateFirstAndLastNames) {
          this.codeContent += `
        name = name.replace(', ', ',').split()`;
        }

        let firstNameParser = "' '.join(name[0:-1])";
        let lastNameParser = "name[-1]";
        if (this.separateFirstAndLastNames) {
          firstNameParser = "first_name";
          lastNameParser = "last_name";
        }

        this.codeContent += `
        email = email.replace('Email: ', '').replace('mailto:', '').strip() if email else None
        phone = phone.replace('Tel: ', '').replace('tel:', '').strip() if phone else None

        send_to_firebase(ref, university_id, {
            'first_name': ${firstNameParser},
            'last_name': ${lastNameParser},
            'email': email,
            'phone': phone,
            'url': url,
            'title': title,
            'image_url': image_url,
            'department': department,
        })
    return 'OK'


`;
      }
    },
    async copyCode() {
      await navigator.clipboard.writeText(this.codeContent);
      this.$toast.success("Copied!");
    },
    visitUrl() {
      const url = `${this.crawlerBaseUrl}/crawl/${this.universityName
        .toLowerCase()
        .replaceAll(" ", "-")}/${this.schoolName
        .toLowerCase()
        .replaceAll(" ", "-")}`;
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped>
th {
  text-align: left;
}
.current-element {
  outline: 2px solid red;
}
#scraper-controls {
  font-family: "Source Sans 3", Sans-serif;
  position: fixed;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  z-index: 100;
  left: 20px;
}

#scraper-controls-header {
  padding: 5px 20px;
  cursor: move;
  z-index: 10;
  background-color: #d3d3d3;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 15px;
}
</style>
