<template>
  <div>
    <div v-for="(item, index) in subquery.items" :key="index">
      <div
        v-if="item.itemType == 'group'"
        :class="{
          'tree-node pl-[20px]': !root,
          last: index == subquery.items.length - 1,
        }"
      >
        <div class="flex">
          <div :class="{ 'tree-leaf': !root }">
            <div
              :class="{
                'opacity-50 cursor-default': item.items.length < 2,
                'cursor-pointer': item.items.length >= 2,
              }"
              class="flex bg-blueGray-200 border border-blueGray-300 uppercase text-xs rounded mt-3 text-blueGray-500"
            >
              <div
                class="px-2 py-1 border-r border-blueGray-300"
                :class="{ 'font-bold': item.boolType == 'and' }"
                @click="setBoolType(item, 'and')"
              >
                And
              </div>
              <div
                class="px-2 py-1"
                :class="{ 'font-bold': item.boolType == 'or' }"
                @click="setBoolType(item, 'or')"
              >
                Or
              </div>
            </div>
          </div>
          <div class="relative ml-3 mt-3">
            <i
              class="fa-regular fa-square-plus cursor-pointer text-xl text-green-600"
              @click="toggleDropdown()"
            ></i>
            <div
              v-if="showDropdown"
              v-on-click-outside="onClickOutside"
              class="absolute bg-white border border-1 border-black/10 z-10 w-[150px] rounded outline-none ring ring-chartreuseYellow"
            >
              <div
                class="px-3 py-2 text-sm hover:bg-blueGray-100 cursor-pointer text-blueGray-600"
                @click="addCondition(item)"
              >
                Add Condition
              </div>
              <div
                class="px-3 py-2 text-sm hover:bg-blueGray-100 cursor-pointer text-blueGray-600"
                @click="addGroup(item)"
              >
                Add Group
              </div>
            </div>
          </div>
          <div
            v-if="!root"
            class="cursor-pointer ml-2 mt-3"
            @click="
              $emit('removeGroup', { items: subquery.items, index: index })
            "
          >
            <i class="fa-regular fa-circle-xmark text-xl text-red-600"></i>
          </div>
        </div>
        <div class="ml-[20px]">
          <condition-group
            ref="conditionGroup"
            :enums="enums"
            :subquery="item"
            :root="false"
            :journal-title-list="journalTitleList"
            :research-interest-list="researchInterestList"
            @search-has-focus="$emit('searchHasFocus')"
            @removeGroup="removeGroup($event.items, $event.index)"
          />
        </div>
      </div>
      <div
        v-if="item.itemType == 'condition'"
        class="pl-[20px]"
        :class="{
          'tree-node': !root,
          last: index == subquery.items.length - 1,
        }"
      >
        <condition
          ref="condition"
          :selected-field="item.selectedField"
          :query="item.query"
          :enums="enums"
          :journal-title-list="journalTitleList"
          :research-interest-list="researchInterestList"
          :available-fields="availableFields"
          @search-has-focus="$emit('searchHasFocus')"
          @removeCondition="removeCondition(subquery.items, index)"
          @selectField="selectField(item, $event)"
          @setQuery="setQuery(item, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import Condition from "./Condition.vue";
import ConditionGroup from "./ConditionGroup.vue";  // eslint-disable-line

export default {
  components: {
    Condition,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    enums: Object,
    subquery: Object,
    root: Boolean,
    journalTitleList: Array,
    researchInterestList: Array,
  },
  emits: ["removeGroup", "searchHasFocus"],
  data() {
    return {
      showDropdown: false,
      availableFields: {
        text: {
          label: "Any Text Contains",
          type: "query_string",
          fields: [
            "first_name",
            "last_name",
            "display_name",
            "headline",
            "about",
            "research_interests.research_interest",
            "school_names.school_name",
          ],
        },
        display_name: {
          label: "Name Contains",
          type: "text",
        },
        headline: {
          label: "Headline Contains",
          type: "text",
        },
        about: {
          label: "About Contains",
          type: "text",
        },
        journal_title: {
          label: "Journal Title Matches",
          type: "select",
          filterName: "journal_titles.journal_title.keyword",
          values: "journalTitleOptions",
        },
        research_interest: {
          label: "Research Expertise Matches",
          type: "select",
          filterName: "research_interests.research_interest.keyword",
          values: "researchInterestOptions",
        },
      },
    };
  },
  watch: {
    "subquery.items": {
      handler(newValue) {
        this.updateSubqueryClause(newValue, null);
      },
      deep: true,
    },
    "subquery.boolType": {
      handler(newValue) {
        this.updateSubqueryClause(null, newValue);
      },
    },
  },
  methods: {
    setBoolType(item, newType) {
      if (item.items.length >= 2) {
        item.boolType = newType;
      }
    },
    setFocus() {
      nextTick(() => {
        if (this.$refs.condition) {
          this.$refs.condition[0].setFocus();
        } else if (this.$refs.conditionGroup) {
          this.$refs.conditionGroup[0].setFocus();
        }
      });
    },
    addGroup(item) {
      this.showDropdown = false;
      item.items.push({
        itemType: "group",
        boolType: "and",
        clause: {},
        items: [
          { itemType: "condition", selectedField: "", query: "", clause: {} },
        ],
      });
    },
    addCondition(item) {
      this.showDropdown = false;
      item.items.push({
        itemType: "condition",
        selectedField: "",
        query: "",
        clause: {},
      });
    },
    removeCondition(items, index) {
      items.splice(index, 1);
    },
    removeGroup(items, index) {
      items.splice(index, 1);
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    onClickOutside() {
      this.showDropdown = false;
    },
    selectField(item, selectedField) {
      item.selectedField = selectedField;
      this.updateConditionSubqueryClause(item);
    },
    setQuery(item, query) {
      item.query = query;
      this.updateConditionSubqueryClause(item);
    },
    updateSubqueryClause(items, boolType) {
      if (items === null) {
        items = this.subquery.items; // eslint-disable-line
      }
      if (boolType === null) {
        boolType = this.subquery.boolType; // eslint-disable-line
      }
      const clause = { bool: { should: [] } };
      items.forEach((item) => {
        clause.bool.should.push(item.clause);
      });
      if (clause.bool.should.length === 0) {
        clause.bool.should.push({ match_all: {} });
      } else if (boolType === "and") {
        clause.bool.minimum_should_match = clause.bool.should.length;
      } else {
        clause.bool.minimum_should_match = 1;
      }
      this.subquery.clause = clause;
    },
    updateConditionSubqueryClause(item) {
      const selectedField = this.availableFields[item.selectedField];
      if (selectedField?.type === "text") {
        item.clause = {
          match_phrase: {
            [item.selectedField]: {
              query: item.query || "",
            },
          },
        };
      }
      if (selectedField?.type === "query_string") {
        item.clause = {
          query_string: {
            query: `"${item.query}"`,
            fields: selectedField.fields,
          },
        };
      } else if (
        selectedField?.type === "selectEnum" ||
        selectedField?.type === "select"
      ) {
        item.clause = {
          bool: {
            filter: {
              term: {
                [selectedField.filterName]: item.query,
              },
            },
          },
        };
      }
    },
  },
};
</script>

<style lang="scss">
.tree-node {
  border-left: 2px dotted #cccccc;
  &.last {
    border-left: 2px solid transparent;
    &::before {
      position: absolute;
      content: "";
      width: 0px;
      height: 25px;
      margin-left: -22px;
      background-color: transparent;
      border-left: 2px dotted #cccccc;
    }
  }
}
.tree-leaf {
  &::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 0px;
    margin-top: 25px;
    margin-left: -20px;
    border-top: 2px dotted #cccccc;
    background-color: transparent;
  }
}
</style>
