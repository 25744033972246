<template>
  <div class="bg-chartreuseYellow-300 rounded-lg mb-3 p-2">
    <label class="flex">
      <span
        class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
        >Headline</span
      >
      <div class="w-full md:w-5/6 text-xs">
        <textarea
          v-model="candidate.headline"
          class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
          rows="2"
        ></textarea>
      </div>
    </label>
    <demo-person
      v-if="candidate.person"
      :active-requests="activeRequests"
      :person="candidate.person"
      @setPerson="candidate.person = $event"
    />
    <label class="flex mt-2">
      <span
        class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
        >Author Pool</span
      >
      <div class="w-fill md:w-5/6 text-xs flex flex-wrap">
        <span
          v-for="(author, authorIndex) in candidate.authorPool"
          :key="authorIndex"
          class="w-1/4"
          >{{ author }}</span
        >
      </div>
    </label>
    <label class="flex mt-2">
      <div
        class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
      >
        Location
      </div>
      <div v-if="candidate.location" class="w-full md:w-5/6 text-xs flex">
        <div>
          <span class="text-xs">City (Can be anything)</span>
          <input
            v-model="candidate.location.city"
            class="rounded mr-2 text-sm w-[100px] px-2 py-1 bg-white/30 border-black/20"
            type="text"
          />
        </div>
        <div>
          <span class="text-xs">State (ex. "US_NY")</span>
          <input
            v-model="candidate.location.state"
            class="rounded mr-2 text-sm w-[100px] px-2 py-1 bg-white/30 border-black/20"
            type="text"
          />
        </div>
        <div>
          <span class="text-xs">Country (ex. "US")</span>
          <input
            v-model="candidate.location.country"
            class="rounded mr-2 text-sm w-[100px] px-2 py-1 bg-white/30 border-black/20"
            type="text"
          />
        </div>
      </div>
    </label>
    <label class="flex mt-2">
      <div
        class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
      >
        Publications
      </div>
      <div class="w-full md:w-5/6 text-xs">
        <ul>
          <li
            v-for="(publication, publicationIndex) in candidate.publications"
            :key="publicationIndex"
            class="mt-2"
          >
            "{{ publication.articleTitle }}"<br />
            <em>{{ publication.journalTitle }}</em>
          </li>
        </ul>
      </div>
    </label>
    <label class="flex mt-2">
      <div
        class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
      >
        Experience
      </div>
      <div class="w-full md:w-5/6 text-xs">
        <ul>
          <li
            v-for="(experience, experienceIndex) in candidate.experience"
            :key="experienceIndex"
            class="mt-2"
          >
            {{ experience.companyName }}
            <ul>
              <li
                v-for="(position, positionIndex) in experience.positions"
                :key="positionIndex"
                class="mt-2"
              >
                {{ position.positionName }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </label>
    <label class="flex mt-2">
      <div
        class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
      >
        Education
      </div>
      <div class="w-full md:w-5/6 text-xs">
        <ul>
          <li
            v-for="education in candidate.education"
            :key="education.hashedId"
            class="mt-2"
          >
            {{ education.degree }}, {{ education.graduationYear }}<br />
            {{ education.schoolName }}
          </li>
        </ul>
      </div>
    </label>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import DemoPerson from "./Person.vue";

const { keysToCamel, keysToSnake, addRequest, removeRequest, askQuestion } =
  utils;

function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default {
  components: {
    DemoPerson,
  },
  props: {
    activeRequests: Array,
    index: Number,
    researchInterests: Array,
    candidate: Object,
  },
  data() {
    return {
      loading: false,
      months: [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
      ],
      locations: [
        {
          city: "Boston",
          state: "US_MA",
          country: "US",
        },
        {
          city: "London",
          state: null,
          country: "GB",
        },
        {
          city: "Alexandria",
          state: "US_VA",
          country: "US",
        },
        {
          city: "Toronto",
          state: "CA_ON",
          country: "CA",
        },
        {
          city: "New York",
          state: "US_NY",
          country: "US",
        },
        {
          city: "San Francisco",
          state: "US_CA",
          country: "US",
        },
        {
          city: "Houston",
          state: "US_TX",
          country: "US",
        },
        {
          city: "Amsterdam",
          state: null,
          country: "NL",
        },
        {
          city: "Mexico City",
          state: null,
          country: "MX",
        },
        {
          city: "Sydney",
          state: "AU_NSW",
          country: "AU",
        },
      ],
    };
  },
  mounted() {
    this.fillInTheGaps();
  },
  methods: {
    fillInTheGaps() {
      if (Object.keys(this.candidate).indexOf("person") === -1) {
        this.candidate.person = {};
      }
      if (Object.keys(this.candidate).indexOf("location") === -1) {
        this.selectLocation();
      }
      if (Object.keys(this.candidate).indexOf("education") === -1) {
        this.generateEducation();
      }
      if (Object.keys(this.candidate).indexOf("experience") === -1) {
        this.generateExperience();
      }
      if (Object.keys(this.candidate).indexOf("headline") === -1) {
        this.generateHeadline();
      }
      if (Object.keys(this.candidate).indexOf("authorPool") === -1) {
        this.generateAuthorPool();
      }
      if (
        Object.keys(this.candidate).indexOf("primaryResearchInterest") === -1
      ) {
        this.candidate.primaryResearchInterest =
          this.researchInterests[this.index].interest;
      }
      if (Object.keys(this.candidate).indexOf("researchInterests") === -1) {
        this.pruneResearchInterests();
      }
      if (Object.keys(this.candidate).indexOf("publications") === -1) {
        this.generatePublications();
      }
    },
    generateHeadline() {
      if (this.candidate.experience.length > 0) {
        const lastExperience =
          this.candidate.experience[this.candidate.experience.length - 1];
        const lastPosition =
          lastExperience.positions[lastExperience.positions.length - 1];
        this.candidate.headline = `${lastPosition.positionName} at ${lastExperience.companyName}`;
      } else {
        this.candidate.headline = `Professor of ${
          this.researchInterests[this.index].interest
        } at ${this.researchInterests[this.index].universities.random()}`;
      }
    },
    generateAuthorPool() {
      const key = addRequest(this, "Generating candidate author pool...");
      this.$api.axiosThrottled
        .post(
          `/get-person/`,
          keysToSnake({
            count: 20,
          }),
        )
        .then((resp) => {
          const data = keysToCamel(resp.data);
          removeRequest(this, key);
          const names = data.results.map((name) => {
            return `${name.lastName} ${name.firstName[0]}.`;
          });
          this.candidate.authorPool = names;
        })
        .catch(() => removeRequest(this, key, true));
    },
    selectLocation() {
      this.candidate.location = this.locations.random();
    },
    pruneResearchInterests() {
      this.candidate.researchInterests = shuffle([...this.researchInterests])
        .splice(randomNumber(3, 5))
        .map((researchInterest) => {
          return researchInterest.interest;
        });
    },
    generateEducation() {
      const artsOrSciences = ["Arts", "Sciences"].random();
      const masters = {
        schoolName: this.researchInterests[this.index].universities.random(),
        degree: [
          `M.${artsOrSciences[0]}.`,
          `Master of ${artsOrSciences}`,
        ].random(),
        specialization: this.researchInterests[this.index].interest,
        graduationYear:
          new Date().getFullYear() - Math.floor(randomNumber(8, 12)),
        graduationMonth: this.months.random(),
      };
      const phd = {
        schoolName: this.researchInterests[this.index].universities.random(),
        degree: "Ph.D",
        specialization: this.researchInterests[this.index].interest,
        graduationYear:
          new Date().getFullYear() - Math.floor(randomNumber(3, 6)),
        graduationMonth: this.months.random(),
      };
      this.candidate.education = [masters, phd];
    },
    generateExperience() {
      const latestGraduationYear =
        this.candidate.education[this.candidate.education.length - 1]
          .graduationYear;
      const titles = [
        "Associate Professor",
        "Assistant Professor",
        "Professor",
        "Department Chair",
      ];
      const positions = [
        {
          positionName: `${titles.random()} of ${
            this.researchInterests[this.index].interest
          }`,
          isCurrent: true,
          startMonth: this.months.random(),
          startYear: latestGraduationYear,
        },
      ];
      this.candidate.experience = [
        {
          companyName: this.researchInterests[this.index].universities.random(),
          positions,
        },
      ];
    },
    generatePublications() {
      askQuestion(
        this,
        "Generating candidate publications...",
        `Please provide 6 sample article titles and journal titles of academic papers relevant to "${
          this.researchInterests[this.index].interest
        }" in the format [article title] - [journal title]`,
      ).then((resp) => {
        const data = keysToCamel(resp.data);
        this.candidate.publications = [];
        data.answer
          .trim()
          .replaceAll("\n\n", "\n")
          .split("\n")
          .forEach((publication) => {
            const pubInfo = publication.split(" - ");
            if (pubInfo.length === 1) {
              pubInfo[1] = `${
                this.researchInterests[this.index].interest
              } Review`;
            }
            this.candidate.publications.push({
              articleTitle: pubInfo[0]
                .replaceAll(/^\d+\s*[-\\.)]?\s+/gi, "")
                .replaceAll('"', "")
                .trim(),
              journalTitle: pubInfo[1]
                .replaceAll(/^\d+\s*[-\\.)]?\s+/gi, "")
                .trim(),
            });
          });
      });
    },
  },
};
</script>
