<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Contract Templates
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
    <right-side-modal ref="newContractFromTemplateModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">New Contract From Template</h4>
      </template>
      <template #body>
        <new-contract-from-template-modal
          v-if="contractTemplateHashedId"
          :hashed-id="contractTemplateHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";
import RightSideModal from "../Modals/RightSide.vue";
import NewContractFromTemplateModal from "../Contract/NewContractFromTemplateModal.vue";

export default {
  name: "ContractTemplates",
  components: {
    DataTable,
    RightSideModal,
    NewContractFromTemplateModal,
  },
  data() {
    return {
      singularLabel: "contract template",
      pluralLabel: "contract templates",
      adminPrefix: "contract-templates",
      apiPrefix: "contract-template",
      contractTemplateHashedId: null,
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Contract Template ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">CONTEM-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "CONTEM-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        slug: {
          type: "string",
          label: "Slug",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter Name",
          type: "string",
          value: "",
          default: "",
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter Slug",
          type: "string",
          value: "",
          default: "",
        },
        text: {
          label: "Text:",
          placeholder: "Enter Text",
          type: "text",
          value: "",
          default: "",
        },
        variables: {
          label: "Variables",
          type: "dataTable",
          value: "",
          singularLabel: "variable",
          pluralLabel: "variables",
          adminPrefix: "contract-template-variables",
          apiPrefix: "contract-template-variable",
          parentKey: "contract_template.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Variable ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">contemvar-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "CONTEMVAR-",
              },
            },
            name: {
              type: "string",
              label: "Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            slug: {
              type: "string",
              label: "Slug",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            defaultValue: {
              type: "string",
              label: "Default Value",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            contractTemplateId: {
              label: "Contract Template ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            name: {
              label: "Name:",
              placeholder: "Enter Name",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter Slug",
              type: "string",
              value: "",
              default: "",
            },
            description: {
              label: "Description:",
              placeholder: "Enter Description",
              type: "string",
              value: "",
              default: "",
            },
            variableType: {
              label: "Contract Variable Type",
              placeholder: "Select contract variable type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/ContractVariableType",
              searchOnFocus: true,
            },
            defaultValue: {
              label: "Default Value:",
              placeholder: "Enter Default Value",
              type: "string",
              value: "",
              default: "",
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            name: {
              label: "Name:",
              placeholder: "Enter Name",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter Slug",
              type: "string",
              value: "",
              default: "",
            },
            description: {
              label: "Description:",
              placeholder: "Enter Description",
              type: "string",
              value: "",
              default: "",
            },
            variableType: {
              label: "Contract Variable Type",
              placeholder: "Select contract variable type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/ContractVariableType",
              searchOnFocus: true,
            },
            defaultValue: {
              label: "Name:",
              placeholder: "Enter Default Value",
              type: "string",
              value: "",
              default: "",
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
      },
      createForm: {
        contractType: {
          label: "Contract Type",
          placeholder: "Select contract type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: ["required"],
          idKey: "hashedId",
          labelKey: "name",
          labelFunction(row) {
            return `${row.name}, contyp-${row.hashedId})`;
          },
          autocompleteEndpoint: "contract-type",
          searchOnFocus: true,
        },
        name: {
          label: "Name:",
          placeholder: "Enter Name",
          type: "string",
          value: "",
          default: "",
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter Slug",
          type: "string",
          value: "",
          default: "",
        },
        text: {
          label: "Text:",
          placeholder: "Enter Text",
          type: "text",
          value: "",
          default: "",
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [],
      },
    };
  },
  methods: {
    loadNewContractFromTemplateModal(hashedId) {
      this.contractTemplateHashedId = hashedId;
      this.toggleNewContractFromTemplateModal();
    },
    toggleNewContractFromTemplateModal() {
      this.$refs.newContractFromTemplateModal.toggleModal();
    },
  },
};
</script>
