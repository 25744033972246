<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <h4
          class="bg-blueGray-500 text-white text-xl font-light px-6 pt-3 pb-2 rounded-t-lg"
        >
          Log out
        </h4>
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-white/75 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-6">
            <div class="relative w-full mb-3">
              <div>
                <p class="text-sm">Logging you out...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("logout").then(() => {
      this.$router.push("/");
    });
  },
};
</script>
