<template>
  <div class="w-full min-w-full">
    <Hero />
  </div>
</template>

<script>
import Hero from "../../components/ProfileSearch2/Hero.vue";

export default {
  components: {
    Hero,
  },
  data() {
    return {};
  },
};
</script>
