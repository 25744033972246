<template>
  <div>
    <div
      v-if="activeRequests.length"
      class="w-full h-full bg-black/50 text-white text-center flex items-center fixed top-0 left-0"
    >
      <div class="mx-auto">
        <i class="text-3xl fas fa-circle-notch fa-spin mb-3"></i>
        <p
          v-for="(request, index) in activeRequests"
          :key="index"
          class="text-center"
        >
          <span class="text-white p-1" :class="{ 'bg-red-500': request.failed }"
            >{{ request.text }}
            <span
              v-if="request.failed"
              class="cursor-pointer"
              @click="removeRequest(this, index)"
              ><i class="fa-solid fa-xmark"></i
            ></span>
          </span>
        </p>
      </div>
    </div>
    <div class="relative flex flex-col min-w-0 break-words w-full">
      <div class="rounded-t mb-0 pb-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full max-w-full flex-grow flex-1">
            <h3 class="text-3xl text-black font-serif mt-1 mx-5">
              Create Demo Environment
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-5">
      <h1
        class="text-2xl ml-5 mt-10 mb-3 cursor-pointer"
        @click="stepIndex = 0"
      >
        1. Client Information
      </h1>
      <div class="ml-10" :class="{ 'max-h-0 overflow-hidden': stepIndex != 0 }">
        <p>
          Please provide or adjust this information so we can use AI to generate
          the Collaboration Space, Collaboration Requests, Candidates, and
          Projects.
        </p>
        <div class="block mt-4">
          <h4 class="text-xl font-light mt-4">Select company size:</h4>
          <p class="form-help">Select one.</p>
          <div class="flex flex-wrap">
            <div
              v-for="cs in enums.companySizeOptions"
              :key="cs"
              class="mr-3 mb-3 bg-white rounded-lg"
            >
              <label
                class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                :class="{
                  'border-blueGray-500 bg-blueGray-200':
                    result.companySize == cs.value,
                }"
              >
                <input
                  v-model="result.companySize"
                  :value="cs.value"
                  type="radio"
                  class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                />
                <span class="text-blueGray-600 flex items-center">
                  <span class="text-2xl mr-2">{{
                    String.fromCodePoint("0x" + cs.emoji)
                  }}</span>
                  <span class="text-sm">{{ cs.label }}</span>
                </span>
              </label>
            </div>
          </div>
          <h4 class="text-xl font-light mt-4">Select company stage:</h4>
          <p class="form-help">Select one.</p>
          <div class="flex flex-wrap">
            <div
              v-for="cs in enums.companyStageOptions"
              :key="cs"
              class="mr-3 mb-3 bg-white rounded-lg"
            >
              <label
                class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                :class="{
                  'border-blueGray-500 bg-blueGray-200':
                    result.companyStage == cs.value,
                }"
              >
                <input
                  v-model="result.companyStage"
                  :value="cs.value"
                  type="radio"
                  class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                />
                <span class="text-blueGray-600 flex items-center">
                  <span class="text-2xl mr-2">{{
                    String.fromCodePoint("0x" + cs.emoji)
                  }}</span>
                  <span class="text-sm">{{ cs.label }}</span>
                </span>
              </label>
            </div>
          </div>
          <h4 class="text-xl font-light mt-4">Select company industry:</h4>
          <p class="form-help">Select one.</p>
          <div class="flex flex-wrap">
            <div
              v-for="i in enums.industryOptions"
              :key="i"
              class="mr-3 mb-3 bg-white rounded-lg"
            >
              <label
                class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                :class="{
                  'border-blueGray-500 bg-blueGray-200':
                    result.industry == i.value,
                }"
              >
                <input
                  v-model="result.industry"
                  :value="i.value"
                  type="radio"
                  class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                />
                <span class="text-blueGray-600 flex items-center">
                  <span class="text-2xl mr-2">{{
                    String.fromCodePoint("0x" + i.emoji)
                  }}</span>
                  <span class="text-sm">{{ i.label }}</span>
                </span>
              </label>
            </div>
          </div>
          <h4 class="text-xl font-light mt-4">Select job function(s):</h4>
          <p class="form-help">
            Select as many as you'd like. One collaboration space will be
            generated for each selected job function.
          </p>
          <div class="flex flex-wrap">
            <div
              v-for="jf in enums.jobFunctionOptions"
              :key="jf"
              class="mr-3 mb-3 bg-white rounded-lg"
            >
              <label
                class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                :class="{
                  'border-blueGray-500 bg-blueGray-200':
                    result.jobFunction.indexOf(jf.value) > -1,
                }"
              >
                <input
                  v-model="result.jobFunction"
                  :value="jf.value"
                  type="checkbox"
                  class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                />
                <span class="text-blueGray-600 flex items-center">
                  <span class="text-2xl mr-2">{{
                    String.fromCodePoint("0x" + jf.emoji)
                  }}</span>
                  <span class="text-sm">{{ jf.label }}</span>
                </span>
              </label>
            </div>
          </div>

          <div class="w-full text-right">
            <button
              class="bg-blueGray-500 text-white px-4 py-2 rounded-lg mt-5"
              :class="{ 'opacity-50': !formComplete || loading }"
              @click="generateCompanyName()"
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <h1
        class="text-2xl ml-5 mt-10 mb-3"
        :class="{ 'cursor-pointer': stepIndex > 1 }"
        @click="stepIndex = Math.min(stepIndex, 1)"
      >
        2. Company & Collaboration Spaces
      </h1>
      <div class="ml-10" :class="{ 'max-h-0 overflow-hidden': stepIndex != 1 }">
        <p>
          Please adjust the information below as needed and click "Create Demo".
          The impersonation link for your your main stakeholder will appear upon
          completion.
        </p>

        <label class="flex items-start mb-2 mt-10">
          <span
            class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
            >Company Name</span
          >
          <div class="w-full md:w-5/6">
            <input
              v-model="result.companyName"
              class="rounded w-full"
              type="text"
            />
            <button
              class="-ml-8 mt-2"
              :class="{ 'opacity-50': !formComplete || loading }"
              @click="generateCompanyName()"
            >
              <i class="fa-solid fa-rotate"></i>
            </button>
          </div>
        </label>
        <label class="flex items-start mb-2">
          <span
            class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
            >Main Stakeholder</span
          >
          <div class="w-full md:w-5/6">
            <demo-person
              v-if="stepIndex == 1"
              :active-requests="activeRequests"
              :person="result.person"
              @setPerson="result.person = $event"
            />
          </div>
        </label>
        <label
          v-for="(collaborationSpace, index) in result.collaborationSpaces"
          :key="index"
          class="flex items-start mb-2"
        >
          <span
            class="w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
            >Collaboration Space #{{ index + 1 }}</span
          >
          <div class="w-5/6">
            <demo-collaboration-space
              :enums="enums"
              :index="index"
              :company-size="result.companySize"
              :company-stage="result.companyStage"
              :industry="result.industry"
              :job-function="result.jobFunction[index]"
              :active-requests="activeRequests"
              :company-name="result.companyName"
              :collaboration-space="collaborationSpace"
              @reloadCollaborationSpace="reloadCollaborationSpace(index)"
            />
          </div>
        </label>
        <div class="w-full text-right">
          <button
            class="bg-blueGray-500 text-white px-4 py-2 rounded-lg mt-5 mr-3"
            @click="addCollaborationSpace()"
          >
            Add Collaboration Space
          </button>
          <button
            v-if="result.collaborationSpaces.length > 0"
            class="bg-blueGray-500 text-white px-4 py-2 rounded-lg mt-5 mr-3"
            @click="createDemo()"
          >
            Create Demo
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import DemoPerson from "../../components/DemoWizard/Person.vue";
import DemoCollaborationSpace from "../../components/DemoWizard/CollaborationSpace.vue";

const {
  keysToCamel,
  keysToSnake,
  loadEnums,
  addRequest,
  removeRequest,
  askQuestion,
  pluckEnum,
} = utils;

export default {
  components: {
    DemoPerson,
    DemoCollaborationSpace,
  },
  data() {
    return {
      activeRequests: [],
      result: {
        companyName: "",
        collaborationSpaces: [],
        person: {},
        companySize: "MEDIUM",
        companyStage: "MATURITY",
        industry: "CONSTRUCTION",
        jobFunction: ["FINANCE", "MARKETING"],
      },
      enums: {},
      stepIndex: 0,
      removeRequest,
    };
  },
  computed: {
    formComplete() {
      return (
        this.result.companySize !== "" &&
        this.result.companyStage !== "" &&
        this.result.industry !== "" &&
        this.result.jobFunction.length > 0
      );
    },
    loading() {
      return this.activeRequests.length > 0;
    },
  },
  mounted() {
    loadEnums(this, {
      Industry: "industryOptions",
      CompanySize: "companySizeOptions",
      CompanyStage: "companyStageOptions",
      JobFunction: "jobFunctionOptions",
    });
  },
  methods: {
    generateCompanyName() {
      if (this.formComplete) {
        const companySizeLabel = pluckEnum(
          this.enums.companySizeOptions,
          this.result.companySize,
        ).label;
        const companyStageLabel = pluckEnum(
          this.enums.companyStageOptions,
          this.result.companyStage,
        ).label;
        const industryLabel = pluckEnum(
          this.enums.industryOptions,
          this.result.industry,
        ).label;
        askQuestion(
          this,
          "Generating company name...",
          `What is a good fake name for a ${companyStageLabel}-stage ${companySizeLabel} company in the ${industryLabel} industry?`,
        ).then((resp) => {
          this.stepIndex = 1;
          const data = keysToCamel(resp.data);
          const companyName = data.answer.replaceAll(".", "").trim();
          this.result.companyName = companyName;
        });
      }
    },
    addCollaborationSpace() {
      this.result.collaborationSpaces.push({});
    },
    reloadCollaborationSpace(index) {
      this.result.collaborationSpaces.splice(index, 1, {});
    },
    removeCollaborationSpace(index) {
      this.result.collaborationSpaces.splice(index, 1);
    },
    validateDemo() {
      let hasErrors = false;
      this.result.collaborationSpaces.forEach((collaborationSpace) => {
        if (!collaborationSpace.name || collaborationSpace.name.length > 256) {
          hasErrors = true;
          this.$toast.error(
            "A Collaboration Space name is longer than 256 characters.",
          );
        }
        collaborationSpace.collaborationRequests.forEach(
          (collaborationRequest) => {
            if (
              !collaborationRequest.title ||
              collaborationRequest.title.length > 256
            ) {
              hasErrors = true;
              this.$toast.error(
                "A Collaboration Request title is longer than 256 characters.",
              );
            }
            collaborationRequest.candidates.forEach((candidate) => {
              if (!candidate.headline || candidate.headline.length > 1024) {
                hasErrors = true;
                this.$toast.error(
                  "A candidate headline is longer than 1024 characters.",
                );
              }
              if (
                !candidate.person.firstName ||
                candidate.person.firstName.length > 256
              ) {
                hasErrors = true;
                this.$toast.error(
                  "A candidate first name is longer than 256 characters.",
                );
              }
              if (
                !candidate.person.lastName ||
                candidate.person.lastName.length > 256
              ) {
                hasErrors = true;
                this.$toast.error(
                  "A candidate last name is longer than 256 characters.",
                );
              }
            });
          },
        );
      });
      return !hasErrors;
    },
    createDemo() {
      if (this.validateDemo()) {
        const key = addRequest(
          this,
          "Creating demo account (this can take around five minutes)...",
        );
        this.$api.axiosThrottled
          .post(`/create-demo/`, keysToSnake({ result: this.result }))
          .then((resp) => {
            removeRequest(this, key);

            const key2 = addRequest(this, "Getting impersonation link...");
            const demoData = keysToCamel(resp.data);
            this.$api.axiosThrottled
              .post(
                `/person/get-magic-link/`,
                keysToSnake({
                  emailAddress: demoData.person.emailAddress,
                }),
              )
              .then((linkResp) => {
                removeRequest(this, key);
                const data = [
                  new ClipboardItem({
                    "text/plain": new Blob([linkResp.data.link], {
                      type: "text/plain",
                    }),
                  }),
                ];
                const self = this;
                navigator.clipboard.write(data).then(function _() {
                  self.$toast.success("Copied to clipboard.");
                  self.$router.push(
                    '/admin/collaboration-spaces?isTestAccount={"label":"Yes","value":true}',
                  );
                });
              })
              .catch(() => removeRequest(this, key2, true));
          })
          .catch(() => removeRequest(this, key, true));
      }
    },
  },
};
</script>
