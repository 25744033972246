<template>
  <div
    class="collaboration-space-logo-container shadow ease-linear transition-all duration-150 mx-auto shrink-0"
    :style="`background-image: url(${imgUrl}); width: ${size}px; height: ${size}px; border: ${border}px solid white;`"
  ></div>
</template>

<script>
export default {
  props: {
    imgUrl: String,
    size: Number,
  },
  data() {
    return {
      border: this.size > 50 ? 3 : 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.collaboration-space-logo-container {
  background-size: cover;
  border-radius: 50%;
}
</style>
