<template>
  <div ref="otpContainer">
    <input
      v-for="(el, ind) in digits"
      :key="el + ind"
      v-model="digits[ind]"
      type="text"
      class="digit-box"
      :autofocus="ind === 0"
      :placeholder="ind + 1"
      maxlength="1"
      @keydown="handleKeyDown($event, ind)"
    />
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";

const emit = defineEmits(["update:otp"]);

const props = defineProps({
  default: {
    type: String,
  },
  digitCount: {
    type: Number,
    required: true,
  },
});

const otpContainer = ref(null);

const digits = reactive([]);

if (props.default && props.default.length === props.digitCount) {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = props.default.charAt(i);
  }
} else {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = null;
  }
}

const isDigitsFull = function () {
  const emptyDigits = digits.filter((digit) => {
    return digit === null || digit === undefined;
  });
  return emptyDigits.length === 0;
};

const handleKeyDown = function (event, index) {
  if (
    event.key !== "Tab" &&
    event.key !== "ArrowRight" &&
    event.key !== "ArrowLeft"
  ) {
    event.preventDefault();
  }

  if (event.key === "Backspace") {
    digits[index] = null;

    if (index !== 0) {
      otpContainer.value.children[index - 1].focus();
    }

    return;
  }

  if (/^([0-9])$/.test(event.key)) {
    digits[index] = event.key;

    if (index !== props.digitCount - 1) {
      otpContainer.value.children[index + 1].focus();
    }

    if (isDigitsFull()) {
      emit("update:otp", digits.join(""));
    }
  }
};

const setFocus = function () {
  otpContainer.value.children[0].focus();
};

const reset = function () {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = null;
  }
};

defineExpose({
  setFocus,
  reset,
});
</script>
<style scoped>
.digit-box {
  height: 3rem;
  width: 2.5rem;
  border: 1px solid black;
  display: inline-block;
  border-radius: 5px;
  margin: 5px;
  padding: 15px 0px;
  font-size: 2rem;
  text-align: center;
}

.digit-box::placeholder {
  color: #eeeeee;
}

.digit-box:focus {
  border: 1px solid black;
  outline: 3px solid rgb(211 245 46);
}
</style>
