<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Site Themes
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "SiteThemes",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "site theme",
      pluralLabel: "site themes",
      adminPrefix: "site-themes",
      apiPrefix: "site-theme",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Site ID",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">ST-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "ST-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        name: {
          type: "string",
          label: "Theme Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      createForm: {
        name: {
          label: "Name:",
          placeholder: "Enter a name for your reference.",
          type: "string",
          value: "",
          default: "",
        },
        json__: {
          label: "JSON:",
          placeholder: "Enter the JSON represenation of this theme.",
          type: "text",
          value: "",
          default: "",
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter a name for your reference.",
          type: "string",
          value: "",
          default: "",
        },
        json__: {
          label: "JSON:",
          placeholder: "Enter the JSON represenation of this page.",
          type: "text",
          value: "",
          default: "",
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
      },
    };
  },
};
</script>
