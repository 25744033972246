<template>
  <section
    v-if="column.expanded"
    class="flex flex-col min-h-[350px] justify-between rounded-lg bg-white shadow-lg mb-3 border-2 border-blueGray-500"
  >
    <div class="min-h-[300px]">
      <collaboration-space-banner
        class="relative rounded-md bg-blueGray-500"
        :height="300"
        :img-url="bannerUrl(this, work?.collaborationSpace?.banner, 400)"
        :banner="work?.collaborationSpace?.banner"
      />
      <div
        class="relative -mt-[290px] flex items-center justify-between mb-3 px-4"
      >
        <div class="flex items-center">
          <collaboration-space-logo
            class="bg-white ml-0 z-10"
            :size="30"
            :img-url="logoUrl(work?.collaborationSpace)"
          />
          <div
            class="-ml-3 pl-5 bg-black/50 rounded-r-lg pr-2 text-sm py-1 text-white"
          >
            {{ work?.collaborationSpace?.name }}
          </div>
        </div>
        <assembly-line-work-dropdown :column="column" :work="work" />
      </div>
      <div class="relative rounded-lg bg-white px-3 mx-3 py-2 min-h-[200px]">
        <h4
          class="text-lg underline cursor-pointer"
          @click="$emit('selectWork')"
          v-html="work?.title || '<em>Untitled</em>'"
        ></h4>
        <hr class="mt-1 mb-3" />
        <div v-if="work?.workRoles.length > 0">
          <h6 class="uppercase text-xs mt-4 mb-2">Participants</h6>
          <div
            v-for="workRole in work?.workRoles"
            :key="workRole.hashedId"
            class="flex items-center mb-3"
          >
            <profile-image
              :size="45"
              :img-url="avatarUrl(null, workRole.person)"
            />
            <div class="ml-2">
              <p class="m-0">
                {{ workRole.person.firstName }} {{ workRole.person.lastName }}
                <span
                  v-if="workRole.person.isTestAccount"
                  class="bg-orange-200 ml-2 px-1 text-xs"
                  >TEST</span
                >
              </p>
              <p class="relative -mt-1">
                <span class="form-help mb-0">{{
                  workRole.person.calc_EmailAddress
                }}</span>
              </p>
              <p
                v-if="workRole.person.lastLoginAt"
                class="relative uppercase text-xs"
              >
                Last Login
                <moment-from-now
                  v-if="workRole.person.lastLoginAt"
                  :title="workRole.person.lastLoginAt"
                  :value="workRole.person.lastLoginAt"
                  :interval="5000"
                  :tag="'span'"
                />
              </p>
            </div>
          </div>
        </div>
        <div v-if="work?.childWork.length > 0">
          <h6 class="uppercase text-xs mt-4 mb-2">Milestones</h6>
          <div v-for="milestone in work.childWork" :key="milestone.hashedId">
            {{ milestone.title }}
            <ul
              v-if="milestone.dates.length > 0"
              class="list-disc ml-5 text-sm"
            >
              <li v-for="date in milestone.dates" :key="date.hashedId">
                {{ date.workDateType?.label }}:
                <moment-from-now
                  v-if="date.happensAt"
                  :title="date.happensAt"
                  :value="date.happensAt"
                  :interval="5000"
                  :tag="'span'"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="border-t px-4 pb-2 bg-blueGray-300 rounded-b-md">
      <assembly-line-representatives
        :collaboration-space="work?.collaborationSpace"
        :roles="roles"
        @reload="$emit('reload')"
      />
    </div>
  </section>
  <section v-else>
    <div class="w-[200px]">
      <h4
        class="text-xs underline cursor-pointer"
        @click="$emit('selectWork')"
        v-html="work?.title || '<em>Untitled</em>'"
      ></h4>
    </div>
  </section>
</template>
<script>
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";
import CollaborationSpaceBanner from "../CollaborationSpaceBanner.vue";
import CollaborationSpaceLogo from "../CollaborationSpaceLogo.vue";
import MomentFromNow from "../MomentFromNow.vue";
import AssemblyLineWorkDropdown from "../Dropdowns/AssemblyLineWork.vue";
import AssemblyLineRepresentatives from "./Representatives.vue";

const { avatarUrl, bannerUrl, logoUrl } = utils;

export default {
  components: {
    MomentFromNow,
    ProfileImage,
    CollaborationSpaceBanner,
    CollaborationSpaceLogo,
    AssemblyLineWorkDropdown,
    AssemblyLineRepresentatives,
  },
  props: {
    work: Object,
    column: Object,
    roles: Array,
  },
  data() {
    return {
      avatarUrl,
      bannerUrl,
      logoUrl,
    };
  },
  computed: {},
  methods: {},
};
</script>
