<template>
  <div class="flex flex-wrap">
    <div
      v-for="(groupPermissions, groupName) in permissionsByGroup"
      :key="groupName"
      class="w-full"
    >
      <div
        class="relative flex flex-col min-w-0 break-words w-full mt-3 mb-6 shadow-lg rounded-lg bg-white px-6 pb-6"
      >
        <h4 class="text-3xl font-light mt-6">{{ groupName }}</h4>
        <table class="w-full">
          <thead>
            <tr>
              <th scope="col">
                <div class="flex justify-between font-normal"></div>
              </th>
              <th
                v-for="role in roles"
                :key="role.hashedId"
                :class="{
                  hidden:
                    role.roleType.value.toLowerCase() !==
                    groupName.toLowerCase().replace(' ', '_'),
                }"
                class="px-3 pt-2"
                scope="col"
              >
                <div class="d-flex justify-between font-normal">
                  {{ role.name }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="permission in groupPermissions"
              :key="permission.value"
              class="border-t border-blueGray-200"
            >
              <td>
                {{ permission.label }}
              </td>
              <td
                v-for="role in roles"
                :key="role.hashedId"
                :class="{
                  hidden:
                    role.roleType.value.toLowerCase() !==
                    groupName.toLowerCase().replace(' ', '_'),
                }"
                class="px-3"
              >
                <div v-if="Object.keys(rolePermissionMatrix).length > -1">
                  <slide-toggle
                    :value="
                      rolePermissionMatrix[role.hashedId][
                        permission.value
                      ][0] === 1
                    "
                    @change="
                      togglePermission($event, role.hashedId, permission.value)
                    "
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import slideToggle from "../../components/SlideToggle.vue";

const { keysToCamel } = utils;

export default {
  name: "RolePermissions",
  components: {
    slideToggle,
  },
  data() {
    return {
      permissions: [],
      test: true,
      roles: [],
      sort: [],
      loading: true,
      rolePermissionMatrix: {},
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      enumPath: `${import.meta.env.VITE_API_BASE_URL}/enum`,
    };
  },
  computed: {
    permissionsByGroup() {
      const permissionsByGroup = {};
      this.permissions.forEach((permission) => {
        if (Object.keys(permissionsByGroup).indexOf(permission.group) === -1) {
          permissionsByGroup[permission.group] = [permission];
        } else {
          permissionsByGroup[permission.group].push(permission);
        }
      });
      return permissionsByGroup;
    },
  },
  created() {
    this.loadPermissionsAndRoles();
  },
  methods: {
    loadPermissionsAndRoles() {
      this.loading = true;
      this.$api.axios.get(`${this.enumPath}/Permission/`).then((res) => {
        this.permissions = keysToCamel(res.data.results);
      });
      this.$api.axios
        .get(`${this.apiBaseUrl}/role/`, {
          params: {
            limit: 100,
          },
        })
        .then((res) => {
          this.roles = keysToCamel(res.data.results);
          this.loading = false;
          this.setupMatrix();
        });
    },
    rolesForRoleType(roleType) {
      return this.roles.filter((role) => {
        return role.roleType.label.toUpperCase() === roleType.toUpperCase();
      });
    },
    async togglePermission(event, roleHashedId, permissionValue) {
      this.loading = true;
      let url = "";
      if (event === true) {
        url = `${this.apiBaseUrl}/role/${roleHashedId}/add-permission/`;
      } else {
        url = `${this.apiBaseUrl}/role/${roleHashedId}/remove-permission/`;
      }
      await this.$api.axios.post(url, {
        permission: permissionValue,
      });
      const personResp = await this.$api.axios.get(
        `${this.apiBaseUrl}/person/mine/`,
      );
      await this.$store.commit("setPerson", keysToCamel(personResp.data));
      this.loadPermissionsAndRoles();
      this.$toast.success("Role permissions have been updated.");
    },
    setupMatrix() {
      if (this.roles.length && this.permissions.length) {
        const permissionList = this.permissions.map((a) => a.value);
        const perms = this.roles.map((a) => {
          const rolePermissions = a.permissions.map((c) => c.permission);
          let permissions = permissionList.map((b) => {
            if (rolePermissions.indexOf(b) > -1) {
              return { permissionName: [b], granted: [1] };
            }
            return { permissionName: [b], granted: [0] };
          });
          permissions = permissions.reduce((m, v) => {
            m[v.permissionName] = v.granted;
            return m;
          }, {});
          return { hashedId: [a.hashedId], permissions };
        });
        this.rolePermissionMatrix = perms.reduce((m, v) => {
          m[v.hashedId] = v.permissions;
          return m;
        }, {});
      } else {
        this.rolePermissionMatrix = [];
      }
    },
  },
};
</script>
