<template>
  <footer></footer>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
