<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            RSS Feed Publishers
          </h3>
        </div>
      </div>
    </div>
    <p class="mb-1">
      <router-link
        to="/admin/rss-feed-disciplines"
        class="underline text-dodgerBlue-500"
        >View By Discipline and Subdiscipline</router-link
      >
    </p>
    <p class="mb-1">
      <router-link
        to="/admin/rss-feed-items"
        class="underline text-dodgerBlue-500"
        >View Items</router-link
      >
    </p>
    <p class="mb-4">
      <router-link
        to="/admin/e-feed-item-search"
        class="underline text-dodgerBlue-500"
        >Search Items</router-link
      >
    </p>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "RssFeedPublishers",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "RSS feed publisher",
      pluralLabel: "RSS feed publishers",
      adminPrefix: "rss-feed-publishers",
      apiPrefix: "rss-feed-publisher",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "RSS Feed Publisher ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">pub-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "pub-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      createForm: {
        name: {
          label: "Name:",
          placeholder: "RSS feed publisher name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "RSS feed publisher name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        feeds: {
          label: "RSS Feeds",
          type: "dataTable",
          value: "",
          singularLabel: "RSS feed",
          pluralLabel: "RSS feeds",
          adminPrefix: "rss-feeds",
          apiPrefix: "rss-feed",
          parentKey: "publisher.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "RSS Feed ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">rss-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "rss-",
              },
            },
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: false,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            journalTitle: {
              type: "formattedString",
              label: "Journal",
              format(row) {
                const els = [];
                els.push(`<span>${row.journalTitle}</span><br>`);
                els.push(`<span class="text-xs">${row.url}</span>`);
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            calc_ItemCount: {
              type: "string",
              label: "Item Count",
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            disciplines: {
              type: "formattedString",
              label: "Disciplines",
              format(row) {
                const els = [];
                row.disciplines.forEach((discipline) => {
                  els.push(
                    `<span class="inline-block text-nowrap bg-blueGray-200 rounded-lg px-2 py-1 text-sm mb-2">${discipline.discipline.label}</span><br>`,
                  );
                });
                return `
                  <span>
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            subdisciplines: {
              type: "formattedString",
              label: "Subdisciplines",
              format(row) {
                const els = [];
                row.subdisciplines.forEach((subdiscipline) => {
                  els.push(
                    `<span class="inline-block text-nowrap bg-blueGray-200 rounded-lg px-2 py-1 text-sm mb-2">${subdiscipline.subdiscipline.label}</span><br>`,
                  );
                });
                return `
                  <span>
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            languages: {
              type: "formattedString",
              label: "Languages",
              format(row) {
                const els = [];
                row.languages.forEach((language) => {
                  els.push(
                    `<span class="inline-block text-nowrap bg-blueGray-200 rounded-lg px-2 py-1 text-sm mb-2">${language.language.label}</span><br>`,
                  );
                });
                return `
                  <span>
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            publisherId: {
              label: "RSS Feed Publisher Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            journalTitle: {
              label: "Journal Title:",
              placeholder: "Enter journal title",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            url: {
              label: "URL:",
              placeholder: "Enter RSS URL",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            crawlFrequency: {
              label: "Crawl Frequency",
              placeholder: "Select",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/RssFeedCrawlFrequency",
              searchOnFocus: true,
            },
          },
          updateForm: {
            hashedId: {
              label: "RSS Feed Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            journalTitle: {
              label: "Journal Title:",
              placeholder: "Enter journal title",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            url: {
              label: "URL:",
              placeholder: "Enter RSS URL",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            crawlFrequency: {
              label: "Crawl Frequency",
              placeholder: "Select",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/RssFeedCrawlFrequency",
              searchOnFocus: true,
            },
            crawls: {
              label: "Crawls",
              type: "dataTable",
              value: "",
              singularLabel: "crawl",
              pluralLabel: "crawls",
              adminPrefix: "rss-feed-crawls",
              apiPrefix: "rss-feed-crawl",
              parentKey: "feed.hashed_id",
              createForm: {},
              updateForm: {},
              columns: {
                hashedId: {
                  type: "formattedString",
                  label: "Crawl ID",
                  format(row) {
                    const els = [];
                    els.push(
                      `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                    );
                    return `
                      <span class="text-nowrap">
                        ${els.join("")}
                      </span>
                    `;
                  },
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: true,
                    matchType: "hashedId",
                    prefix: "cr-",
                  },
                },
                crawledAt: {
                  type: "absoluteDate",
                  label: "Crawled At",
                  sort: {
                    allow: true,
                    default: "desc",
                  },
                  filter: {
                    allow: false,
                    matchType: "dateRange",
                    value: "",
                    // config: https://chmln.github.io/flatpickr/options/
                    config: {
                      mode: "range",
                      maxDate: "today",
                    },
                  },
                },
              },
              actions: {
                create: {
                  allow: false,
                },
                update: {
                  allow: false,
                },
                delete: {
                  allow: false,
                },
                menuItems: [],
              },
            },
            disciplines: {
              label: "Disciplines",
              type: "dataTable",
              value: "",
              singularLabel: "discipline",
              pluralLabel: "disciplines",
              adminPrefix: "rss-feed-disciplines",
              apiPrefix: "rss-feed-discipline",
              parentKey: "feed.hashed_id",
              updateForm: {},
              columns: {
                discipline: {
                  type: "enum",
                  label: "Discipline",
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
              },
              createForm: {
                rssFeedId: {
                  label: "RSS Feed Hashed ID:",
                  default(row) {
                    return row.hashedId;
                  },
                  type: "hidden",
                  validatorTypes: ["required"],
                },
                discipline: {
                  label: "Discipline",
                  placeholder: "Select",
                  type: "autocomplete-select",
                  default: [],
                  value: [],
                  validatorTypes: [],
                  idKey: "value",
                  labelKey: "label",
                  autocompleteEndpoint: "enum/RssFeedDiscipline",
                  searchOnFocus: true,
                },
              },
              actions: {
                create: {
                  allow: true,
                },
                update: {
                  allow: false,
                },
                delete: {
                  allow: true,
                },
                menuItems: [],
              },
            },
            subdisciplines: {
              label: "Subdisciplines",
              type: "dataTable",
              value: "",
              singularLabel: "subdiscipline",
              pluralLabel: "subdisciplines",
              adminPrefix: "rss-feed-subdisciplines",
              apiPrefix: "rss-feed-subdiscipline",
              parentKey: "feed.hashed_id",
              updateForm: {},
              columns: {
                subdiscipline: {
                  type: "enum",
                  label: "Subdiscipline",
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
              },
              createForm: {
                rssFeedId: {
                  label: "RSS Feed Hashed ID:",
                  default(row) {
                    return row.hashedId;
                  },
                  type: "hidden",
                  validatorTypes: ["required"],
                },
                subdiscipline: {
                  label: "Subdiscipline",
                  placeholder: "Select",
                  type: "autocomplete-select",
                  default: [],
                  value: [],
                  validatorTypes: [],
                  idKey: "value",
                  labelKey: "label",
                  autocompleteEndpoint: "enum/RssFeedSubdiscipline",
                  searchOnFocus: true,
                },
              },
              actions: {
                create: {
                  allow: true,
                },
                update: {
                  allow: false,
                },
                delete: {
                  allow: true,
                },
                menuItems: [],
              },
            },
            items: {
              label: "Items",
              type: "dataTable",
              value: "",
              singularLabel: "item",
              pluralLabel: "items",
              adminPrefix: "rss-feed-items",
              apiPrefix: "rss-feed-item",
              parentKey: "feed.hashed_id",
              createForm: {},
              updateForm: {},
              columns: {
                hashedId: {
                  type: "formattedString",
                  label: "Crawl ID",
                  format(row) {
                    const els = [];
                    els.push(
                      `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                    );
                    return `
                      <span class="text-nowrap">
                        ${els.join("")}
                      </span>
                    `;
                  },
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: true,
                    matchType: "hashedId",
                    prefix: "cr-",
                  },
                },
                publishedAt: {
                  type: "absoluteDate",
                  label: "Published At",
                  sort: {
                    allow: true,
                    default: "desc",
                  },
                  filter: {
                    allow: false,
                  },
                },
                title: {
                  type: "string",
                  label: "Title",
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
                bannerHash: {
                  type: "banner",
                  label: "Banner Hash",
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: false,
                  },
                },
                bannerKeywords: {
                  type: "string",
                  label: "Banner Keywords",
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: false,
                  },
                },
              },
              actions: {
                create: {
                  allow: false,
                },
                update: {
                  allow: false,
                },
                delete: {
                  allow: false,
                },
                menuItems: [
                  {
                    label: "Assign Image",
                    qualifier(row) {
                      return !row.bannerHash;
                    },
                    action(event, row) {
                      const url = `rss-feed-item/${row.hashedId}/image/`;
                      return ["apiAction", "postReload", url];
                    },
                  },
                  {
                    label: "Delete Image",
                    qualifier(row) {
                      return row.bannerHash;
                    },
                    action(event, row) {
                      const url = `rss-feed-item/${row.hashedId}/image/`;
                      return ["apiAction", "deleteReload", url];
                    },
                  },
                ],
              },
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [
              {
                label: "Crawl Now",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `rss-feed/${row.hashedId}/crawl/`;
                  return ["apiAction", "postReload", url];
                },
              },
            ],
          },
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [],
      },
    };
  },
};
</script>
