<template>
  <div v-if="!isCheckingAuth" class="bg-porcelain">
    <sidebar-admin />
    <div
      class="relative md:ml-64 min-h-screen overflow-x-hidden md:max-w-[calc(100vw-16rem)]"
    >
      <div class="mx-auto w-full min-h-screen">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import SidebarAdmin from "../components/Sidebar/SidebarAdmin.vue";
import FooterAdmin from "../components/Footers/FooterAdmin.vue";

export default {
  name: "AdminLayout",
  components: {
    SidebarAdmin,
    FooterAdmin,
  },
  data() {
    return {
      isCheckingAuth: true,
    };
  },
  mounted() {
    this.isCheckingAuth = true;
    this.$api
      .attemptAccessTokenRefresh(true)
      .then(() => {
        this.isCheckingAuth = false;
        if (!this.$store.getters.isPersonLoggedIn) {
          this.$router.push("/");
        }
      })
      .catch(() => {
        this.$router.push("/");
      });
  },
};
</script>
