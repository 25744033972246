<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Feature Pops
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "FeaturePops",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "feature pop",
      pluralLabel: "feature pops",
      adminPrefix: "feature-pops",
      apiPrefix: "feature-pop",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Category ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">pi-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "cat-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        audience: {
          type: "enum",
          label: "Audience",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        title: {
          type: "string",
          label: "Title",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        position: {
          type: "enum",
          label: "Position",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        displayOrder: {
          type: "string",
          label: "Display Order",
          sort: {
            allow: true,
            default: false,
          },
          filter: {
            allow: false,
          },
        },
      },
      createForm: {
        title: {
          label: "Title:",
          placeholder: "Enter feature pop title",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        body: {
          label: "Body:",
          placeholder: "Enter feature pop body",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        ref: {
          label: "Element ID:",
          placeholder: "Enter the ID of the element to anchor",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        position: {
          label: "Position:",
          placeholder: "Select position",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FeaturePopPosition",
          searchOnFocus: true,
        },
        audience: {
          label: "Audience:",
          placeholder: "Select audience",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FeaturePopAudience",
          searchOnFocus: true,
        },
        spotlightShape: {
          label: "Spotlight Shape:",
          placeholder: "Select spotlight shape",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FeaturePopSpotlightShape",
          searchOnFocus: true,
        },
        cta: {
          label: "Button Text:",
          placeholder: "Enter the text to dismiss",
          type: "string",
          default: "Okay",
          value: "",
          validatorTypes: ["required"],
        },
        displayOrder: {
          label: "Display Order:",
          placeholder: "Enter display order",
          type: "integer",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        margin: {
          label: "Spotlight Margin:",
          placeholder: "Enter spotlight margin",
          type: "integer",
          default: "10",
          value: "",
          validatorTypes: ["required"],
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        title: {
          label: "Title:",
          placeholder: "Enter feature pop title",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        body: {
          label: "Body:",
          placeholder: "Enter feature pop body",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        ref: {
          label: "Element ID:",
          placeholder: "Enter the ID of the element to anchor",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        position: {
          label: "Position:",
          placeholder: "Select position",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FeaturePopPosition",
          searchOnFocus: true,
        },
        audience: {
          label: "Audience:",
          placeholder: "Select audience",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FeaturePopAudience",
          searchOnFocus: true,
        },
        spotlightShape: {
          label: "Spotlight Shape:",
          placeholder: "Select spotlight shape",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FeaturePopSpotlightShape",
          searchOnFocus: true,
        },
        cta: {
          label: "Button Text:",
          placeholder: "Enter the text to dismiss",
          type: "string",
          default: "Okay",
          value: "",
          validatorTypes: ["required"],
        },
        displayOrder: {
          label: "Display Order:",
          placeholder: "Enter display order",
          type: "integer",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        margin: {
          label: "Spotlight Margin:",
          placeholder: "Enter spotlight margin",
          type: "integer",
          default: "10",
          value: "",
          validatorTypes: ["required"],
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [],
      },
    };
  },
};
</script>
