<template>
  <div class="container w-full min-w-full h-full bg-blue-400/10">
    <navbar-auth />
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div class="absolute top-0 w-full h-full"></div>
        <router-view />
        <footer-small absolute />
      </section>
    </main>
  </div>
</template>
<script>
import NavbarAuth from "../components/Navbars/NavbarAuth.vue";
import FooterSmall from "../components/Footers/FooterSmall.vue";

export default {
  components: {
    NavbarAuth,
    FooterSmall,
  },
  data() {
    return {};
  },
};
</script>
