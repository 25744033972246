<template>
  <div class="flex flex-wrap">
    <div class="px-4 w-full">
      <div class="relative flex flex-col min-w-0 break-words w-full">
        <div class="rounded-t mb-0 pb-3 border-0">
          <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
              <h3 class="text-3xl text-blueGray-700 font-light mt-4">
                Wrapped
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 w-full flex">
      <div class="w-full rounded-lg bg-white px-4 pt-4 pb-3 shadow-lg">
        <!-- <div class="flex flex-col justify-between">
          <h2 class="pb-3 text-lg">Structure</h2>
          <div class="w-full">
            <pre style="font-size: 8pt">{{ prettyStructure }}</pre>
          </div>
        </div> -->

        <template v-for="(client, index) in jsonStructure.clients">
          <!-- eslint-disable -->
          <div class="border p-5">
            <!-- eslint-enable -->
            <h1 class="text-2xl">{{ client.name }}</h1>
            <ul class="list-disc ml-5 my-2">
              <li
                v-for="(recipient, recipientIndex) in client.recipients"
                :key="recipientIndex"
              >
                {{ recipient.name }} ({{ recipient.emailAddress }})
                <template
                  v-if="
                    $store.getters.person.calc_EmailAddress ==
                    'cory@notedsource.com'
                  "
                >
                  <button
                    class="bg-chartreuseYellow px-2 py-1 rounded-lg"
                    @click="sendEmail(recipient.emailAddress, index)"
                  >
                    Send Email
                  </button>
                </template>
              </li>
            </ul>
            <iframe
              :ref="`iframe${index}`"
              class="w-full"
              style="height: 1500px"
            ></iframe>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { utils } from "common-frontend";

const { keysToSnake, keysToCamel } = utils;

export default {
  data() {
    return {
      structure: `{
        "clients": [
          {
            "name": "Cory's test",
            "recipients": [
              {
                "name": "Cory Goodale",
                "emailAddress": "cory.goodale@gmail.com"
              }
            ],
            "projects": [
              {
                "name": "Test Project",
                "description": "Test Description",
                "researchers": [
                  {
                    "name": "Thomai Serdari",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/Y7O4QR63/content/",
                    "title": "Professor of Marketing and Branding",
                    "institution": "New York University"
                  },
                  {
                    "name": "Scott Baker",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/G13RRPL3/content/",
                    "title": "Professor of Finance",
                    "institution": "Kellogg School of Management"
                  },
                  {
                    "name": "Ludovica Cesareo",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/VM3DLWP9/content/",
                    "title": "Assistant Professor of Marketing",
                    "institution": "Lehigh University"
                  }
                ]
              }
            ]
          },
          {
            "name": "Omnicom Media Group",
            "recipients": [
              {
                "name": "Ryan Richmond",
                "emailAddress": "ryan.richmond@omnicommediagroup.com"
              },
              {
                "name": "Pamela Marsh",
                "emailAddress": "pamela.marsh@omnicommediagroup.com"
              },
              {
                "name": "Megan O'Brien",
                "emailAddress": "megan.obrien@omnicommediagroup.com"
              }
            ],
            "projects": [
              {
                "name": "Luxury Post Economic Downturn / Crisis",
                "description": "Learn from industry and research experts about the impact of covid and other negative economic events on luxury behavior of consumers with an emphasis on generational differences in consumption.",
                "researchers": [
                  {
                    "name": "Thomai Serdari",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/Y7O4QR63/content/",
                    "title": "Professor of Marketing and Branding",
                    "institution": "New York University"
                  },
                  {
                    "name": "Scott Baker",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/G13RRPL3/content/",
                    "title": "Professor of Finance",
                    "institution": "Kellogg School of Management"
                  },
                  {
                    "name": "Ludovica Cesareo",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/VM3DLWP9/content/",
                    "title": "Assistant Professor of Marketing",
                    "institution": "Lehigh University"
                  }
                ]
              },
              {
                "name": "Joy Project",
                "description": "1. Gaining a greater understanding of joy research. Looking for academics who study joy, as well as joy research in relation to consumer markets. Also interested in brand strategists who focus on joy in consumer markets.<br>2. Gaining a greater understanding of how sectors within a company assess wellbeing, methods to quantify success in wellbeing, and methods to standardize the acquired data.",
                "researchers": [
                  {
                    "name": "Eve Ekman",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/VD9P6Z63/content/",
                    "title": "Instructor of Contemplative Science Curriculum",
                    "institution": "University of California Berkeley"
                  },
                  {
                    "name": "Ingrid Fetell Lee",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/P8OVLYX3/content/",
                    "title": "Founding Faculty Member",
                    "institution": "MFA Products of Design, School of Visual Arts"
                  },
                  {
                    "name": "Sonja Lyubomirsky",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/E5OKYZZ3/content/",
                    "title": "Distinguished Professor",
                    "institution": "University of California, Riverside"
                  },
                  {
                    "name": "Ryan Howell",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/VX96QED9/content/",
                    "title": "Professor of Psychology",
                    "institution": "San Francisco State University"
                  }
                ]
              },
              {
                "name": "Podcast Brand Safety",
                "description": "",
                "researchers": [
                  {
                    "name": "Matty Staudt",
                    "avatarUrl": "https://app.notedsource.io/api/v0/avatar/Matty%20Staudt/",
                    "title": "Professor",
                    "institution": "Academy of Art University"
                  },
                  {
                    "name": "Elsie Escobar",
                    "avatarUrl": "https://app.notedsource.io/api/v0/avatar/Elsie%20Escobar/",
                    "title": "Podcasting Pundit, Advocate and Community Leader",
                    "institution": ""
                  }
                ]
              }
            ]
          },
          {
            "name": "Slalom",
            "recipients": [
              {
                "name": "Bryan Hollaway",
                "emailAddress": "bryan.hollaway@slalom.com"
              },
              {
                "name": "Natalie Richardson",
                "emailAddress": "natalie.richardson@slalom.com"
              }
            ],
            "projects": [
              {
                "name": "Connection at Slalom",
                "description": "Slalom Hablab would like to work to understand employee connections with each other and with their employer. We would like to understand the impact of in-person working environments vs. remote working environments on an employees sense of belonging. We are excited to work with Dr. Lori Foster on this project.<br><br><strong>Project Overview</strong><br>Our people, culture, and values are top of mind for how we think about the future of work at Slalom.<br><br>In this experiment, we are seeking to define how Slalom employees get connection, want to get connection, and define connection, resulting in an opportunity to reframe ways our people connect.<br><br><strong>Experiment 1:</strong> Identify the spaces and behaviors at Slalom that drove connection before the pandemic and what will drive it in the future.<br><br><strong>Why:</strong> Connection in the workplace is crucial for high-performing teams, innovation, a strong culture, retention of top talent, and attraction of future talent. Neuroscience shows us that connection is one of our basic human needs and with the future of work changing, we must be intentional about discovering what our people need.<br><br><strong>Hypothesis:</strong> People define connection differently pre-pandemic than now, therefore, the way we drive connection at Slalom needs to be radicalized by implementing a shadow program focused on small group engagements to build trust and connection at an individual level to best live out Slalom’s values.",
                "researchers": [
                  {
                    "name": "Lori Foster",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/LG19RX94/content/",
                    "title": "Professor of Psychology",
                    "institution": "North Carolina State University"
                  }
                ]
              }
            ]
          },
          {
            "name": "Gale",
            "recipients": [
              {
                "name": "Srishti Arora",
                "emailAddress": "srishti.arora@galepartners.com"
              },
              {
                "name": "Robyn Cauchy",
                "emailAddress": "robyn.cauchy@galepartners.com"
              },
              {
                "name": "Jennifer Kung",
                "emailAddress": "jennifer.kung@galepartners.com"
              }
            ],
            "projects": [
              {
                "name": "Research Interviews on American Family Dynamics",
                "description": "We're looking to present insights on the Modern American Family (9-49 y/o) for our client, to immerse them in their audience's mindset and understand the larger cultural forces that are shaping their behaviors today and tomorrow. We'd like to conduct 2 interviews (60 minute video calls) with seasoned experts on the Modern American Family (e.g., psychologists, sociologists, has published research/books on the topic) to understand the following: <ol style='font-size: 10pt;'><li>What are key ways the Modern American Family (9-49 y/o) has shifted in the past 10-20 years?</li><li>What do you predict will be the defining characteristics of the Modern American Family of tomorrow (next 5-10 years)?</li><li>What are the major forces that are impacting the Modern American Family today? (e.g, environmental, institutional, socio-economic, etc.) Especially for the single mom?</li><li>What cultural forces are impacting the consumption habits of the Modern American Family today? (e.g., how they shop, how they eat/drink, how they exercise, how they spend their time, etc.)</li><li>What will Gen Z be like as parents? How will they be different than Millennials?</li></ol>",
                "researchers": [
                  {
                    "name": "Laurence Steinberg",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/G1ORRQLO/content/",
                    "title": "Podcasting Pundit, Advocate and Community Leader",
                    "institution": ""
                  },
                  {
                    "name": "Kathleen Gerson",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/WV3MGZ53/content/",
                    "title": "Collegiate Professor of Sociology",
                    "institution": "New York University"
                  },
                  {
                    "name": "Ariel Kalil",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/D4OYR423/content/",
                    "title": "Professor and Expert on Childhood Development",
                    "institution": "University of Chicago"
                  },
                  {
                    "name": "Daniel Puhlman",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/P8OVLWY3/content/",
                    "title": "Assistant Professor of Family Studies",
                    "institution": "University of Maine"
                  }
                ]
              }
            ]
          },
          {
            "name": "Clarivate",
            "recipients": [
              {
                "name": "Jess Byrne",
                "emailAddress": "jess.byrne@clarivate.com"
              }
            ],
            "projects": [
              {
                "name": "TDM Project for Data Librarians",
                "description": "<p><strong>Goal:</strong> Connect with 10 Data Librarians as a minimum (the more the better) for a focus group with as many candidates on one call as possible, but can accommodate more than one. An interview or survey as a possible step 2 within set timelines, completed work by mid December.</p><p><strong>Purpose:</strong> Understand needs of the library and students they support. What are the T&DM needs and how the solution can solve some of those problems. Understand who makes the purchases and how they go about that doing that, including funding.</p>",
                "researchers": [
                  {
                    "name": "Verletta Kern",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/EQ9GJZ69/content/",
                    "title": "Librarian, Digital Scholarship",
                    "institution": "University of Washington, University of Redlands"
                  },
                  {
                    "name": "Tristan Daniels",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/X8O7E4L3/content/",
                    "title": "Technology Strategies & Data Librarian",
                    "institution": "Coastal Carolina University"
                  },
                  {
                    "name": "Kristi Thompson",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/8X91KM8O/content/",
                    "title": "Research Data Management Librarian",
                    "institution": "Western University"
                  },
                  {
                    "name": "Benjamin Hall",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/163ZL1PO/content/",
                    "title": "Business & Data Services Librarian",
                    "institution": "Santa Clara University"
                  },
                  {
                    "name": "Sarah Thorngate",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/PN9LLYD9/content/",
                    "title": "Data Analysis Librarian",
                    "institution": "Northwestern University"
                  },
                  {
                    "name": "Nick Ruhs",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/G1ORR2GO/content/",
                    "title": "STEM Data & Research Librarian",
                    "institution": "Florida State University"
                  },
                  {
                    "name": "Lynnee Argabright",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/E53KY1E9/content/",
                    "title": "Research Data Librarian & Team Lead of Scholarly Research Services",
                    "institution": "University of North Carolina Wilmington"
                  },
                  {
                    "name": "Li Zhang",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/G19RRXR9/content/",
                    "title": "Social Sciences and Data Librarian",
                    "institution": "Skidmore College"
                  },
                  {
                    "name": "Mahria Lebow",
                    "avatarUrl": "https://app.notedsource.io/api/v0/public-file/G1ORRKRO/content/",
                    "title": "Data Librarian",
                    "institution": "Reed College"
                  }
                ]
              }
            ]
          }
        ]
      }`,
    };
  },
  computed: {
    prettyStructure() {
      return JSON.stringify(JSON.parse(this.structure), null, 2);
    },
    jsonStructure() {
      return JSON.parse(this.structure);
    },
  },
  watch: {
    structure() {
      this.jsonStructure.clients.forEach((client, index) => {
        this.updateHtml(index);
      });
    },
  },
  mounted() {
    this.jsonStructure.clients.forEach((client, index) => {
      this.updateHtml(index);
    });
  },
  methods: {
    sendEmail(emailAddress, clientIndex) {
      const payload = keysToSnake({
        emailAddress,
        templateKey: "BLANK_BRANDED_TEMPLATE",
        properties: {
          subject: "Your NotedSource Year in Review",
          html: this.getClientHtml(clientIndex),
          preheader: "Check out your NotedSource projects from 2023!",
        },
      });
      this.$api.axios.post(`send-email-template/`, payload).then((res) => {
        const data = keysToCamel(res.data);
        console.log(data);  // eslint-disable-line
      });
    },
    getClientHtml(index) {
      const client = this.jsonStructure.clients[index];
      let projectHtml = "";
      client.projects.forEach((project, projectIndex) => {
        let researcherHtml = "";
        project.researchers.forEach((researcher) => {
          researcherHtml += `<table border="0" cellpadding="0" cellspacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout:fixed; padding: 10px;" width="100%" data-muid="d050540f-4672-4f31-80d9-b395dc08abe1">
            <tbody>
              <tr>
                <td align="center" bgcolor="#ffffff" class="outer-td" style="padding:0px 0px 0px 0px; background-color:#ffffff; width: 60px">
                <div data-v-5abdb450="" class="profile-image-container shadow ease-linear transition-all duration-150" style="background-image: url(&quot;${researcher.avatarUrl}&quot;); width: 50px; height: 50px; border: 1px solid white; background-size: cover; border-radius: 50%;"></div>
                </td>
                <td align="left" bgcolor="#ffffff" class="outer-td" style="padding:0px 0px 0px 0px; background-color:#ffffff; padding-left: 20px; line-height">
                  <p style="margin:0px; padding: 0px;"><strong>${researcher.name}</strong></p>
                  <p style="margin:0px; padding: 0px;"><span style="font-size: 14px;">${researcher.title}</p>
                  <p style="margin:0px; padding: 0px;"><span style="font-size: 14px;">${researcher.institution}</p>
                </td>
              </tr>
            </tbody>
          </table>`;
        });
        projectHtml += `<table border="0" cellpadding="0" cellspacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout:fixed; ${
          projectIndex === 0 ? "border-top: 1px solid #dddddd;" : ""
        } border-bottom: 1px solid #dddddd;" width="100%" data-muid="d050540f-4672-4f31-80d9-b395dc08abe1">
            <tbody>
              <tr>
                <td align="center" bgcolor="#ffffff" class="outer-td" style="padding:0px 0px 0px 0px; background-color:#ffffff;">
                  <table border="0" cellpadding="0" cellspacing="0" class="wrapper-mobile" style="text-align:center;">
                    <tbody>
                      <tr>
                      <td align="center" bgcolor="#d3f52e" class="inner-td" style="border-radius:6px; font-size:16px; text-align:left; background-color:inherit; padding: 20px">
                         <h4 style="font-size: 24px; margin: 0px; font-weight: normal;">
                           ${project.name}
                         </h4>
                         <p style="margin: 20px 0px;">${project.description}</p>
                         ${researcherHtml}
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>`;
      });

      return `<table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="948e3f3f-5214-4721-a90e-625a47b1c957" data-mc-module-version="2019-10-22">
  <tbody>
    <tr>
      <td style="padding:50px 30px 5px 30px; line-height:NaNpx; text-align:inherit; background-color:#ffffff;" height="100%" valign="top" bgcolor="#ffffff" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="font-family: inherit; color: #aaaaaa; font-size: 14px"><strong>YOUR YEAR IN REVIEW</strong></span></div><div></div></div></td>
    </tr>
  </tbody>
</table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="9314bf01-7519-4bec-80c1-96ca239d946c" data-mc-module-version="2019-10-22">
  <tbody>
    <tr>
      <td style="padding:5px 20px 5px 20px; line-height:24px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="color: #000000; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space-collapse: preserve; text-wrap: wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline; font-family: inherit; font-size: 14px">Thank you for trusting NotedSource in 2023!<br>Here's a recap of the work you did with researchers this year.</span></div>
<div></div></div></td>
    </tr>
  </tbody>
</table><table class="module" role="module" data-type="spacer" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="7770fdab-634a-4f62-a277-1c66b2646d8d.1">
  <tbody>
    <tr>
      <td style="padding:0px 0px 30px 0px;" role="module-content" bgcolor="#ffffff">
      </td>
    </tr>
  </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout:fixed;" width="100%" data-muid="d050540f-4672-4f31-80d9-b395dc08abe1">
    <tbody>
      <tr>
        <td align="center" bgcolor="#ffffff" class="outer-td" style="padding:0px 0px 0px 0px; background-color:#ffffff;">
          <table border="0" cellpadding="0" cellspacing="0" class="wrapper-mobile" style="text-align:center;">
            <tbody>
              <tr>
              <td align="center" bgcolor="#d3f52e" class="inner-td" style="border-radius:6px; font-size:16px; text-align:center; background-color:inherit; padding: 0px 50px;">
                ${projectHtml}
              </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table><table class="module" role="module" data-type="spacer" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="7770fdab-634a-4f62-a277-1c66b2646d8d.1.2">
  <tbody>
    <tr>
      <td style="padding:0px 0px 30px 0px;" role="module-content" bgcolor="#ffffff">
      </td>
    </tr>
  </tbody>
</table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="9314bf01-7519-4bec-80c1-96ca239d946c" data-mc-module-version="2019-10-22">
  <tbody>
    <tr>
      <td style="padding:5px 20px 5px 20px; line-height:24px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="color: #000000; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space-collapse: preserve; text-wrap: wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline; font-family: inherit; font-size: 14px">You can review your work anytime on your <a href="https://app.notedsource.io??utm_medium=email&utm_source=platform&utm_content=login_link&utm_campaign=year_in_review">account dashboard</a>.<br>We hope to see you back in 2024!</span></div>
<div></div></div></td>
    </tr>
  </tbody>
</table>`;
    },
    updateHtml(index) {
      const doc = this.$refs[`iframe${index}`][0].contentWindow.document;
      doc.open();
      doc.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=Edge">
    <!--<![endif]-->
    <!--[if (gte mso 9)|(IE)]>
    <xml>
      <o:OfficeDocumentSettings>
        <o:AllowPNG/>
        <o:PixelsPerInch>96</o:PixelsPerInch>
      </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <!--[if (gte mso 9)|(IE)]>
<style type="text/css">
  body {width: 600px;margin: 0 auto;}
  table {border-collapse: collapse;}
  table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
  img {-ms-interpolation-mode: bicubic;}
</style>
<![endif]-->
    <style type="text/css">
  body, p, div {
    font-family: inherit;
    font-size: 14px;
  }
  body {
    color: #000000;
  }
  body a {
    color: #1188E6;
    text-decoration: none;
  }
  p { margin: 0; padding: 0; }
  table.wrapper {
    width:100% !important;
    table-layout: fixed;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
  img.max-width {
    max-width: 100% !important;
  }
  .column.of-2 {
    width: 50%;
  }
  .column.of-3 {
    width: 33.333%;
  }
  .column.of-4 {
    width: 25%;
  }
  ul ul ul ul  {
    list-style-type: disc !important;
  }
  ol ol {
    list-style-type: lower-roman !important;
  }
  ol ol ol {
    list-style-type: lower-latin !important;
  }
  ol ol ol ol {
    list-style-type: decimal !important;
  }
  @media screen and (max-width:480px) {
    .preheader .rightColumnContent,
    .footer .rightColumnContent {
      text-align: left !important;
    }
    .preheader .rightColumnContent div,
    .preheader .rightColumnContent span,
    .footer .rightColumnContent div,
    .footer .rightColumnContent span {
      text-align: left !important;
    }
    .preheader .rightColumnContent,
    .preheader .leftColumnContent {
      font-size: 80% !important;
      padding: 5px 0;
    }
    table.wrapper-mobile {
      width: 100% !important;
      table-layout: fixed;
    }
    img.max-width {
      height: auto !important;
      max-width: 100% !important;
    }
    a.bulletproof-button {
      display: block !important;
      width: auto !important;
      font-size: 80%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .columns {
      width: 100% !important;
    }
    .column {
      display: block !important;
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .social-icon-column {
      display: inline-block !important;
    }
  }
</style>
    <!--user entered Head Start--><link href="https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,300;0,400;0,700;1,400&display=swap" rel="stylesheet" type="text/css"><style>
  * {font-family: "Source Sans 3", sans-serif;}
</style><!--End Head user entered-->
  </head>
  <body>
    <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size:14px; font-family:inherit; color:#000000; background-color:#FFFFFF;">
      <div class="webkit">
        <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#FFFFFF">
          <tr>
            <td valign="top" bgcolor="#FFFFFF" width="100%">
              <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                <tr>
                  <td width="100%">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                      <tr>
                        <td>
                          <!--[if mso]>
  <center>
  <table><tr><td width="600">
<![endif]-->
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:600px;" align="center">
                                    <tr>
                                      <td role="modules-container" style="padding:0px 0px 0px 0px; color:#000000; text-align:left;" bgcolor="#FFFFFF" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
  <tr>
    <td role="module-content">
      <p>Click below to access the unlocked whitepaper.</p>
    </td>
  </tr>
</table><table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" role="module" data-type="columns" style="padding:30px 20px 30px 20px;" bgcolor="#EBF1FC" data-distribution="1">
  <tbody>
    <tr role="module-content">
      <td height="100%" valign="top"><table width="540" style="width:540px; border-spacing:0; border-collapse:collapse; margin:0px 10px 0px 10px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="" class="column column-0">
    <tbody>
      <tr>
        <td style="padding:0px;margin:0px;border-spacing:0;"><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="72aac1ba-9036-4a77-b9d5-9a60d9b05cba">
  <tbody>
    <tr>
      <td style="font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="center">
        
      <a href="https://app.notedsource.io?utm_medium=email&utm_source=platform&utm_content=logo_link&utm_campaign=case_study_key"><img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px;" width="200" alt="NotedSource logo" data-proportionally-constrained="true" data-responsive="false" src="http://cdn.mcauto-images-production.sendgrid.net/7795f89fd84d55b7/3dad9f1e-7956-40e5-a20d-bbdc42efe9c8/400x90.png" height="45"></a></td>
    </tr>
  </tbody>
</table><table class="module" role="module" data-type="spacer" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="331cde94-eb45-45dc-8852-b7dbeb9101d7">
  <tbody>
    <tr>
      <td style="padding:0px 0px 20px 0px;" role="module-content" bgcolor="">
      </td>
    </tr>
  </tbody>
</table>
${this.getClientHtml(index)}
<table class="module" role="module" data-type="spacer" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="7770fdab-634a-4f62-a277-1c66b2646d8d.1">
  <tbody>
    <tr>
      <td style="padding:0px 0px 30px 0px;" role="module-content" bgcolor="#ffffff">
      </td>
    </tr>
  </tbody>
</table><table class="module" role="module" data-type="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="c5ec2cad-15b4-4a4a-bdd9-e9fd9702a5dd">
  <tbody>
    <tr>
      <td style="padding:10px 0px 10px 0px;" role="module-content" height="100%" valign="top" bgcolor="">
        <table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" height="1px" style="line-height:1px; font-size:1px;">
          <tbody>
            <tr>
              <td style="padding:0px 0px 1px 0px;" bgcolor="#F6F6F6"></td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="c28ff3ad-7253-4ca2-87d2-a875857b30a7" data-mc-module-version="2019-10-22">
  <tbody>
    <tr>
      <td style="padding:18px 20px 18px 20px; line-height:16px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="font-size: 12px; font-family: inherit">NotedSource</span></div>
<div style="font-family: inherit; text-align: center"><span style="color: #000000; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap: wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline; font-size: 12px; font-family: inherit">116 Nassau St, Fl 5</span></div>
<div style="font-family: inherit; text-align: center"><span style="color: #000000; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap: wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline; font-size: 12px; font-family: inherit">New York, NY 10038</span></div><div></div></div></td>
    </tr>
  </tbody>
</table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="c28ff3ad-7253-4ca2-87d2-a875857b30a7.1" data-mc-module-version="2019-10-22">
  <tbody>
    <tr>
      <td style="padding:18px 20px 18px 20px; line-height:16px; text-align:inherit; background-color:#FFFFFF;" height="100%" valign="top" bgcolor="#FFFFFF" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="font-size: 12px; font-family: inherit">This email was sent because a person using this email address created an account on NotedSource.</span></div><div></div></div></td>
    </tr>
  </tbody>
</table><div data-role="module-unsubscribe" class="module" role="module" data-type="unsubscribe" style="color:#444444; font-size:12px; line-height:20px; padding:16px 16px 16px 16px; text-align:center;" data-muid="d482e97f-8231-4fd0-8eac-ebf5845c5424"><div class="Unsubscribe--addressLine"></div><p style="font-size:12px; line-height:20px;"><a class="Unsubscribe--unsubscribeLink" href="{{{unsubscribe}}}" target="_blank" style="">Unsubscribe</a></p></div></td>
      </tr>
    </tbody>
  </table></td>
    </tr>
  </tbody>
</table></td>
                                    </tr>
                                  </table>
                                  <!--[if mso]>
                                </td>
                              </tr>
                            </table>
                          </center>
                          <![endif]-->
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </center>
  </body>
</html>`);
      doc.close();
    },
  },
};
</script>
