<template>
  <div
    class="bg-blueGray-200 rounded-lg flex items-start justify-between px-3 py-2"
  >
    <div v-if="Object.keys(person).length > 0" class="flex grow">
      <span class="w-full md:w-1/6 mr-5 text-right shrink-0">
        <img
          class="rounded-full w-[50px] h-[50px] ml-auto"
          :src="person.avatar"
        />
      </span>
      <div class="w-full md:w-5/6">
        <div class="flex">
          <input
            v-model="person.firstName"
            class="rounded mr-2 text-sm w-[100px] px-2 py-1 bg-white/30 border-black/20"
            type="text"
          />
          <input
            v-model="person.lastName"
            class="rounded mr-2 text-sm w-[100px] px-2 py-1 bg-white/30 border-black/20"
            type="text"
          />
        </div>
        <span class="text-sm overflow-x-hidden text-ellipsis">{{
          person.calc_EmailAddress
        }}</span>
      </div>
    </div>
    <div class="text-right">
      <button @click="getPerson()">
        <i class="fa-solid fa-rotate" :class="{ 'fa-spin': loading }"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";

const { keysToCamel, keysToSnake } = utils;

function randomKey() {
  return (Math.random() + 1).toString(36).substring(7);
}

export default {
  props: {
    person: Object,
    activeRequests: Array,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (Object.keys(this.person).length === 0) {
      this.getPerson();
    }
  },
  methods: {
    getPerson() {
      this.loading = true;
      const key = randomKey();
      this.activeRequests.push({ key, text: "Generating person..." });
      this.$api.axiosThrottled
        .post(
          `/get-person/`,
          keysToSnake({
            count: 1,
          }),
        )
        .then((resp) => {
          this.loading = false;
          const activeRequestIndex = this.activeRequests.findIndex(
            (item) => item.key === key,
          );
          this.activeRequests.splice(activeRequestIndex, 1);
          const data = keysToCamel(resp.data);
          this.$emit("setPerson", data.results[0]);
        })
        .catch(() => {
          const request = this.activeRequests.find((item) => item.key === key);
          if (request) {
            request.failed = true;
          }
        });
    },
  },
};
</script>
