<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-black font-serif mt-1 mx-5">Roles</h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "Roles",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "role",
      pluralLabel: "roles",
      adminPrefix: "roles",
      apiPrefix: "role",
      columns: {
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        name: {
          type: "string",
          label: "Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        roleType: {
          type: "formattedString",
          label: "Role Type",
          format(row) {
            return row.roleType?.label;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      createForm: {
        name: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          default: "",
          value: "",
          validatorTypes: ["required"],
        },
        roleType: {
          label: "Role Type",
          placeholder: "Select role type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/RoleType",
          searchOnFocus: true,
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        roleType: {
          label: "Role Type",
          placeholder: "Select role type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/RoleType",
          searchOnFocus: true,
        },
      },
      actions: {
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
      },
    };
  },
};
</script>
