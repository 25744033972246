<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mt-3 mb-6 shadow-lg rounded-lg bg-white px-6 pb-6"
      >
        <h4 class="text-3xl font-light mt-6">AI Experiment</h4>

        <div class="ml-10">
          <p>
            Please provide or adjust this information so we can use AI to
            generate the Collaboration Space, Collaboration Requests,
            Candidates, and Projects.
          </p>
          <div class="block mt-4">
            <h4 class="text-xl font-light mt-4">Select company size:</h4>
            <p class="form-help">Select one.</p>
            <div class="flex flex-wrap">
              <div
                v-for="cs in enums.companySizeOptions"
                :key="cs"
                class="mr-3 mb-3 bg-white rounded-lg"
              >
                <label
                  class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                  :class="{
                    'border-blueGray-500 bg-blueGray-200':
                      result.companySize == cs.value,
                  }"
                >
                  <input
                    v-model="result.companySize"
                    :value="cs.value"
                    type="radio"
                    class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                  />
                  <span class="text-blueGray-600 flex items-center">
                    <span class="text-2xl mr-2">{{
                      String.fromCodePoint("0x" + cs.emoji)
                    }}</span>
                    <span class="text-sm">{{ cs.label }}</span>
                  </span>
                </label>
              </div>
            </div>
            <h4 class="text-xl font-light mt-4">Select company stage:</h4>
            <p class="form-help">Select one.</p>
            <div class="flex flex-wrap">
              <div
                v-for="cs in enums.companyStageOptions"
                :key="cs"
                class="mr-3 mb-3 bg-white rounded-lg"
              >
                <label
                  class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                  :class="{
                    'border-blueGray-500 bg-blueGray-200':
                      result.companyStage == cs.value,
                  }"
                >
                  <input
                    v-model="result.companyStage"
                    :value="cs.value"
                    type="radio"
                    class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                  />
                  <span class="text-blueGray-600 flex items-center">
                    <span class="text-2xl mr-2">{{
                      String.fromCodePoint("0x" + cs.emoji)
                    }}</span>
                    <span class="text-sm">{{ cs.label }}</span>
                  </span>
                </label>
              </div>
            </div>
            <h4 class="text-xl font-light mt-4">Select company industries:</h4>
            <p class="form-help">Select as many as you'd like.</p>
            <div class="flex flex-wrap">
              <div
                v-for="i in enums.industryOptions"
                :key="i"
                class="mr-3 mb-3 bg-white rounded-lg"
              >
                <label
                  class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                  :class="{
                    'border-blueGray-500 bg-blueGray-200':
                      result.industries.indexOf(i.value) > -1,
                  }"
                >
                  <input
                    v-model="result.industries"
                    :value="i.value"
                    type="checkbox"
                    class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                  />
                  <span class="text-blueGray-600 flex items-center">
                    <span class="text-2xl mr-2">{{
                      String.fromCodePoint("0x" + i.emoji)
                    }}</span>
                    <span class="text-sm">{{ i.label }}</span>
                  </span>
                </label>
              </div>
            </div>
            <h4 class="text-xl font-light mt-4">Select job function(s):</h4>
            <p class="form-help">
              Select as many as you'd like. One collaboration space will be
              generated for each selected job function.
            </p>
            <div class="flex flex-wrap">
              <div
                v-for="jf in enums.jobFunctionOptions"
                :key="jf"
                class="mr-3 mb-3 bg-white rounded-lg"
              >
                <label
                  class="border-2 px-3 py-1 inline-flex items-center cursor-pointer rounded-lg"
                  :class="{
                    'border-blueGray-500 bg-blueGray-200':
                      result.jobFunction.indexOf(jf.value) > -1,
                  }"
                >
                  <input
                    v-model="result.jobFunction"
                    :value="jf.value"
                    type="checkbox"
                    class="hidden form-checkbox bg-blueGray-200 border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 focus:ring-chartreuseYellow cursor-pointer"
                  />
                  <span class="text-blueGray-600 flex items-center">
                    <span class="text-2xl mr-2">{{
                      String.fromCodePoint("0x" + jf.emoji)
                    }}</span>
                    <span class="text-sm">{{ jf.label }}</span>
                  </span>
                </label>
              </div>
            </div>

            <div class="w-full text-right">
              <button
                class="bg-blueGray-500 text-white px-4 py-2 rounded-lg mt-5"
                :class="{ 'opacity-50': !formComplete || loading }"
                @click="generateSuggestedProjects()"
              >
                Continue
              </button>
            </div>
          </div>
          <div class="mt-5">
            <template v-if="projectSuggestions?.length > 0">
              <h3 class="text-2xl font-light mt-4">Suggested Projects</h3>
              <div
                v-for="(suggestion, index) in projectSuggestions"
                :key="index"
                class="border rounded-lg mb-3 px-5 py-3"
              >
                <h3 class="text-lg">{{ suggestion.title }}</h3>
                <p>{{ suggestion.description }}</p>
                <p class="my-3">
                  <span
                    v-for="(skill, skillIndex) in suggestion.skills"
                    :key="skillIndex"
                    class="bg-blueGray-200 rounded-lg px-2 py-1 mr-2"
                  >
                    {{ skill }}
                  </span>
                </p>
                <p>
                  <em>Approx duration {{ suggestion.duration }}</em>
                </p>
                <p>{{ suggestion.idealBackground }}</p>
                <p>
                  <span
                    v-for="(
                      institution, institutionIndex
                    ) in suggestion.institutions"
                    :key="institutionIndex"
                    class="inline-block bg-blueGray-200 rounded-lg px-2 py-2 mr-2 leading-none"
                  >
                    {{ institution.name }}<br />
                    <span class="text-xs">{{ institution.location }}</span>
                  </span>
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";

const { keysToCamel, keysToSnake, loadEnums, addRequest, removeRequest } =
  utils;

export default {
  name: "DataIntegrity",
  components: {},
  data() {
    return {
      activeRequests: [],
      enums: {},
      result: {
        companySize: "MEDIUM",
        companyStage: "MATURITY",
        industries: ["CONSTRUCTION"],
        jobFunction: ["FINANCE", "MARKETING"],
      },
      projectSuggestions: [],
    };
  },
  computed: {
    formComplete() {
      return (
        this.result.companySize !== "" &&
        this.result.companyStage !== "" &&
        this.result.industries.length > 0 &&
        this.result.jobFunction.length > 0
      );
    },
    loading() {
      return this.activeRequests.length > 0;
    },
  },
  mounted() {
    loadEnums(this, {
      CompanySize: "companySizeOptions",
      CompanyStage: "companyStageOptions",
      Industry: "industryOptions",
      JobFunction: "jobFunctionOptions",
    });
  },
  methods: {
    generateSuggestedProjects() {
      const key = addRequest(this, "Generating suggested projects...");
      this.$api.axiosThrottled
        .post(
          `/ai-experiment/project-suggestion/`,
          keysToSnake({ result: this.result }),
        )
        .then((resp) => {
          removeRequest(this, key);
          const data = keysToCamel(resp.data);
          this.projectSuggestions = keysToCamel(
            JSON.parse(data.answer),
          ).projectSuggestions;
        })
        .catch(() => removeRequest(this, key, true));
    },
  },
};
</script>
