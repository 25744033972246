<template>
  <section class="relative">
    <div class="container">
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg border-black/10 border border-solid"
      >
        <div class="px-4 py-4">
          <div class="flex">
            <div class="flex-shrink text-center">
              <div class="cursor-pointer">
                <profile-image
                  :size="75"
                  :img-url="avatarUrl(profile)"
                  @click="$emit('loadProfileDetail')"
                />
              </div>
              <button
                class="mt-3 bg-porcelain-300 text-white text-xs uppercase px-2 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                @click.prevent="$emit('loadProfileDetail')"
              >
                View&nbsp;Profile
              </button>
            </div>
            <div class="flex-grow pl-5">
              <h3
                class="text-xl leading-normal mb-2 text-mallard-500 cursor-pointer"
                @click="$emit('loadProfileDetail')"
              >
                {{ profile.fullDisplayName }}
              </h3>
              <h4 class="text-sm">{{ profile.headline }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";

const { avatarUrl, keysToSnake } = utils;

export default {
  components: {
    ProfileImage,
  },
  props: {
    profile: Object,
    sharedNotes: String,
    showClientNotes: Boolean,
    clientNotes: String,
    collaborationRequestId: String,
    collaborationRequestProfileId: String,
  },
  data() {
    return {
      educationExpanded: false,
      experienceExpanded: false,
      avatarUrl,
      autosaveStatus: null,
      autosaveTimeout: null,
    };
  },
  computed: {
    companyNames() {
      if (this.profile.experiences) {
        return this.profile.experiences
          .map((experience) => {
            return experience.companyName;
          })
          .join(", ");
      }
      return [];
    },
    schoolNames() {
      if (this.profile.educations) {
        return this.profile.educations
          .map((education) => {
            return education.schoolName;
          })
          .join(", ");
      }
      return [];
    },
  },
  watch: {
    clientNotes(note) {
      this.saveClientNotes(note);
    },
  },
  methods: {
    changeConnections() {
      this.$emit("changeConnections");
    },
    saveClientNotes(note) {
      const self = this;
      this.autosaveStatus = "Saving...";
      clearTimeout(this.autosaveTimeout);
      this.autosaveTimeout = setTimeout(() => {
        self.$api.axios
          .put(
            `/work/request/${self.collaborationRequestId}/profile/${self.collaborationRequestProfileId}/`,
            keysToSnake({
              clientNotes: note,
            }),
          )
          .then(() => {
            self.autosaveStatus = "Saved.";
            setTimeout(() => {
              if (self.autosaveStatus === "Saved.") {
                self.autosaveStatus = null;
              }
            }, 1000);
          });
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.markdown-container {
  p {
    margin-bottom: 20px;
  }
}
</style>
