<template>
  <div class="flex flex-wrap px-4">
    <h3 class="text-3xl text-blueGray-700 font-light my-4">Feed Item Search</h3>
    <SearchSection />
  </div>
</template>
<script>
import SearchSection from "../../components/FeedItemSearch/SearchSection.vue";

export default {
  components: {
    SearchSection,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
