<template>
  <div class="bg-blueGray-100">
    <div class="relative min-h-screen overflow-x-auto">
      <div class="mx-auto w-full md:p-5">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import FooterAdmin from "../components/Footers/FooterAdmin.vue";

export default {
  name: "AdminLayout",
  components: {
    FooterAdmin,
  },
  mounted() {
    if (!this.$store.getters.isPersonLoggedIn) {
      this.$router.push("/");
    }
  },
};
</script>
