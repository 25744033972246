<template>
  <div v-show="facet.data.length" class="mt-2 mb-5">
    <div>
      <span class="form-label">{{ label }}</span>
    </div>
    <div>
      <label
        v-for="facetItem in facet.data"
        :key="getValue(facetItem, facet.type)"
        class="flex justify-between"
      >
        <div>
          <input
            type="checkbox"
            class="mr-2"
            :value="getValue(facetItem, facet.type)"
            :checked="isChecked(getValue(facetItem, facet.type))"
            @change="$emit('change', $event)"
          />
          <span class="text-sm text-ellipsis overflow-hidden">{{
            getValue(facetItem, facet.type)
          }}</span>
        </div>
        <span>{{ facetItem.count }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    facet: {
      type: Object,
      required: true,
    },
    checked: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isChecked(value) {
      return this.checked && this.checked.includes(value);
    },
    getValue(facetItem, type) {
      return type === "range" ? facetItem.value.name : facetItem.value;
    },
  },
};
</script>
