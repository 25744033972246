<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { provide } from "vue";
import dayjs from "dayjs";

export default {
  setup() {
    provide("dayjs", dayjs);
  },
};
</script>
