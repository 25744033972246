<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div
        class="relative flex flex-col min-w-0 break-words mt-3 mb-6 shadow-lg rounded-lg bg-white px-6 pb-6 mx-6"
      >
        <h4 class="text-3xl font-light mt-6">Data Integrity > Schools</h4>

        <div class="my-10">
          <p v-for="category in categories" :key="category" class="mt-5">
            <template v-if="statistics[category]">
              <span class="form-label"
                >{{ category }} ({{
                  statistics[category]?.total -
                  statistics[category]?.unclassified
                }}
                / {{ statistics[category].total }})</span
              >
              <div class="w-full bg-gray-500/20 rounded-full h-[20px]">
                <div
                  class="bg-green-500 rounded-l-full h-[20px]"
                  :class="{
                    'rounded-r-full': statistics[category]?.unclassified == 0,
                  }"
                  :style="`width: ${
                    ((statistics[category]?.total -
                      statistics[category]?.unclassified) /
                      statistics[category].total) *
                    100
                  }%`"
                >
                  &nbsp;
                </div>
              </div>
            </template>
          </p>
        </div>

        <div class="flex flex-col">
          <div v-for="item in education" :key="item.name">
            <grid-institute-row
              :item="item"
              :suggestions="suggestions"
              @change-connection="changeConnection"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import GridInstituteRow from "../../components/Admin/GridInstituteRow.vue";

const { keysToCamel } = utils;

export default {
  name: "RolePermissions",
  components: {
    GridInstituteRow,
  },
  data() {
    return {
      loading: true,
      statistics: {},
      education: [],
      suggestions: [],
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      enumPath: `${import.meta.env.VITE_API_BASE_URL}/enum`,
      categories: ["educations", "experiences"],
    };
  },
  created() {
    this.loadStatistics();
    this.loadSchools();
  },
  methods: {
    loadStatistics() {
      this.loading = true;
      this.$api.axios
        .get(`${this.apiBaseUrl}/institute/statistics/`)
        .then((res) => {
          this.statistics = keysToCamel(res.data.counts);
        });
    },
    loadSchools() {
      this.loading = true;
      this.$api.axios
        .get(`${this.apiBaseUrl}/institute/education/`)
        .then((res) => {
          const data = keysToCamel(res.data);
          this.education = data.results;
          this.suggestions = data.suggestions;
        });
    },
    changeConnection() {
      this.loadStatistics();
      this.loadSchools();
    },
  },
};
</script>
