<script>
import { h } from "vue";

export default {
  name: "MomentFromNow",
  props: {
    tag: { type: String, default: "span" },
    value: { type: String, default: () => this.$dayjs().toISOString() },
    interval: { type: Number, default: 1000 },
  },
  data() {
    return {
      fromNow: this.$dayjs.utc(this.value).local().fromNow(),
    };
  },
  mounted() {
    this.intervalId = setInterval(this.updateFromNow, this.interval);
    this.$watch("value", this.updateFromNow);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    updateFromNow() {
      const newFromNow = this.$dayjs
        .utc(this.value)
        .local()
        .fromNow(this.dropFixes);
      if (newFromNow !== this.fromNow) {
        this.fromNow = newFromNow;
      }
    },
  },
  render() {
    return h(this.tag, this.fromNow);
  },
};
</script>
