<template>
  <assembly-line-right-side-modal ref="modal" :showing="false">
    <template #body>
      <section
        class="flex flex-col h-full justify-between rounded-l-lg bg-white shadow-lg mb-3"
      >
        <div>
          <collaboration-space-banner
            class="relative rounded-l-lg bg-blueGray-500"
            :height="300"
            :img-url="bannerUrl(this, work?.collaborationSpace?.banner, 400)"
            :banner="work?.collaborationSpace?.banner"
          />
          <div
            class="relative -mt-[290px] flex items-center justify-between mb-3 px-4"
          >
            <div class="flex items-center">
              <collaboration-space-logo
                class="bg-white ml-0 z-10"
                :size="30"
                :img-url="logoUrl(work?.collaborationSpace)"
              />
              <div
                class="-ml-3 pl-5 bg-white/80 rounded-r-lg pr-2 text-sm py-1"
              >
                {{ work?.collaborationSpace?.name }}
              </div>
            </div>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              @click.prevent="toggleModal()"
            >
              <span
                class="bg-transparent text-white h-6 w-6 text-2xl block outline-none focus:outline-none"
              >
                ×
              </span>
            </button>
          </div>
          <div
            class="relative rounded-lg bg-white px-3 mx-3 py-2 min-h-[200px]"
          >
            <h4
              class="text-lg"
              v-html="work?.title || '<em>Untitled</em>'"
            ></h4>
            <hr class="mt-1 mb-3" />
            {{ work }}
            <div>
              <div
                v-for="workRole in work?.workRoles"
                :key="workRole.hashedId"
                class="flex items-center mb-3"
              >
                <profile-image
                  :size="40"
                  :img-url="avatarUrl(null, workRole.person)"
                />
                <div class="ml-2">
                  <p class="m-0">
                    {{ workRole.person.firstName }}
                    {{ workRole.person.lastName }}
                  </p>
                  <p class="relative -mt-1">
                    <span class="uppercase text-xs">{{
                      workRole.role.name
                    }}</span>
                  </p>
                </div>
              </div>
              <div v-if="work.collaborationRequest.hashedId">
                <h6 class="uppercase text-xs">Collaboration Request</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </assembly-line-right-side-modal>
</template>
<script>
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";
import CollaborationSpaceBanner from "../CollaborationSpaceBanner.vue";
import CollaborationSpaceLogo from "../CollaborationSpaceLogo.vue";
import AssemblyLineRightSideModal from "../Modals/AssemblyLineRightSide.vue";

const { avatarUrl, bannerUrl, logoUrl } = utils;

export default {
  components: {
    ProfileImage,
    CollaborationSpaceBanner,
    CollaborationSpaceLogo,
    AssemblyLineRightSideModal,
  },
  props: {
    work: Object,
  },
  data() {
    return {
      avatarUrl,
      bannerUrl,
      logoUrl,
    };
  },
  computed: {},
  methods: {
    toggleModal() {
      this.$refs.modal.toggleModal();
    },
  },
};
</script>
