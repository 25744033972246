<template>
  <section class="relative bg-white">
    <div v-if="stepIndex == 0 && !hashedId" class="container mx-auto flex-col">
      <div class="h-[calc(100vh-120px)] overflow-y-scroll">
        <div
          class="relative flex flex-col min-w-0 break-words w-full px-5 grow"
        >
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">
              Contract Package Name
            </h6>
          </div>
          <div class="w-full">
            <input
              ref="contractName"
              v-model="name"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
              type="text"
              :placeholder="`i.e., NDA with ${$store.getters.person.firstName} ${$store.getters.person.lastName}`"
            />
          </div>
        </div>
        <div class="relative flex flex-col min-w-0 break-words w-full px-5">
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">Signatures</h6>
          </div>
          <p class="form-help">
            Enter the details of the person signing this contract.
          </p>
          <div
            class="relative flex flex-col min-w-0 break-words w-full px-5 pb-5 border"
          >
            <div
              v-for="(signature, index) in contractPackageSignatures"
              :key="index"
            >
              <div class="relative flex flex-col min-w-0 break-words w-full">
                <div>
                  <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                    Email Address
                  </h6>
                </div>
                <div class="w-full">
                  <input
                    v-model="signature.emailAddress"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
                    type="text"
                  />
                </div>
              </div>
              <div class="relative flex min-w-0 break-words w-full">
                <div class="w-full md:w-1/2 md:pr-5">
                  <div>
                    <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                      First Name
                    </h6>
                  </div>
                  <div class="w-full">
                    <input
                      v-model="signature.firstName"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
                      type="text"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2">
                  <div>
                    <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                      Last Name
                    </h6>
                  </div>
                  <div class="w-full">
                    <input
                      v-model="signature.lastName"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label class="text-blueGray-500 my-3">
            <input v-model="includeRequester" type="checkbox" class="mr-2" />
            Send to me for signature afterwards.
          </label>
        </div>
      </div>
      <div
        class="flex items-center justify-between px-6 py-3 border-t border-solid border-bianca-700 rounded-b bg-bianca-500"
      >
        <p class="text-red-500">{{ errorMessage }}</p>
        <div class="text-right">
          <button
            class="bg-blueGray-500 rounded-lg px-3 py-2 text-white"
            @click="createContractPackage()"
          >
            Create and Select Contracts
          </button>
        </div>
      </div>
    </div>
    <div v-if="stepIndex == 0 && hashedId" class="container mx-auto flex-col">
      <div class="h-[calc(100vh-120px)] overflow-y-scroll">
        <div
          class="relative flex flex-col min-w-0 break-words w-full px-5 grow"
        >
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">
              Contract Package Name
            </h6>
          </div>
          <div class="w-full">
            <input
              ref="contractName"
              v-model="editForm.name"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
              type="text"
              :placeholder="`i.e., NDA with ${$store.getters.person.firstName} ${$store.getters.person.lastName}`"
            />
          </div>
        </div>
        <div class="relative flex flex-col min-w-0 break-words w-full px-5">
          <div>
            <h6 class="uppercase text-sm mt-5 text-blueGray-500">Signatures</h6>
          </div>
          <p class="form-help">
            Enter the details of the person signing this contract.
          </p>
          <div
            class="relative flex flex-col min-w-0 break-words w-full px-5 pb-5 border"
          >
            <div
              v-for="(signature, index) in editForm.contractPackageSignatures"
              :key="index"
            >
              <div class="relative flex flex-col min-w-0 break-words w-full">
                <div>
                  <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                    Email Address
                  </h6>
                </div>
                <div class="w-full">
                  <input
                    v-model="signature.emailAddress"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
                    type="text"
                  />
                </div>
              </div>
              <div class="relative flex min-w-0 break-words w-full">
                <div class="w-full md:w-1/2 md:pr-5">
                  <div>
                    <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                      First Name
                    </h6>
                  </div>
                  <div class="w-full">
                    <input
                      v-model="signature.firstName"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
                      type="text"
                    />
                  </div>
                </div>
                <div class="w-full md:w-1/2">
                  <div>
                    <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                      Last Name
                    </h6>
                  </div>
                  <div class="w-full">
                    <input
                      v-model="signature.lastName"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-golden-fizz w-full ease-linear transition-all duration-150"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label class="text-blueGray-500 my-3">
            <input v-model="includeRequester" type="checkbox" class="mr-2" />
            Send to me for signature afterwards.
          </label>
        </div>
      </div>
      <div
        class="flex items-center justify-between px-6 py-3 border-t border-solid border-bianca-700 rounded-b bg-bianca-500"
      >
        <p class="text-red-500">{{ errorMessage }}</p>
        <div class="text-right">
          <button
            class="bg-blueGray-500 rounded-lg px-3 py-2 text-white"
            @click="next()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div v-if="stepIndex == 1" class="container mx-auto flex-col">
      <div
        class="relative flex flex-col min-w-0 break-words w-full px-5 grow h-[calc(100vh-120px)] overflow-y-scroll"
      >
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Contract Documents
          </h6>
        </div>
        <div
          class="relative flex flex-col min-w-0 break-words w-full px-5 pb-5 border"
        >
          <div
            v-for="contractDocument in contractPackage.contracts"
            :key="contractDocument.hashedId"
          >
            <div>
              <h6 class="text-lg mt-5">
                {{ contractDocument.name }}
              </h6>
              <div>
                <ul
                  v-for="variable in contractDocument.contractVariables"
                  :key="variable.name"
                  class="text-sm list-disc ml-6"
                >
                  <li>
                    {{ variable.name }}:
                    <em>Default {{ variable.defaultValue }}</em>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Add new document from template
          </h6>
        </div>
        <div
          class="relative flex flex-col min-w-0 break-words w-full px-5 pb-5 border mb-10"
        >
          <div class="flex flex-wrap pt-5">
            <div
              v-for="contractTemplate in contractTemplates"
              :key="contractTemplate.name"
              class="bg-bianca-500 rounded-lg border border-bianca-700 w-full md:w-1/2 lg:w-1/3 px-4 py-3"
            >
              <h3 class="text-xl">{{ contractTemplate.name }}</h3>
              <p><em>Variables</em></p>
              <ul
                v-for="variable in contractTemplate.contractTemplateVariables"
                :key="variable.name"
                class="text-sm list-disc ml-6"
              >
                <li>{{ variable.name }}</li>
              </ul>
              <div class="text-right">
                <button
                  class="text-sm bg-blueGray-500 rounded-md px-3 py-1 text-white mt-3"
                  @click="
                    toggleNewContractFromTemplateModal(
                      contractTemplate.hashedId,
                    )
                  "
                >
                  Use Template
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-end px-6 py-3 border-t border-solid border-bianca-700 rounded-b bg-bianca-500 grow-0"
      >
        <div class="flex justify-between w-full">
          <button
            class="bg-blueGray-200 rounded-lg px-3 py-2"
            @click="previous()"
          >
            Previous
          </button>
          <button
            class="bg-blueGray-500 rounded-lg px-3 py-2 text-white"
            @click="createContractPackage()"
          >
            Save Documents
          </button>
        </div>
      </div>
    </div>
    <right-side-modal
      ref="newContractFromTemplateModal"
      :showing="false"
      :hide-footer="true"
    >
      <template #header>
        <h4 class="text-xl font-light">
          New Contract From Template {{ contractTemplateHashedId }}
        </h4>
      </template>
      <template #body>
        <new-contract-from-template-modal
          v-if="hashedId && contractTemplateHashedId"
          :package-hashed-id="hashedId"
          :template-hashed-id="contractTemplateHashedId"
          :contract-package="contractPackage"
          @finished="finishAddingContract()"
        />
      </template>
    </right-side-modal>
  </section>
</template>
<script>
import { utils } from "common-frontend";
import "vue-slider-component/theme/default.css";
import RightSideModal from "../Modals/RightSide.vue";
import NewContractFromTemplateModal from "./NewContractFromTemplateModal.vue";

const { keysToCamel, keysToSnake } = utils;

export default {
  components: {
    RightSideModal,
    NewContractFromTemplateModal,
  },
  props: {
    contractPackageHashedId: String,
  },
  data() {
    return {
      hashedId: this.contractPackageHashedId,
      contractPackage: {},
      stepIndex: 0,
      name: null,
      editForm: {},
      contractPackageSignatures: [],
      contractDocuments: [],
      contractTemplates: [],
      includeRequester: false,
      loading: true,
      contractTemplateHashedId: null,
      errorMessage: null,
    };
  },
  mounted() {
    if (this.hashedId) {
      this.loadContractPackage();
    }
    this.loadContractTemplates();
    this.addContractPackageSignature();
  },
  methods: {
    loadContractTemplates() {
      this.$api.axios.get(`/contract-template/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.contractTemplates = data.results;
        this.loading = false;
      });
    },
    loadContractPackage() {
      this.loading = true;
      this.$api.axios
        .get(`/contract-package/${this.hashedId}/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.contractPackage = data;
          this.editForm = {
            name: this.contractPackage.name,
            contractPackageSignatures: [],
          };
          this.contractPackage.contractPackageSignatures.forEach(
            (signature) => {
              this.editForm.contractPackageSignatures.push({
                firstName: signature.firstName,
                lastName: signature.lastName,
                emailAddress: signature.emailAddress,
                hashedId: signature.hashedId,
              });
            },
          );
          this.loading = false;
        });
    },
    addContractPackageSignature() {
      this.contractPackageSignatures.push({
        firstName: "",
        lastName: "",
        emailAddress: "",
      });
    },
    next() {
      this.stepIndex += 1;
    },
    previous() {
      this.stepIndex -= 1;
    },
    createContractPackage() {
      this.errorMessage = null;
      if (
        !this.name ||
        !this.contractPackageSignatures[0].firstName ||
        !this.contractPackageSignatures[0].lastName ||
        !this.contractPackageSignatures[0].emailAddress
      ) {
        this.errorMessage =
          "You need a package name and all fields for a signature.";
      } else {
        const payload = keysToSnake({
          name: this.name,
          contractPackageSignatures: this.contractPackageSignatures,
          includeRequester: this.includeRequester,
        });
        this.$api.axios
          .post(`/contract-package/create-from-wizard/`, payload)
          .then((resp) => {
            const data = keysToCamel(resp.data);
            this.hashedId = data.hashedId;
            this.$toast.success(`This contract package has been created.`);
            this.$emit("reloadList");
            this.loadContractPackage();
            this.next();
          });
      }
    },
    toggleNewContractFromTemplateModal(hashedId) {
      if (hashedId) {
        this.contractTemplateHashedId = hashedId;
      }
      this.$refs.newContractFromTemplateModal.toggleModal();
    },
    finishAddingContract() {
      this.toggleNewContractFromTemplateModal();
      this.loadContractPackage();
    },
  },
};
</script>
