<template>
  <div>
    <condition-group
      ref="conditionGroup"
      :enums="enums"
      :subquery="subquery"
      :root="true"
      :journal-title-list="journalTitleList"
      :research-interest-list="researchInterestList"
      @search-has-focus="$emit('searchHasFocus')"
    />
  </div>
</template>

<script>
import ConditionGroup from "./ConditionGroup.vue";

export default {
  components: {
    ConditionGroup,
  },
  props: {
    enums: Object,
    subquery: Object,
    journalTitleList: Array,
    researchInterestList: Array,
  },
  data() {
    return {};
  },
  mounted() {
    this.$refs.conditionGroup.setFocus();
  },
  methods: {},
};
</script>
