<template>
  <div
    class="border border-porcelain-300 rounded-lg mx-5 pt-3 bg-white/50 mb-3"
  >
    <div class="px-3 flex justify-between items-center">
      <span class="form-label text-porcelain-900/75">Decode a hashed ID</span>
      <span
        v-if="results.length"
        class="cursor-pointer bg-porcelain-900/75 text-white text-xs uppercase rounded px-1 relative -mx-[20px] -mt-[40px]"
        @click="clear()"
        >Clear</span
      >
    </div>
    <div v-if="!results.length" class="flex justify-between px-3 pb-2">
      <input
        v-model="idToDecode"
        class="px-3 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-l text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150 border border-porcelain-900/75"
        type="text"
      />
      <button
        class="bg-porcelain-900/75 border border-porcelain-900/75 text-white rounded-r px-2"
        @click="decodeId()"
      >
        <span v-if="!loading">Decode</span>
        <span v-else><i class="fas fa-circle-notch fa-spin mt-1"></i></span>
      </button>
    </div>
    <ul v-if="results.length" class="ml-10 mb-2 list-disc text-sm">
      <li v-for="(result, index) in results" :key="index">
        {{ result[0] }} {{ result[1] }}
      </li>
    </ul>
  </div>
</template>

<script>
import { utils } from "common-frontend";

const { keysToSnake, keysToCamel } = utils;

export default {
  components: {},
  data() {
    return {
      loading: false,
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      idToDecode: null,
      results: [],
    };
  },
  methods: {
    decodeId() {
      this.loading = true;
      const payload = keysToSnake({
        hashedId: this.idToDecode,
      });
      this.$api.axios
        .post(`${this.apiBaseUrl}/decode-id/`, payload)
        .then((resp) => {
          this.loading = false;
          const data = keysToCamel(resp.data);
          this.results = data.results;
          if (this.results.length === 0) {
            this.$toast.warning("ID not found");
          }
        });
    },
    clear() {
      this.results = [];
      this.idToDecode = null;
    },
  },
};
</script>
