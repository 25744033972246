<template>
  <div>
    <div
      v-for="discipline in item.disciplines"
      :key="discipline.hashedId"
      class="border rounded-lg px-3 py-2 mb-2 bg-blueGray-100"
    >
      <data-integrity-research-interest-discipline
        :enums="enums"
        :discipline="discipline"
        @load-research-interests="$emit('loadResearchInterests')"
      />
    </div>
    <div>
      <button
        class="px-1 bg-blueGray-200 rounded text-sm text-blueGray-500 ml-1"
        @click="toggleShowDisciplines()"
      >
        <i class="fa-solid fa-plus"></i>
      </button>
      <div
        class="bg-white border p-2 shadow-lg"
        :class="{ absolute: showDisciplines, hidden: !showDisciplines }"
      >
        <span
          v-for="(
            discipline, disciplineIndex
          ) in remainingItemsForDisciplineList(item.disciplines)"
          :key="disciplineIndex"
          class="text-sm rounded ml-1 mt-1 inline-block px-1 cursor-pointer"
          :style="`background-color: #${discipline.bgColor}; color: #${discipline.textColor}`"
          @click="createResearchInterestDiscipline(item, discipline)"
          >{{ discipline.label.replace(" ", "&nbsp;") }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import DataIntegrityResearchInterestDiscipline from "./DataIntegrityResearchInterestDiscipline.vue";

const { keysToSnake } = utils;

export default {
  name: "DataIntegrityResearchInterest",
  components: {
    DataIntegrityResearchInterestDiscipline,
  },
  props: {
    item: Object,
    enums: Object,
  },
  data() {
    return {
      loading: true,
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      showDisciplines: false,
    };
  },
  methods: {
    logRefs(id) {
      this.$refs[id][0].toggleExpanded();
    },
    remainingItemsForDisciplineList(disciplineList) {
      return this.enums.rssFeedDisciplineOptions.filter((item) => {
        return disciplineList.indexOf(item.value) === -1;
      });
    },
    createResearchInterestDiscipline(researchInterest, discipline) {
      this.loading = true;
      this.$api.axios
        .post(
          `${
            this.apiBaseUrl
          }/person/public-profile/research-interest-discipline/${encodeURIComponent(
            researchInterest.name,
          )}/`,
          keysToSnake({ rssFeedDiscipline: discipline.value }),
        )
        .then(() => {
          this.$toast.success(`Added discipline to research expertise.`);
          this.$emit("loadResearchInterests");
          this.toggleShowDisciplines();
        });
    },
    toggleShowDisciplines() {
      this.showDisciplines = !this.showDisciplines;
    },
  },
};
</script>
