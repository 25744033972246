<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Collaboration Inquiries
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :test-boolean-key="testBooleanKey"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "CollaborationInquiries",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "collaboration inquiry",
      pluralLabel: "collaboration inquiries",
      adminPrefix: "collaboration-inquiries",
      apiPrefix: "collaboration-inquiry",
      testBooleanKey: "profile.person.isTestAccount",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "cr-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        clientName: {
          type: "formattedString",
          label: "Client Name",
          format(row) {
            const els = [];
            if (row.inquirer.hashedId) {
              els.push(
                `<p class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">
                  ${row.inquirer.firstName} ${row.inquirer.lastName}
                 </p>
                 <p class="text-black/50 text-sm italic">${row.inquirer.calc_EmailAddress}</p>`,
              );
              if (row.inquirer.primaryEmailAddress.verifiedAt) {
                els.push(
                  `<span class="text-sm text-black/50"><i class="fas fa-check-square"></i> Verified Email</span>`,
                );
              }
            } else {
              els.push(
                `<p class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">
                   ${row.firstName} ${row.lastName}
                 </p>
                 <p class="text-black/50 text-sm italic">
                   ${row.emailAddress}
                 </p>`,
              );
              if (row.emailAddressVerifiedAt) {
                els.push(
                  `<span class="text-sm text-black/50"><i class="fas fa-check-square"></i> Verified Email</span>`,
                );
              }
            }
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          hidden: "never",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        expertName: {
          type: "formattedString",
          label: "Expert Name",
          format(row) {
            const els = [];
            els.push(
              `<p class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">${row.profile.fullDisplayName}</p>
               <p class="text-black/50 text-sm italic">
                ${row.profile.person.calc_EmailAddress}
               </p>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          hidden: "never",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        topic: {
          type: "formattedString",
          label: "Title",
          format(row) {
            const els = [];
            els.push(
              `<p class="max-w-[300px] overflow-ellipsis text-nowrap overflow-hidden">${row.topic}</p>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          hidden: "never",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        status: {
          type: "enum",
          label: "Status",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        firstName: {
          label: "First Name:",
          placeholder: "Enter first name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: [],
        },
        lastName: {
          label: "Last Name:",
          placeholder: "Enter last name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: [],
        },
        emailAddress: {
          label: "Email Address:",
          placeholder: "Enter email address",
          type: "string",
          value: "",
          default: "",
          validatorTypes: [],
        },
        companyName: {
          label: "Company Name:",
          placeholder: "Enter company name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: [],
        },
        topic: {
          label: "Topic:",
          placeholder: "Enter topic",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        detail: {
          label: "Detail:",
          placeholder: "Enter detail",
          type: "text",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        status: {
          label: "Status",
          placeholder: "Select status",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/CollaborationInquiryStatus",
          searchOnFocus: true,
        },
        engagementTimeline: {
          label: "Anticipated Start Date",
          placeholder: "Select when to start",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/EngagementTimeline",
          searchOnFocus: true,
        },
        engagementDuration: {
          label: "Anticipated Duration",
          placeholder: "Select how long this project will last",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/EngagementDuration",
          searchOnFocus: true,
        },
        deliverableTypes: {
          label: "Deliverable Types",
          type: "dataTable",
          value: "",
          singularLabel: "deliverable type",
          pluralLabel: "deliverable types",
          adminPrefix: "collaboration-inquiries",
          apiPrefix: "collaboration-inquiry/deliverable-type",
          parentKey: "collaboration_inquiry.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Collaboration Inquiry ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">cr-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "grp-",
              },
            },
            createdAt: {
              type: "relativeDate",
              label: "Created At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
            deliverableType: {
              type: "enum",
              label: "Deliverable Type",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [],
          },
        },
        revisions: {
          type: "revisionHistory",
          modelClassName: "collaboration_inquiry",
          childKeys: [
            {
              modelClassName: "collaboration_inquiry_deliverable_type",
              relationship: "deliverableTypes",
            },
          ],
          value: "",
        },
      },
      actions: {
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
        menuItems: [
          {
            label: "Send Verification Reminder",
            qualifier(row) {
              if (row.inquirer.hashedId) {
                return row.inquirer.primaryEmailAddress.verifiedAt === null;
              }
              return row.emailAddressVerifiedAt === null;
            },
            action(event, row) {
              const url = `collaboration-inquiry/${row.hashedId}/send-verification-reminder-email/`;
              return ["apiAction", "put", url, {}];
            },
          },
          {
            label: "Manually Verify Email",
            qualifier(row) {
              if (row.inquirer.hashedId) {
                return row.inquirer.primaryEmailAddress.verifiedAt === null;
              }
              return row.emailAddressVerifiedAt === null;
            },
            action(event, row) {
              const url = `collaboration-inquiry/${row.hashedId}/verify-email/`;
              return [
                "apiAction",
                "putReload",
                url,
                { verificationCode: row.emailAddressVerificationCode },
              ];
            },
          },
          {
            label: "Send To Expert",
            qualifier(row) {
              return row.status?.value === "VERIFIED" && row.sentAt === null;
            },
            action(event, row) {
              const url = `collaboration-inquiry/${row.hashedId}/send-to-expert/`;
              return ["apiAction", "postReload", url, {}];
            },
          },
        ],
      },
    };
  },
};
</script>
