<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            People & Profiles
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :test-boolean-key="testBooleanKey"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
    <right-side-modal ref="profileDetailModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">Profile Detail</h4>
      </template>
      <template #body>
        <profile-detail-modal
          v-if="profileDetailHashedId"
          :hashed-id="profileDetailHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>
<script>
import { utils } from "common-frontend";
import DataTable from "./DataTable.vue";
import RightSideModal from "../Modals/RightSide.vue";
import ProfileDetailModal from "../Profile/ProfileDetailModal.vue";

const { avatarUrl } = utils;

export default {
  name: "Profiles",
  components: {
    DataTable,
    RightSideModal,
    ProfileDetailModal,
  },
  data() {
    return {
      singularLabel: "profile",
      profileDetailHashedId: null,
      pluralLabel: "profiles",
      adminPrefix: "profiles",
      apiPrefix: "person/profile",
      testBooleanKey: "person.isTestAccount",
      columns: {
        "person.isTestAccount": {
          type: "testBoolean",
          label: "Is Test Account",
          hidden: "never",
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "testBoolean",
            default: { label: "No", value: false },
          },
        },
        hashedId: {
          type: "formattedString",
          label: "Profile ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">pro-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "pro-",
          },
        },
        "person.hashedId": {
          type: "formattedString",
          label: "Person ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">per-${row.person?.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "per-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
          },
        },
        avatar: {
          type: "avatarWithActivityLevel",
          label: "Avatar",
          format(row) {
            return avatarUrl(row);
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
          },
        },
        "person.firstName": {
          type: "string",
          label: "First Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        "person.lastName": {
          type: "string",
          label: "Last Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        displayName: {
          type: "string",
          label: "Display Name",
          hidden: "yes",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        fullDisplayName: {
          type: "string",
          label: "Full Display Name",
          hidden: "yes",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        "person.calc_EmailAddress": {
          type: "formattedString",
          label: "Primary Email Address",
          hidden: "never",
          format(row) {
            const els = [];
            els.push(`${row.person?.primaryEmailAddress?.emailAddress}`);
            if (row.person?.primaryEmailAddress?.verifiedAt) {
              els.push(
                `<span class="text-sm text-black/50"><i class="fas fa-check-square"></i> Verified Email</span>`,
              );
            }
            if (row.person?.isTwoFactorAuthenticationEnabled) {
              els.push(
                `<span class="text-sm text-black/50"><i class="fas fa-lock"></i> 2FA Enabled</span>`,
              );
            }
            return `
              <span>
                ${els.join("<br>")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        "person.personType": {
          type: "enum",
          label: "Person Type",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        completenessScore: {
          type: "formattedString",
          label: "% Complete",
          format(row) {
            const els = [];
            if (row.person.personType?.value === "EXPERT") {
              els.push(`${row.completenessScore || 0}%`);
            } else {
              els.push(`--`);
            }
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: false,
          },
        },
        affiliations: {
          type: "formattedString",
          label: "Affiliations",
          format(row) {
            const els = [];
            if (row.person.personType?.value === "EXPERT") {
              row.collaborationRequests.forEach(
                (collaborationRequestProfile) => {
                  els.push(`
                  <div class="border border-porcelain-300 bg-white rounded-md my-1 w-auto">
                    <div class="text-xs bg-porcelain-300 rounded-t-sm text-white uppercase px-2">Collaboration Request</div>
                    <div class="flex">
                      <div class="text-xs px-2 py-1 w-full grow">
                        <span class="truncate block max-w-[200px]"><em>${collaborationRequestProfile.status?.label}</em> / ${collaborationRequestProfile.collaborationRequest.title}</span>
                      </div>
                      <button class="text-xs border-l border-solid border-porcelain-300 px-2 py-1 ml-2 text-porcelain-300 grow-0"><a href="/admin/collaboration-requests/edit/${collaborationRequestProfile.collaborationRequest.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>
                    </div>
                  </div>`);
                },
              );
              row.person.researchCommunityRoles.forEach((role) => {
                els.push(`
                  <div class="border border-porcelain-300 bg-white rounded-md my-1 w-auto">
                    <div class="text-xs bg-porcelain-300 rounded-t-sm text-white uppercase px-2">Research Community</div>
                    <div class="flex">
                      <div class="text-xs px-2 py-1 w-full grow">
                        <span class="truncate block max-w-[200px]"><em>${role.role.name}</em> / ${role.researchCommunity.name}</span>
                      </div>
                      <button class="text-xs border-l border-solid border-porcelain-300 px-2 py-1 ml-2 text-porcelain-300 grow-0"><a href="/admin/research-communities/edit/${role.researchCommunity.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>
                    </div>
                  </div>`);
              });
            } else if (row.person.personType?.value === "CLIENT") {
              row.person.collaborationSpaceRoles.forEach((role) => {
                els.push(`
                  <div class="border border-porcelain-300 bg-white rounded-md my-1 w-auto">
                    <div class="text-xs bg-porcelain-300 rounded-t-sm text-white uppercase px-2">Collaboration Space</div>
                    <div class="flex">
                      <div class="text-xs px-2 py-1 w-full grow">
                        <span class="truncate block max-w-[200px]"><em>${role.role.name}</em> / ${role.collaborationSpace.name}</span>
                      </div>
                      <button class="text-xs border-l border-solid border-porcelain-300 px-2 py-1 ml-2 text-porcelain-300 grow-0"><a href="/admin/collaboration-spaces/edit/${role.collaborationSpace.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>
                    </div>
                  </div>`);
              });
            } else {
              els.push("--");
            }
            row.person.workRoles.forEach((role) => {
              els.push(`
                <div class="border border-porcelain-300 bg-white rounded-md my-1 w-auto">
                  <div class="text-xs bg-porcelain-300 rounded-t-sm text-white uppercase px-2">Project</div>
                  <div class="flex">
                    <div class="text-xs px-2 py-1 w-full grow">
                      <span class="truncate block max-w-[200px]"><em>${role.role.name}</em> / ${role.work.name}</span>
                    </div>
                    <button class="text-xs border-l border-solid border-porcelain-300 px-2 py-1 ml-2 text-porcelain-300 grow-0"><a href="/admin/work/edit/${role.work.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>
                  </div>
                </div>`);
            });
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: false,
          },
        },
        timezone: {
          type: "formattedString",
          label: "Timezone",
          format(row, vm) {
            const els = [];
            if (row.person.timezone) {
              els.push(`${row.person.timezone}`);
              els.push(
                `<span class="text-xs m-0">${vm.$dayjs
                  .utc()
                  .tz(row.person.timezone)
                  .local()
                  .format("ddd, h:mm a")}</span>`,
              );
              if (row.person.timezoneConfidence?.value === "CONFIRMED") {
                els.push(
                  `<span class="text-sm text-black/50"><i class="fas fa-check-square"></i> Confirmed</span>`,
                );
              } else if (row.person.timezoneConfidence?.value === "INFERRED") {
                els.push(
                  `<span class="text-sm text-black/50"><i class="fas fa-question-circle"></i> Inferred</span>`,
                );
              }
            } else {
              els.push("Not set");
            }
            return `
              <span class="text-nowrap">
                ${els.join("<br>")}
              </span>
            `;
          },
          sort: {
            allow: false,
            default: null,
          },
          filter: {
            allow: false,
          },
        },
      },
      createForm: {
        firstName: {
          label: "First Name:",
          placeholder: "Enter first name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        lastName: {
          label: "Last Name:",
          placeholder: "Enter last name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        emailAddress: {
          label: "Email Address:",
          placeholder: "Enter email address",
          type: "string",
          value: "",
          default(row, vm) {
            return vm.$store.getters.person?.primaryEmailAddress?.emailAddress.replace(
              "@",
              `+${(Math.random() + 1).toString(36).substring(7)}@`,
            );
          },
          validatorTypes: ["required", "email"],
        },
        isTestAccount: {
          label: "Test Account:",
          checkboxLabel:
            "This is a test account (exclude from search results, etc.)",
          type: "boolean",
          value: "",
          default: true,
          validatorTypes: ["required"],
        },
        impersonate: {
          label: "Impersonate on creation:",
          checkboxLabel:
            "Yes, create and copy an impersonation link on the creation of this user.",
          type: "boolean",
          value: "",
          default: true,
          validatorTypes: ["required"],
        },
        personType: {
          label: "Person Type",
          placeholder: "Select person type",
          type: "autocomplete-select",
          default: { label: "Expert", value: "EXPERT" },
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/PersonType",
          searchOnFocus: true,
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        "person.firstName": {
          label: "First Name:",
          placeholder: "Enter first name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        "person.lastName": {
          label: "Last Name:",
          placeholder: "Enter last name",
          type: "string",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        "person.isTestAccount": {
          label: "Test Account:",
          checkboxLabel:
            "This is a test account (exclude from search results, etc.)",
          type: "boolean",
          value: "",
          default: "",
          validatorTypes: ["required"],
        },
        "person.timezoneConfidence": {
          label: "Timezone Confidence:",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/TimezoneConfidence",
          searchOnFocus: true,
        },
        researchCommunityRoles: {
          label: "Research Communities",
          type: "dataTable",
          value: "",
          qualifier(row) {
            return row.person?.personType?.value === "EXPERT";
          },
          singularLabel: "research community role",
          pluralLabel: "research community roles",
          adminPrefix: "research-communities",
          apiPrefix: "research-community-role",
          parentKey: "person.profile.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
            "researchCommunity.name": {
              type: "string",
              label: "Research Community",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            "role.name": {
              type: "string",
              label: "Role Name",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
          },
          actions: {
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `research-communities/edit/${row.researchCommunity.hashedId}`;
              },
            },
          },
        },
        collaborationSpaceRoles: {
          label: "Collaboration Spaces",
          type: "dataTable",
          value: "",
          qualifier(row) {
            return row.person?.personType?.value === "CLIENT";
          },
          singularLabel: "collaboration space role",
          pluralLabel: "collaboration space roles",
          adminPrefix: "collaboration-spaces",
          apiPrefix: "collaboration-space-role",
          parentKey: "person.profile.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
            "collaborationSpace.name": {
              type: "string",
              label: "Collaboration Space",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            "role.name": {
              type: "string",
              label: "Role Name",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
          },
          actions: {},
        },
        emails: {
          label: "Sent Emails",
          type: "dataTable",
          value: "",
          singularLabel: "email",
          pluralLabel: "emails",
          adminPrefix: "emails",
          apiPrefix: "email",
          parentKey: "person.profile.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
            template: {
              type: "formattedString",
              format(row) {
                return `<span>${row.template}</span>`;
              },
              label: "Template",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
            opens: {
              type: "string",
              label: "Opens",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            clicks: {
              type: "string",
              label: "Clicks",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
          },
          viewDetail: {
            properties: {
              type: "formattedString",
              label: "Properties",
              format(row) {
                return `
                  <span class="text-nowrap">
                    ${row.properties}
                  </span>
                `;
              },
            },
          },
          actions: {
            viewDetail: {
              allow: true,
            },
          },
        },
        emailAddresses: {
          label: "Email Addresses",
          type: "dataTable",
          value: "",
          singularLabel: "email address",
          pluralLabel: "email addressses",
          adminPrefix: "email-addresses",
          apiPrefix: "person/email-address",
          parentKey: "person.profile.hashed_id",
          columns: {
            isPrimary: {
              type: "formattedString",
              format(row, vm) {
                return vm.parentRow.person.calc_EmailAddress ===
                  row.emailAddress
                  ? "Yes"
                  : "No";
              },
              label: "Is Primary?",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            emailAddress: {
              type: "formattedString",
              format(row) {
                return row.emailAddress;
              },
              label: "Email Address",
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
              },
            },
            verifiedAt: {
              type: "relativeDate",
              label: "Verified At",
              sort: {
                allow: true,
                default: "desc",
              },
              filter: {
                allow: true,
                matchType: "dateRange",
                value: "",
                // config: https://chmln.github.io/flatpickr/options/
                config: {
                  mode: "range",
                  maxDate: "today",
                },
              },
            },
          },
          createForm: {
            personId: {
              label: "Person Hashed ID:",
              default(row) {
                return row.person.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            emailAddress: {
              label: "Email Address:",
              placeholder: "Enter email addresss",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required", "email"],
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Make Primary",
                qualifier(row, vm) {
                  return (
                    vm.parentRow.person.calc_EmailAddress !== row.emailAddress
                  );
                },
                action(event, row) {
                  const url = `person/email-address/${row.hashedId}/make-primary/`;
                  return ["apiAction", "postReload", url, {}];
                },
              },
              {
                label: "Resend Verification",
                qualifier(row) {
                  return row.verifiedAt == null;
                },
                action(event, row) {
                  const url = `person/send-verification-email/${row.hashedId}/`;
                  return [
                    "apiAction",
                    "put",
                    url,
                    { emailAddress: row.emailAddress },
                  ];
                },
              },
              {
                label: "Verify Manually",
                qualifier(row) {
                  return row.verifiedAt == null;
                },
                action(event, row) {
                  const url = `person/${row.hashedId}/verify-email/`;
                  return [
                    "apiAction",
                    "putReload",
                    url,
                    { verificationCode: row.verificationCode },
                  ];
                },
              },
              {
                label: "Delete",
                qualifier(row, vm) {
                  return (
                    vm.parentRow.person.calc_EmailAddress !== row.emailAddress
                  );
                },
                confirmFirst: true,
                confirmationMessage:
                  "Are you sure you want to remove this email address?",
                action(event, row) {
                  const url = `person/email-address/${row.hashedId}/`;
                  return [
                    "apiAction",
                    "deleteReload",
                    url,
                    { emailAddressId: row.hashedId },
                  ];
                },
              },
            ],
          },
        },
        agreements: {
          label: "Agreements",
          type: "dataTable",
          value: "",
          singularLabel: "agreement",
          pluralLabel: "agreements",
          adminPrefix: "agreements",
          apiPrefix: "person/agreement",
          parentKey: "person.profile.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            acceptedAt: {
              type: "absoluteDate",
              label: "Accepted At",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            agreement: {
              type: "formattedString",
              format(row) {
                return `<span>${row.agreementVersion.agreement.name}</span>`;
              },
              label: "Agreement Name",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            version: {
              type: "formattedString",
              format(row) {
                return `<span>${row.agreementVersion.effectiveAt}</span>`;
              },
              label: "Version Date",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
          },
          actions: {
            delete: {
              allow: true,
            },
          },
        },
        loginAttempts: {
          label: "Login Attempts",
          type: "dataTable",
          value: "",
          singularLabel: "login attempt",
          pluralLabel: "login attempts",
          adminPrefix: "login-attempts",
          apiPrefix: "person/login-attempt",
          parentKey: "person.profile.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            attemptedAt: {
              type: "absoluteDate",
              label: "Attempted At",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
            city: {
              type: "string",
              label: "City",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            region: {
              type: "string",
              label: "Region",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            country: {
              type: "string",
              label: "Country",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            result: {
              type: "formattedString",
              format(row) {
                return `<span>${row.result.label}</span>`;
              },
              label: "Result",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
          },
          actions: {},
        },
        authenticationCodes: {
          label: "Authentication Codes",
          type: "dataTable",
          value: "",
          singularLabel: "authentication code",
          pluralLabel: "authentication codes",
          adminPrefix: "authentication-codes",
          apiPrefix: "person/authentication-code",
          parentKey: "person.profile.hashed_id",
          createForm: {},
          updateForm: {},
          columns: {
            createdAt: {
              type: "absoluteDate",
              label: "Created At",
              sort: {
                allow: true,
              },
              filter: {
                allow: false,
              },
            },
            codeType: {
              type: "formattedString",
              format(row) {
                return `<span>${row.codeType.label}</span>`;
              },
              label: "Code Type",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            expiresAt: {
              type: "absoluteDate",
              label: "Expires At",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            createdBy: {
              type: "createdByWithAvatar",
              label: "Created By",
              format(row) {
                return avatarUrl(row);
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
          },
          actions: {},
        },
        physicalAddresses: {
          label: "Addresses",
          type: "dataTable",
          value: "",
          singularLabel: "address",
          pluralLabel: "addressses",
          adminPrefix: "addresses",
          apiPrefix: "person/physical-address",
          parentKey: "person.profile.hashed_id",
          columns: {
            isPrimary: {
              type: "formattedString",
              format(row, vm) {
                return vm.parentRow.physicalAddress.hashedId === row.hashedId;
              },
              label: "Is Primary?",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            country: {
              type: "formattedString",
              format(row) {
                return row.country.value;
              },
              label: "Country",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
            address: {
              type: "formattedString",
              format(row) {
                if (row.country.value === "US") {
                  let street2String = "";
                  let telephoneString = "";
                  if (row.street_2) {
                    street2String = `<br>${row.street_2}`;
                  }
                  if (row.telephone) {
                    telephoneString = `<br>${row.telephone}`;
                  }
                  return `<span>${row.street_1}${street2String}<br>${row.city}, ${row.state.label} ${row.postalCode}${telephoneString}</span>`;
                }
                return `<span></span>`;
              },
              label: "Address",
              sort: {
                allow: false,
              },
              filter: {
                allow: false,
              },
            },
          },
          createForm: {
            personId: {
              label: "Person Hashed ID:",
              default(row) {
                return row.person.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            street_1: {
              label: "Street Line 1:",
              placeholder: "Enter street address line 1",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            street_2: {
              label: "Street Line 2:",
              placeholder: "Enter street address line 2",
              type: "string",
              value: "",
              default: "",
              validatorTypes: [],
            },
            city: {
              label: "City:",
              placeholder: "Enter city",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            country: {
              label: "Country",
              placeholder: "Select country",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/Country",
              searchOnFocus: true,
            },
            state: {
              label: "State",
              placeholder: "Select state",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/State",
              searchOnFocus: true,
            },
            postalCode: {
              label: "Postal Code:",
              placeholder: "Enter postal code",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            telephone: {
              label: "Telephone:",
              placeholder: "Enter telephone",
              type: "string",
              value: "",
              default: "",
              validatorTypes: [],
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            street_1: {
              label: "Street Line 1:",
              placeholder: "Enter street address line 1",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            street_2: {
              label: "Street Line 2:",
              placeholder: "Enter street address line 2",
              type: "string",
              value: "",
              default: "",
              validatorTypes: [],
            },
            city: {
              label: "City:",
              placeholder: "Enter city",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            country: {
              label: "Country",
              placeholder: "Select country",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/Country",
              searchOnFocus: true,
            },
            state: {
              label: "State",
              placeholder: "Select state",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/State",
              searchOnFocus: true,
            },
            postalCode: {
              label: "Postal Code:",
              placeholder: "Enter postal code",
              type: "string",
              value: "",
              default: "",
              validatorTypes: ["required"],
            },
            telephone: {
              label: "Telephone:",
              placeholder: "Enter telephone",
              type: "string",
              value: "",
              default: "",
              validatorTypes: [],
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [
              {
                label: "Make Primary",
                qualifier(row, vm) {
                  return vm.parentRow.physicalAddress.hashedId !== row.hashedId;
                },
                action(event, row) {
                  const url = `person/physical-address/${row.hashedId}/make-primary/`;
                  return ["apiAction", "postReload", url, {}];
                },
              },
            ],
          },
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: true,
          confirmFirst: true,
        },
        menuItems: [
          {
            label: "Invite to Account",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `person/send-password-set-email/`;
              return [
                "apiAction",
                "put",
                url,
                { emailAddress: row.person.calc_EmailAddress },
              ];
            },
          },
          {
            label: "Send Password Reset",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `person/send-password-reset-email/`;
              return [
                "apiAction",
                "put",
                url,
                { emailAddress: row.person.calc_EmailAddress },
              ];
            },
          },
          {
            label: "View Public Profile",
            qualifier(row) {
              return row.person.personType?.value === "EXPERT";
            },
            action(event, row) {
              let url = `${import.meta.env.VITE_CV_BASE_URL}/me/${
                row.hashedId
              }`;
              if (row.slug) {
                url = `${import.meta.env.VITE_CV_BASE_URL}/me/${row.slug}`;
              }
              return ["visitUrl", "get", url];
            },
          },
          {
            label: "View Internal Profile",
            qualifier(row) {
              return row.person.personType?.value === "EXPERT";
            },
            action(event, row) {
              const self = this;
              return [
                "executeFunc",
                function _() {
                  self.vm.loadProfileDetail(row.hashedId);
                },
              ];
            },
            vm: this,
          },
          {
            label: "View on Trolley",
            qualifier(row) {
              return (
                row.person.personType?.value === "EXPERT" &&
                row.person.trolleyRecipientId
              );
            },
            action(event, row) {
              return [
                "visitUrl",
                "get",
                `https://dashboard.trolley.com/recipients/${row.person.trolleyRecipientId}`,
              ];
            },
          },
          {
            label: "Add Recipient to Trolley",
            qualifier(row) {
              return (
                row.person.personType?.value === "EXPERT" &&
                !row.person.trolleyRecipientId
              );
            },
            action(event, row) {
              const url = `person/${row.person.hashedId}/add-to-trolley/`;
              return ["apiAction", "postReload", url];
            },
          },
          {
            label: "Schedule Meeting",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `schedule-meeting?person_id=${row.person.hashedId}`;
              return ["visitUrl", "get", url];
            },
          },
          {
            label: "Confirm Timezone",
            qualifier(row) {
              return (
                row.person.timezoneConfidence?.value === "INFERRED" &&
                row.person.timezone
              );
            },
            action(event, row) {
              return [
                "apiAction",
                "postReload",
                `person/${row.person.hashedId}/confirm-timezone/`,
              ];
            },
          },
          {
            label: "Manually Send Collaboration Request Nudge",
            qualifier(row) {
              return row.person.personType?.value === "CLIENT";
            },
            action(event, row) {
              const url = `person/send-welcome-collaboration-request-email/${row.person.hashedId}/`;
              return ["apiAction", "put", url, {}];
            },
          },
          {
            label: "Manually Send Collaboration Request Reminder",
            qualifier(row) {
              return row.person.personType?.value === "CLIENT";
            },
            action(event, row) {
              const url = `person/send-welcome-collaboration-request-reminder-email/${row.person.hashedId}/`;
              return ["apiAction", "put", url, {}];
            },
          },
          {
            label: "Disable Two-Factor Auth",
            qualifier(row) {
              return row.person.isTwoFactorAuthenticationEnabled;
            },
            action(event, row) {
              const url = `person/${row.person.hashedId}/disable-two-factor-authentication/`;
              return ["apiAction", "postReload", url];
            },
          },
          {
            label: "Get Magic Link",
            qualifier() {
              return true;
            },
            action(event, row) {
              const url = `person/get-magic-link/`;
              return [
                "apiAction",
                "postCopy",
                url,
                { emailAddress: row.person.calc_EmailAddress },
              ];
            },
          },
        ],
      },
    };
  },
  methods: {
    loadProfileDetail(hashedId) {
      this.profileDetailHashedId = hashedId;
      this.toggleProfileDetailModal();
    },
    toggleProfileDetailModal() {
      this.$refs.profileDetailModal.toggleModal();
    },
  },
};
</script>
