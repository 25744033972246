<template>
  <form @submit.prevent="$emit('submit', $event.target.value)">
    <div class="flex w-full mb-10">
      <div class="w-full">
        <input
          type="text"
          placeholder="Try searching for 'thermodynamics', 'UCLA' or 'Jacksonville'"
          class="w-full form-text-input rounded-none rounded-l border-r-0"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        />
      </div>
      <input
        type="submit"
        class="button bg-chartreuseYellow px-3 rounded-r"
        value="Search"
      />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    modelValue: String,
  },
  emits: ["update:modelValue"],
};
</script>
