<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Problems
          </h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";

export default {
  name: "Problems",
  components: {
    DataTable,
  },
  data() {
    return {
      singularLabel: "problem",
      pluralLabel: "problems",
      adminPrefix: "problems",
      apiPrefix: "problem",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Problem ID",
          hidden: "yes",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">prob-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "PROB-",
          },
        },
        reportedAt: {
          type: "absoluteDate",
          label: "Reported At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        "reporter.fullName": {
          type: "formattedString",
          label: "Reporter",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-nowrap">${row.reporter.firstName} ${row.reporter.lastName}</span>`,
            );
            els.push(
              `<button class="text-xs bg-porcelain-300 px-2 py-1 ml-2 text-white rounded"><a href="/admin/profiles/edit/${row.reporter.profile.hashedId}" target="_blank"><i aria-hidden="true" class="fa-solid fa-arrow-up-right-from-square m-[2.5px]"></i></a></button>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        calc_ResolutionActionType: {
          type: "enum",
          label: "Resolution Action Type",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        url: {
          label: "URL:",
          placeholder: "Enter URL",
          type: "string",
          value: "",
          default: "",
        },
        itemType: {
          label: "Item Type:",
          placeholder: "Enter Item Type",
          type: "string",
          value: "",
          default: "",
        },
        itemId: {
          label: "Item ID:",
          placeholder: "Enter Item ID",
          type: "string",
          value: "",
          default: "",
        },
        problemType: {
          label: "Problem Type",
          placeholder: "Select problem type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/ProblemType",
          searchOnFocus: true,
        },
        problemValue: {
          label: "Problem Value:",
          placeholder: "Enter problem value",
          type: "string",
          value: "",
          default: "",
        },
        problemNote: {
          label: "Problem Note:",
          placeholder: "Enter problem note",
          type: "text",
          value: "",
          default: "",
        },
        reporter: {
          label: "Reporter",
          type: "parentDataTable",
          value: "",
          singularLabel: "reporter",
          pluralLabel: "reporters",
          apiPrefix: "person",
          childKey(row) {
            return row.reporter.hashedId;
          },
          createForm: {},
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Person ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">per-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "per-",
              },
            },
            firstName: {
              type: "string",
              label: "First Name",
              sort: {
                allow: false,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            lastName: {
              type: "string",
              label: "Last Name",
              sort: {
                allow: false,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            emailAddress: {
              type: "formattedString",
              label: "Email Address",
              format(row) {
                const els = [];
                els.push(`${row.calc_EmailAddress}`);
                if (row.primaryEmailAddress.verifiedAt) {
                  els.push(`<i class="fas fa-check-square ml-2"></i>`);
                }
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            create: {
              allow: false,
            },
            update: {
              allow: false,
            },
            linkToRecord: {
              allow: true,
              formatUrl(row) {
                return `profiles/edit/${row.profile.hashedId}`;
              },
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
        resolutionActions: {
          label: "Resolution Actions",
          type: "dataTable",
          value: "",
          singularLabel: "resolution action",
          pluralLabel: "resolution actions",
          adminPrefix: "resolution-actions",
          apiPrefix: "problem-resolution-action",
          parentKey: "problem.hashed_id",
          updateForm: {},
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Resolution Action ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">prob-res-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "prob-res-",
              },
            },
            actionType: {
              type: "enum",
              label: "Resolution Action Type",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            problemId: {
              label: "Problem Hashed ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            actor: {
              label: "Actor (Search by Last Name)",
              placeholder: "Select person",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: ["required"],
              idKey: "hashedId",
              labelKey: "lastName",
              labelFunction(row) {
                return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
              },
              autocompleteEndpoint: "person",
              searchOnFocus: false,
            },
            actionType: {
              label: "Action Type",
              placeholder: "Select action type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/ProblemResolutionActionType",
              searchOnFocus: true,
            },
            actionNote: {
              label: "Action Note:",
              placeholder: "Enter action note",
              type: "text",
              value: "",
              default: "",
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: false,
            },
            delete: {
              allow: false,
            },
            menuItems: [
              {
                label: "Get Magic Link",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const url = `person/get-magic-link/`;
                  return [
                    "apiAction",
                    "postCopy",
                    url,
                    { emailAddress: row.calc_EmailAddress },
                  ];
                },
              },
            ],
          },
        },
      },
      createForm: {
        reporter: {
          label: "Reporter (Search by Last Name)",
          placeholder: "Select person",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: ["required"],
          idKey: "hashedId",
          labelKey: "lastName",
          labelFunction(row) {
            return `${row.firstName} ${row.lastName} (${row.calc_EmailAddress}, per-${row.hashedId})`;
          },
          autocompleteEndpoint: "person",
          searchOnFocus: false,
        },
        url: {
          label: "URL:",
          placeholder: "Enter URL",
          type: "string",
          value: "",
          default: "",
        },
        itemType: {
          label: "Item Type:",
          placeholder: "Enter Item Type",
          type: "string",
          value: "",
          default: "",
        },
        itemId: {
          label: "Item ID:",
          placeholder: "Enter Item ID",
          type: "string",
          value: "",
          default: "",
        },
        problemType: {
          label: "Problem Type",
          placeholder: "Select problem type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/ProblemType",
          searchOnFocus: true,
        },
        problemValue: {
          label: "Problem Value:",
          placeholder: "Enter problem value",
          type: "string",
          value: "",
          default: "",
        },
        problemNote: {
          label: "Problem Note:",
          placeholder: "Enter problem note",
          type: "text",
          value: "",
          default: "",
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: true,
        },
        menuItems: [],
      },
    };
  },
};
</script>
