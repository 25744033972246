<template>
  <div class="flex items-start justify-between px-3 py-2 mb-2">
    <div v-if="Object.keys(collaborationRequest).length > 0" class="w-full">
      <label class="flex mr-3">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
        >
          Name
        </span>
        <div class="w-full md:w-5/6">
          <textarea
            v-model="collaborationRequest.title"
            class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
            rows="2"
          ></textarea>
          <p class="form-help">
            If you adjust the callaboration request title, you can regenerate
            the description and experts.
          </p>
        </div>
      </label>
      <hr class="border-black/20 mb-2" />
      <div class="flex">
        <div class="w-full">
          <label class="flex mr-3">
            <span
              class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
              >Description</span
            >
            <div class="w-full md:w-5/6">
              <textarea
                v-model="collaborationRequest.description"
                class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
                rows="10"
              ></textarea>
            </div>
          </label>
          <label
            v-if="collaborationRequest.researchInterests"
            class="flex mr-3"
          >
            <span
              class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
              >Candidates</span
            >
            <div class="w-full md:w-5/6 border border-black/10 rounded-lg p-2">
              <demo-candidate
                v-for="(
                  candidate, candidateIndex
                ) in collaborationRequest.candidates"
                :key="candidateIndex"
                :active-requests="activeRequests"
                :index="index"
                :research-interests="collaborationRequest.researchInterests"
                :candidate="candidate"
              />
            </div>
          </label>
        </div>
        <div class="text-right grow">
          <button @click="regenerateFromTitle()">
            <i class="fa-solid fa-rotate" :class="{ 'fa-spin': loading }"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import DemoCandidate from "./Candidate.vue";

const { keysToCamel, askQuestion, pluckEnum } = utils;

export default {
  components: {
    DemoCandidate,
  },
  props: {
    enums: Object,
    index: Number,
    activeRequests: Array,
    companySize: String,
    companyStage: String,
    industry: String,
    jobFunction: String,
    collaborationRequest: Object,
  },
  data() {
    return {
      loading: false,
      extraTopicPrompts: [
        "artificial intelligence",
        "remote work",
        "digital transformation",
        "blockchain",
        "mergers",
        "acquisitions",
      ],
    };
  },
  mounted() {
    this.fillInTheGaps();
  },
  methods: {
    regenerateFromTitle() {
      delete this.collaborationRequest.description;
      delete this.collaborationRequest.researchInterests;
      delete this.collaborationRequest.candidates;
      this.fillInTheGaps();
    },
    fillInTheGaps() {
      if (Object.keys(this.collaborationRequest).indexOf("prompt") === -1) {
        this.generatePrompt();
      } else if (
        Object.keys(this.collaborationRequest).indexOf("title") === -1
      ) {
        this.generateTitle();
      } else {
        if (
          Object.keys(this.collaborationRequest).indexOf("description") === -1
        ) {
          this.generateDescription();
        }
        if (
          Object.keys(this.collaborationRequest).indexOf(
            "researchInterests",
          ) === -1
        ) {
          this.generateResearchInterests();
        }
      }
      if (Object.keys(this.collaborationRequest).indexOf("status") === -1) {
        this.collaborationRequest.status = "CLIENT_REVIEW";
      }
    },
    generatePrompt() {
      const prompt = this.extraTopicPrompts.random();
      const jobFunctionLabel = pluckEnum(
        this.enums.jobFunctionOptions,
        this.jobFunction,
      ).label;
      const companySizeLabel = pluckEnum(
        this.enums.companySizeOptions,
        this.companySize,
      ).label;
      const industryLabel = pluckEnum(
        this.enums.industryOptions,
        this.industry,
      ).label;
      this.collaborationRequest.prompt = `What is a possible title of one ${prompt} project on which the ${jobFunctionLabel} team at a ${companySizeLabel} company in the ${industryLabel} industry might benefit from research done by a professor or an academic researcher?`;
      this.generateTitle();
    },
    generateTitle() {
      askQuestion(
        this,
        "Generating collaboration request title...",
        this.collaborationRequest.prompt,
      ).then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationRequest.title = data.answer
          .replaceAll(".", "")
          .replaceAll('"', "")
          .trim();
        this.generateDescription();
        this.generateResearchInterests();
      });
    },
    generateDescription() {
      askQuestion(
        this,
        "Generating collaboration request description...",
        `Write a description for a project called "${this.collaborationRequest.title}" from the perspective of the person looking for academic research to advance the project.`,
      ).then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationRequest.description = data.answer.trim();
      });
    },
    generateResearchInterests() {
      askQuestion(
        this,
        "Generating collaboration request research areas...",
        `What are 5 key areas of understanding to make someone knowledgeable about "${this.collaborationRequest.title}"? Please provide a bulleted list of university subjects with a paranthetical list of the world's best universities for each subject separated by semicolons.`,
      ).then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationRequest.researchInterests = data.answer
          .trim()
          .replaceAll("\n\n", "\n")
          .split("\n")
          .map((interest) => {
            const contents = interest.split(" (");
            if (contents[0] === "-") {
              contents[0] = contents[0].replace("-", "").trim();
            }
            if (contents.length === 1) {
              contents[1] =
                "University of Washington; University of Hawaii; University of Arizona";
            }
            return {
              interest: contents[0]
                .replaceAll("•", "")
                .replaceAll(/^\d+\s*[-\\.)]?\s+/gi, "")
                .trim(),
              universities: contents[1].replaceAll(")", "").split("; "),
            };
          });

        this.collaborationRequest.candidates = [{}, {}, {}, {}];
      });
    },
  },
};
</script>
