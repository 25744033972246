<template>
  <div class="flex flex-wrap">
    <div class="w-full mx-10">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mt-3 mb-6 shadow-lg rounded-lg bg-white px-6 pb-6"
      >
        <div class="mt-10 mb-3 uppercase text-sm font-bold text-blueGray-500">
          <router-link to="/admin/collaboration-requests">
            Collaboration Requests
          </router-link>
          <span class="inline-block mx-3"> > </span>
          <router-link
            :to="`/admin/collaboration-requests/detail/${collaborationRequest.hashedId}`"
          >
            {{ collaborationRequest?.title }} ({{
              collaborationRequest?.collaborationSpace?.name
            }})
          </router-link>
        </div>

        <h4 class="text-3xl font-light mt-6">Collaboration Request Detail</h4>

        <div class="my-5">
          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Collaboration Space
          </h6>
          <p>{{ collaborationRequest.collaborationSpace?.name }}</p>

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Anticipated Start Date
          </h6>
          {{ collaborationRequest?.engagementTimeline?.label }}

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Anticipated Duration
          </h6>
          {{ collaborationRequest?.engagementDuration?.label }}

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Delviverable Type
          </h6>
          {{ collaborationRequest?.deliverableType?.label }}

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Status</h6>
          {{ collaborationRequest?.status?.label }}

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Credit Budget
          </h6>
          {{ collaborationRequest?.creditBudget }}

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">
            Internal Notes
          </h6>
          <markdown-viewer
            v-if="collaborationRequest.internalNotes"
            :text="collaborationRequest.internalNotes"
          />

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Description</h6>
          <markdown-viewer
            v-if="collaborationRequest.description"
            :text="collaborationRequest.description"
          />

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Anonymized</h6>
          <span v-if="collaborationRequest.isAnonymized">
            This request will <strong>hide</strong> the names from the client.
          </span>
          <span v-else>
            This request will <strong>show</strong> the names to the client.
          </span>

          <h6 class="uppercase text-sm mt-5 text-blueGray-500">Profiles</h6>
          <div
            v-for="(
              collaborationRequestProfile, index
            ) in collaborationRequest.profiles"
            :key="index"
          >
            <div class="border py-3 px-5 mb-3">
              <div class="flex justify-between">
                <div>
                  <h6 class="uppercase text-sm text-blueGray-500">Status</h6>
                  {{ collaborationRequestProfile.status?.label }}
                </div>
                <div>
                  <div class="flex">
                    <div
                      class="flex flex-col items-center text-mallard-500/50 mx-2"
                    >
                      <i
                        class="fa-solid fa-caret-up cursor-pointer"
                        @click="moveUp(collaborationRequestProfile)"
                      ></i>
                      {{ collaborationRequestProfile.orderIndex }}
                      <i
                        class="fa-solid fa-caret-down cursor-pointer"
                        @click="moveDown(collaborationRequestProfile)"
                      ></i>
                    </div>
                    <button
                      class="text-mallard-500/50 text-xs ml-1 px-1"
                      type="button"
                      @click="toggleDeleteModal(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                    <button
                      class="text-mallard-500/50 text-xs ml-1 px-1"
                      type="button"
                      @click="toggleEditModal(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                </div>
              </div>

              <h6 class="uppercase text-sm mt-5 text-blueGray-500">Profile</h6>

              <profile-card-small
                :shared-notes="collaborationRequestProfile.sharedNotes"
                :client-notes="collaborationRequestProfile.clientNotes"
                :show-client-notes="true"
                :collaboration-request-id="collaborationRequest.hashedId"
                :collaboration-request-profile-id="
                  collaborationRequestProfile.hashedId
                "
                :profile="collaborationRequestProfile.profile"
                @load-profile-detail="
                  setProfileUrl(collaborationRequestProfile.profile.hashedId)
                "
              />

              <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                Internal (NotedSource) Notes
              </h6>
              <markdown-viewer
                :text="
                  collaborationRequestProfile.internalNotes || '<em>None</em>'
                "
              />

              <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                Shared (Client-Facing) Notes
              </h6>
              <markdown-viewer
                :text="
                  collaborationRequestProfile.sharedNotes || '<em>None</em>'
                "
              />

              <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                Client Notes
              </h6>
              <markdown-viewer
                :text="
                  collaborationRequestProfile.clientNotes || '<em>None</em>'
                "
              />

              <h6 class="uppercase text-sm mt-5 text-blueGray-500">Files</h6>
              <div class="flex flex-wrap mt-2">
                <div
                  v-for="file in collaborationRequestProfile.files"
                  :key="file.hashedId"
                >
                  <div class="mr-4">
                    <a :href="fileUrl(file.file)" target="_blank">
                      <img
                        class="max-w-[150px] max-h-[200px]"
                        :src="previewUrl(file.file)"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <right-side-modal
              :id="`edit-modal-${index}`"
              :ref="`editModal${index}`"
            >
              <template #header>
                <h3 class="text-xl block-title">
                  Edit Collaboration Request Profile
                </h3>
              </template>
              <template #body>
                <div class="block block-themed block-transparent mb-0">
                  <div class="block-content font-size-sm px-5">
                    <div>
                      <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                        Profile
                      </h6>
                    </div>
                    <div>
                      {{ collaborationRequestProfile.profile.fullDisplayName
                      }}<br />
                      <a
                        class="text-sm text-blue-500"
                        :href="
                          publicProfileUrl(
                            collaborationRequestProfile.profile.hashedId,
                          )
                        "
                        target="_blank"
                        >Public Profile
                        <i
                          class="text-sm fa-solid fa-arrow-up-right-from-square"
                        ></i
                      ></a>
                    </div>
                    <div>
                      <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                        Status
                      </h6>
                    </div>
                    <div>
                      <vue-select
                        v-if="enums.collaborationRequestProfileStatusOptions"
                        v-model="editProfileForms[index].status"
                        class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-blue-500 w-full ease-linear transition-all duration-150 w-full"
                        label-by="label"
                        value-by="value"
                        :close-on-select="true"
                        :searchable="true"
                        :clear-on-close="true"
                        :clear-on-select="true"
                        :options="[
                          { label: 'Select...', value: '' },
                          ...enums.collaborationRequestProfileStatusOptions,
                        ]"
                        placeholder="Select..."
                      />
                    </div>
                    <div>
                      <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                        Internal (NotedSource) Notes
                      </h6>
                      <markdown-editor
                        v-model="editProfileForms[index].internalNotes"
                        :height="`200px`"
                        class="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                    <div>
                      <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                        Shared (Client-Facing) Notes
                      </h6>
                      <markdown-editor
                        v-model="editProfileForms[index].sharedNotes"
                        :height="`200px`"
                        class="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                    <div>
                      <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                        Client Notes
                      </h6>
                      <markdown-viewer
                        :text="
                          editProfileForms[index].clientNotes || '<em>None</em>'
                        "
                      />
                    </div>
                    <div>
                      <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                        Shared (Client-Facing) File(s)
                      </h6>
                      <p class="my-2">
                        <em class="text-sm text-blueGray-400 leading-4">
                          Note: These files are not shared with the expert.
                        </em>
                      </p>
                      <dropzone
                        id="collaboration-request-profile-file-input"
                        :max-files="10"
                        :files="editProfileForms[index].files"
                        @uploaded="
                          addUploadedFiles(
                            editProfileForms[index].files,
                            $event.files,
                          )
                        "
                        @removeFile="
                          removeUploadedFile(
                            editProfileForms[index].files,
                            $event,
                          )
                        "
                      ></dropzone>
                    </div>
                  </div>
                </div>
              </template>
              <template #footer>
                <div class="text-right border-top">
                  <button
                    type="button"
                    class="text-black bg-transparent hover:bg-porcelain-200 active:bg-porcelain-300 uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mr-2"
                    @click="toggleEditModal(index)"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="text-black bg-transparent border border-solid border-black-500 hover:shadow-md hover:bg-chartreuseYellow active:bg-chartreuseYellow-600 font-bold uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    @click="editProfile(index)"
                  >
                    Submit
                  </button>
                </div>
              </template>
            </right-side-modal>
            <regular-modal :ref="`deleteModal${index}`" :showing="false">
              <template #header>
                <h4 class="text-xl font-light">
                  Delete Profile from Collaboration Request
                </h4>
              </template>
              <template #body>
                <div
                  class="w-full max-h-[calc(100vh-200px)] overflow-scroll px-6 py-3"
                >
                  <p>
                    Are you sure you want to delete this profile from the
                    collaboration request? This will remove the connection
                    entirely. You can also move the candidate to the status
                    "Disqualified" to preserve the Internal and Client Notes.
                  </p>
                </div>
              </template>
              <template #footer>
                <button
                  class="modal-nonprimary-button"
                  type="button"
                  @click="toggleDeleteModal(index)"
                >
                  Cancel
                </button>
                <button
                  class="modal-primary-button"
                  type="button"
                  @click="deleteProfile(index)"
                >
                  <span v-if="!formSubmitting">Yes</span>
                  <span v-else
                    ><i class="fas fa-circle-notch fa-spin"></i
                  ></span>
                </button>
              </template>
            </regular-modal>
          </div>
        </div>

        <button
          class="bg-blueGray-200 rounded-lg px-3 py-1 text-blueGray-500 hover:bg-blueGray-300"
          type="button"
          @click="toggleCreateModal(`addProfileModal`)"
        >
          <i class="fa fa-sm fa-plus"></i>
          <slot name="button">Add Profile</slot>
        </button>

        <right-side-modal
          id="add-profile-modal"
          ref="addProfileModal"
          @hide="setProfileUrl()"
        >
          <template #header>
            <h3 class="text-xl block-title">Add Profile</h3>
          </template>
          <template #body>
            <div class="block block-themed block-transparent mb-0">
              <div class="block-content font-size-sm px-5">
                <div>
                  <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                    Profile
                  </h6>
                </div>
                <div>
                  <autocomplete-select
                    v-model="addProfileForm.profile"
                    placeholder="Expert Name"
                    id-key="profile.hashedId"
                    label-key="fullName"
                    :label-function="
                      function (expert) {
                        return `${expert.firstName} ${expert.lastName} (${expert.calc_EmailAddress}, per-${expert.hashedId})`;
                      }
                    "
                    :filters="{ personType: 'EXPERT' }"
                    autocomplete-endpoint="person"
                    :initial-options="[]"
                    :search-on-focus="true"
                    :multiple="false"
                    :row="{}"
                    @input="selectProfile($event)"
                  />
                </div>
                <div>
                  <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                    Candidate Status
                  </h6>
                </div>
                <p class="my-2">
                  <em class="text-sm text-blueGray-400 leading-4"
                    >Note: The only two candidate statuses visible to the client
                    are "Broad Pool" and "Shortlist". Candidates will only be
                    visible to the client when the Collaboration Request itself
                    is in the status "Client Review" or "Completed". All other
                    Collaboration Request statuses will hide all candidates.</em
                  >
                </p>
                <div>
                  <vue-select
                    v-if="enums.collaborationRequestProfileStatusOptions"
                    v-model="addProfileForm.status"
                    class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring focus:ring-blue-500 w-full ease-linear transition-all duration-150 w-full"
                    label-by="label"
                    value-by="value"
                    :close-on-select="true"
                    :searchable="true"
                    :clear-on-close="true"
                    :clear-on-select="true"
                    :options="[
                      { label: 'Select...', value: '' },
                      ...enums.collaborationRequestProfileStatusOptions,
                    ]"
                    placeholder="Select..."
                  />
                </div>
                <div>
                  <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                    Internal (NotedSource) Notes
                  </h6>
                  <p class="my-2">
                    <em class="text-sm text-blueGray-400 leading-4"
                      >Note: These notes are only for NotedSource usage in
                      sharing information related to this candidate and their
                      fit for this Collaboration Request. These notes will not
                      be shared with the client or the expert.</em
                    >
                  </p>
                  <markdown-editor
                    v-model="addProfileForm.internalNotes"
                    :height="`200px`"
                    class="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                <div>
                  <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                    Shared (Client-Facing) Notes
                  </h6>
                  <p class="my-2">
                    <em class="text-sm text-blueGray-400 leading-4"
                      >Note: These notes should explain to the client why this
                      expert is a good fit for this Collaboration Request. You
                      can summarize parts of the candidate profile or
                      editorialize. These notes are not shared with the
                      expert.</em
                    >
                  </p>
                  <markdown-editor
                    ref="addProfileFormSharedNotes"
                    v-model="addProfileForm.sharedNotes"
                    :height="`200px`"
                    class="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                <div>
                  <h6 class="uppercase text-sm mt-5 text-blueGray-500">
                    Shared (Client-Facing) File(s)
                  </h6>
                  <p class="my-2">
                    <em class="text-sm text-blueGray-400 leading-4">
                      Note: These files are not shared with the expert.
                    </em>
                  </p>
                  <dropzone
                    id="collaboration-request-profile-file-input"
                    :max-files="10"
                    :files="addProfileForm.files"
                    @uploaded="
                      addUploadedFiles(addProfileForm.files, $event.files)
                    "
                    @removeFile="
                      removeUploadedFile(addProfileForm.files, $event)
                    "
                  ></dropzone>
                </div>
                <p
                  v-if="addProfileError"
                  class="text-red-500 text-sm mx-6 my-6"
                >
                  {{ addProfileError }}
                </p>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="text-right border-top">
              <button
                type="button"
                class="text-black bg-transparent hover:bg-porcelain-200 active:bg-porcelain-300 uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mr-2"
                @click="toggleCreateModal(`addProfileModal`)"
              >
                Cancel
              </button>
              <button
                type="button"
                class="text-black bg-transparent border border-solid border-black-500 hover:shadow-md hover:bg-chartreuseYellow active:bg-chartreuseYellow-600 font-bold uppercase text-sm px-6 py-2 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                @click="addProfile()"
              >
                Submit
              </button>
            </div>
          </template>
        </right-side-modal>
      </div>
    </div>
    <right-side-modal ref="profileDetailModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">Profile Detail</h4>
      </template>
      <template #body>
        <profile-detail-modal
          v-if="profileDetailHashedId"
          :hashed-id="profileDetailHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import MarkdownViewer from "../../components/MarkdownViewer.vue";
import RightSideModal from "../../components/Modals/RightSide.vue";
import ProfileCardSmall from "../../components/Profile/ProfileCardSmall.vue";
import ProfileDetailModal from "../../components/Profile/ProfileDetailModal.vue";
import RegularModal from "../../components/Modals/Regular.vue";
import AutocompleteSelect from "../../components/AutocompleteSelect.vue";
import MarkdownEditor from "../../components/MarkdownEditor.vue";
import Dropzone from "../../components/Dropzone.vue";

const { keysToCamel, keysToSnake, loadEnums } = utils;

export default {
  name: "CollaborationRequestDetail",
  components: {
    MarkdownViewer,
    RightSideModal,
    ProfileCardSmall,
    ProfileDetailModal,
    RegularModal,
    AutocompleteSelect,
    MarkdownEditor,
    Dropzone,
  },
  data() {
    return {
      enums: {},
      loadingCollaborationRequests: true,
      hashedId: this.$route.params.hashedId,
      collaborationRequest: {},
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      enumPath: `${import.meta.env.VITE_API_BASE_URL}/enum`,
      addProfileForm: {
        profile: { id: null, label: null },
        profileDetail: {},
        sharedNotes: null,
        internalNotes: null,
        status: null,
        files: [],
      },
      addProfileError: null,
      profileDetailHashedId: this.$route.params.profileHashedId,
      editProfileForms: [],
      baseUrl: `${
        import.meta.env.VITE_ADMIN_BASE_URL
      }/admin/collaboration-requests/detail/${this.$route.params.hashedId}`,
    };
  },
  created() {
    this.loadCollaborationRequest();
  },
  mounted() {
    loadEnums(this, {
      CollaborationRequestStatus: "collaborationRequestStatusOptions",
      CollaborationRequestProfileStatus:
        "collaborationRequestProfileStatusOptions",
    });
    if (this.profileDetailHashedId) {
      this.loadProfileDetail(this.profileDetailHashedId);
    }
  },
  methods: {
    loadCollaborationRequest() {
      this.loadingCollaborationRequests = true;
      this.$api.axios.get(`/work/request/${this.hashedId}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationRequest = data;
        this.initializeEditForms();
        this.loadingCollaborationRequests = false;
      });
    },
    initializeCreateForm() {
      this.addProfileForm = {
        profile: { id: null, label: null },
        internalNotes: null,
        sharedNotes: null,
        status: null,
        files: [],
      };
    },
    initializeEditForms() {
      this.editProfileForms = [];
      if (this.collaborationRequest.profiles) {
        this.collaborationRequest.profiles.forEach((profile) => {
          const files = [];
          profile.files.forEach((file) => {
            files.push(file.file);
          });
          this.editProfileForms.push({
            internalNotes: profile.internalNotes,
            sharedNotes: profile.sharedNotes,
            clientNotes: profile.clientNotes,
            status: profile.status.value,
            files,
          });
        });
      }
    },
    selectProfile(event) {
      this.addProfileForm.profile = event;
      this.$api.axios.get(`/person/profile/${event.id}/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.addProfileForm.sharedNotes = "";
        if (data.result.educations.length > 0) {
          this.addProfileForm.sharedNotes = `${this.addProfileForm.sharedNotes}<ul>`;
          const educations = [...data.result.educations].sort((a, b) =>
            a.graduationYear < b.graduationYear ? 1 : -1,
          );
          educations.forEach((education) => {
            let eduString = "";
            if (
              education.degree &&
              education.specialization &&
              education.schoolName
            ) {
              eduString = `${education.degree} in ${education.specialization}, ${education.schoolName}`;
            } else if (education.degree && education.schoolName) {
              eduString = `${education.degree}, ${education.schoolName}`;
            } else if (education.degree && education.specialization) {
              eduString = `${education.degree} in ${education.specialization}`;
            } else if (education.specialization) {
              eduString = `Degree in ${education.specialization}`;
            }
            if (eduString) {
              this.addProfileForm.sharedNotes = `${this.addProfileForm.sharedNotes}<li>${eduString}</li>`;
            }
          });
          this.addProfileForm.sharedNotes = `${this.addProfileForm.sharedNotes}</ul>`;
        }
        if (data.result.about) {
          this.addProfileForm.sharedNotes += data.result.about;
        }
        if (this.addProfileForm.sharedNotes) {
          this.$refs.addProfileFormSharedNotes.setHTML(
            this.addProfileForm.sharedNotes,
          );
        }
      });
    },
    addProfile() {
      this.loadingCollaborationRequests = true;
      this.addProfileError = null;
      if (!this.addProfileForm.profile.id || !this.addProfileForm.status) {
        this.addProfileError =
          "Please select a profile and a status to add a profile to this Collaboration Request.";
      } else {
        this.$api.axios
          .post(
            `/work/request/${this.hashedId}/profile/`,
            keysToSnake({
              profileId: this.addProfileForm.profile.id,
              internalNotes: this.addProfileForm.internalNotes,
              sharedNotes: this.addProfileForm.sharedNotes,
              status: this.addProfileForm.status,
              files: this.addProfileForm.files,
            }),
          )
          .then(() => {
            this.loadCollaborationRequest();
            this.initializeCreateForm();
            this.toggleCreateModal(`addProfileModal`);
          });
      }
    },
    editProfile(index) {
      this.loadingCollaborationRequests = true;
      this.$api.axios
        .put(
          `/work/request/${this.hashedId}/profile/${this.collaborationRequest.profiles[index].hashedId}/`,
          keysToSnake({
            internalNotes: this.editProfileForms[index].internalNotes,
            sharedNotes: this.editProfileForms[index].sharedNotes,
            status: this.editProfileForms[index].status,
            files: this.editProfileForms[index].files,
          }),
        )
        .then(() => {
          this.loadCollaborationRequest();
          this.toggleEditModal(index);
        });
    },
    deleteProfile(index) {
      this.loadingCollaborationRequests = true;
      this.$api.axios
        .delete(
          `/work/request/${this.hashedId}/profile/${this.collaborationRequest.profiles[index].hashedId}/`,
        )
        .then(() => {
          this.loadCollaborationRequest();
          this.toggleDeleteModal(index);
        });
    },
    moveUp(collaborationRequestProfile) {
      this.loadingCollaborationRequests = true;
      this.$api.axios
        .put(
          `/work/request/${this.hashedId}/profile/${collaborationRequestProfile.hashedId}/`,
          keysToSnake({
            orderIndex: (collaborationRequestProfile.orderIndex || 0) + 1,
          }),
        )
        .then(() => {
          this.loadCollaborationRequest();
        });
    },
    moveDown(collaborationRequestProfile) {
      this.loadingCollaborationRequests = true;
      this.$api.axios
        .put(
          `/work/request/${this.hashedId}/profile/${collaborationRequestProfile.hashedId}/`,
          keysToSnake({
            orderIndex: (collaborationRequestProfile.orderIndex || 0) - 1,
          }),
        )
        .then(() => {
          this.loadCollaborationRequest();
        });
    },
    setProfileUrl(hashedId) {
      if (hashedId) {
        const url = `${this.baseUrl}/profile/${hashedId}`;
        window.history.pushState({}, null, url);
        this.loadProfileDetail(hashedId);
      } else {
        const url = this.baseUrl;
        window.history.pushState({}, null, url);
      }
    },
    loadProfileDetail(hashedId) {
      this.profileDetailHashedId = hashedId;
      this.toggleProfileDetailModal();
    },
    toggleProfileDetailModal() {
      this.$refs.profileDetailModal.toggleModal();
    },
    toggleCreateModal(modalName) {
      let ref = this.$refs[modalName];
      if (Array.isArray(ref)) {
        ref = ref[0]; // eslint-disable-line
      }
      ref.toggleModal();
    },
    toggleEditModal(index) {
      let ref = this.$refs[`editModal${index}`];
      if (Array.isArray(ref)) {
        ref = ref[0]; // eslint-disable-line
      }
      ref.toggleModal();
    },
    toggleDeleteModal(index) {
      let ref = this.$refs[`deleteModal${index}`];
      if (Array.isArray(ref)) {
        ref = ref[0]; // eslint-disable-line
      }
      ref.toggleModal();
    },
    publicProfileUrl(hashedId) {
      return `${import.meta.env.VITE_CV_BASE_URL}/me/${hashedId}`;
    },
    addUploadedFiles(fileArray, files) {
      files.forEach((file) => {
        fileArray.push(file);
      });
    },
    removeUploadedFile(fileArray, index) {
      fileArray.splice(index, 1);
    },
    fileUrl(file) {
      return `${import.meta.env.VITE_API_BASE_URL}/file/${
        file.id || file.hashedId
      }/content/`;
    },
    previewUrl(file) {
      if (
        file.type === "application/pdf" ||
        file.contentType === "application/pdf"
      ) {
        return `${import.meta.env.VITE_API_BASE_URL}/file/${
          file.id || file.hashedId
        }/content/pdf-preview/`;
      }
      return `${import.meta.env.VITE_API_BASE_URL}/file/${
        file.id || file.hashedId
      }/content/`;
    },
  },
};
</script>
