<template>
  <div>
    <a
      ref="btnDropdownRef"
      class="text-blueGray-500 block"
      href="#"
      @click="toggleDropdown"
    >
      <div class="items-center flex">
        <profile-image :size="40" :img-url="imageUrl" />
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      :class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        to="/admin/settings"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Settings
      </router-link>
      <router-link
        to="/auth/logout"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Logout
      </router-link>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { utils } from "common-frontend";
import ProfileImage from "../ProfileImage.vue";

const { avatarUrl } = utils;

export default {
  components: {
    ProfileImage,
  },
  data() {
    return {
      dropdownPopoverShow: false,
      popper: null,
      avatarUrl,
    };
  },
  computed: {
    imageUrl() {
      const profile = this.$store.getters.person?.profile;
      if (profile && profile.imageHash) {
        return `${import.meta.env.VITE_API_BASE_URL}/file/${
          profile.imageHash
        }/content/`;
      }
      return "";
    },
  },
  methods: {
    toggleDropdown(event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        if (!this.popper) {
          this.popper = createPopper(
            this.$refs.btnDropdownRef,
            this.$refs.popoverDropdownRef,
            {
              placement: "bottom-start",
            },
          );
        }
      }
    },
  },
};
</script>
