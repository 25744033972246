<template>
  <div class="flex items-center">
    <div class="form-label mr-3">Show</div>
    <select
      name="resultsPerPage"
      class="form-select-input"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option :value="20">20</option>
      <option :value="40">40</option>
      <option :value="60">60</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
  },
  emits: ["update:modelValue"],
};
</script>
