<template>
  <div class="flex flex-wrap h-screen">
    <div class="w-full h-full">
      <div ref="mapContainer" class="map-container h-full"></div>
    </div>
  </div>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_KEY;

export default {
  name: "AdminMap",
  components: {},
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/mapbox/light-v11", // Replace with your preferred map style
      center: [-90.1994, 38.627],
      zoom: 4,
    });

    map.on("load", () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.loadImage("/apple-touch-icon.png", (error, image) => {
        if (error) throw error;

        // Add the image to the map style.
        map.addImage("asterisk", image);

        map.addSource("institutions", {
          type: "geojson",
          data: `${import.meta.env.VITE_API_BASE_URL}/map-data/`,
          cluster: true,
          clusterMaxZoom: 12, // Max zoom to cluster points on
          clusterRadius: 80, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "institutions",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#77a7f9",
              50,
              "#5785f7",
              100,
              "#2856e8",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              50,
              30,
              100,
              40,
            ],
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "institutions",
          filter: ["has", "point_count"],
          layout: {
            "text-field": ["get", "point_count_abbreviated"],
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": "#FFFFFF",
          },
        });

        map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "institutions",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "asterisk",
            "icon-size": 0.25,
          },
        });
      });

      // inspect a cluster on click
      map.on("click", "clusters", (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map
          .getSource("institutions")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          });
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      map.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<h3>${e.features[0].properties.title}</h3>
             <p class="italic">${e.features[0].properties.location}</p>
             ${e.features[0].properties.count_html}`,
          )
          .addTo(map);
      });

      map.on("mouseenter", "clusters", () => {
        map.getCanvas().style.cursor = "pointer";
      });
      map.on("mouseleave", "clusters", () => {
        map.getCanvas().style.cursor = "";
      });
    });
    this.map = map;
  },
  unmounted() {
    this.map.remove();
    this.map = null;
  },
};
</script>

<style lang="scss">
.map-container {
  flex: 1;
}
.marker {
  background-image: url("/apple-touch-icon.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #2856e8;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #2856e8;
}
.mapboxgl-popup-content {
  border-radius: 12px;
  font-family: "Open Sans", sans-serif;
  background-color: #2856e8;
  color: white;
  padding: 15px;

  h3 {
    font-size: 22px;
    line-height: 28px;
    padding-bottom: 10px;
  }

  p.italic {
    font-family: "Lora", serif;
    font-style: italic;
    margin-bottom: 5px;
  }
}
.mapboxgl-popup-close-button {
  padding: 0px 5px;
}
</style>
