<template>
  <div class="inline-flex">
    <a
      ref="btnRef"
      class="text-blueGray-500/50 block cursor-pointer"
      @mouseover="showPopper"
      @mouseleave="hidePopper()"
    >
      <slot>
        <i class="fa-solid fa-circle-question mx-2"></i>
      </slot>
    </a>
    <div
      ref="popoverRef"
      class="bg-black/90 text-sm text-white absolute z-50 py-2 px-4 list-none text-left rounded-lg shadow-lg min-w-48"
      :class="{
        hidden: !popoverShow,
        block: popoverShow,
      }"
    >
      <p class="font-normal normal-case" v-html="text"></p>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  props: {
    text: String,
  },
  data() {
    return {
      popoverShow: false,
      popper: null,
    };
  },
  methods: {
    showPopper() {
      this.popoverShow = true;
      if (!this.popper) {
        this.popper = createPopper(this.$refs.btnRef, this.$refs.popoverRef, {
          placement: "right",
        });
      }
    },
    hidePopper() {
      this.popper.destroy();
      this.popper = null;
      this.popoverShow = false;
    },
  },
};
</script>
