<template>
  <div class="flex flex-wrap">
    <div class="px-4 w-full">
      <CollaborationInquiries v-if="itemType0 == 'collaboration-inquiries'" />
      <CollaborationRequests v-if="itemType0 == 'collaboration-requests'" />
      <CollaborationSpaces v-if="itemType0 == 'collaboration-spaces'" />
      <ContractPackages v-if="itemType0 == 'contract-packages'" />
      <Contracts v-if="itemType0 == 'contracts'" />
      <ContractTemplates v-if="itemType0 == 'contract-templates'" />
      <ContractTypes v-if="itemType0 == 'contract-types'" />
      <ExpertDashboard v-if="itemType0 == 'expert-dashboard'" />
      <FeaturePops v-if="itemType0 == 'feature-pops'" />
      <Firms v-if="itemType0 == 'firms'" />
      <Invoices v-if="itemType0 == 'invoices'" />
      <Meetings v-if="itemType0 == 'meetings'" />
      <Organizations v-if="itemType0 == 'organizations'" />
      <Problems v-if="itemType0 == 'problems'" />
      <Profiles v-if="itemType0 == 'profiles'" />
      <ResearchCommunities v-if="itemType0 == 'research-communities'" />
      <Roles v-if="itemType0 == 'roles'" />
      <RssFeedPublishers v-if="itemType0 == 'rss-feed-publishers'" />
      <Sites v-if="itemType0 == 'sites'" />
      <SiteThemes v-if="itemType0 == 'site-themes'" />
      <Work v-if="itemType0 == 'work'" />
    </div>
  </div>
</template>
<script>
import CollaborationInquiries from "../../components/Admin/CollaborationInquiries.vue";
import CollaborationRequests from "../../components/Admin/CollaborationRequests.vue";
import CollaborationSpaces from "../../components/Admin/CollaborationSpaces.vue";
import ContractPackages from "../../components/Admin/ContractPackages.vue";
import Contracts from "../../components/Admin/Contracts.vue";
import ContractTemplates from "../../components/Admin/ContractTemplates.vue";
import ContractTypes from "../../components/Admin/ContractTypes.vue";
import ExpertDashboard from "../../components/Admin/ExpertDashboard.vue";
import FeaturePops from "../../components/Admin/FeaturePops.vue";
import Firms from "../../components/Admin/Firms.vue";
import Invoices from "../../components/Admin/Invoices.vue";
import Meetings from "../../components/Admin/Meetings.vue";
import Organizations from "../../components/Admin/Organizations.vue";
import Problems from "../../components/Admin/Problems.vue";
import Profiles from "../../components/Admin/Profiles.vue";
import ResearchCommunities from "../../components/Admin/ResearchCommunities.vue";
import Roles from "../../components/Admin/Roles.vue";
import RssFeedPublishers from "../../components/Admin/RssFeedPublishers.vue";
import Sites from "../../components/Admin/Sites.vue";
import SiteThemes from "../../components/Admin/SiteThemes.vue";
import Work from "../../components/Admin/Work.vue";

export default {
  components: {
    CollaborationInquiries,
    CollaborationRequests,
    CollaborationSpaces,
    ContractPackages,
    Contracts,
    ContractTemplates,
    ContractTypes,
    ExpertDashboard,
    FeaturePops,
    Firms,
    Invoices,
    Meetings,
    Organizations,
    Problems,
    Profiles,
    ResearchCommunities,
    Roles,
    RssFeedPublishers,
    Sites,
    SiteThemes,
    Work,
  },
  data() {
    return {
      itemType0: this.$route.params.itemType0,
    };
  },
  watch: {
    $route(to) {
      if (to.params.itemType0 !== this.itemType0) {
        this.itemType0 = to.params.itemType0;
      }
    },
  },
};
</script>
