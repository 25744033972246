<template>
  <ul class="flex flex-wrap justify-start">
    <li v-for="result in results" :key="result.id.raw" class="w-full">
      <SearchResult :result="result" :enums="enums" />
    </li>
  </ul>
</template>

<script>
import SearchResult from "./SearchResult.vue";

export default {
  components: {
    SearchResult,
  },
  props: {
    enums: Object,
    results: {
      type: Array,
      required: true,
    },
  },
};
</script>
