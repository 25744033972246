<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div
        class="relative flex flex-col min-w-0 break-words mt-3 mb-6 shadow-lg rounded-lg bg-white px-6 pb-6 mx-6"
      >
        <h4 class="text-3xl font-light mt-6">
          Data Integrity > Research Expertise
        </h4>

        <div class="mt-5">
          <p>
            Research expertise items with no discipline will automatically be
            excluded from public research expertise pages
            (/expert-areas/[research-expertise-name]). This is to prevent
            interests that are in poor taste or senstitive content.
          </p>

          <div
            v-for="item in researchInterests"
            :key="item.name"
            class="flex mb-5 pb-5 border-b border-blueGray-300"
          >
            <div class="w-1/4">{{ item.count }}</div>
            <div class="w-1/4">{{ item.name }}</div>
            <div class="w-1/2">
              <data-integrity-research-interest
                :enums="enums"
                :item="item"
                @load-research-interests="loadResearchInterests()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import DataIntegrityResearchInterest from "../../components/DataIntegrityResearchInterest.vue";

const { keysToCamel, keysToSnake, loadEnums } = utils;

export default {
  name: "RolePermissions",
  components: {
    DataIntegrityResearchInterest,
  },
  data() {
    return {
      enums: {},
      loading: true,
      researchInterests: [],
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
    };
  },
  mounted() {
    loadEnums(this, {
      RssFeedDiscipline: "rssFeedDisciplineOptions",
    });
  },
  created() {
    this.loadResearchInterests();
  },
  methods: {
    loadResearchInterests() {
      this.loading = true;
      this.$api.axios
        .get(
          `${this.apiBaseUrl}/person/public-profile/research-interest-discipline/`,
        )
        .then((res) => {
          const data = keysToCamel(res.data);
          this.researchInterests = data.results;
        });
    },
    createResearchInterestDiscipline(researchInterest, discipline) {
      this.loading = true;
      this.$api.axios
        .post(
          `${
            this.apiBaseUrl
          }/person/public-profile/research-interest-discipline/${encodeURIComponent(
            researchInterest.name,
          )}/`,
          keysToSnake({ rssFeedDiscipline: discipline.value }),
        )
        .then(() => {
          this.$toast.success(`Added discipline to research expertise.`);
          this.loadResearchInterests();
        });
    },
    deleteResearchInterestDiscipline(researchInterest, discipline) {
      this.loading = true;
      this.$api.axios
        .delete(
          `${
            this.apiBaseUrl
          }/person/public-profile/research-interest-discipline/${encodeURIComponent(
            researchInterest.name,
          )}/${discipline.value}/`,
        )
        .then(() => {
          this.$toast.success(`Removed discipline from research expertise.`);
          this.loadResearchInterests();
        });
    },
  },
};
</script>
