<template>
  <span>
    <span v-for="(clause, index) in phrase" :key="index">
      <span v-if="clause.itemType == 'group'">
        <span
          ><boolean-search
            ref="nested"
            :phrase="clause.items"
            :bool-type="clause.boolType"
        /></span>
        <span v-if="index < phrase.length - 1">{{ boolType }}</span>
      </span>
      <span v-else>
        ({{ clause.selectedField }}: {{ clause.query }})
        <span v-if="index < phrase.length - 1">{{ boolType }}</span>
      </span>
    </span>
  </span>
</template>

<script>
import BooleanSearch from "./BooleanSearch.vue";  // eslint-disable-line

export default {
  components: {},
  props: {
    phrase: Object,
    boolType: String,
  },
  data() {
    return {};
  },
  methods: {
    updateOutput() {
      let output = "";
      this.phrase.forEach((clause, index) => {
        if (
          clause.itemType === "group" &&
          this.$refs.nested &&
          this.$refs.nested[0].updateOutput()
        ) {
          output += this.$refs.nested[0].updateOutput();
          if (index < this.phrase.length - 1) {
            output += ` ${this.boolType} `;
          }
        } else if (clause.itemType === "condition") {
          output += `(${clause.selectedField}: ${clause.query})`;
          if (index < this.phrase.length - 1) {
            output += ` ${this.boolType} `;
          }
        }
      });
      return output;
    },
  },
};
</script>
