<template>
  <div class="px-4 w-full">
    <h3 class="text-3xl text-blueGray-700 font-light my-4">Email Templates</h3>
    <div v-for="template in templates" :key="template.id">
      <div
        class="px-3 py-3 border border-blueGray-200 -mt-[1px] flex justify-between"
      >
        <span>{{ template.name }}</span>
        <span class="cursor-pointer" @click="selectTemplate(template.id, true)"
          >View</span
        >
      </div>
    </div>
    <right-side-modal
      ref="templateDetailModal"
      :showing="false"
      @hide="closeModal()"
    >
      <template #header>
        <h4 class="text-xl font-light">Template Detail</h4>
      </template>
      <template #body>
        <div class="flex flex-wrap px-5 py-5">
          <div class="w-full mb-5">
            <span class="form-label">Template Name</span>
            {{ selectedTemplate.name }}
          </div>
          <div v-for="version in selectedTemplate.versions" :key="version.id">
            <div class="w-full mb-5">
              <span class="form-label">Subject</span>
              {{ version.subject }}
            </div>
            <div class="w-full md:w-1/2 mb-5">
              <span class="form-label">Template HTML</span>
              <iframe
                width="600"
                height="1000"
                :srcdoc="version.htmlContent"
              ></iframe>
            </div>
          </div>
          <div class="w-full mb-5">
            <span class="form-label">Performance</span>
            <div class="flex justify-end">
              <div class="mr-3">
                Start at
                <datepicker
                  v-model="statsStartAt"
                  placeholder="Filter Text"
                  input-class="!py-1"
                  @update="statsStartAt = $event"
                ></datepicker>
              </div>
              <div class="mr-3">
                End at
                <datepicker
                  v-model="statsEndAt"
                  placeholder="Filter Text"
                  input-class="!py-1"
                  @update="statsEndAt = $event"
                ></datepicker>
              </div>
            </div>
            <div class="flex my-5">
              <div
                class="bg-white rounded border border-blueGray-200 px-3 py-2 w-1/4 mr-3"
              >
                <h6 class="text-sm italic">Attempted</h6>
                <h3 class="text-xl">{{ templateStats.attempted }}</h3>
              </div>
              <div
                class="bg-white rounded border border-blueGray-200 px-3 py-2 w-1/4 mr-3"
              >
                <h6 class="text-sm italic">Sent</h6>
                <h3 class="text-xl">{{ templateStats.sent }}</h3>
                <p>
                  ({{
                    Math.floor(
                      (templateStats.sent / templateStats.attempted) * 100,
                    )
                  }}% of attempted)
                </p>
              </div>
              <div
                class="bg-white rounded border border-blueGray-200 px-3 py-2 w-1/4 mr-3"
              >
                <h6 class="text-sm italic">Opened</h6>
                <h3 class="text-xl">{{ templateStats.opened }}</h3>
                <p>
                  ({{
                    Math.floor(
                      (templateStats.opened / templateStats.sent) * 100,
                    )
                  }}% of sent)
                </p>
                <p>
                  ({{
                    Math.floor(
                      (templateStats.opened / templateStats.attempted) * 100,
                    )
                  }}% of attempted)
                </p>
              </div>
              <div
                class="bg-white rounded border border-blueGray-200 px-3 py-2 w-1/4 mr-3"
              >
                <h6 class="text-sm italic">Clicked</h6>
                <h3 class="text-xl">{{ templateStats.clicked }}</h3>
                <p>
                  ({{
                    Math.floor(
                      (templateStats.clicked / templateStats.opened) * 100,
                    )
                  }}% of opened)
                </p>
                <p>
                  ({{
                    Math.floor(
                      (templateStats.clicked / templateStats.sent) * 100,
                    )
                  }}% of sent)
                </p>
                <p>
                  ({{
                    Math.floor(
                      (templateStats.clicked / templateStats.attempted) * 100,
                    )
                  }}% of attempted)
                </p>
              </div>
            </div>
            <div class="mr-3">
              <GChart
                v-if="chartData"
                class="bg-white rounded border border-blueGray-200"
                type="LineChart"
                :data="chartData"
                :options="chartOptions"
              />
            </div>
          </div>
        </div>
      </template>
    </right-side-modal>
  </div>
</template>
<script>
import { utils } from "common-frontend";
import { GChart } from "vue-google-charts";
import Datepicker from "../../components/Datepicker.vue";
import RightSideModal from "../../components/Modals/RightSide.vue";

const { keysToCamel, keysToSnake } = utils;

export default {
  components: {
    Datepicker,
    RightSideModal,
    GChart,
  },
  data() {
    return {
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
      templates: [],
      selectedTemplateId: null,
      selectedTemplate: {},
      loadingStats: false,
      templateStats: {},
      statsStartAt: this.$dayjs()
        .subtract(30, "day")
        .format("YYYY-MM-DD HH:mm:ss"),
      statsEndAt: this.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
      chartData: null,
      chartOptions: {
        chart: {
          title: "Emails Sent",
        },
        legend: {
          position: "bottom",
        },
      },
    };
  },
  watch: {
    statsStartAt() {
      this.loadEmailStats();
    },
    statsEndAt() {
      this.loadEmailStats();
    },
  },
  mounted() {
    this.$api.axios.get(`${this.apiBaseUrl}/email-template/`).then((res) => {
      this.templates = keysToCamel(res.data.results.result);
    });
    if (this.$route.params.id) {
      this.selectTemplate(this.$route.params.id);
    }
  },
  methods: {
    selectTemplate(templateId, changeRoute) {
      this.$api.axios
        .get(`${this.apiBaseUrl}/email-template/${templateId}/`)
        .then((res) => {
          this.selectedTemplateId = templateId;
          this.selectedTemplate = keysToCamel(res.data.result);
          this.loadEmailStats();
          if (changeRoute) {
            this.$router.push({
              name: "AdminEmailTemplatesItem",
              params: {
                id: templateId,
              },
            });
          }
          this.toggleTemplateDetailModal();
        });
    },
    loadEmailStats() {
      this.loadingStats = true;
      this.$api.axios
        .get(
          `${this.apiBaseUrl}/email-template/${this.selectedTemplateId}/stats/`,
          keysToSnake({
            params: {
              startAt: this.$dayjs(this.statsStartAt).format("YYYY-MM-DD"),
              endAt: this.$dayjs(this.statsEndAt).format("YYYY-MM-DD"),
            },
          }),
        )
        .then((res) => {
          this.loadingStats = false;
          this.templateStats = keysToCamel(res.data);
          const chartData = [["Date", "Sends"]];
          Object.keys(this.templateStats.sentOnDates).forEach((day) => {
            chartData.push([
              this.$dayjs(day).toISOString(),
              this.templateStats.sentOnDates[day],
            ]);
          });
          this.chartData = chartData;
        });
    },
    closeModal() {
      this.$router.push({
        name: "AdminEmailTemplates",
      });
    },
    toggleTemplateDetailModal() {
      this.$refs.templateDetailModal.toggleModal();
    },
  },
};
</script>
