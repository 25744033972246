<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Sources of Note
          </h3>
          <p class="mx-5 form-help">Signups in the past 7 days</p>
        </div>
      </div>
    </div>
    <div class="flex px-8">
      <div class="w-2/3 ml-10">
        <section
          ref="iframeHolder"
          class="w-[625px] border-2 border-ceruleanBlue"
        ></section>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";

const { keysToCamel } = utils;

export default {
  name: "SourcesOfNote",
  components: {},
  data() {
    return {
      loading: false,
      templateId: "d-1e21007f959c4d9bbe92f5f706019ba5",
      selectedTemplate: {},
      profileHtml: {},
    };
  },
  watch: {},
  mounted() {
    this.loadTemplate();
    this.loadData();
  },
  methods: {
    loadData() {
      this.$api.axios.get(`/new-profiles/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.profileHtml = data.hitHtml;
        this.computeTemplate();
      });
    },
    computeTemplate() {
      let baseHtml = this.selectedTemplate.versions
        ? this.selectedTemplate.versions[0].htmlContent
        : "";
      baseHtml = baseHtml.replaceAll("{{{ html }}}", this.profileHtml);
      this.loadIntoIframe(baseHtml);
    },
    loadIntoIframe(html) {
      this.$refs.iframeHolder.innerHTML = "";
      const iframe = document.createElement("iframe");
      iframe.setAttribute("style", "height:3000px;width:600px;");
      this.$refs.iframeHolder.appendChild(iframe);
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();
    },
    loadTemplate() {
      this.$api.axios.get(`/email-template/${this.templateId}/`).then((res) => {
        this.selectedTemplate = keysToCamel(res.data.result);
        this.computeTemplate();
      });
    },
  },
};
</script>
