<template>
  <div ref="editor" class="rounded-lg"></div>
</template>

<script>
import { onMounted, ref } from "vue";

import Editor from "@toast-ui/editor";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    height: String,
    toolbarType: String,
  },
  setup(props, { emit }) {
    const editor = ref();

    const height = props.height ? props.height : "500px";
    const val = props.modelValue ? props.modelValue : "";
    const toolbarItems = [];

    if (props.toolbarType && props.toolbarType === "limited") {
      toolbarItems.push(["bold", "italic", "strike", "link"]);
      toolbarItems.push(["ul", "ol"]);
    } else {
      toolbarItems.push(["heading", "bold", "italic", "strike"]);
      toolbarItems.push(["hr", "quote"]);
      toolbarItems.push(["ul", "ol", "task", "indent", "outdent"]);
      toolbarItems.push(["table", "image", "link"]);
    }

    const e = {};

    onMounted(() => {
      e.editor = new Editor({
        el: editor.value,
        height,
        initialValue: val,
        initialEditType: "markdown",
        previewStyle: "tab",
        plugins: [],
        toolbarItems,
        hideModeSwitch: true,
        events: {
          change: () => emit("update:modelValue", e.editor.getMarkdown()),
        },
      });
    });

    return { editor, e };
  },
  data() {
    return {};
  },
  methods: {
    clear() {
      this.e.editor.setHTML("");
    },
    setHTML(newHTML) {
      this.e.editor.setHTML(newHTML);
    },
  },
};
</script>
