<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">
            Expert Newsletter
          </h3>
        </div>
      </div>
    </div>
    <div class="flex px-8">
      <div class="w-1/3 pt-10">
        <h3 class="text-xl">Send a Preview</h3>
        <div class="mt-5 mb-10">
          <span class="form-label">Email Address</span>
          <div class="flex">
            <input
              v-model="previewEmailAddress"
              class="form-text-input"
              type="text"
            />
            <button
              class="rounded-r bg-blueGray-300 px-3"
              @click="sendPreview()"
            >
              Send&nbsp;Preview
            </button>
          </div>
        </div>

        <h3 class="text-xl">Data & Profile</h3>
        <div class="mt-5">
          <span class="form-label">Template ID</span>
          <input v-model="templateId" class="form-text-input" type="text" />
        </div>
        <div class="mt-5">
          <span class="form-label">Issue Date</span>
          <input v-model="issueDate" class="form-text-input" type="text" />
        </div>
        <div class="mt-5">
          <span class="form-label">Next Issue Date</span>
          <input v-model="nextIssueDate" class="form-text-input" type="text" />
        </div>
        <div class="mt-5">
          <span class="form-label">Subject</span>
          <input v-model="subject" class="form-text-input" type="text" />
        </div>
        <div class="mt-5">
          <span class="form-label">Preheader</span>
          <input v-model="preheader" class="form-text-input" type="text" />
        </div>
        <div class="mt-5">
          <span class="form-label">Table of Contents</span>
          <textarea
            v-model="tableOfContents"
            class="form-text-input"
            rows="10"
          ></textarea>
        </div>
        <div class="mt-5">
          <span class="form-label">Intro Content</span>
          <textarea
            v-model="introContent"
            class="form-text-input"
            rows="40"
          ></textarea>
        </div>
        <div class="mt-5">
          <span class="form-label">Main Content</span>
          <textarea
            v-model="mainContent"
            class="form-text-input"
            rows="40"
          ></textarea>
        </div>
        <div class="mt-5">
          <span class="form-label">Featured Collaborations</span>
          <textarea
            v-model="featuredCollaborations"
            class="form-text-input"
            rows="40"
          ></textarea>
        </div>
        <div class="border-2 border-red-500 p-3 mb-10">
          <h3 class="text-xl">Send Newsletter</h3>
          <div>
            <div class="flex justify-end">
              <button
                class="rounded bg-blueGray-300 px-3 py-2"
                @click="sendNewsletter()"
              >
                Send&nbsp;Newsletter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="previewMode == 'one'" class="w-2/3 ml-10">
        <div class="flex justify-between">
          <h3 class="text-xl">Preview One</h3>
          <a
            class="rounded bg-blueGray-300 px-3 py-1"
            href="#"
            @click.prevent="previewMode = 'all'"
            >Switch to Preview All</a
          >
        </div>
        <div class="my-5">
          <span class="form-label">Profile</span>
          <autocomplete-select
            v-model="profileId"
            class="bg-white"
            placeholder="Expert Name"
            id-key="profile.hashedId"
            label-key="fullName"
            :label-function="
              function (expert) {
                return `${expert.firstName} ${expert.lastName} (per-${expert.hashedId})`;
              }
            "
            :filters="{ personType: 'EXPERT' }"
            autocomplete-endpoint="person"
            :initial-options="[]"
            :search-on-focus="true"
            :multiple="false"
            :row="{}"
            @input="selectProfile($event)"
          />
        </div>

        <section
          ref="iframeHolderOne"
          class="w-[810px] border-2 border-ceruleanBlue"
        ></section>
      </div>
      <div v-if="previewMode == 'all'" class="w-2/3 ml-10">
        <div class="flex justify-between">
          <h3 class="text-xl">Preview All</h3>
          <a
            class="rounded bg-blueGray-300 px-3 py-1"
            href="#"
            @click.prevent="previewMode = 'one'"
            >Switch to Preview One</a
          >
        </div>
        <section
          ref="iframeHolderAll"
          class="w-[810px] border-2 border-ceruleanBlue flex flex-wrap"
        ></section>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import { nextTick } from "vue";
import AutocompleteSelect from "../../components/AutocompleteSelect.vue";

const { keysToCamel, keysToSnake } = utils;

export default {
  name: "ExpertNewsletter",
  components: {
    AutocompleteSelect,
  },
  data() {
    return {
      loading: false,
      previewMode: "one",
      previewEmailAddress: "",
      subject: "Intersect: Check out our featured collaborations",
      preheader:
        "Upcoming webinar, featured collaborations, profile health, publications, connection suggestions",
      templateId: "d-53a7b14f29f84eeab073db0ff53157f3",
      selectedTemplate: {},
      profileId: null,
      profileData: {},
      templateReplacementVars: [
        "issueDate",
        "tableOfContents",
        "introContent",
        "mainContent",
        "nextIssueDate",
      ],
      profileReplacementVars: [
        "firstName",
        "referralLink",
        "publicProfileUrl",
        "profileViewString",
        "profileCompletenessString",
        "profileCompletenessTable",
        "profileCompletenessSuggestionString",
        "publications",
        "connectionSuggestions",
      ],
      issueDate: this.$dayjs().format("MMMM D, YYYY"),
      nextIssueDate: this.$dayjs().add(14, "day").format("MMMM D, YYYY"),
      tableOfContents: `<p>In this issue:</p>
        <ul>
          <li>Conferences, webinars, and blog posts</li>
          <li>Featured collaborations</li>
          <li>Your profile health</li>
          <li>Publications you may have missed</li>
          <li>Connection suggestions</li>
        </ul>`,
      introContent: `<p>The NotedSource community is continuing to grow! New experts are joining every day, and the number of collaboration requests is increasing. The NotedSource team is headed on the road to the <a href="https://informaconnect.com/feiusa/about-fei/" target="_blank">Front End of Innovation conference</a>, and we can't wait to see what we learn! Stay tuned as more companies seek out experts for their innovative projects.</p>`,
      mainContent: `<p>Learn more about net-zero research and collaborations by catching our most recent <a href="https://notedsource.io/resources/events/building-and-investing-in-academic-industry-partnerships-0" target="_blank">webinar on-demand</a>. If you're a marketing expert, you don't want to miss the upcoming webinar, "<a href="https://notedsource.io/resources/events/ai-for-innovative-marketing" target="_blank">AI for Innovative Marketing</a>." Plus, explore  shifts in the freelance research economy in our <a href="https://notedsource.io/resources/the-rise-of-the-freelance-researcher-economy" target="_blank">recent blog post</a>.</p>`,
      featuredCollaborations: `
        <p style="margin-bottom: 20px;"><strong>Translating Previous Research for Societal Impact</strong><br>We are seeking academics who are interested in translating previous research into technological innovations with commercial potential and societal impact. The RFP will support projects across all science and engineering disciplines, focusing on proof-of-concept, prototyping, and technology development. Participating students and postdocs will have the opportunity to gain valuable education and leadership training in innovation and entrepreneurship. <a href="mailto:support@notedsource.com?subject=Translating Previous Research for Societal Impact">Submit yourself or someone else</a></p>

        <p style="margin-bottom: 20px;"><strong>Startups and Small Business Owners</strong><br>We are seeking academics who own startups or small businesses that address federal research needs, promote the commercialization of federally funded research outcomes, and contributes to workforce expansion, particularly by supporting socially and economically disadvantaged and women-owned enterprises. <a href="mailto:support@notedsource.com?subject=Startups and Small Business Owners">Submit yourself or someone else</a></p>

        <p style="margin-bottom: 20px;"><strong>Impacts of STEM</strong><br>We are seeking academics with interdisciplinary expertise on the intellectual, material, and social aspects of STEM, with a particular emphasis on interdisciplinary investigations into ethics, equity, governance, and policy considerations. Research questions can range from exploring ethical dimensions such as diversity, equity, inclusion, bias, and transparency to investigating organizational practices and incentives that support ethical STEM research. Key questions addressed can include understanding cross-disciplinary and cross-sectoral variations in ethical norms, identifying effective strategies for promoting ethical research environments, and facilitating the transfer of ethical practices across different research and learning settings. <a href="mailto:support@notedsource.com?subject=Impacts of STEM">Submit yourself or someone else</a></p>

        <p style="margin-bottom: 20px;"><strong>Sustainable Polymers</strong><br>We are seeking academics who have expertise in sustainable polymers. The RFP aims to address pressing global challenges like plastic waste while boosting national competitiveness. This effort aligns with the "CHIPS and Science Act of 2022," emphasizing sustainable chemistry research and education through partnerships between academia, industry, and other entities. By combining cutting-edge chemistry with advanced data analytics, the RFP seeks to develop high-value polymers that can mitigate pollution, reduce waste, and contribute to a healthier planet and more resilient communities. <a href="mailto:support@notedsource.com?subject=Sustainable Polymers">Submit yourself or someone else</a></p>`,
    };
  },
  watch: {
    templateId() {
      this.loadTemplate();
    },
    issueDate() {
      this.computeTemplates();
    },
    nextIssueDate() {
      this.computeTemplates();
    },
    tableOfContents() {
      this.computeTemplates();
    },
    introContent() {
      this.computeTemplates();
    },
    mainContent() {
      this.computeTemplates();
    },
    featuredCollaborations() {
      this.computeTemplates();
    },
    profileData() {
      this.computeTemplates();
    },
    previewMode() {
      nextTick(() => {
        this.computeTemplates();
      });
    },
  },
  mounted() {
    this.loadTemplate();
  },
  methods: {
    selectProfile(event) {
      this.profileId = event;
      this.$api.axios
        .get(`/person/profile/${event.id}/newsletter-data/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.profileData = data;
        });
    },
    mergeProfileData(html, profile) {
      let baseHtml = html.replaceAll("{{ first_name }}", profile.firstName);
      baseHtml = baseHtml.replaceAll(
        "{{{ public_profile_url }}}",
        profile.publicProfileUrl,
      );
      baseHtml = baseHtml.replaceAll("{{ referral_url }}", profile.referralUrl);
      baseHtml = baseHtml.replaceAll(
        "{{{ profile_view_string }}}",
        profile.profileViewString,
      );
      baseHtml = baseHtml.replaceAll(
        "{{ profile_completeness_string }}",
        profile.profileCompletenessString,
      );
      baseHtml = baseHtml.replaceAll(
        "{{{ profile_completeness_table }}}",
        profile.profileCompletenessTable,
      );
      baseHtml = baseHtml.replaceAll(
        "{{{ profile_completeness_suggestion_string }}}",
        profile.profileCompletenessSuggestionString,
      );
      baseHtml = baseHtml.replaceAll(
        "{{{ research_interest_suggestion_string }}}",
        profile.researchInterestSuggestionString,
      );
      baseHtml = baseHtml.replaceAll(
        "{{{ publications_you_missed }}}",
        profile.publicationsYouMissed,
      );
      baseHtml = baseHtml.replaceAll(
        "{{{ connection_suggestions }}}",
        profile.connectionSuggestions,
      );
      return baseHtml;
    },
    computeTemplates() {
      let baseHtml = this.selectedTemplate.versions[0].htmlContent;
      baseHtml = baseHtml.replaceAll("{{ issue_date }}", this.issueDate);
      baseHtml = baseHtml.replaceAll(
        "{{ next_issue_date }}",
        this.nextIssueDate,
      );
      baseHtml = baseHtml.replaceAll(
        "{{{ table_of_contents }}}",
        this.tableOfContents,
      );
      baseHtml = baseHtml.replaceAll(
        "{{{ intro_content }}}",
        this.introContent,
      );
      baseHtml = baseHtml.replaceAll("{{{ main_content }}}", this.mainContent);
      baseHtml = baseHtml.replaceAll(
        "{{{ featured_collaborations }}}",
        this.featuredCollaborations,
      );
      if (this.previewMode === "one") {
        if (Object.keys(this.profileData).length) {
          baseHtml = this.mergeProfileData(baseHtml, this.profileData);
        }
        this.loadIntoIframe(
          this.$refs.iframeHolderOne,
          baseHtml,
          true,
          "800px",
          "3000px",
          "1",
        );
      } else {
        this.$api.axios.get("/person/profile/newsletter-data/").then((resp) => {
          const data = keysToCamel(resp.data);
          const { profileHashedIds } = data;
          profileHashedIds.forEach((hashedId) => {
            this.$api.axios
              .get(`/person/profile/${hashedId}/newsletter-data/`)
              .then((profileResp) => {
                const profileData = keysToCamel(profileResp.data);
                const profileHtml = this.mergeProfileData(
                  baseHtml,
                  profileData,
                );
                this.loadIntoIframe(
                  this.$refs.iframeHolderAll,
                  profileHtml,
                  false,
                  "800px",
                  "1000px",
                  "0.5",
                );
              });
          });
        });
      }
    },
    delay(milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    },
    loadIntoIframe(ref, html, clearFirst, width, height, zoom) {
      if (ref) {
        if (clearFirst) {
          ref.innerHTML = "";
        }
        const iframe = document.createElement("iframe");
        iframe.setAttribute(
          "style",
          `height:${height};width:${width};-moz-transform: scale(${zoom}); -moz-transform-origin: 0 0; -webkit-transform: scale(${zoom}); -webkit-transform-origin: 0 0;`,
        );
        ref.appendChild(iframe);
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(html);
        iframe.contentWindow.document.close();
      }
    },
    loadTemplate() {
      this.$api.axios.get(`/email-template/${this.templateId}/`).then((res) => {
        this.selectedTemplate = keysToCamel(res.data.result);
        this.computeTemplates();
      });
    },
    sendPreview() {
      if (this.profileId.id) {
        this.$api.axios
          .post(
            `/person/profile/${this.profileId.id}/newsletter-data/`,
            keysToSnake({
              emailAddress: this.previewEmailAddress,
              templateId: this.templateId,
              subject: this.subject,
              preheader: this.preheader,
              issueDate: this.issueDate,
              nextIssueDate: this.nextIssueDate,
              tableOfContents: this.tableOfContents,
              introContent: this.introContent,
              mainContent: this.mainContent,
              featuredCollaborations: this.featuredCollaborations,
            }),
          )
          .then(() => {
            this.$toast.success(
              `Your preview has been sent to ${this.previewEmailAddress}.`,
            );
          });
      } else {
        this.$toast.error(
          `Please select a profile to render in the template preview.`,
        );
      }
    },
    sendNewsletter() {
      this.$api.axios
        .post(
          `/person/profile/newsletter-data/`,
          keysToSnake({
            templateId: this.templateId,
            subject: this.subject,
            preheader: this.preheader,
            issueDate: this.issueDate,
            nextIssueDate: this.nextIssueDate,
            tableOfContents: this.tableOfContents,
            introContent: this.introContent,
            mainContent: this.mainContent,
            featuredCollaborations: this.featuredCollaborations,
          }),
        )
        .then(() => {
          this.$toast.success(`The newsletter has been sent.`);
        });
    },
  },
};
</script>
