<template>
  <div>
    <textarea
      v-model="milestone.title"
      class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
      rows="2"
    ></textarea>
    <textarea
      v-model="milestone.description"
      class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
      rows="10"
    ></textarea>
    <label class="flex">
      <span
        class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
        >Deliverables</span
      >
      <div class="w-full md:w-5/6">
        <div
          v-for="deliverable in milestone.deliverables"
          :key="deliverable.hashedId"
        >
          <textarea
            v-model="deliverable.title"
            class="w-full rounded bg-white/30 border-black/20 text-sm px-2 py-1"
            rows="2"
          ></textarea>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { utils } from "common-frontend";

const { keysToCamel, askQuestion } = utils;

export default {
  props: {
    enums: Object,
    activeRequests: Array,
    milestone: Object,
    project: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.fillInTheGaps();
  },
  methods: {
    fillInTheGaps() {
      if (Object.keys(this.milestone).indexOf("description") === -1) {
        this.generateMilestoneDescription();
      }
      if (Object.keys(this.milestone).indexOf("status") === -1) {
        this.milestone.status = "NEW";
      }
    },
    generateMilestoneDescription() {
      askQuestion(
        this,
        "Generating milestone description...",
        `Describe a milestone called "${this.milestone.title}" for a research project called "${this.project.title}"`,
      ).then((resp) => {
        const data = keysToCamel(resp.data);
        this.milestone.description = data.answer.trim();
      });
    },
  },
};
</script>
