<template>
  <div class="flex">
    <litepie-datepicker
      v-model="dateValue"
      as-single
      :formatter="{ date: 'MMM DD YYYY', month: 'MMM' }"
      :class="inputClass || 'py-3'"
    ></litepie-datepicker>
  </div>
</template>

<script>
import { ref } from "vue";
import LitepieDatepicker from "litepie-datepicker-tw3";
import "../assets/styles/litepie-datepicker.css";

export default {
  name: "DatePicker",
  components: {
    LitepieDatepicker,
  },
  props: {
    modelValue: [Date, String, Object],
    inputClass: String,
  },
  data() {
    return {
      dateValue: null,
    };
  },
  watch: {
    dateValue(newValue) {
      if (newValue) {
        this.$emit(
          "update",
          this.$dayjs(newValue)
            .toDate()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
        );
      } else {
        this.$emit("update", "");
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.dateValue = ref(this.$dayjs(this.modelValue).format("MMM DD YYYY"));
    } else {
      this.dateValue = ref("");
    }
  },
};
</script>
