<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-black font-serif mt-1 mx-5">
            RSS Feed Items
          </h3>
        </div>
      </div>
    </div>
    <div class="px-5">
      <div
        v-for="subdiscipline in enums.rssFeedSubdisciplineOptions"
        :key="subdiscipline.value"
        class="px-2 pt-1 mr-3 mb-3 rounded-lg inline-block cursor-pointer bg-blueGray-200 text-sm"
        :class="{
          'bg-blueGray-500 text-white':
            selectedSubdisciplines.indexOf(subdiscipline.value) > -1,
        }"
        @click="toggleSubdiscipline(subdiscipline.value)"
      >
        {{ subdiscipline.label }} ({{ counts[subdiscipline.value] || "--" }})
      </div>
      <hr class="my-10" />
      <div v-for="item in items" :key="item.linkUrl">
        <div class="border border-blueGray-300 bg-white mb-2 px-3 py-2 flex">
          <div
            v-if="item.bannerHash"
            class="shrink-0"
            :style="`width: 300px; height: 300px; background-image: url(${item.banner.unsplashUrlRaw}); background-repeat: none; background-size: cover; background-position: center center;`"
          >
            &nbsp;
            <p class="bg-white">{{ item.bannerKeywords }}</p>
          </div>
          <div class="pl-3">
            <p class="text-xl">{{ item.title }}</p>
            <p class="text-blueGray-400">{{ item.authors }}</p>
            <p>{{ item.feed.journalTitle }}</p>
            <div>
              <div
                v-for="discipline in item.feed.disciplines"
                :key="discipline.discipline.label"
                class="px-2 mr-3 mb-3 rounded-lg inline-block cursor-pointer"
                :style="{
                  'background-color': `#${discipline.discipline.bgColor}`,
                  color: `#${discipline.discipline.textColor}`,
                }"
              >
                {{ discipline.discipline.label }}
              </div>
            </div>
            <div>
              <div
                v-for="subdiscipline in item.feed.subdisciplines"
                :key="subdiscipline.subdiscipline.label"
                class="px-2 mr-3 mb-3 rounded-lg inline-block cursor-pointer bg-blueGray-200"
              >
                {{ subdiscipline.subdiscipline.label }}
              </div>
            </div>
            <p>{{ item.linkUrl }}</p>
            <p>{{ item.publishedAt }}</p>
            <div v-html="item.text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { utils } from "common-frontend";

const { keysToCamel, loadEnums } = utils;

export default {
  data() {
    return {
      enums: {},
      selectedSubdisciplines: [],
      counts: {},
      items: [],
      loading: true,
    };
  },
  mounted() {
    loadEnums(this, {
      RssFeedSubdiscipline: "rssFeedSubdisciplineOptions",
    });
    this.loadCounts();
    this.loadRssFeedItems();
  },
  methods: {
    loadCounts() {
      this.$api.axios.get(`/rss-feed-subdiscipline/counts/`).then((res) => {
        // do not convert to camel
        const { data } = res;
        this.counts = data.results;
      });
    },
    loadRssFeedItems() {
      this.loading = true;
      this.$api.axios
        .get(`/rss-feed-item/for-subdisciplines/`, {
          params: {
            subdisciplines: JSON.stringify(this.selectedSubdisciplines),
          },
        })
        .then((res) => {
          const data = keysToCamel(res.data);
          this.items = data.results;
          this.loading = false;
        });
    },
    toggleSubdiscipline(subdiscipline) {
      const index = this.selectedSubdisciplines.indexOf(subdiscipline);
      if (index === -1) {
        this.selectedSubdisciplines.push(subdiscipline);
      } else {
        this.selectedSubdisciplines.splice(index, 1);
      }
      this.loadRssFeedItems();
    },
  },
};
</script>
