<template>
  <div class="px-4 w-full">
    <h3 class="text-3xl text-blueGray-700 font-light my-4">
      OneSpan Contracts
    </h3>
    <div v-for="pkg in packages" :key="pkg.id" class="border">
      {{ pkg.id }} <button @click="deletePackage(pkg.id)">Delete</button>
    </div>
  </div>
</template>
<script>
import { utils } from "common-frontend";

const { keysToCamel } = utils;

export default {
  data() {
    return {
      packages: [],
    };
  },
  mounted() {
    this.$api.axios.get(`/onespan-contract-packages/`).then((res) => {
      this.packages = keysToCamel(res.data.packages.results);
    });
  },
  methods: {
    deletePackage(id) {
      this.$api.axios.delete(`/onespan-contract-packages/${id}/`);
    },
  },
};
</script>
