<template>
  <v-select
    :id="id"
    v-model="selectedTags"
    :options="options"
    :multiple="multiple"
    :select-on-tab="true"
    :placeholder="placeholder"
    @search="fetchOptions"
    @search:focus="searchFocus"
  >
    <template slot="no-options"> type to search... </template>
    <template slot="option">
      <div class="d-center">
        {{ option.label }}
      </div>
    </template>
    <template slot="selected-option">
      <div class="selected d-center">
        {{ option.label }}
      </div>
    </template>
  </v-select>
</template>

<script>
import axios from "axios";
import { utils } from "common-frontend";

const { keysToCamel, keysToSnake } = utils;

export default {
  name: "AutocompleteSelect",
  props: {
    id: String,
    multiple: Boolean,
    placeholder: String,
    value: [Object, Array],
    labelKey: String,
    labelFunction: Function,
    idKey: String,
    filters: Object,
    autocompleteEndpoint: String,
    minLength: Number,
    optionMetadataKeys: Array,
    searchOnFocus: Boolean,
    initialOptions: Array,
    modelValue: Object,
  },
  emits: ["input"],
  data() {
    return {
      options: this.initialOptions,
      combinedFilters: {},
      debounceTimer: null,
      axiosCancelToken: null,
      apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
    };
  },
  computed: {
    selectedTags: {
      get() {
        return this.modelValue;
      },
      set(selectedTags) {
        this.$emit("input", selectedTags);
      },
    },
  },
  created() {
    if (typeof this.filters !== "undefined") {
      Object.keys(this.filters).forEach((key) => {
        this.combinedFilters[key] = this.filters[key];
      });
    }
  },
  methods: {
    searchFocus() {
      if (this.searchOnFocus) {
        this.fetchOptions("", function _() {});
      }
    },
    fetchOptions(search, loading) {
      if (this.minLength && search.length < this.minLength) {
        return;
      }

      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (this.axiosCancelToken) {
          this.axiosCancelToken.cancel();
        }
        this._fetchOptions(search, loading);
      }, 300);
    },
    _fetchOptions(search, loading) {
      const self = this;
      const lastChildIdKey =
        this.idKey.split(".")[this.idKey.split(".").length - 1];
      const lastChildLabelKey =
        this.labelKey.split(".")[this.labelKey.split(".").length - 1];
      const additionalKeys = this.optionMetadataKeys || [];
      loading(true);
      this.combinedFilters[lastChildLabelKey] = search;
      this.axiosCancelToken = axios.CancelToken.source();
      this.$api.axios
        .get(`${self.apiBaseUrl}/${self.autocompleteEndpoint}/`, {
          params: keysToSnake({
            limit: 10,
            offset: 0,
            sort: JSON.stringify([]),
            filters: JSON.stringify(keysToSnake(this.combinedFilters)),
          }),
          cancelToken: this.axiosCancelToken.token,
        })
        .then((res) => {
          loading(false);
          self.options = keysToCamel(res.data).results.map((i) => {
            let data;
            let id = i[lastChildIdKey];
            if (self.idKey.split(".").length === 2) {
              id = i[self.idKey.split(".")[0]][self.idKey.split(".")[1]];
            }
            if (this.labelFunction) {
              data = { id, label: this.labelFunction(i) };
            } else {
              data = { id, label: i[lastChildLabelKey] };
            }
            additionalKeys.forEach((k) => {
              data[k] = i[k];
            });
            return data;
          });
          this.axiosCancelToken = null;
        })
        .catch(() => {
          loading(false);
        });
    },
  },
};
</script>
