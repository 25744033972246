<template>
  <div
    class="flex w-full justify-between border-b border-blueGray-500 mt-1 py-2"
    @mouseup="getSelectionText()"
  >
    <div class="flex">
      <div class="bg-blueGray-200 rounded-lg px-2 py-1 text-xs mr-3">
        {{ item.count }}
      </div>
      <div>
        {{ item.name }}<br />
        <div class="text-xs text-blueGray-400">
          <context-popper
            text="Convert any experience items with this term to be Nonprofit"
          >
            <a
              class="bg-blueGray-200 px-1 rounded mr-2"
              href="#"
              @click="changeInstituteType('NONPROFIT')"
            >
              Make Nonprofit
            </a>
          </context-popper>
          <context-popper
            text="Convert any experience items with this term to be Commercial"
          >
            <a
              class="bg-blueGray-200 px-1 rounded mr-2"
              href="#"
              @click="changeInstituteType('COMMERCIAL')"
            >
              Make Commercial
            </a>
          </context-popper>
          <context-popper
            text="Convert any experience items with this term to be Other"
          >
            <a
              class="bg-blueGray-200 px-1 rounded mr-2"
              href="#"
              @click="changeInstituteType('OTHER')"
            >
              Make Other
            </a>
          </context-popper>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="flex">
      <div class="flex flex-col">
        <div
          v-for="(suggestion, index) in suggestionsForItem(item)"
          :key="index"
          class="text-right"
        >
          <div
            class="cursor-pointer inline-block rounded-lg px-2 py-1 text-sm mb-1"
            :class="{
              'bg-christi-500 text-white': suggestion.resultType == 'canonical',
              'bg-inch-worm-500': suggestion.resultType == 'alias',
              'bg-lemon-500': suggestion.resultType == 'label',
            }"
            @click="connectGridInstitute(suggestion.instituteId)"
          >
            {{ suggestion.instituteId }} / {{ suggestion.instituteName }}
          </div>
        </div>
        <div class="text-right">
          <input v-model="autocomplete" class="text-sm border-0" type="text" />
        </div>
        <div v-for="(option, index) in options" :key="index" class="text-right">
          <div
            class="cursor-pointer inline-block rounded-lg px-2 py-1 text-sm mb-1"
            :class="{
              'bg-christi-500 text-white': option.resultType == 'canonical',
              'bg-inch-worm-500': option.resultType == 'alias',
              'bg-lemon-500': option.resultType == 'label',
            }"
            @click="connectGridInstitute(option.id)"
          >
            {{ option.id }} / {{ option.label }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <em>Loading</em>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { utils } from "common-frontend";
import ContextPopper from "../ContextPopper.vue";

const { keysToCamel, keysToSnake } = utils;

export default {
  name: "GridInstituteRow",
  components: {
    ContextPopper,
  },
  props: {
    item: Object,
    suggestions: Object,
  },
  data() {
    return {
      loading: false,
      autocomplete: null,
      selected: false,
      options: [],
      debounceTimer: null,
      axiosCancelToken: null,
      minLength: 3,
    };
  },
  watch: {
    autocomplete(newValue) {
      if (!this.selected) {
        this.fetchOptions(newValue);
      } else {
        this.options = [];
      }
    },
  },
  methods: {
    suggestionsForItem(item) {
      return this.suggestions.filter((suggestion) => {
        return (
          suggestion.instituteName?.toLowerCase() === item.name?.toLowerCase()
        );
      });
    },
    getSelectionText() {
      let text = "";
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type !== "Control") {
        text = document.selection.createRange().text;
      }
      this.autocomplete = text;
    },
    connectGridInstitute(gridId) {
      this.loading = true;
      this.$api.axios
        .post(
          `/institute/connect-grid/`,
          keysToSnake({ term: this.item.name, gridId }),
        )
        .then((res) => {
          const data = keysToCamel(res.data);
          this.$toast.success(`Modified ${data.count} records.`);
          this.$emit("changeConnection");
        });
    },
    changeInstituteType(newType) {
      this.loading = true;
      this.$api.axios
        .post(
          `/institute/change-type/`,
          keysToSnake({ term: this.item.name, newType }),
        )
        .then((res) => {
          const data = keysToCamel(res.data);
          this.$toast.success(`Connected ${data.count} records.`);
          this.$emit("changeConnection");
        });
    },
    fetchOptions(search) {
      if (this.minLength && search.length < this.minLength) {
        return;
      }

      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (this.axiosCancelToken) {
          this.axiosCancelToken.cancel();
        }
        this._fetchOptions(search);
      }, 200);
    },
    _fetchOptions(search) {
      const self = this;
      this.axiosCancelToken = axios.CancelToken.source();
      if (search.length > 3) {
        this.$api.axios
          .get(`/institute/autocomplete/`, {
            params: keysToSnake({
              q: search,
            }),
            cancelToken: this.axiosCancelToken.token,
          })
          .then((res) => {
            self.options = keysToCamel(res.data).results;
            this.axiosCancelToken = null;
          });
      } else {
        this.options = [];
      }
    },
  },
};
</script>
