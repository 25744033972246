<template>
  <div class="relative flex flex-col min-w-0 break-words w-full">
    <div class="rounded-t mb-0 pb-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-3xl text-blueGray-700 font-light mt-1 mx-5">Sites</h3>
        </div>
      </div>
    </div>
    <DataTable
      class=""
      :admin-prefix="adminPrefix"
      :api-prefix="apiPrefix"
      :singular-label="singularLabel"
      :plural-label="pluralLabel"
      :columns="columns"
      :create-form="createForm"
      :update-form="updateForm"
      :actions="actions"
      :nested-level="0"
    />
    <right-side-modal ref="editContentModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">Edit Content</h4>
      </template>
      <template #body>
        <edit-content-modal
          v-if="siteSectionHashedId"
          :hashed-id="siteSectionHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>
<script>
import DataTable from "./DataTable.vue";
import RightSideModal from "../Modals/RightSide.vue";
import EditContentModal from "../EditContentModal.vue";

export default {
  name: "Sites",
  components: {
    DataTable,
    RightSideModal,
    EditContentModal,
  },
  data() {
    return {
      singularLabel: "site",
      siteSectionHashedId: null,
      pluralLabel: "sites",
      adminPrefix: "sites",
      apiPrefix: "site",
      columns: {
        hashedId: {
          type: "formattedString",
          label: "Site ID",
          format(row) {
            const els = [];
            els.push(
              `<span class="text-xs uppercase font-mono text-nowrap">SI-${row.hashedId}</span>`,
            );
            return `
              <span class="text-nowrap">
                ${els.join("")}
              </span>
            `;
          },
          sort: {
            allow: false,
          },
          filter: {
            allow: true,
            matchType: "hashedId",
            prefix: "SI-",
          },
        },
        createdAt: {
          type: "absoluteDate",
          label: "Created At",
          sort: {
            allow: true,
            default: "desc",
          },
          filter: {
            allow: false,
            matchType: "dateRange",
            value: "",
            // config: https://chmln.github.io/flatpickr/options/
            config: {
              mode: "range",
              maxDate: "today",
            },
          },
        },
        slug: {
          type: "string",
          label: "URL Slug",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        name: {
          type: "string",
          label: "Site Name",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
        siteType: {
          type: "enum",
          label: "Site Type",
          sort: {
            allow: true,
            default: null,
          },
          filter: {
            allow: true,
            matchType: "wildcard",
            value: "",
          },
        },
      },
      updateForm: {
        hashedId: {
          type: "hidden",
          value: "",
          default: "",
        },
        name: {
          label: "Name:",
          placeholder: "Enter a name for your reference.",
          type: "string",
          value: "",
          default: "",
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter the site URL slug",
          type: "string",
          value: "",
          default: "",
        },
        siteType: {
          label: "Site Type",
          placeholder: "Select site type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/SiteType",
          searchOnFocus: true,
        },
        siteTheme: {
          label: "Site Theme ID",
          placeholder: "Select theme",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "hashedId",
          labelKey: "name",
          autocompleteEndpoint() {
            return `site-theme`;
          },
          searchOnFocus: true,
        },
        fontGrouping: {
          label: "Font Grouping",
          placeholder: "Select font grouping",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FontGrouping",
          searchOnFocus: true,
        },
        colorPalette: {
          label: "Color Palette",
          placeholder: "Select color palette",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/ColorPalette",
          searchOnFocus: true,
        },
        sitePages: {
          label: "Site Pages",
          type: "dataTable",
          value: "",
          singularLabel: "site page",
          pluralLabel: "site pages",
          adminPrefix: "site-pages",
          apiPrefix: "site-page",
          parentKey: "site.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Site Page ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">SP-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "SP-",
              },
            },
            slug: {
              type: "string",
              label: "URL Slug",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            name: {
              type: "string",
              label: "Page Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            siteId: {
              label: "Site ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            name: {
              label: "Name:",
              placeholder: "Enter a name for your reference.",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter the site URL slug",
              type: "string",
              value: "",
              default: "",
            },
            metaTitle: {
              label: "Meta Title:",
              placeholder: "Enter the meta title of this page.",
              type: "string",
              value: "",
              default: "",
            },
            metaDescription: {
              label: "Meta Description:",
              placeholder: "Enter the meta description of this page.",
              type: "text",
              value: "",
              default: "",
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            name: {
              label: "Name:",
              placeholder: "Enter a name for your reference.",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter the site URL slug",
              type: "string",
              value: "",
              default: "",
            },
            sitePageSections: {
              label: "Included Sections",
              type: "dataTable",
              value: "",
              singularLabel: "site page section",
              pluralLabel: "site page sections",
              adminPrefix: "site-page-sections",
              apiPrefix: "site-page-section",
              parentKey: "sitePage.hashed_id",
              createForm: {
                sitePageId: {
                  label: "Site Page ID:",
                  default(row) {
                    return row.hashedId;
                  },
                  type: "hidden",
                  validatorTypes: ["required"],
                },
                siteSection: {
                  label: "Site Section ID",
                  placeholder: "Select section",
                  type: "autocomplete-select",
                  default: [],
                  value: [],
                  validatorTypes: [],
                  idKey: "hashedId",
                  labelKey: "name",
                  autocompleteEndpoint() {
                    return `site-section`;
                  },
                  searchOnFocus: true,
                },
                displayOrder: {
                  label: "Display Order:",
                  placeholder: "Enter integer display order",
                  type: "integer",
                  value: "",
                  default: "",
                },
              },
              updateForm: {
                hashedId: {
                  type: "hidden",
                  value: "",
                  default: "",
                },
                displayOrder: {
                  label: "Display Order:",
                  placeholder: "Enter integer display order",
                  type: "integer",
                  value: "",
                  default: "",
                },
              },
              columns: {
                hashedId: {
                  type: "formattedString",
                  label: "Site Page Section ID",
                  format(row) {
                    const els = [];
                    els.push(
                      `<span class="text-xs uppercase font-mono text-nowrap">SPS-${row.hashedId}</span>`,
                    );
                    return `
                      <span class="text-nowrap">
                        ${els.join("")}
                      </span>
                    `;
                  },
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: true,
                    matchType: "hashedId",
                    prefix: "SPS-",
                  },
                },
                sectionName: {
                  type: "formattedString",
                  label: "Section Name",
                  format(row) {
                    const els = [];
                    els.push(`<span>${row.siteSection.name}</span>`);
                    return `
                      <span class="text-nowrap">
                        ${els.join("")}
                      </span>
                    `;
                  },
                  sort: {
                    allow: true,
                    default: null,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
                displayOrder: {
                  type: "integer",
                  label: "Display Order",
                  sort: {
                    allow: true,
                    default: null,
                  },
                  filter: {
                    allow: false,
                  },
                },
              },
              actions: {
                create: {
                  allow: true,
                },
                update: {
                  allow: true,
                },
                delete: {
                  allow: true,
                },
                menuItems: [],
              },
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
        sitePosts: {
          label: "Site Posts",
          type: "dataTable",
          value: "",
          singularLabel: "site post",
          pluralLabel: "site posts",
          adminPrefix: "site-posts",
          apiPrefix: "site-post",
          parentKey: "site.hashed_id",
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Site Post ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">SPO-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "SPO-",
              },
            },
            title: {
              type: "string",
              label: "Title",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
            slug: {
              type: "string",
              label: "URL Slug",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          createForm: {
            siteId: {
              label: "Site ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            sitePostType: {
              label: "Site Post Type",
              placeholder: "Select site post type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/SitePostType",
              searchOnFocus: true,
            },
            title: {
              label: "Name:",
              placeholder: "Enter a title for this post.",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter the site URL slug",
              type: "string",
              value: "",
              default: "",
            },
            preview: {
              label: "Preview:",
              placeholder: "Enter a preview of this post",
              type: "text",
              value: "",
              default: "",
            },
            content: {
              label: "Content:",
              placeholder: "Enter this content",
              type: "text",
              value: "",
              default: "",
            },
            metaTitle: {
              label: "Meta Title:",
              placeholder: "Enter the meta title of this page.",
              type: "string",
              value: "",
              default: "",
            },
            metaDescription: {
              label: "Meta Description:",
              placeholder: "Enter the meta description of this page.",
              type: "text",
              value: "",
              default: "",
            },
            sitePostPublicationStatus: {
              label: "Site Post Publication Status",
              placeholder: "Select site post publication status",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/SitePostPublicationStatus",
              searchOnFocus: true,
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            sitePostType: {
              label: "Site Post Type",
              placeholder: "Select site post type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/SitePostType",
              searchOnFocus: true,
            },
            title: {
              label: "Name:",
              placeholder: "Enter a title for this post.",
              type: "string",
              value: "",
              default: "",
            },
            slug: {
              label: "Slug:",
              placeholder: "Enter the site URL slug",
              type: "string",
              value: "",
              default: "",
            },
            preview: {
              label: "Preview:",
              placeholder: "Enter a preview of this post",
              type: "text",
              value: "",
              default: "",
            },
            content: {
              label: "Content:",
              placeholder: "Enter this content",
              type: "text",
              value: "",
              default: "",
            },
            metaTitle: {
              label: "Meta Title:",
              placeholder: "Enter the meta title of this page.",
              type: "string",
              value: "",
              default: "",
            },
            metaDescription: {
              label: "Meta Description:",
              placeholder: "Enter the meta description of this page.",
              type: "text",
              value: "",
              default: "",
            },
            sitePostPublicationStatus: {
              label: "Site Post Publication Status",
              placeholder: "Select site post publication status",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/SitePostPublicationStatus",
              searchOnFocus: true,
            },
            sitePageSections: {
              label: "Included Sections",
              type: "dataTable",
              value: "",
              singularLabel: "site page section",
              pluralLabel: "site page sections",
              adminPrefix: "site-page-sections",
              apiPrefix: "site-page-section",
              parentKey: "sitePage.hashed_id",
              createForm: {
                sitePageId: {
                  label: "Site Page ID:",
                  default(row) {
                    return row.hashedId;
                  },
                  type: "hidden",
                  validatorTypes: ["required"],
                },
                siteSection: {
                  label: "Site Section ID",
                  placeholder: "Select section",
                  type: "autocomplete-select",
                  default: [],
                  value: [],
                  validatorTypes: [],
                  idKey: "hashedId",
                  labelKey: "name",
                  autocompleteEndpoint() {
                    return `site-section`;
                  },
                  searchOnFocus: true,
                },
                displayOrder: {
                  label: "Display Order:",
                  placeholder: "Enter integer display order",
                  type: "integer",
                  value: "",
                  default: "",
                },
              },
              updateForm: {
                hashedId: {
                  type: "hidden",
                  value: "",
                  default: "",
                },
                displayOrder: {
                  label: "Display Order:",
                  placeholder: "Enter integer display order",
                  type: "integer",
                  value: "",
                  default: "",
                },
              },
              columns: {
                hashedId: {
                  type: "formattedString",
                  label: "Site Page Section ID",
                  format(row) {
                    const els = [];
                    els.push(
                      `<span class="text-xs uppercase font-mono text-nowrap">SPS-${row.hashedId}</span>`,
                    );
                    return `
                      <span class="text-nowrap">
                        ${els.join("")}
                      </span>
                    `;
                  },
                  sort: {
                    allow: false,
                  },
                  filter: {
                    allow: true,
                    matchType: "hashedId",
                    prefix: "SPS-",
                  },
                },
                sectionName: {
                  type: "formattedString",
                  label: "Section Name",
                  format(row) {
                    const els = [];
                    els.push(`<span>${row.siteSection.name}<span>`);
                    return `
                      <span class="text-nowrap">
                        ${els.join("")}
                      </span>
                    `;
                  },
                  sort: {
                    allow: true,
                    default: null,
                  },
                  filter: {
                    allow: true,
                    matchType: "wildcard",
                    value: "",
                  },
                },
                displayOrder: {
                  type: "integer",
                  label: "Display Order",
                  sort: {
                    allow: true,
                    default: null,
                  },
                  filter: {
                    allow: false,
                  },
                },
              },
              actions: {
                create: {
                  allow: true,
                },
                update: {
                  allow: true,
                },
                delete: {
                  allow: true,
                },
                menuItems: [],
              },
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [],
          },
        },
        siteSections: {
          label: "Site Sections",
          type: "dataTable",
          value: "",
          singularLabel: "site section",
          pluralLabel: "site sections",
          adminPrefix: "site-sections",
          apiPrefix: "site-section",
          parentKey: "site.hashed_id",
          createForm: {
            siteId: {
              label: "Site ID:",
              default(row) {
                return row.hashedId;
              },
              type: "hidden",
              validatorTypes: ["required"],
            },
            name: {
              label: "Name:",
              placeholder: "Enter a name for your reference.",
              type: "string",
              value: "",
              default: "",
            },
            siteSectionType: {
              label: "Site Section Type",
              placeholder: "Select site section type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/SiteSectionType",
              searchOnFocus: true,
            },
            content: {
              label: "Content:",
              placeholder: "Enter content notes",
              type: "text",
              value: "",
              default: "",
            },
          },
          updateForm: {
            hashedId: {
              type: "hidden",
              value: "",
              default: "",
            },
            name: {
              label: "Name:",
              placeholder: "Enter a name for your reference.",
              type: "string",
              value: "",
              default: "",
            },
            siteSectionType: {
              label: "Site Section Type",
              placeholder: "Select site section type",
              type: "autocomplete-select",
              default: [],
              value: [],
              validatorTypes: [],
              idKey: "value",
              labelKey: "label",
              autocompleteEndpoint: "enum/SiteSectionType",
              searchOnFocus: true,
            },
            content: {
              label: "Content:",
              placeholder: "Enter content notes",
              type: "text",
              value: "",
              default: "",
            },
          },
          columns: {
            hashedId: {
              type: "formattedString",
              label: "Site Section ID",
              format(row) {
                const els = [];
                els.push(
                  `<span class="text-xs uppercase font-mono text-nowrap">SS-${row.hashedId}</span>`,
                );
                return `
                  <span class="text-nowrap">
                    ${els.join("")}
                  </span>
                `;
              },
              sort: {
                allow: false,
              },
              filter: {
                allow: true,
                matchType: "hashedId",
                prefix: "SS-",
              },
            },
            name: {
              type: "string",
              label: "Site Section Name",
              sort: {
                allow: true,
                default: null,
              },
              filter: {
                allow: true,
                matchType: "wildcard",
                value: "",
              },
            },
          },
          actions: {
            create: {
              allow: true,
            },
            update: {
              allow: true,
            },
            delete: {
              allow: true,
            },
            menuItems: [
              {
                label: "Edit Content",
                qualifier() {
                  return true;
                },
                action(event, row) {
                  const self = this;
                  return [
                    "executeFunc",
                    function _() {
                      self.vm.loadEditContent(row.hashedId);
                    },
                  ];
                },
                vm: this,
              },
            ],
          },
        },
      },
      createForm: {
        name: {
          label: "Name:",
          placeholder: "Enter a name for your reference.",
          type: "string",
          value: "",
          default: "",
        },
        slug: {
          label: "Slug:",
          placeholder: "Enter the site URL slug",
          type: "string",
          value: "",
          default: "",
        },
        siteType: {
          label: "Site Type",
          placeholder: "Select site type",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/SiteType",
          searchOnFocus: true,
        },
        siteTheme: {
          label: "Site Theme ID",
          placeholder: "Select theme",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "hashedId",
          labelKey: "name",
          autocompleteEndpoint() {
            return `site-theme`;
          },
          searchOnFocus: true,
        },
        fontGrouping: {
          label: "Font Grouping",
          placeholder: "Select font grouping",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/FontGrouping",
          searchOnFocus: true,
        },
        colorPalette: {
          label: "Color Palette",
          placeholder: "Select color palette",
          type: "autocomplete-select",
          default: [],
          value: [],
          validatorTypes: [],
          idKey: "value",
          labelKey: "label",
          autocompleteEndpoint: "enum/ColorPalette",
          searchOnFocus: true,
        },
      },
      actions: {
        create: {
          allow: true,
        },
        update: {
          allow: true,
        },
        delete: {
          allow: false,
        },
      },
    };
  },
  methods: {
    loadEditContent(hashedId) {
      this.siteSectionHashedId = hashedId;
      this.toggleEditContentModal();
    },
    toggleEditContentModal() {
      this.$refs.editContentModal.toggleModal();
    },
  },
};
</script>
