import { createWebHistory, createRouter } from "vue-router";

// layouts

import Auth from "../layouts/Auth.vue";
import Admin from "../layouts/Admin.vue";
import AssemblyLine from "../layouts/AssemblyLine.vue";

// views for Admin layout
import AdminBaseDataTable from "../views/admin/BaseDataTable.vue";

import AdminCollaborationRequestDetail from "../views/admin/CollaborationRequestDetail.vue";
import AdminCollaborationSpaceDemoWizard from "../views/admin/CollaborationSpaceDemoWizard.vue";
import AdminRssFeedDisciplines from "../views/admin/RssFeedDisciplines.vue";
import AdminRssFeedItems from "../views/admin/RssFeedItems.vue";
import AdminRolePermissions from "../views/admin/RolePermissions.vue";
import AdminAiExperiment from "../views/admin/AiExperiment.vue";
import AdminMeetingExperiment from "../views/admin/MeetingExperiment.vue";
import AdminCrawler from "../views/admin/Crawler.vue";
import AdminScraper from "../views/admin/Scraper.vue";
import AdminExpertNewsletter from "../views/admin/ExpertNewsletter.vue";
import AdminCollaboragent from "../views/admin/Collaboragent.vue";
import AdminSourcesOfNote from "../views/admin/SourcesOfNote.vue";
import AdminDataIntegrity from "../views/admin/DataIntegrity.vue";
import AdminDataIntegritySchools from "../views/admin/DataIntegritySchools.vue";
import AdminDataIntegrityResearchInterests from "../views/admin/DataIntegrityResearchInterests.vue";
import AdminPerplexicaExperiment from "../views/admin/PerplexicaExperiment.vue";
import AdminProfileSearch2 from "../views/admin/ProfileSearch2.vue";
import AdminEFeedItemSearch from "../views/admin/eFeedItemSearch.vue";
import AdminEmailTemplates from "../views/admin/EmailTemplates.vue";
import AdminOnespanContracts from "../views/admin/OnespanContracts.vue";
import AdminScheduleMeeting from "../views/admin/ScheduleMeeting.vue";
import Settings from "../views/Settings.vue";
import Stats from "../views/Stats.vue";
import AdminMap from "../views/admin/Map.vue";
import Wrapped from "../views/Wrapped.vue";

import PreviewProfile from "../views/admin/PreviewProfile.vue";

// views for Auth layout
import Login from "../views/auth/Login.vue";
import Logout from "../views/auth/Logout.vue";

// views for AssemblyLine layout
import AssemblyLineDashboard from "../views/assembly-line/Dashboard.vue";

// Dev only - UI example playground
import AdminPlayground from "../views/admin/Playground.vue";

// all routes need names for us to properly push page transitions to PostHog
const routes = [
  {
    name: "Admin",
    path: "/admin",
    component: Admin,
    children: [
      {
        name: "Stats",
        path: "/admin",
        component: Stats,
      },
      {
        name: "Settings",
        path: "/admin/settings",
        component: Settings,
      },
      {
        name: "Wrapped",
        path: "/admin/wrapped",
        component: Wrapped,
      },
      {
        name: "AdminMap",
        path: "/admin/map",
        component: AdminMap,
      },
      {
        name: "AdminCollaborationSpaceDemoWizard",
        path: "/admin/collaboration-spaces/demo-wizard",
        component: AdminCollaborationSpaceDemoWizard,
      },
      {
        name: "AdminCollaborationRequestDetail",
        path: "/admin/collaboration-requests/detail/:hashedId",
        component: AdminCollaborationRequestDetail,
      },
      {
        name: "AdminCollaborationRequestDetailWithRequest",
        path: "/admin/collaboration-requests/detail/:hashedId/profile/:profileHashedId",
        component: AdminCollaborationRequestDetail,
      },
      {
        name: "AdminRolePermissions",
        path: "/admin/role-permissions",
        component: AdminRolePermissions,
      },
      {
        name: "PreviewProfile",
        path: "/admin/preview-profile/:hashedId",
        component: PreviewProfile,
      },
      {
        name: "AdminRssFeedDisciplines",
        path: "/admin/rss-feed-disciplines",
        component: AdminRssFeedDisciplines,
      },
      {
        name: "AdminRssFeedItems",
        path: "/admin/rss-feed-items",
        component: AdminRssFeedItems,
      },
      {
        name: "AdminDataIntegrity",
        path: "/admin/data-integrity",
        component: AdminDataIntegrity,
      },
      {
        name: "AdminCrawler",
        path: "/admin/crawler",
        component: AdminCrawler,
      },
      {
        name: "AdminScraper",
        path: "/admin/scraper",
        component: AdminScraper,
      },
      {
        name: "AdminExpertNewsletter",
        path: "/admin/expert-newsletter",
        component: AdminExpertNewsletter,
      },
      {
        name: "AdminCollaboragent",
        path: "/admin/collaboragent",
        component: AdminCollaboragent,
      },
      {
        name: "AdminSourcesOfNote",
        path: "/admin/sources-of-note",
        component: AdminSourcesOfNote,
      },
      {
        name: "AdminMeetingExperiment",
        path: "/admin/meeting-experiment",
        component: AdminMeetingExperiment,
      },
      {
        name: "AdminPerplexicaExperiment",
        path: "/admin/perplexica-experiment",
        component: AdminPerplexicaExperiment,
      },
      {
        name: "AdminAiExperiment",
        path: "/admin/ai-experiment",
        component: AdminAiExperiment,
      },
      {
        name: "AdminDataIntegritySchools",
        path: "/admin/data-integrity/schools",
        component: AdminDataIntegritySchools,
      },
      {
        name: "AdminDataIntegrityResearchInterests",
        path: "/admin/data-integrity/research-interests",
        component: AdminDataIntegrityResearchInterests,
      },
      {
        name: "AdminProfileSearch2",
        path: "/admin/profile-search-2",
        component: AdminProfileSearch2,
      },
      {
        name: "AdminProfileSearch2WithProfile",
        path: "/admin/profile-search-2/:hashedId",
        component: AdminProfileSearch2,
      },
      {
        name: "AdminEFeedItemSearch",
        path: "/admin/e-feed-item-search",
        component: AdminEFeedItemSearch,
      },
      {
        name: "AdminEmailTemplates",
        path: "/admin/email-templates",
        component: AdminEmailTemplates,
      },
      {
        name: "AdminEmailTemplatesItem",
        path: "/admin/email-templates/:id",
        component: AdminEmailTemplates,
      },
      {
        name: "AdminScheduleMeeting",
        path: "/admin/schedule-meeting",
        component: AdminScheduleMeeting,
      },
      {
        name: "AdminOnespanContracts",
        path: "/admin/onespan-contracts",
        component: AdminOnespanContracts,
      },
      {
        name: "AdminBaseDataTableNestedItem",
        path: "/admin/:itemType0/:action0/:hashedId0/:itemType1/:action1/:hashedId1",
        component: AdminBaseDataTable,
      },
      {
        name: "AdminBaseDataTableCreateNestedItem",
        path: "/admin/:itemType0/:action0/:hashedId0/:itemType1/:action1",
        component: AdminBaseDataTable,
      },
      {
        name: "AdminBaseDataTableItem",
        path: "/admin/:itemType0/:action0/:hashedId0",
        component: AdminBaseDataTable,
      },
      {
        name: "AdminBaseDataTableCreateItem",
        path: "/admin/:itemType0/:action0",
        component: AdminBaseDataTable,
      },
      {
        name: "AdminBaseDataTable",
        path: "/admin/:itemType0",
        component: AdminBaseDataTable,
      },
    ],
  },
  {
    name: "Auth",
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        name: "Login",
        path: "/auth/login",
        component: Login,
      },
      {
        name: "Logout",
        path: "/auth/logout",
        component: Logout,
      },
    ],
  },
  {
    name: "AssemblyLine",
    path: "/assembly-line",
    component: AssemblyLine,
    children: [
      {
        name: "Kanban",
        path: "/assembly-line",
        component: AssemblyLineDashboard,
      },
    ],
  },
  {
    name: "AuthRedirect",
    path: "/",
    redirect: "/auth",
  },
  { name: "GeneralRedirect", path: "/:pathMatch(.*)*", redirect: "/" },
];

if (import.meta.env.MODE === "development") {
  const exampleUIPlaygroundRoute = {
    name: "AdminPlayground",
    path: "/admin/playground",
    component: AdminPlayground,
  };

  routes[0].children.push(exampleUIPlaygroundRoute);
}
// Dev only end

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
