<template>
  <div class="px-3 lg:px-10 overflow-x-hidden">
    <div
      class="container max-w-[1200px] mx-auto flex items-center z-3 relative"
    >
      <div :class="{ 'py-10': !asModal }" class="w-full">
        <div v-if="!asModal" class="w-full flex flex-wrap justify-between">
          <div class="w-full md:w-1/2">
            <h1 class="text-2xl mt-2 font-light mb-3">
              Internal Expert Search
            </h1>
          </div>
          <div class="w-full md:w-1/2 text-right">
            <h6 class="text-xl mt-2 font-light mb-3">
              <a href="https://forms.gle/CEwRwJjwSkBASxMc6" target="_blank"
                >Submit Feedback <i class="fa-solid fa-angle-right"></i
              ></a>
            </h6>
          </div>
        </div>
        <div class="flex flex-wrap mb-40">
          <div class="w-full">
            <div>
              <div
                tabindex="-1"
                class="bg-white/80 flex flex-col flex-wrap p-2 rounded-lg justify-between items-end"
                @focus="searchHasFocus()"
              >
                <div v-if="queryMode == 'simple'" class="p-3 w-full">
                  <p class="text-md mb-3">Text Search</p>
                  <p class="form-help">
                    Search names, headlines, and about. Use quotes to group
                    words into exact phrase matches.
                  </p>
                  <input
                    ref="simpleQuery"
                    v-model="simpleQuery"
                    class="border border-1 border-black/10 focus:border-white px-2 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring focus:ring-chartreuseYellow w-full ease-linear transition-all duration-150"
                    type="text"
                    placeholder="e.g. academic collaboration"
                    @focus="searchHasFocus()"
                  />
                  <button
                    class="text-blueGray-500 underline text-sm mt-3 block"
                    @click="queryMode = 'advanced'"
                  >
                    Switch to Advanced Search
                  </button>
                </div>

                <div v-if="queryMode == 'advanced'" class="p-3 w-full">
                  <p class="text-md mb-3">Advanced Search</p>
                  <p class="form-help">
                    Create your search below using the query builder tool. Use
                    the "[ + ]" to add conditions and condition groups.
                  </p>
                  <query-builder
                    :enums="enums"
                    :subquery="subquery"
                    :journal-title-list="journalTitleList"
                    :research-interest-list="researchInterestList"
                    @search-has-focus="searchHasFocus()"
                  />
                  <p class="form-help pt-3">Your Search:</p>
                  <div
                    class="text-sm py-3 px-4 bg-blueGray-200 text-blueGray-500 rounded-lg font-mono"
                  >
                    <boolean-search
                      ref="booleanSearch"
                      :phrase="subquery.items"
                    />
                  </div>
                  <div
                    class="w-full overflow-y-auto mt-5 mb-3 transition-all duration-150"
                    :class="{
                      'max-h-[500px]': queryShown,
                      'max-h-0': !queryShown,
                    }"
                  >
                    <pre class="text-xs">{{
                      JSON.stringify(assembledQuery, null, 2)
                    }}</pre>
                  </div>
                  <div class="flex items-center mt-3">
                    <button
                      class="text-blueGray-500 underline text-sm block"
                      @click="queryMode = 'simple'"
                    >
                      Switch to Text Search
                    </button>
                    <span class="text-blueGray-300">&nbsp; &bull; &nbsp;</span>
                    <button
                      class="text-blueGray-500 underline text-sm block"
                      @click="queryShown = !queryShown"
                    >
                      Show/Hide Elasticsearch Query
                    </button>
                  </div>
                </div>
                <div class="px-3 my-3 w-full md:hidden">
                  <button
                    class="bg-chartreuseYellow rounded-lg px-3 py-3 uppercase text-xs w-full"
                    @click="scrollToSearch()"
                  >
                    <span v-if="searching">
                      <i
                        class="text-xs fas fa-circle-notch fa-spin text-black"
                      ></i>
                    </span>
                    <span v-else>
                      View {{ searchState?.hits?.total?.value }} Results
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/4 md:pr-5">
            <h6 class="text-sm uppercase mt-10 mb-2">Select Mode</h6>
            <div class="inline-block">
              <div
                class="border border-blueGray-500 rounded flex w-auto text-sm cursor-pointer"
              >
                <p
                  class="px-3 py-1 uppercase rounded-l-sm"
                  :class="{
                    'bg-blueGray-200 text-black': isTestAccount,
                    'text-blueGray-400': !isTestAccount,
                  }"
                  @click="isTestAccount = 1"
                >
                  Test
                </p>
                <p
                  class="px-3 py-1 uppercase rounded-r-sm"
                  :class="{
                    'bg-blueGray-200 text-black': !isTestAccount,
                    'text-blueGray-400': isTestAccount,
                  }"
                  @click="isTestAccount = 0"
                >
                  Live
                </p>
              </div>
            </div>
            <hr class="my-5" />
            <div class="mb-5 items-center">
              <h6 class="text-sm uppercase mb-2">Last login</h6>
              <vue-select
                v-model="duration"
                class="border border-1 border-black/10 focus:border-white px-2 py-1 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm focus:outline-none focus:ring focus:ring-chartreuseYellow ease-linear transition-all duration-150"
                label-by="label"
                value-by="value"
                :close-on-select="true"
                :searchable="true"
                :clear-on-close="true"
                :clear-on-select="true"
                :options="[
                  { label: 'Select...', value: '' },
                  { label: 'Ever', value: 'ever' },
                  { label: 'One Year', value: 'one-year' },
                  { label: 'Six Months', value: 'six-months' },
                  { label: 'Three Months', value: 'three-months' },
                  { label: 'Month', value: 'month' },
                  { label: 'Two Weeks', value: 'two-weeks' },
                  { label: 'Week', value: 'week' },
                  { label: 'Day', value: 'day' },
                ]"
                placeholder="Select..."
              />
            </div>
            <hr class="my-5" />
            <h6
              v-if="
                (searchState.aggregations
                  ? searchState.aggregations[
                      'research_interests.research_interest'
                    ].buckets
                  : []
                ).length > 0
              "
              class="text-sm uppercase mb-5"
            >
              Select Research Expertise
            </h6>
            <div class="mb-10">
              <span
                v-for="(researchInterest, index) in searchState.aggregations
                  ? searchState.aggregations[
                      'research_interests.research_interest'
                    ].buckets
                  : []"
                :key="index"
                class="rounded-lg px-2 py-0 inline-block text-sm mr-1 mb-1 cursor-pointer bg-blueGray-300 truncate max-w-full"
                @click="
                  addFacet(
                    'research_interest',
                    researchInterest.key.toLowerCase(),
                  )
                "
              >
                ({{ researchInterest.doc_count }})
                {{ researchInterest.key.toLowerCase() }}
              </span>
              <span
                v-for="(researchInterest, index) in unaddedResearchInterests"
                :key="index"
                class="rounded-lg px-2 py-0 inline-block text-sm mr-1 mb-1 cursor-pointer bg-blueGray-300 truncate max-w-full"
                @click="
                  addFacet(
                    'research_interest',
                    reseachInterest.value.toLowerCase(),
                  )
                "
              >
                {{ researchInterest.value.toLowerCase() }}
              </span>
            </div>
            <hr class="my-5" />
            <h6
              v-if="
                (searchState.aggregations
                  ? searchState.aggregations['journal_titles.journal_title']
                      .buckets
                  : []
                ).length > 0
              "
              class="text-sm uppercase mb-5"
            >
              Select Journals
            </h6>
            <div class="mb-10">
              <span
                v-for="journalTitle in searchState.aggregations
                  ? searchState.aggregations['journal_titles.journal_title']
                      .buckets
                  : []"
                :key="journalTitle.key"
                class="rounded-lg px-2 py-0 inline-block text-sm mr-1 mb-1 cursor-pointer bg-blueGray-300 truncate max-w-full"
                @click="addFacet('journal_title', journalTitle.key)"
              >
                ({{ journalTitle.doc_count }}) {{ journalTitle.key }}
              </span>
              <span
                v-for="journalTitle in unaddedJournalTitles"
                :key="journalTitle.value"
                class="rounded-lg px-2 py-0 inline-block text-sm mr-1 mb-1 cursor-pointer bg-blueGray-300 truncate max-w-full"
                @click="addFacet('journal_title', journalTitle.value)"
              >
                {{ journalTitle.value }}
              </span>
            </div>
          </div>

          <div ref="searchResults" class="w-full md:w-3/4 mt-10">
            <div v-if="searching" class="py-10 text-center">
              <i
                class="text-3xl fas fa-circle-notch fa-spin text-blueGray-300"
              ></i>
            </div>
            <div v-else-if="!queryIsValid" class="py-10 text-center">
              <p class="form-help">Please ensure your search is valid.</p>
            </div>
            <div v-else-if="searchState.hits" class="p-4">
              <div class="w-full">
                <p>
                  Showing {{ Math.min(1, searchState?.hits?.total?.value) }} to
                  {{
                    Math.min(offset + limit, searchState?.hits?.total?.value)
                  }}
                  of {{ searchState?.hits?.total?.value }} results.
                </p>
              </div>
              <div>
                <ul class="flex flex-wrap justify-start">
                  <li
                    v-for="(hit, index) in searchState?.hits?.hits"
                    :key="index"
                    class="w-full"
                  >
                    <SearchResult
                      :result="hit._source"
                      :hashed-id="hit._id"
                      :score="hit._score"
                      :max-score="searchState.hits?.max_score"
                      :highlight="hit.highlight"
                      @open-profile-detail-modal="
                        openProfileDetailModal($event)
                      "
                    />
                  </li>
                </ul>
                <div v-if="searchState?.hits?.total?.value > 0">
                  <p>
                    Showing
                    {{ Math.min(1, searchState?.hits?.total?.value) }} to
                    {{
                      Math.min(offset + limit, searchState?.hits?.total?.value)
                    }}
                    of {{ searchState?.hits?.total?.value }} results.
                  </p>
                  <button
                    v-if="offset + limit < searchState?.hits?.total?.value"
                    class="bg-blueGray-500 text-white rounded-lg px-3 py-2 my-2"
                    @click="loadMore()"
                  >
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <right-side-modal ref="profileDetailModal" :showing="false">
      <template #header>
        <h4 class="text-xl font-light">Profile Detail</h4>
      </template>
      <template #body>
        <profile-detail-modal
          v-if="profileDetailHashedId"
          :hashed-id="profileDetailHashedId"
        />
      </template>
    </right-side-modal>
  </div>
</template>

<script>
import axios from "axios";
import bqpjs from "bqpjs";
import { nextTick } from "vue";
import { utils } from "common-frontend";
import SearchResult from "./SearchResult.vue";
import QueryBuilder from "./QueryBuilder.vue";
import BooleanSearch from "./BooleanSearch.vue";
import RightSideModal from "../Modals/RightSide.vue";
import ProfileDetailModal from "../Profile/ProfileDetailModal.vue";

const { keysToCamel, scrollIntoViewWithOffset } = utils;

function queryFromTree(tree) {
  if (!tree) {
    return [
      { itemType: "condition", selectedField: "text", query: "", clause: {} },
    ];
  }
  if (tree.type === "term") {
    return [
      {
        itemType: "condition",
        selectedField: "text",
        query: tree.value,
        clause: {},
      },
    ];
  }
  if (tree?.type === "operator") {
    const items = [];
    // default operation is "AND" but should be "OR" -- identify "OR" by operator length
    if (tree.operation === "OR" || tree.position.start === tree.position.end) {
      items.push(queryFromTree(tree.left)[0]);
      items.push(queryFromTree(tree.right)[0]);
      return [{ itemType: "group", boolType: "or", clause: {}, items }];
    }
    if (tree.operation === "AND") {
      items.push(queryFromTree(tree.left)[0]);
      items.push(queryFromTree(tree.right)[0]);
      return [{ itemType: "group", boolType: "and", clause: {}, items }];
    }
  }
  return null;
}

const baseSubquery = {
  clause: {},
  items: [
    {
      itemType: "group",
      boolType: "and",
      clause: {},
      items: [
        {
          itemType: "condition",
          selectedField: "text",
          query: null,
          clause: {},
        },
      ],
    },
  ],
};

export default {
  components: {
    SearchResult,
    QueryBuilder,
    BooleanSearch,
    RightSideModal,
    ProfileDetailModal,
  },
  props: {
    asModal: Boolean,
    presetQuery: String,
  },
  data() {
    return {
      enums: {},
      searching: false,
      searchState: {},
      simpleQuery: "",
      queryMode: "simple", // simple or advanced
      duration: "ever",
      subquery: baseSubquery,
      selectedFacets: {
        "journal_titles.journal_title": [],
        "research_interests.research_interest": [],
      },
      isTestAccount: false,
      limit: 10,
      offset: 0,
      searchTitle: "",
      displayQuery: "",
      queryIsValid: true,
      queryShown: false,
      debounceTimer: null,
      axiosCancelToken: null,
      profileDetailHashedId: null,
      journalTitleList: [],
      researchInterestList: [],
      step: 1,
    };
  },
  computed: {
    searchStartDate() {
      if (this.duration === "day") {
        return this.$dayjs().subtract(1, "day").unix();
      }
      if (this.duration === "week") {
        return this.$dayjs().subtract(7, "day").unix();
      }
      if (this.duration === "two-weeks") {
        return this.$dayjs().subtract(14, "day").unix();
      }
      if (this.duration === "month") {
        return this.$dayjs().subtract(1, "month").unix();
      }
      if (this.duration === "three-months") {
        return this.$dayjs().subtract(3, "month").unix();
      }
      if (this.duration === "six-months") {
        return this.$dayjs().subtract(6, "month").unix();
      }
      if (this.duration === "one-year") {
        return this.$dayjs().subtract(1, "year").unix();
      }
      if (this.duration === "ever") {
        return this.$dayjs().subtract(100, "year").unix();
      }
      return null;
    },
    unaddedJournalTitles() {
      if (
        this.searchState?.facets &&
        this.searchState?.facets["journal_titles.journal_title"] &&
        this.searchState?.facets["journal_titles.journal_title"].length > 0
      ) {
        return this.searchState?.facets[
          "journal_titles.journal_title"
        ][0].data.filter((option) => {
          return (
            this.selectedFacets["journal_titles.journal_title"].indexOf(
              option.value,
            ) === -1
          );
        });
      }
      return [];
    },
    unaddedResearchInterests() {
      if (
        this.searchState?.facets &&
        this.searchState?.facets["research_interests.research_interest"] &&
        this.searchState?.facets["research_interests.research_interest"]
          .length > 0
      ) {
        return this.searchState?.facets[
          "research_interests.research_interest"
        ][0].data.filter((option) => {
          return (
            this.selectedFacets["research_interests.research_interest"].indexOf(
              option.value,
            ) === -1
          );
        });
      }
      return [];
    },
    variableQuery() {
      let customQuery = {};
      if (this.queryMode === "simple") {
        customQuery = {
          should: {
            query_string: {
              query: this.simpleQuery,
              fields: [
                "first_name",
                "last_name",
                "headline",
                "about",
                "research_interests.research_interest",
                "school_name.school_names",
                "company_names.company_name",
                "position_names.position_name",
                "journal_titles.journal_title",
                "educations.specialization",
                "publications.title",
                "publications.publisher",
              ],
            },
          },
          minimum_should_match: 1,
        };
      } else {
        customQuery = this.subquery.clause.bool;
      }
      return customQuery;
    },
    assembledQuery() {
      const query = {
        from: this.offset,
        size: this.limit,
        query: {
          bool: {
            ...this.variableQuery,
            filter: {
              bool: {
                should: [
                  {
                    range: {
                      last_login_at: {
                        gte: this.searchStartDate,
                        lt: this.$dayjs().unix(),
                      },
                    },
                  },
                  {
                    term: {
                      is_test_account: Boolean(this.isTestAccount),
                    },
                  },
                ],
                minimum_should_match: 2,
              },
            },
          },
        },
        aggs: {
          "journal_titles.journal_title": {
            terms: {
              field: "journal_titles.journal_title.keyword",
              size: 20,
            },
          },
          "research_interests.research_interest": {
            terms: {
              field: "research_interests.research_interest.keyword",
              size: 20,
            },
          },
        },
        highlight: {
          fields: {
            headline: {},
            about: {},
            first_name: {},
            last_name: {},
            "research_interests.research_interest": {},
            "school_names.school_name": {},
            "company_names.company_name": {},
            "position_names.position_name": {},
            "journal_titles.journal_title": {},
            "educations.specialization": {},
            "publications.title": {},
            "publications.publisher": {},
          },
        },
      };
      return query;
    },
  },
  watch: {
    duration() {
      nextTick(() => {
        this.getResults();
      });
    },
    isTestAccount() {
      this.getResults();
    },
    simpleQuery(newValue) {
      this.waitSearch();
      try {
        const { tree } = bqpjs(newValue);
        if (tree && tree.type === "operator") {
          this.subquery = { clause: {}, items: queryFromTree(tree) };
        } else {
          this.subquery = {
            clause: {},
            items: [
              {
                itemType: "group",
                boolType: "and",
                clause: {},
                items: queryFromTree(tree),
              },
            ],
          };
        }
      } catch (error) {
        this.subquery = baseSubquery;
      }
    },
    queryMode(newValue) {
      nextTick(() => {
        this.getResults();
        if (newValue === "simple") {
          this.$refs.simpleQuery.focus();
        }
      });
    },
    subquery: {
      handler() {
        this.waitSearch();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadJournalTitles();
    this.loadResearchInterests();
    if (this.presetQuery) {
      this.simpleQuery = this.presetQuery;
      this.getResults();
    }
  },
  methods: {
    waitSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (this.axiosCancelToken) {
          this.axiosCancelToken.cancel();
        }
        this.getResults();
      }, 500);
    },
    getResults(append = false) {
      if (!append) {
        this.offset = 0;
        this.searching = true;
      }
      const url = `https://notedsource-a.ent.us-east1.gcp.elastic-cloud.com/api/as/v1/engines/${
        import.meta.env.VITE_ELASTICSEARCH_PREFIX
      }-researcher-22/elasticsearch/_search`;
      const config = {
        headers: {
          Authorization: "Bearer private-6shxqpgfvfhwa115kofgr32o",
          "Content-Type": "application/json",
        },
      };
      axios
        .post(url, this.assembledQuery, config)
        .then((resp) => {
          if (!append) {
            this.searchState = resp.data;
          } else {
            resp.data.hits?.hits.forEach((hit) => {
              this.searchState?.hits?.hits.push(hit);
            });
          }
          this.queryIsValid = true;
          this.searching = false;
          this.step = this.searchState.hits ? 2 : 1;
        })
        .catch(() => {
          this.queryIsValid = false;
          this.searching = false;
          this.step = 1;
        });
    },
    scrollToSearch() {
      if (this.asModal) {
        this.$emit("scrollToSearch", this.$refs.searchResults);
      } else {
        scrollIntoViewWithOffset(this.$refs.searchResults, 100, window);
      }
    },
    addFacet(key, facet) {
      this.queryMode = "advanced";
      this.subquery.items[0].items.push({
        itemType: "condition",
        selectedField: key,
        query: facet,
        clause: {},
      });
    },
    loadJournalTitles() {
      axios
        .get(`${import.meta.env.VITE_API_BASE_URL}/rss-feed-journal-titles/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.journalTitleList = data.results;
        });
    },
    loadResearchInterests() {
      axios
        .get(`${import.meta.env.VITE_API_BASE_URL}/research-interests/`)
        .then((resp) => {
          const data = keysToCamel(resp.data);
          this.researchInterestList = data.results;
        });
    },
    showSaveSearchModal() {
      this.$refs.saveSearchModal.showModal();
      if (this.queryMode === "simple") {
        this.searchTitle = this.simpleQuery;
        this.displayQuery = this.simpleQuery;
      } else {
        this.searchTitle = this.$refs.booleanSearch.updateOutput();
        this.displayQuery = this.$refs.booleanSearch.updateOutput();
      }
    },
    searchHasFocus() {
      this.step = 1;
    },
    loadMore() {
      this.offset += this.limit;
      this.getResults(true);
    },
    openProfileDetailModal(hashedId) {
      this.profileDetailHashedId = hashedId;
      this.$refs.profileDetailModal.toggleModal();
    },
  },
};
</script>
