<template>
  <div
    class="bg-chartreuseYellow-100 rounded-lg flex items-start px-3 py-2 w-full"
  >
    <div v-if="Object.keys(collaborationSpace).length > 0" class="w-full mr-3">
      <label class="flex my-3">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
          >Name</span
        >
        <div class="w-full md:w-5/6 mb-3">
          <input
            v-model="collaborationSpace.name"
            class="rounded w-full bg-white/30 border-black/20 text-sm px-2 py-1"
            type="text"
          />
        </div>
      </label>
      <label class="flex my-3">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
          >Logo</span
        >
        <div class="w-full md:w-5/6 mb-3 flex items-start">
          <img
            :src="collaborationSpace.logoUrl"
            class="w-[100px] h-[100px] rounded-full"
          />
          <button @click="getLogo()"><i class="fa-solid fa-rotate"></i></button>
        </div>
      </label>
      <label class="flex my-3">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
        >
          Collaborators<br />
        </span>
        <div class="w-full md:w-5/6 border border-black/10 rounded-lg pl-2">
          <div class="flex">
            <div class="grow">
              <div class="flex flex-wrap">
                <div
                  v-for="(
                    person, personIndex
                  ) in collaborationSpace.collaborators"
                  :key="personIndex"
                  class="pt-2 pr-2 w-1/2"
                >
                  <demo-person
                    :active-requests="activeRequests"
                    :person="person"
                    @setPerson="
                      collaborationSpace.collaborators[personIndex] = $event
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="pt-2 pb-2">
            <button
              class="bg-blueGray-500 rounded px-3 py-1 text-white"
              @click="addCollaborator()"
            >
              Add Collaborator
            </button>
          </div>
        </div>
      </label>
      <label class="flex">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
        >
          Collaboration Requests<br />
        </span>
        <div class="w-full md:w-5/6 border border-black/10 rounded-lg p-2">
          <div
            v-for="(
              collaborationRequest, requestIndex
            ) in collaborationSpace.collaborationRequests"
            :key="requestIndex"
            class="bg-chartreuseYellow-200 rounded-lg"
          >
            <demo-collaboration-request
              :enums="enums"
              :index="requestIndex"
              :company-size="companySize"
              :company-stage="companyStage"
              :industry="industry"
              :job-function="jobFunction"
              :active-requests="activeRequests"
              :collaboration-request="collaborationRequest"
            />
          </div>
          <button
            class="bg-blueGray-500 rounded px-3 py-1 text-white"
            @click="addCollaborationRequest()"
          >
            Add Collaboration Request
          </button>
        </div>
      </label>
      <label class="flex">
        <span
          class="w-full md:w-1/6 text-right mr-5 mt-2 uppercase text-black/50 text-sm"
        >
          Projects<br />
        </span>
        <div class="w-full md:w-5/6 border border-black/10 rounded-lg p-3">
          <p class="text-sm italic">
            Select a collaboration request to create its project.
          </p>
          <div
            v-for="(
              collaborationRequest, requestIndex
            ) in collaborationSpace.collaborationRequests"
            :key="requestIndex"
          >
            <div
              v-if="
                collaborationSpace.projects.length == 0 ||
                collaborationSpace.projects
                  .map((project) => {
                    return project.title;
                  })
                  .indexOf(collaborationRequest.title) === -1
              "
              class="border border-black/10 rounded flex items-center p-3 my-2 justify-between"
            >
              <p class="text-sm mr-5">{{ collaborationRequest.title }}</p>
              <button
                class="bg-blueGray-500 rounded px-3 py-1 text-white text-sm"
                @click.prevent.stop="addProject(requestIndex)"
              >
                Select
              </button>
            </div>
          </div>
          <div v-if="collaborationSpace.projects.length > 0">
            <div
              v-for="(project, projectIndex) in collaborationSpace.projects"
              :key="projectIndex"
              class="bg-chartreuseYellow-200 rounded-lg"
            >
              <demo-project
                :enums="enums"
                :company-size="companySize"
                :company-stage="companyStage"
                :industry="industry"
                :job-function="jobFunction"
                :active-requests="activeRequests"
                :project="project"
              />
            </div>
          </div>
        </div>
      </label>
    </div>
    <button @click="reload()"><i class="fa-solid fa-rotate"></i></button>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { utils } from "common-frontend";
import DemoPerson from "./Person.vue";
import DemoCollaborationRequest from "./CollaborationRequest.vue";
import DemoProject from "./Project.vue";

const { keysToCamel, pluckEnum } = utils;

export default {
  components: {
    DemoPerson,
    DemoCollaborationRequest,
    DemoProject,
  },
  props: {
    enums: Object,
    index: Number,
    companySize: String,
    companyStage: String,
    industry: String,
    jobFunction: String,
    companyName: String,
    collaborationSpace: Object,
    activeRequests: Array,
  },
  data() {
    return {};
  },
  mounted() {
    this.fillInTheGaps();
  },
  methods: {
    fillInTheGaps() {
      if (
        Object.keys(this.collaborationSpace).indexOf("name") === -1 ||
        !this.collaborationSpace.name
      ) {
        const jobFunctionLabel = pluckEnum(
          this.enums.jobFunctionOptions,
          this.jobFunction,
        ).label;
        this.collaborationSpace.name = `${this.companyName} ${jobFunctionLabel} Team`;
      }
      if (
        Object.keys(this.collaborationSpace).indexOf("collaborators") === -1
      ) {
        this.collaborationSpace.collaborators = [{}];
      }
      if (
        Object.keys(this.collaborationSpace).indexOf(
          "collaborationRequests",
        ) === -1
      ) {
        this.collaborationSpace.collaborationRequests = [{}, {}];
      }
      if (Object.keys(this.collaborationSpace).indexOf("projects") === -1) {
        this.collaborationSpace.projects = [];
      }
      if (Object.keys(this.collaborationSpace).indexOf("logoUrl") === -1) {
        this.getLogo();
      }
    },
    addCollaborator() {
      this.collaborationSpace.collaborators.push({});
    },
    removeCollaborator(index) {
      this.collaborationSpace.collaborators.splice(index, 1);
    },
    addCollaborationRequest() {
      this.collaborationSpace.collaborationRequests.push({});
    },
    removeCollaborationRequest(index) {
      this.collaborationSpace.collaborationRequests.splice(index, 1);
    },
    addProject(collaborationRequestIndex) {
      if (
        this.collaborationSpace.collaborationRequests.length >=
        collaborationRequestIndex + 1
      ) {
        this.collaborationSpace.projects.push({
          title:
            this.collaborationSpace.collaborationRequests[
              collaborationRequestIndex
            ].title,
          description:
            this.collaborationSpace.collaborationRequests[
              collaborationRequestIndex
            ].description,
        });
      }
    },
    getLogo() {
      this.$api.axiosThrottled.post(`/get-logo/`).then((resp) => {
        const data = keysToCamel(resp.data);
        this.collaborationSpace.logoUrl = data.result;
      });
    },
    reload() {
      this.$emit("reloadCollaborationSpace", {});
      nextTick(() => {
        this.fillInTheGaps();
      });
    },
  },
};
</script>
