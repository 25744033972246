<template>
  <div class="bg-blueGray-50 text-blueGray-500 border border-blueGray-300">
    <div
      v-for="revisionGroup in revisionGroups"
      :key="revisionGroup.hashedId"
      class="m-3 mx-4"
    >
      <div class="flex justify-between">
        <div>
          <moment-from-now
            v-if="revisionGroup.revisedAt"
            :title="revisionGroup.revisedAt"
            :value="revisionGroup.revisedAt"
            :interval="5000"
            :tag="'span'"
            class="uppercase text-sm"
          />
          <span class="text-xs font-light ml-2">{{
            revisionGroup.revisedAt
          }}</span>
        </div>
        <div class="uppercase text-sm text-blueGray-500">
          <span v-if="revisionGroup.city">{{ revisionGroup.city }}, </span>
          <span v-if="revisionGroup.region">{{ revisionGroup.region }}, </span>
          <span v-if="revisionGroup.country">{{ revisionGroup.country }}</span>
        </div>
      </div>
      <div
        v-for="revision in revisionGroup.revisions"
        :key="revision.hashedId"
        class="border-0 py-2 px-3 text-blueGray-600 bg-white rounded text-sm shadow mb-2 flex justify-between"
      >
        <div>
          <div>
            {{ revisionGroup.author.firstName }}
            {{ revisionGroup.author.lastName }}
            {{ revision.revisionType.label }}d
            {{
              revisionHistory.metadata.idMap[revision.association.hashedId]
                .modelClassName
            }}
            {{
              revisionHistory.metadata.idMap[revision.association.hashedId].id
            }}.
          </div>
        </div>
        <div class="text-blueGray-400">
          <div
            v-for="fieldChange in revision.fieldChanges"
            :key="fieldChange.hashedId"
            class="flex items-center"
          >
            <div class="md:w-[200px] text-right">
              <span class="uppercase font-light text-xs mr-3"
                >{{ fieldChange.key }}:</span
              >
            </div>
            <div class="md:w-[200px] text-left">
              {{ fieldChange.newValue }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utils } from "common-frontend";
import MomentFromNow from "./MomentFromNow.vue";

const { keysToCamel, keysToSnake } = utils;

export default {
  components: {
    MomentFromNow,
  },
  props: {
    hashedId: String,
    parentRow: Object,
    modelClassName: String,
    childKeys: Array,
    limit: Number,
  },
  data() {
    return {
      revisionHistory: {
        loading: false,
        results: [],
        sort: [],
        filters: {},
        offset: 0,
        limit: 20,
        totalCount: 0,
        metadata: {},
      },
      revisionGroups: [],
      queriedItems: [],
    };
  },
  watch: {
    "revisionHistory.results": {
      handler(newValue) {
        const revisionGroups = [];
        newValue.forEach((revision) => {
          let thisGroup = revisionGroups.find((group) => {
            return group.hashedId === revision.revisionGroup.hashedId;
          });
          if (!thisGroup) {
            revisionGroups.push({ ...revision.revisionGroup });
            thisGroup = revisionGroups[revisionGroups.length - 1];
            thisGroup.revisions = [];
          }
          const simpleRev = { ...revision };
          delete simpleRev.revisionGroup;
          thisGroup.revisions.push(simpleRev);
        });
        this.revisionGroups = revisionGroups;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.limit) {
      this.revisionHistory.limit = this.limit;
    }

    this.queriedItems.push({
      modelClassName: this.modelClassName,
      hashedId: this.hashedId,
    });
    this.childKeys.forEach((childKey) => {
      this.parentRow[childKey.relationship].forEach((childItem) => {
        this.queriedItems.push({
          modelClassName: childKey.modelClassName,
          hashedId: childItem.hashedId,
        });
      });
    });
    this.getData();
  },
  methods: {
    getData(
      limit = this.revisionHistory.limit,
      offset = this.revisionHistory.offset,
      sort = this.revisionHistory.sort,
      filters = this.revisionHistory.filters,
    ) {
      this.$api.axios
        .post(
          "/revision/",
          keysToSnake({
            items: this.queriedItems,
          }),
          {
            params: keysToSnake({
              limit,
              offset,
              sort: JSON.stringify(keysToSnake(sort)),
              filters: JSON.stringify(keysToSnake(filters)),
            }),
          },
        )
        .then((res) => {
          const data = keysToCamel(res.data);
          this.revisionHistory = data;
        })
        .catch(() => {
          this.revisionHistory.loading = false;
        });
    },
  },
};
</script>
