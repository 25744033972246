<template>
  <div class="px-4 w-full">
    <h3 class="text-3xl text-blueGray-700 font-light my-4">
      RSS Feed Disciplines
    </h3>
    <div
      v-for="discipline in enums.rssFeedDisciplineOptions"
      :key="discipline.label"
      class="px-2 pt-1 mr-3 mb-3 rounded-lg inline-block cursor-pointer"
      :style="{
        'background-color': `#${discipline.bgColor}`,
        color: `#${discipline.textColor}`,
      }"
      @click="scrollTo(discipline.label)"
    >
      {{ discipline.label }} ({{ subdisciplineCount(discipline.label) }})
    </div>
    <hr class="my-10" />
    <div
      v-for="result in distinctCategories"
      :key="`${result.discipline.name}-${result.subdiscipline?.name}`"
    >
      <div :ref="result.discipline.name" class="block">
        <div
          class="px-2 pt-1 rounded-lg inline-block"
          :style="{
            'background-color': `#${result.discipline.bgColor}`,
            color: `#${result.discipline.textColor}`,
          }"
        >
          {{ result.discipline.name }}
        </div>
        <span class="mx-3"> > </span>
        <div class="px-2 pt-1 rounded-lg inline-block bg-blueGray-200">
          {{ result.subdiscipline?.name }} ({{ result.count }})
        </div>
      </div>
      <div class="text-sm flex flex-wrap ml-10 mt-2 mb-4">
        <div
          v-for="feed in result.feeds"
          :key="feed.journalTitle"
          class="w-1/4"
        >
          {{ feed.journalTitle }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { utils } from "common-frontend";

const { keysToCamel, loadEnums } = utils;

export default {
  data() {
    return {
      enums: {},
      distinctCategories: [],
      loading: true,
    };
  },
  mounted() {
    loadEnums(this, {
      RssFeedDiscipline: "rssFeedDisciplineOptions",
      RssFeedSubdiscipline: "rssFeedSubdisciplineOptions",
    });
    this.loadRssFeedCategories();
  },
  methods: {
    loadRssFeedCategories() {
      this.loading = true;
      this.$api.axios.get(`/rss-feed-categorization/`).then((res) => {
        const data = keysToCamel(res.data);
        this.distinctCategories = data.results;
        this.loading = false;
      });
    },
    scrollTo(refName) {
      const elements = this.$refs[refName];
      if (elements && elements.length > 0) {
        const top = elements[0].offsetTop;
        window.scrollTo(0, top);
      }
    },
    subdisciplineCount(discipline) {
      return this.distinctCategories.filter((category) => {
        return category.discipline.name === discipline;
      }).length;
    },
  },
};
</script>
